<template>
    <div v-if="form.errors.has('form')" class="rounded-md bg-red-50 p-4 mb-4">
        <div class="flex items-start">
            <div class="flex-shrink-0">
                <!-- Heroicon name: x-circle -->
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
                </svg>
            </div>
            <div class="ml-3 flex-1">
                <h3 class="text-sm leading-5 font-medium text-red-800">
                    {{ form.errors.get('form') }}
                </h3>
            </div>
            <div class="flex-shrink-0 w-4 h-4 text-gray-300 cursor-pointer"
                 @click.prevent="form.errors.forget()">
                <x-icon/>
            </div>
        </div>
    </div>
</template>

<script>
import XIcon from "../../svgs/general/XIcon"

export default {
    name: 'FormError',

    components: {
        XIcon
    },

    props: {
        form: {
            type: Object,
        }
    },

}
</script>
