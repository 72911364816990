<template>
    <button class="inline-flex border border-gray-50 items-center text-xs font-semibold rounded-full py-0.5 pr-0.5 pl-2 ml-4 ">
        {{ name }}
        <span class="rounded-full inline-block h-4 w-4 p-0.5 ml-1"
              :class="circleClass">
            <x-icon stroke="3"/>
        </span>
    </button>
</template>

<script>
import XIcon from "../../svgs/general/XIcon"

export default {
    name: 'FilterBadge',

    components: {
        XIcon
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        circleClass: {
            type: String,
            default: 'bg-gray-100',
        }
    }
}
</script>