<template>
    <nav>
        <ul class="overflow-hidden">

            <template v-for="(step, index) in steps" :key="step.id">
                <vertical-step :step="step"
                               :line-active="lineActive"
                               :previous-step="previousStep(index)"
                               :lastStep="isLastStep(index)"/>
            </template>

        </ul>
    </nav>
</template>

<script>
import VerticalStep from "./VerticalStep"

export default {
    name: 'VerticalSteps',

    components: {
        VerticalStep
    },

    props: {
        steps: {
            type: Array,
            required: true,
        }
    },

    methods: {
        isLastStep(index) {
            return index === _.findLastIndex(this.steps)
        },

        previousStep(index) {
            if (index === 0) {
                return {}
            }

            let previousStepIndex = index - 1;
            return this.steps[previousStepIndex];
        }
    },

    computed: {
        lineActive() {
            return false;

            // Leave in in case we have the line change based on the status
            return _.head(this.steps).status === 'active'
        }
    }
}
</script>
