<template>
    <div class="flex flex-col h-full">
        <vertical-steps :steps="steps"/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import VerticalSteps from "../../../elements/steps/VerticalSteps"

export default {
    name: 'LegacyFlowSteps',

    components: {VerticalSteps},

    methods: {
        triggerIcon() {
            return _.get(this.flow, 'trigger.icon', 'trigger')
        },

        webhookSteps() {
            let state = _.get(this.flow, 'trigger.state');
            switch (state) {
                case 'new':
                    return [{
                        name: 'Waiting for webhook',
                        path: this.flow.views.trigger,
                    }];
                case 'received':
                    let confirmed = _.get(this.flow, 'settings.confirmed', false)
                    return [{
                        name: confirmed ? 'Trigger fields' : 'Webhook Received',
                        path: this.flow.views.trigger,
                    }];
            }
        },

        emailSteps() {
            let state = _.get(this.flow, 'trigger.state');
            switch (state) {
                case 'created':
                    return [{
                        name: 'Select a Mailbox',
                        path: `${this.flow.views.trigger}/mailbox`,
                    }];
                case 'waiting':
                    return [{
                        name: 'Mailbox',
                        path: `${this.flow.views.trigger}/mailbox`,
                    }, {
                        name: 'Waiting for email',
                        path: `${this.flow.views.trigger}/fields`,
                    }]
                case 'received':
                    return [{
                        name: 'Mailbox',
                        path: `${this.flow.views.trigger}/mailbox`,
                    }, {
                        name: 'Create Trigger Fields',
                        path: `${this.flow.views.trigger}/fields`,
                    }]
                case 'has_field':
                case 'inactive':
                case 'active':
                    return [{
                        name: 'Change Mailbox',
                        path: `${this.flow.views.trigger}/mailbox`,
                    }, {
                        name: 'Trigger Fields',
                        path: `${this.flow.views.trigger}/fields`,
                    }]
            }
        },

        actionStep(action) {
            let id = `action_${action.id}`
            let name = action.name;
            let status = 'current';
            let path = action.views.edit;
            let icon = _.get(action, 'app.icon', 'action');
            let steps = [];

            let showAuth = this.$store.getters['apps/showAuthentication'](action)

            if (showAuth) {
                steps.push({
                    id: `${action.id}_auth`,
                    name: 'Authentication',
                    path: action.views.edit + '/auth'
                })
            }

            _.each(action.events, event => {
                steps.push({
                    id: event.id,
                    name: event.name,
                    path: event.views.edit,
                })
            })

            if ((!showAuth || action.auth) && _.size(action.events) === 0) {
                let primaryStep = this.$store.getters["apps/legacyPrimaryStep"](action.app.key)
                steps.push({
                    id: `${action.id}_create`,
                    name: primaryStep.name,
                    path: action.views.edit + '/events/create'
                })
            }

            if (_.size(action.events) > 0) {
                status = 'complete';

                if (action.active) {
                    status = 'active'
                }
            }

            return {
                id,
                icon,
                status,
                name,
                path,
                steps,
            }
        },
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        triggerStatus() {
            let status = 'current';

            if (_.get(this.flow, 'trigger.type') === 'clickbank') {
                if (!_.has(this.flow, 'settings.clickbank_secret_key')) {
                    return 'incomplete';
                }
            }

            if (_.size(_.get(this.flow, 'trigger.fields')) > 0) {
                status = 'complete';

                if (_.get(this.flow, 'active', false)) {
                    status = 'active';
                }

            }

            return status;
        },

        triggerStep() {
            let id = Date.now()
            let name = 'Flow Trigger';
            let path = '/flows/legacy/create'
            let status = this.triggerStatus;
            let icon = this.triggerIcon();

            let steps = [
                {
                    name: 'Choose Trigger',
                    path
                }
            ];

            if (_.get(this.flow, 'id')) {
                id = `flow_${this.flow.id}`;
                path = this.flow.views.trigger
            }

            if (_.get(this.flow, 'type') === 'webhook') {
                name = 'Webhook Trigger';
                steps = this.webhookSteps();
                if (_.get(this.flow, 'trigger.type') === 'clickbank') {
                    name = 'ClickBank Trigger'
                    steps = [{
                        name: 'Settings',
                        path: this.flow.views.trigger,
                    }];
                }
            }

            if (_.get(this.flow, 'type') === 'email') {
                name = 'Email Trigger';
                steps = this.emailSteps();
            }

            return {
                id,
                status,
                icon,
                name,
                path,
                steps,
            }
        },

        steps() {
            let steps = [];
            steps.push(this.triggerStep);

            _.each(this.flow.actions, action => {
                let step = this.actionStep(action);
                steps.push(step);
            })

            if (this.$route.params.panel === 'actions' && this.$route.params.panelId === 'new') {
                steps.push({
                    id: 'new',
                    icon: 'action',
                    status: 'current',
                    name: 'New Action',
                    path: this.$route.path,
                    steps: [
                        {
                            name: 'Choose App',
                            path: this.$route.path
                        }
                    ]
                })
            }

            return steps;
        }
    },
}
</script>
