<template>
    <div>

        <flow-step v-if="showAuthentication"
                   name="Authentication"
                   :open="openPath('auth')"
                   :center-content="true"
                   :name-editable="false"
                   :icon="authIcon"
                   :last="action.auth === null"
                   :center-icon="action.app.icon"
                   :close="action.views.edit">
            <template #content>
                <legacy-authentication :action="action" :redirect="openPath(redirectAfterAuth)"/>
            </template>
        </flow-step>

        <flow-step v-if="showPrimaryStep"
                   :name="primaryStep.name"
                   :open="openPath('create')"
                   :last="true"
                   :icon="primaryStep.icon"
                   :name-editable="false"
                   :close="action.views.edit">
            <template v-if="primaryStep" #content>
                <component :is="primaryComponent" :action="action"/>
            </template>

        </flow-step>

        <template v-if="!showPrimaryStep" v-for="event in action.events" :key="event.id">
            <legacy-event :event="event"
                          :action="action"
                          :show-menu="primaryStep.key !== event.key"
                          :last="lastEvent(event.id)"/>
        </template>

    </div>
</template>

<script>
import LegacyEvent from "./LegacyEvent"
import FlowStep from "../components/FlowStep"
import LegacyAuthentication from "./partials/LegacyAuthentication"
import {defineAsyncComponent} from "vue"

export default {
    name: 'LegacyEvents',

    components: {
        FlowStep,
        LegacyEvent,
        LegacyAuthentication,
    },

    props: {
        action: {
            type: Object,
        }
    },

    data() {
        return {
            authIcon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg>',
        }
    },

    methods: {
        openPath(eventId) {
            if (eventId === 'auth') {
                return `${this.action.views.edit}/${eventId}`
            }

            return `${this.action.views.edit}/events/${eventId}`
        },

        lastEvent(eventId) {
            const lastEvent = _.findLast(this.action.events);
            return lastEvent.id === eventId;
        },

    },

    computed: {

        showAuthentication() {
            return this.$store.getters['apps/showAuthentication'](this.action)
        },

        redirectAfterAuth() {
            if (_.size(this.action.events) === 0) {
                return 'create'
            }

            let firstEvent = _.find(this.action.events)

            return firstEvent.id;
        },

        showPrimaryStep() {
            return (!this.showAuthentication || this.action.auth) && _.size(this.action.events) === 0;
        },

        primaryStep() {
            return this.$store.getters["apps/legacyPrimaryStep"](this.action.app.key)
        },

        primaryComponent() {
            return defineAsyncComponent(() => import(/* webpackMode: "eager" */`./${this.action.app.key}/${this.primaryStep.key}`))
        }

    }

}
</script>
