module.exports = {

    data() {
        return {
            settingKeyBase: 'form.settings',
        }
    },

    methods: {
        setDropdown(dropdownKey, searchKey, objectKey, settingKey, intValue) {

            _.set(this.dropdowns, dropdownKey, _.find(_.get(this, searchKey), object => {
                if (intValue) {
                    return parseInt(_.get(object, objectKey)) === parseInt(_.get(this, `${this.settingKeyBase}.${settingKey}`));
                }

                // Don't want default to make this statement true
                return _.get(object, objectKey, '---') === _.get(this, `${this.settingKeyBase}.${settingKey}`, '___');
            }))
        },

        setDropdownValue(dropdownKey, settingKey, intValue) {
            this.setDropdown(`${dropdownKey}.selected`, `dropdowns.${dropdownKey}.options`, 'value', settingKey, intValue)
        },

        setDropdownValueId(dropdownKey, settingKey) {
            this.setDropdown(`${dropdownKey}.selected`, `dropdowns.${dropdownKey}.options`, 'id', settingKey, true)
        },

        setDropdownValueFromGroups(groups, groupFieldKey, fieldKey, dropdownKey, settingKey) {
            _.find(groups, group => {
                let field = _.find(_.get(group, groupFieldKey), field => {
                    return _.get(field, fieldKey, '---') === _.get(this, `${this.settingKeyBase}.${settingKey}`, '___');
                })
                if (field) {
                    _.set(this.dropdowns, `${dropdownKey}.selected`, field);
                    return true;
                }
                return false;
            })
        },

        setDropdownTrigger(triggerFieldKey, settingKey, triggerFields) {
            if (!triggerFields) {
                triggerFields = 'triggerFields';
            }
            this.setDropdown(`triggerFields.selected.${triggerFieldKey}`, triggerFields, 'id', settingKey, true);
        },

        updateSettings(settingKey, dropdownKey) {
            _.set(this, `${this.settingKeyBase}.${settingKey}`, _.get(this.dropdowns, dropdownKey));
        },

        updateFromDropDown(settingKey, dropdownKey, selectedKey) {
            this.updateSettings(settingKey, `${dropdownKey}.selected.${selectedKey}`)
        },

        updateFromDropDownValue(settingKey, dropdownKey) {
            this.updateFromDropDown(settingKey, dropdownKey, 'value')
        },

        updateFromDropDownId(settingKey, dropdownKey) {
            this.updateFromDropDown(settingKey, dropdownKey, 'id')
        },

        updateFromTriggerField(settingKey, triggerFieldKey) {
            this.updateSettings(settingKey, `triggerFields.selected.${triggerFieldKey}.id`)
        },
    }

}
