<template>
    <div>

        <tab-header>
            Payment Details
            <template #sub>
                Add a payment for this order by configuring the details below
            </template>
        </tab-header>

        <input-label label="Payment Options" class="max-w-xl mb-8">

            <select-field :options="dropdowns.paymentActive.options"
                          v-model="dropdowns.paymentActive.selected"
                          @input="updateFromDropDownValue('payment.active', 'paymentActive')"
                          label="label"
                          track-by="value"/>

        </input-label>

        <div v-if="parseInt(settings.payment.active) === 1">
            <input-label class="max-w-xl mb-8" :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>
                            Payment Type
                        </default-label>
                        <checkbox-field :id="`trigger_field_payment_type_${event.id}`"
                                        v-model="settings.payment.type"
                                        true-value="1" false-value="2">
                            Use trigger field
                        </checkbox-field>
                    </div>
                </template>

                <select-field v-if="parseInt(settings.payment.type) === 1"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.type"
                              label="label"
                              track-by="id"
                              @input="updateFromTriggerField('payment.type_extracted', 'type')" />

                <select-field v-else
                              :options="dropdowns.paymentTypes.options"
                              v-model="dropdowns.paymentTypes.selected"
                              label="label"
                              track-by="value"
                              :loading="dropdowns.paymentTypes.loading"
                              @input="updateFromDropDownValue('payment.type_custom', 'paymentTypes')" />

            </input-label>

            <input-label class="max-w-xl mb-8" :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>
                            Payment Date
                        </default-label>
                        <checkbox-field :id="`trigger_field_payment_date_${event.id}`"
                                        v-model="settings.payment.date"
                                        true-value="1" false-value="0">
                            Use trigger field
                        </checkbox-field>
                    </div>
                </template>

                <select-field v-if="parseInt(settings.payment.date) === 1"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.date"
                              label="label"
                              track-by="id"
                              @input="updateFromTriggerField('payment.date_extracted_field', 'date')" />

                <fake-input v-else>
                    Use current time processed
                </fake-input>

            </input-label>

            <input-label label="Payment Note" class="max-w-xl">
                <event-input-field v-model="settings.payment.manual_note"
                                   placeholder="Charged via Parsey"/>
            </input-label>

        </div>

        <div v-if="parseInt(settings.payment.active) === 2">

            <div class="mb-4"
                 :class="settings.payment.merchant === 'default' ? 'max-w-xl' : 'grid grid-cols-1 md:grid-cols-2 gap-x-10'">

                <input-label label="Merchant Account">
                    <select-field :options="dropdowns.merchantAccount.options"
                                  v-model="dropdowns.merchantAccount.selected"
                                  @input="updateFromDropDownValue('payment.merchant', 'merchantAccount')"
                                  label="label"
                                  track-by="value"/>
                </input-label>

                <input-label v-if="settings.payment.merchant === 'id'" label="Merchant Account Id">
                    <event-input-field v-model="settings.payment.merchant_id" placeholder="3"/>
                </input-label>

            </div>

            <input-label label="Credit Card" class="mb-8 max-w-xl">
                <select-field :options="dropdowns.card.options"
                              v-model="dropdowns.card.selected"
                              @input="updateFromDropDownValue('payment.card_option', 'card')"
                              label="label"
                              track-by="value"/>
            </input-label>

            <input-label label="Payment Note" class="max-w-xl">
                <event-input-field v-model="settings.payment.note"
                                   placeholder="Charged via Parsey"/>
            </input-label>

        </div>


    </div>
</template>

<script>
import DefaultLabel from "../../../../elements/DefaultLabel"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import EventInputField from "../../../../elements/fields/EventInputField"
import {mapGetters} from "vuex"
import EventHelpers from "../mixins/event-helpers"
import FakeInput from "../../../../elements/fields/FakeInput"
import TabHeader from "../../../../elements/tabs/TabHeader"

export default {
    name: 'OrderPaymentOptions',

    components: {
        TabHeader,
        FakeInput,
        CheckboxField,
        DefaultLabel,
        SelectField,
        InputLabel,
        EventInputField
    },

    mixins: [
        EventHelpers
    ],

    props: {
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            settingKeyBase: 'settings',
            dropdowns: {
                triggerFields: {
                    selected: {
                        type: null,
                        date: null,
                    }
                },
                paymentActive: {
                    selected: null,
                    options: [
                        {
                            label: 'Do NOT record any payment',
                            value: 0,
                        },
                        {
                            label: 'Record Payment for the total amount of order',
                            value: 1,
                        },
                        {
                            label: 'Charge credit card for the total amount of order',
                            value: 2,
                        },
                    ],
                },
                merchantAccount: {
                    selected: null,
                    options: [
                        {
                            label: 'Use Default Merchant Account',
                            value: 'default',
                        },
                        {
                            label: 'Use a specific merchant account',
                            value: 'id',
                        },
                    ]
                },

                card: {
                    selected: null,
                    options: [
                        {
                            label: 'Use most recently ADDED card',
                            value: 'recent',
                        },
                        {
                            label: 'Use most recently CHARGED card',
                            value: 'charged',
                        },
                    ]
                },

                paymentTypes: {
                    loading: true,
                    selected: null,
                    options: [],
                }

            },

        }
    },

    mounted() {

        this.getPaymentTypes();

        this.$nextTick(() => {
            this.setOptions();
        })
    },

    methods: {
        setOptions() {
            this.setDropdownValue('paymentActive', 'payment.active', true);
            this.setDropdownValue('merchantAccount', 'payment.merchant')
            this.setDropdownValue('card', 'payment.card_option')
            this.setDropdownTrigger('type', 'payment.type_extracted')
            this.setDropdownTrigger('date', 'payment.date_extracted_field')
        },

        getPaymentTypes() {
            this.dropdowns.paymentTypes.loading = true;
            Parsey.get(`${this.action.routes.search}?type=paymentTypes&search=all`)
                .then(results => {
                    this.dropdowns.paymentTypes.loading = false;
                    this.dropdowns.paymentTypes.options = results;
                    this.setDropdownValue('paymentTypes', 'payment.type_custom')
                })
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList',
        }),
    },

}
</script>