<template>
    <div @click="$emit('click')"
         @mouseenter="hover = true"
         @mouseleave="hover = false"
         v-tooltip="toolTipContent"
         :class="`${backgroundClass} ${textClass} ${clickableClass} ${paddingRightClass}`"
         class="inline-flex items-center h-6 pl-2 py-0.5 rounded-full text-sm whitespace-nowrap">

        {{ pluralizedName }}

        <chevron-right-icon :class="textClass"
                            v-show="toolTip === false"
                            class="flex-shrink-0 ml-1.5 inline-flex focus:outline-none h-4 w-4"/>
    </div>
</template>

<script>
import ChevronRightIcon from "../../svgs/general/ChevronRightIcon"

export default {
    name: 'DefaultBadge',

    components: {
        ChevronRightIcon
    },

    props: {
        color: {
            type: String,
            default: 'indigo',
        },

        number: {
            type: Number,
            default: 0,
        },

        name: {
            required: true,
            default: ''
        },

        clickable: {
            default: true,
        },

        toolTip: {
            default: false,
        },

        toolTipContent: {}
    },

    data() {
        return {
            hover: false,
        }
    },

    computed: {
        paddingRightClass() {
            if (this.toolTip) {
                return 'pr-2'
            }

            return 'pr-1'
        },
        backgroundClass() {
            let number = 100;
            switch (this.color) {
                case 'green':
                    number = 200;
                    break;
            }

            /*
            For Tailwind
            bg-gray-50
            bg-red-100
            bg-indigo-100
            bg-blue-100
            bg-green-200
            */
            return `bg-${this.color}-${number}`;
        },

        textClass() {
            let number = 700;
            switch (this.color) {
                case 'red':
                case 'gray':
                    number = 600
                    break;
            }

            if (this.hover) {
                number += 200;
            }

            /*
            For Tailwind
            text-red-600
            text-red-800
            text-gray-600
            text-gray-800
            text-blue-700
            text-blue-900
            text-indigo-700
            text-indigo-900
            text-green-700
            text-green-900
             */

            return `text-${this.color}-${number}`;
        },

        circleClass() {
            let number = 50;
            switch (this.color) {
                case 'green':
                    number = 100;
                    break;
            }

            /*
            For Tailwind
            bg-red-50
            bg-indigo-50
            bg-blue-50
            bg-green-100
            */
            return `bg-${this.color}-${number}`;
        },

        clickableClass() {
            if (this.clickable) {
                return 'cursor-pointer'
            }

            return '';
        },

        pluralizedName() {
            return pluralize(this.name, this.number)
        }
    }
}
</script>
