<template>

    <flow-layout>

        <div v-if="loading">
            <flow-panel :loading="true" :continued="false"></flow-panel>
            <flow-panel :loading="true" :continued="true"></flow-panel>
            <flow-panel :loading="true" :continued="true"></flow-panel>
            <flow-panel :loading="true" :continued="true"></flow-panel>
        </div>

        <div v-else>

            <legacy-trigger/>

            <legacy-actions/>

            <legacy-new-action/>

            <legacy-filter-modal/>

        </div>

    </flow-layout>

</template>

<script>

import {mapState} from 'vuex'
import FlowLayout from "../FlowLayout"
import LegacyNewAction from "./LegacyNewAction"
import LegacyTrigger from "./LegacyTrigger"
import LegacyActions from "./LegacyActions"
import FlowPanel from "../components/FlowPanel"
import LegacyFilterModal from "../../filters/LegacyFilterModal"

export default {

    name: 'LegacyFlow',

    components: {
        LegacyFilterModal,
        FlowPanel,
        LegacyActions,
        LegacyTrigger,
        LegacyNewAction,
        FlowLayout,
    },

    metaInfo() {
        return {
            title: this.fullTitle,
        }
    },

    created() {
        this.setupFlow()
    },

    watch: {
        '$attrs.id'() {
            this.setupFlow()
        }
    },

    methods: {

        setupFlow() {
            this.$store.commit('flow/setProperties', {
                legacy: true,
                type: this.$attrs.type,
                id: this.$attrs.id,
            })

            this.getFlow();
            this.getApps();
        },

        getFlow() {
            this.$store.dispatch('flow/getFlow')
        },

        getApps() {
            this.$store.dispatch('apps/getLegacyApps')
        },

    },

    computed: {

        ...mapState('flow', {
            flow: state => state.data,
            loading: state => state.loading,
            title: state => state.title,
        }),

        fullTitle() {
            let title = this.title;
            if (this.showTrigger) {
                return _.capitalize(this.flow.type) + ` Trigger | ${title}`
            }

            if (this.showNewAction) {
                return `Create Action | ${title}`
            }

            if (this.$attrs.panel === 'edit') {
                return `Edit | ${title}`
            }

            if (this.$attrs.panel !== 'actions') {
                return title;
            }

            let action = _.find(this.flow.actions, action => {
                return action.id === parseInt(this.$attrs.panelId);
            });

            if (!action) {
                return title;
            }

            title = `${action.name} | ${title}`

            if (parseInt(this.$attrs.eventId)) {
                let event = this.$store.getters["flow/eventName"](action.id, this.$attrs.eventId);
                if (event) {
                    return event + ` - ${title}`
                }
            }

            if (typeof this.$attrs.eventId === 'string') {
                return _.capitalize(this.$attrs.eventId) + ` - ${title}`
            }

            return title;
        },

        closePath() {
            return _.get(this.flow, 'views.edit');
        },

    }
}
</script>

