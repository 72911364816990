<template>
    <div>
        <template v-for="action in flow.actions"
                  :key="action.id">

            <legacy-action :action="action"/>

        </template>
    </div>
</template>

<script>
import {mapState} from "vuex"
import LegacyAction from "./LegacyAction"

export default {
    name: 'LegacyActions',

    components: {
        LegacyAction,
    },

    computed: {

        ...mapState('flow', {
            flow: state => state.data,
        }),

    },
}
</script>
