import FlowIndex from "../components/views/flows/FlowIndex"
import DefaultLayout from "../components/layouts/DefaultLayout"
import CreateLegacyFlow from "../components/views/flows/legacy/CreateLegacyFlow"
import {merge} from "lodash"
import {legacyFlowTypes, legacyPanelTypes, legacyEventsTypes} from '../middlewares'
import LegacyFlow from "../components/views/flows/legacy/LegacyFlow"

let flowRoutes = [

    {
        path: '/flows',
        name: 'Flows',
        components: {
            default: FlowIndex
        },
    },
    {
        path: '/flows/legacy/create',
        name: 'CreateLegacyFlow',
        components: {
            default: CreateLegacyFlow,
        },
    },
    {
        path: '/flows/legacy/:type/:id/:panel/:panelId?/:events?/:eventId?',
        name: 'LegacyFlow',
        components: {
            default: LegacyFlow
        },
        meta: {
            middlewares: [
                legacyFlowTypes,
                legacyPanelTypes,
                legacyEventsTypes,
            ]
        },
        props: {
            default: true,
        },
    }

]

flowRoutes = flowRoutes.map(route => {

    route.meta = merge({layout: DefaultLayout}, route.meta)

    return route;
})


export default flowRoutes;
