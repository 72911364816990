<template>
    <legacy-field :name="field.label"
                  @click-view="clickedView"
                  :error="field.status === 'error'"
                  :loading="loading && field.id === viewing"
                  :viewing="active">
        <template #dropdown>
            <form @submit.prevent="editField">
                <dropdown-link as="button">
                    Edit
                </dropdown-link>
            </form>

            <horizontal-line/>

            <form @submit.prevent="deleteField">
                <dropdown-link as="button">
                    Delete
                </dropdown-link>
            </form>
        </template>

    </legacy-field>
</template>

<script>
import LegacyField from "../elments/LegacyField"
import DropdownLink from "../../../../elements/DropdownLink"
import HorizontalLine from "../../../../elements/HorizontalLine"
import InputLabel from "../../../../elements/fields/InputLabel"
import InputField from "../../../../elements/fields/InputField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"

export default {
    name: 'LegacyEmailField',

    components: {
        SubmitButton,
        InputField,
        InputLabel,
        HorizontalLine,
        DropdownLink,
        LegacyField
    },

    props: {
        field: {
            type: Object,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        viewing: {
            type: [Boolean, Number],
        },
    },

    data() {
        return {
            deleteFieldForm: new ParseyForm(),
        }
    },

    methods: {

        editField(){
            this.$emit('edit-field', this.field)
        },

        deleteField() {
            this.$store.dispatch('flow/deleteLegacyField', {
                form: this.deleteFieldForm,
                field: this.field,
            })
        },

        clickedView() {
            if (this.viewing && this.viewing === this.field.id) {
                return this.$emit('hide-field', this.field)
            }

            this.$emit('show-field', this.field);
        }
    },

    computed: {
        active() {
            return this.field.id === this.viewing;
        }
    }
}
</script>