const state = () => ({
    show: false,
    property: {
        type: 'action',
        name: 'Action'
    },
    loadingFilters: false,
    loadingOptions: false,
    type: '',
    typeId: 0,
    filters: {},
    options: {
        search_fields: [],
        conditions: [],
    },
    paths: {
        filters: '',
        options: '',
    },
    saving: 0,
    changed: false,
    count: 0,
})

const getters = {

    loading: (state) => {
        return (state.loadingOptions === true) || (state.loadingFilters === true);
    },

}

const mutations = {

    close(state) {
        state.show = false;
    },

    open(state) {
        state.show = true;
    },

    setProperties(state, payload) {
        if (payload.property) {
            state.property = payload.property;
        }

        if (payload.type) {
            state.type = payload.type;
        }

        if (payload.typeId) {
            state.typeId = payload.typeId;
        }

        if (payload.filters) {
            state.paths.filters = payload.filters;
        }

        if (payload.options) {
            state.paths.options = payload.options;
        }

        if (payload.count) {
            state.count = payload.count;
        }
    },

    startLoadingOptions(state) {
        state.loadingOptions = true;
    },

    stopLoadingOptions(state) {
        state.loadingOptions = false;
    },

    startLoadingFilters(state) {
        state.loadingFilters = true;
    },

    stopLoadingFilters(state) {
        state.loadingFilters = false;
    },

    setFiltersPath(state, path) {
        state.paths.filters = path;
    },

    setFilters(state, filters) {
        state.filters = filters;
        state.count = _.size(filters);
    },

    setOptionsPath(state, path) {
        state.paths.options = path;
    },

    setOptions(state, options) {
        state.options = options;
    },

    addFilter(state, filter) {
        state.filters = {
            ...state.filters,
            [filter.id]: filter
        }
        state.count = _.size(state.filters)
    },

    addRule(state, rule) {
        state.filters[rule.filter_id].rules = {
            ...state.filters[rule.filter_id].rules,
            [rule.id]: rule
        }
    },

    updateRule(state, rule) {
        state.filters[rule.filter_id].rules[rule.id] = rule
    },

    removeFilter(state, filterId) {
        delete state.filters[filterId]
        state.count = _.size(state.filters)
    },

    removeRule(state, payload) {
        delete state.filters[payload.filterId].rules[payload.ruleId]
    },

    setSaving(state, filterId) {
        state.saving = filterId
    },

    setChanged(state) {
        state.changed = true
    },

    savedChanged(state) {
        state.changed = false
    },

}

const actions = {

    openFilter({commit, dispatch, state}, payload) {
        if (payload.type === state.type && payload.typeId === state.typeId) {
            commit('open')
            return true;
        }

        commit('setProperties', payload)

        if (payload.count === 0) {
            commit('open')
            commit('setFilters', {})
            dispatch('getFilterOptions', payload)
            return;
        }

        commit('startLoadingFilters')
        commit('startLoadingOptions')
        commit('open')
        dispatch('getFilterOptions', payload)
        dispatch('getFilters', payload)
    },

    getFilters({commit, state}, payload) {
        Parsey.get(payload.filters)
            .then(({data}) => {
                commit('setFilters', data)
                commit('stopLoadingFilters')
            })
    },

    getFilterOptions({commit, state}, payload) {
        Parsey.get(payload.options)
            .then(({options}) => {
                commit('setOptions', options)
                commit('stopLoadingOptions')
            })
    },

    addFilter({commit, state}, payload) {
        Parsey.post(state.paths.filters, payload.form)
            .then(({filter}) => {
                commit('addFilter', filter)
                commit('flow/updateFilterCount', {
                    type: state.type,
                    typeId: state.typeId,
                    count: state.count,
                }, {root: true})
            })
    },

    addRule({commit, state}, payload) {
        Parsey.post(payload.path, payload.form)
            .then(({rule}) => {
                commit('addRule', rule)
            })
    },

    deleteFilter({commit, state}, payload) {
        Parsey.delete(payload.path, payload.form)
            .then(() => {
                commit('removeFilter', payload.filterId)
                commit('flow/updateFilterCount', {
                    type: state.type,
                    typeId: state.typeId,
                    count: state.count,
                }, {root: true})
            })
    },

    updateRule({commit, state}, payload) {
        commit('setSaving', payload.filterId)
        Parsey.put(payload.path, payload.form)
            .then(({rule}) => {
                commit('updateRule', rule)
                commit('setSaving', 0)
                commit('savedChanged')
            })
    },

    deleteRule({commit, state, dispatch}, payload) {
        const filter = _.get(state, `filters[${payload.filterId}]`);
        if (_.size(filter.rules) === 1) {
            dispatch('deleteFilter', {
                filterId: payload.filterId,
                path: filter.routes.destroy,
                form: payload.form,
            })
            return
        }

        Parsey.delete(payload.path, payload.form)
            .then(() => {
                commit('removeRule', {
                    filterId: payload.filterId,
                    ruleId: payload.ruleId,
                })
            })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
