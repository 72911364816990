<template>
    <div class="rounded border border-gray-100">
        <legacy-email-content-headers/>
        <slot/>
    </div>
</template>

<script>

import LegacyEmailContentHeaders from "./LegacyEmailContentHeaders"

export default {
    name: 'LegacyEmailContentWrap',

    components: {
        LegacyEmailContentHeaders
    },
}
</script>