<template>
    <div>
        <div class="relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <div class="h-5 w-5 text-gray-200">
                    <search-icon/>
                </div>
            </div>
            <input id="dashboard-search"
                   type="text"
                   class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-lg border-gray-100 rounded-md"
                   placeholder="Search for flows, history, integrations etc... ">
        </div>
    </div>
</template>

<script>
import SearchIcon from "../../../svgs/general/SearchIcon"

export default {
    name: 'DashboardSearch',

    components: {
        SearchIcon
    }
}
</script>
