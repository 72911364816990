<template>

    <div :style="{width: sidebarWidth}"
         class="transition-width bg-white h-screen group overflow-y-scroll">

        <div class="w-1 absolute top-0 right-0 h-full bg-white bg-gradient-to-lr from-gray-50 to-white border-r border-gray-50"></div>

        <div @click="toggleSidebar"
             :class="{'opacity-0': isOpen}"
             class="absolute group-hover:opacity-100 top-0 p-1 right-0 -mr-3 mt-8 w-6 h-6 rounded-full bg-white hover:bg-indigo-600 hover:text-white text-gray-600 border border-gray-50 shadow transition hover:border-indigo-400 cursor-pointer flex items-center justify-center">

            <chevron-left-icon v-if="isOpen" :stroke="3" />
            <chevron-right-icon v-else :stroke="3" />

        </div>

        <div class="pt-6 pl-5 pr-4 pb-4 overflow-hidden">
            <div v-if="loadingFlow" class="animate-pulse">
                <div class="flex items-center mb-6" v-for="row in [1,2,3,4]" :key="row">
                    <div class="rounded-full bg-gray-100 w-8 h-8 mr-6"></div>
                    <div class="bg-gray-100 h-4 rounded flex-1 w-full mr-6"></div>
                </div>
            </div>

            <template v-else>
                <component :is="flowStepsComponent"/>
            </template>

        </div>

    </div>

</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import FlowSteps from "./FlowSteps"
import LegacyFlowSteps from "./legacy/LegacyFlowSteps"
import ChevronRightIcon from "../../svgs/general/ChevronRightIcon"
import ChevronLeftIcon from "../../svgs/general/ChevronLeftIcon"

export default {
    name: 'FlowSidebar',

    components: {
        ChevronLeftIcon,
        FlowSteps,
        LegacyFlowSteps,
        ChevronRightIcon,
    },

    props: {

        legacy: {
            type: Boolean,
        },

    },

    methods: {
        ...mapActions('flow', [
            'toggleSidebar'
        ])
    },

    computed: {
        ...mapState('flow', {
            loading: state => state.loading,
            flow: state => state.data,
            isOpen: state => state.sidebar.open,
        }),

        ...mapGetters('flow', [
            'sidebarWidth',
        ]),

        loadingFlow() {
            if (this.$route.name === 'CreateLegacyFlow') {
                return false;
            }

            return this.loading
        },

        flowStepsComponent() {
            if (this.flow && this.flow.legacy) {
                return 'LegacyFlowSteps'
            }

            if (this.$route.name === 'CreateLegacyFlow') {
                return 'LegacyFlowSteps'
            }

            return 'FlowSteps';
        }
    }
}
</script>
