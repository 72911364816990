<template>
    <flow-panel :show-content="showTrigger"
                :name-value="flow.name"
                :path="triggerPath"
                :close-path="closePath"
                :show-toggle="true"
                :toggle-form="toggleForm"
                @name-updated="saveFlowName"
                badge-name="Filter"
                :badge-count="flow.filters"
                @badge-clicked="$store.dispatch('flow/openFilter')"
                @add-action="$store.dispatch('flow/addLegacyAction')"
                :continue-on="addActionFromTrigger"
                name-placeholder="Name your flow">

        <template #icon>
            <div v-html="flow.trigger.icon"></div>
        </template>

        <template #content>
            <component :is="trigger"/>
        </template>

        <template #modals>
            <delete-legacy-flow :show="showDeleteModal"
                                @close="showDeleteModal = false"
                                :flow="flow"/>
        </template>

        <template #menu>
            <dropdown-link :to="{path: triggerPath}">
                Edit
            </dropdown-link>
            <form v-if="flow.type !== 'webhook'"
                  @submit.prevent="$store.dispatch('flow/openFilter')">
                <dropdown-link as="button">
                    Filters
                </dropdown-link>
            </form>

            <horizontal-line/>

            <form @submit.prevent="showDeleteModal = true">
                <dropdown-link as="button">
                    Delete
                </dropdown-link>
            </form>
        </template>

    </flow-panel>
</template>

<script>
import {mapState} from "vuex"
import FlowPanel from "../components/FlowPanel"
import DeleteLegacyFlow from "./modals/DeleteLegacyFlow"
import DropdownLink from "../../../elements/DropdownLink"
import LegacyEmailTrigger from "./email/LegacyEmailTrigger"
import HorizontalLine from "../../../elements/HorizontalLine"
import LegacyWebhookTrigger from "./webhook/LegacyWebhookTrigger"

export default {
    name: 'LegacyTrigger',

    components: {
        FlowPanel,
        DropdownLink,
        HorizontalLine,
        DeleteLegacyFlow,
        LegacyEmailTrigger,
        LegacyWebhookTrigger,
    },

    data() {
        return {
            nameForm: new ParseyForm({
                name: '',
            }),
            showDeleteModal: false,
            toggleForm: new ParseyForm({
                dispatch: {
                    on: 'flow/activate',
                    off: 'flow/inactivate',
                },
                on: false,
                flowId: parseInt(_.get(this.$route, 'params.id')),
            }),
        }
    },

    watch: {
        'flow.active'() {
            this.toggleForm.on = !!this.flow.active
        }
    },

    mounted() {
        this.toggleForm.on = this.flow.active
    },

    methods: {
        saveFlowName(name) {
            this.nameForm.name = name;
            Parsey.put(this.flow.routes.update, this.nameForm)
                .then(({flow}) => {
                    this.$store.commit('flow/setFlow', flow)
                    this.$store.commit('flows/rename', {
                        name: flow.name,
                        flow: flow,
                    })
                })
        },

    },

    computed: {

        ...mapState('flow', {
            flow: state => state.data,
        }),

        showTrigger() {
            return _.get(this.$route, 'params.panel') === 'trigger'
        },

        triggerPath() {
            return _.get(this.flow, 'views.trigger');
        },

        closePath() {
            return _.get(this.flow, 'views.edit');
        },

        trigger() {
            switch (this.$route.name) {
                case 'attachments':
                    return 'LegacyAttachmentTrigger';
                case 'emails':
                    return 'LegacyEmailTrigger';
                case 'webhooks':
                default:
                    return 'LegacyWebhookTrigger'
            }
        },

        addActionFromTrigger() {
            return _.size(_.get(this.flow, 'trigger.fields')) > 0
                && _.size(_.get(this.flow, 'actions')) === 0
                && _.get(this.$route, 'params.panelId') !== 'new';
        },
    }
}
</script>
