<template>
    <div class="flex">

        <div class="flex-shrink-0 fixed hidden lg:block z-10">
            <flow-sidebar/>
        </div>

        <div class="flex-1 transition-padding min-w-0" :style="{'padding-left' : paddingLeft}">
            <flow-wrapper>
                <slot/>
            </flow-wrapper>
        </div>
    </div>
</template>

<script>
import FlowSidebar from "./FlowSidebar"
import FlowWrapper from "./FlowWrapper"

export default {
    name: 'FlowLayout',

    components: {
        FlowWrapper,
        FlowSidebar
    },

    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },

    computed: {

        paddingLeft() {
            if (this.windowWidth < 1024) {
                return 0;
            }

            return this.$store.getters['flow/sidebarWidth']
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>
