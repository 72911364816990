<template>
    <div>

        <div class="md:hidden">
            <p class="font-semibold mb-3">
                Select a tab
            </p>
            <select-field v-model="selectedTab"
                          @input="selectedTab"
                          class="mb-4"
                          :options="tabs"/>
        </div>

        <div class="hidden md:block">
            <nav class="flex items-center space-x-4" aria-label="Tabs">
                <a v-for="tab in tabs"
                   :key="tab"
                   @click.prevent.stop="selectTab(tab)"
                   :class="{'bg-gray-50 focus:bg-gray-100': tab === selectedTab, 'text-gray-500 hover:text-gray-700 focus:bg-gray-100' : tab !== selectedTab}"
                   class="px-4 py-2 font-semibold md:text-sm lg:text-base rounded-md cursor-pointer text-center">
                    {{ tab }}
                </a>
            </nav>
        </div>

        <div class="py-2 sm:py-6">
            <slot/>
        </div>

    </div>
</template>

<script>
import SelectField from "../fields/SelectField"

import {provide, ref} from "vue"

export default {
    name: 'TabPills',

    components: {
        SelectField
    },

    setup(props, {slots}) {
        const tabSlots = slots.default().filter(child => child.type.name === 'TabPill');
        let preSelectedTab = null;

        const tabs = tabSlots
            .filter(tabSlot => {
                return _.get(tabSlot.props, 'available', true)
            })
            .map(tabSlot => {
                if (_.get(tabSlot.props, 'selected', false)) {
                    preSelectedTab = tabSlot.props.name;
                }

                return tabSlot.props.name
            });

        if (!preSelectedTab) {
            preSelectedTab = _.find(tabs);
        }

        const selectedTab = ref(preSelectedTab);
        provide('selectedTab', selectedTab)

        return {
            selectedTab,
            tabs,
        }
    },


    methods: {
        selectTab(tabName) {
            if (this.selectedTab !== tabName) {
                this.selectedTab = tabName;

                return this.$emit('change', this.selectedTab)
            }
        },

    },

}
</script>

