<template>
    <div>

        <tab-header>
            Additional Charges
            <template #sub>
                If you need to subtract a discount, add shipping and tax to this order configure
                settings bellow
            </template>
        </tab-header>

        <div class="mb-8">
            <checkbox-field class="mb-4"
                            :text-small="false"
                            :id="`add_discount_${event.id}`"
                            v-model="settings.charge_discount"
                            value="1" true-value="1" false-value="0">
                Subtract a discount on this order
            </checkbox-field>

            <input-label v-if="parseInt(settings.charge_discount) === 1"
                         class="max-w-xl"
                         :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>
                            Discount Amount
                        </default-label>
                        <checkbox-field :id="`discount_use_trigger_${event.id}`"
                                        :font-medium="false"
                                        @change="updateDiscountOptions"
                                        v-model="discount.trigger">
                            Use Trigger Field
                        </checkbox-field>
                    </div>
                </template>

                <select-field v-if="discount.trigger"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.discount"
                              @input="updateFromTriggerField('discount_amount_field', 'discount')"
                              label="label"
                              track-by="id"/>

                <event-input-field v-else
                                   placeholder="10.00"
                                   @update="updateDiscountOptions"
                                   :input-class="{'pl-7': !discount.percentage, 'pr-7': discount.percentage}"
                                   v-model="discount.amount">
                    <template v-if="discount.percentage === false" #leading>
                        <leading-text>$</leading-text>
                    </template>

                    <template v-if="discount.percentage === true" #trailing>
                        <trailing-text>%</trailing-text>
                    </template>
                </event-input-field>

                <checkbox-field :font-medium="false"
                                class="mt-2"
                                @change="updateDiscountOptions"
                                :id="`discount_use_percentage_${event.id}`"
                                v-model="discount.percentage">
                    Discount amount is a percentage
                </checkbox-field>

            </input-label>

        </div>

        <div class="mb-8">
            <checkbox-field class="mb-4"
                            :text-small="false"
                            :id="`add_shipping_${event.id}`"
                            v-model="settings.charge_shipping"
                            value="1" true-value="1" false-value="0">
                Add a shipping charge on this order
            </checkbox-field>


            <input-label v-if="parseInt(settings.charge_shipping) === 1"
                         class="max-w-xl"
                         :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>
                            Shipping Amount
                        </default-label>
                        <checkbox-field :id="`shipping_use_trigger_${event.id}`"
                                        :font-medium="false"
                                        v-model="settings.shipping_option"
                                        value="1" true-value="0" false-value="1">
                            Use Trigger Field
                        </checkbox-field>
                    </div>
                </template>

                <select-field v-if="parseInt(settings.shipping_option) === 0"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.shipping"
                              @input="updateFromTriggerField('shipping_charges_field', 'shipping')"
                              label="label"
                              track-by="id"/>

                <event-input-field v-else
                                   input-class="pl-7"
                                   v-model="settings.shipping_amount">
                    <template #leading>
                        <leading-text>$</leading-text>
                    </template>
                </event-input-field>

            </input-label>


        </div>

        <div class="mb-8">
            <checkbox-field class="mb-4"
                            :text-small="false"
                            :id="`add_tax_${event.id}`"
                            v-model="settings.charge_tax"
                            value="1" true-value="1" false-value="0">
                Add tax charge to this order
            </checkbox-field>

            <input-label v-if="parseInt(settings.charge_tax) === 1"
                         class="max-w-xl"
                         :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>
                            Tax Amount
                        </default-label>
                        <checkbox-field :id="`tax_use_trigger_${event.id}`"
                                        :font-medium="false"
                                        v-model="settings.tax_option"
                                        true-value="0" false-value="1">
                            Use Trigger Field
                        </checkbox-field>
                    </div>
                </template>

                <select-field v-if="parseInt(settings.tax_option) === 0"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.tax"
                              @input="updateFromTriggerField('tax_charges_field', 'tax')"
                              label="label"
                              track-by="id"/>

                <event-input-field v-else
                                   :disabled="parseInt(settings.tax_option) === 5"
                                   :input-class="{'pl-7': true, 'cursor-not-allowed text-gray-400': parseInt(settings.tax_option) === 5}"
                                   v-model="settings.tax_amount">
                    <template #leading>
                        <leading-text>$</leading-text>
                    </template>
                </event-input-field>

                <checkbox-field :font-medium="false"
                                class="mt-2"
                                :id="`tax_use_keap_${event.id}`"
                                v-model="settings.tax_option"
                                true-value="5" false-value="1">
                    Calculated tax from Keap settings
                </checkbox-field>

            </input-label>

        </div>
    </div>
</template>

<script>
import CheckboxField from "../../../../elements/fields/CheckboxField"
import DefaultLabel from "../../../../elements/DefaultLabel"
import SelectField from "../../../../elements/fields/SelectField"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"
import {mapGetters} from "vuex"
import EventHelpers from "../mixins/event-helpers"
import LeadingText from "../../../../elements/LeadingText"
import TrailingText from "../../../../elements/TrailingText"
import TabHeader from "../../../../elements/tabs/TabHeader"

export default {
    name: 'OrderAdditionalCharges',

    components: {
        TabHeader,
        TrailingText,
        LeadingText,
        CheckboxField,
        DefaultLabel,
        SelectField,
        InputLabel,
        EventInputField
    },

    mixins: [
        EventHelpers
    ],

    props: {
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            settingKeyBase: 'settings',
            dropdowns: {
                triggerFields: {
                    selected: {
                        discount: null,
                        shipping: null,
                        tax: null,
                    }
                },
            },
            discount: {
                amount: '',
                trigger: false,
                percentage: false,
            },
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.setOptions();
        })
    },

    methods: {
        setOptions() {
            this.setDiscountOptions();
            this.setShippingOptions();
            this.setTaxOptions();
        },

        setDiscountOptions() {
            switch (parseInt(this.settings.discount_option)) {
                case 0:
                    this.discount.trigger = true;
                    this.discount.percentage = false;
                    this.setDropdownTrigger('discount', 'discount_amount_field')
                    break;
                case 1:
                    this.discount.trigger = false;
                    this.discount.percentage = false;
                    this.discount.amount = this.settings.discount_amount;
                    break;
                case 8:
                    this.discount.trigger = true;
                    this.discount.percentage = true;
                    this.setDropdownTrigger('discount', 'discount_amount_field')
                    break;
                case 9:
                    this.discount.trigger = false;
                    this.discount.percentage = true;
                    this.discount.amount = this.settings.discount_percentage;
                    break;
            }
        },

        updateDiscountOptions() {
            if (this.discount.trigger) {
                this.updateFromTriggerField('discount_amount_field', 'discount');
                this.settings.discount_option = this.discount.percentage ? 8 : 0;
                return
            }

            if (this.discount.percentage) {
                this.settings.discount_option = 9;
                this.settings.discount_percentage = this.discount.amount;
                return
            }

            this.settings.discount_option = 1;
            this.settings.discount_amount = this.discount.amount;
        },

        setShippingOptions() {
            this.setDropdownTrigger('shipping', 'shipping_charges_field')
        },

        setTaxOptions() {
            this.setDropdownTrigger('tax', 'tax_charges_field')
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList',
        }),
    },

}
</script>