<template>

    <default-dropdown>
        <template #trigger>
            <button-menu size="8"/>
        </template>
        <template #content>
            <dropdown-link v-for="page in pages" :key="page.name" :to="{name: page.name}">
                {{ page.label }}
            </dropdown-link>
        </template>
    </default-dropdown>

</template>

<script>

import DropdownLink from "../../elements/DropdownLink"
import DefaultDropdown from "../../elements/DefaultDropdown"
import ButtonMenu from "../../elements/buttons/ButtonMenu"

export default {

    name: 'SettingsDropdown',

    components: {
        ButtonMenu,
        DefaultDropdown,
        DropdownLink
    },

    mixins: [
        require('./settings-menu')
    ]
}
</script>

