<template>
    <div class="pt-2 pb-6 md:py-6">
        <div class="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "DefaultWrapper"
}
</script>
