<template>
    <svg width="100%" height="100%" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-3398.99,-3.14105e-09)">
        <g id="create" transform="matrix(1,0,0,1,1158.99,3.14105e-09)">
            <rect x="2240" y="0" width="1000" height="1000" style="fill:none;"/>
            <g>
                <g transform="matrix(1.62343,0,0,1.67784,-3502.1,0)">
                    <path d="M3871,25.156C3871,11.272 3859.35,0 3845,0C3830.65,0 3819,11.272 3819,25.156L3819,569.844C3819,583.728 3830.65,595 3845,595C3859.35,595 3871,583.728 3871,569.844L3871,25.156Z"/>
                </g>
                <g transform="matrix(9.94066e-17,1.62343,-1.67784,1.02738e-16,3239.16,-5742.94)">
                    <path d="M3871,25.156C3871,11.272 3859.35,0 3845,0C3830.65,0 3819,11.272 3819,25.156L3819,569.844C3819,583.728 3830.65,595 3845,595C3859.35,595 3871,583.728 3871,569.844L3871,25.156Z"/>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    name: "PlusIcon"
}
</script>
