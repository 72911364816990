<template>
    <div>
        <default-well title="Account Plan" description="Manage your Parsey plan">

            <form>


            </form>

        </default-well>
    </div>
</template>

<script>
import DefaultWell from "../../elements/DefaultWell"

export default {

    name: "UserBilling",

    components: {
        DefaultWell
    },

    metaInfo: {
        title: 'Plan & Usage | Settings'
    }
}
</script>
