<template>
    <label class="font-medium block text-sm mb-3">
        <slot/>
    </label>
</template>

<script>
export default {
    name: "DefaultLabel"
}
</script>