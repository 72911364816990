<template>
    <div v-show="isActive">
        <slot/>
    </div>
</template>

<script>

import {inject} from "vue"

export default {
    name: 'TabPill',

    props: {
        name: {
            required: true,
            type: String,
        },

        selected: {
            type: Boolean,
            default: false,
        },

        available: {
            type: Boolean,
            default: true,
        },
    },

    setup() {
        inject('selectedTab', '')
    },

    inject: ['selectedTab'],

    computed: {
        isActive() {
            return this.selectedTab === this.name;
        }
    }

}
</script>

