import router from "../../router"
import {get} from 'lodash'

import {useRouter} from 'vue-router'

const state = () => ({
    loading: true,
    data: [],
    updatedAt: null,
    searchSet: false,
    search: {
        page: '1',
        limit: '25',
        status: 'all',
        flows: 'all',
    },
    dropdowns: {
        flows: {
            show: false,
            selected: [],
        },
        status: {
            show: false,
            selected: [],
        }
    },
    links: {
        first: '',
        last: '',
        next: '',
        prev: '',
    },
    meta: {
        current_page: 0,
        from: 0,
        last_page: 0,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
        links: [],
    },
})

const getters = {
    selectedStatuses(state, getters, rootState) {
        return _.map(_.reject(state.dropdowns.status.selected, item => item === 'all'), (status) => {
            return _.find(rootState.config.history.statuses, {'name': status})
        });
    },

    selectedFlows(state, getters, rootState) {
        if (rootState.flows.loading) {
            return [];
        }

        return _.map(_.reject(state.dropdowns.flows.selected, item => item === 'all'), flowKey => {
            let flow = _.get(rootState.flows.all, flowKey);
            flow.key = flowKey;
            return flow;
        })
    },
}

const mutations = {
    setSearch(state) {
        const query = JSON.parse(JSON.stringify(router.currentRoute.value.query));
        const search = JSON.parse(JSON.stringify(state.search));

        state.search = _.merge(search, query);
        state.dropdowns.status.selected = state.search.status.split(',')
        state.dropdowns.flows.selected = state.search.flows.split(',')
    },

    startLoading(state) {
        state.loading = true;
    },

    stopLoading(state) {
        state.loading = false;
        state.searchSet = true;
    },

    setHistoryData(state, data) {
        state.data = data;
        state.updatedAt = _.round(Date.now() / 1000);
    },

    setHistoryMeta(state, meta) {
        state.meta = meta;
    },

    setHistoryLinks(state, links) {
        state.links = links;
    },

    setDropdownSelected(state, payload) {
        state.dropdowns[payload.type].selected = payload.data;
    },

    setSearchFromDropdown(state, key) {
        state.search[key] = state.dropdowns[key].selected.join(',');
    },

    setSearchSet(state, status) {
        state.searchSet = status;
    },
    setSearchPage(state, page) {
        state.search.page = page;
    },

}

const actions = {
    getHistory({commit, state, rootState}, force) {
        commit('startLoading')

        const path = rootState.routes.history.index;
        const query = new URLSearchParams(state.search).toString();

        const queryMatches = _.isEqual(JSON.parse(JSON.stringify(state.search)), JSON.parse(JSON.stringify(router.currentRoute.value.query)));

        if (router.currentRoute.value.name === 'History' && !queryMatches) {
            router.replace({name: 'History', query: state.search})
            return // This prevents a double history ajax call below
        }

        if (state.searchSet && router.currentRoute.value.name === 'FlowHistoryShow') {
            commit('stopLoading')
            return
        }

        Parsey.get(`${path}?${query}`)
            .then((result) => {
                if (result.data.length === 0 && state.search.page > 1) {
                    commit('setSearchPage', result.meta.last_page)
                    router.replace({name: 'History', query: state.search});
                    return;
                }
                commit('setHistoryData', result.data)
                commit('stopLoading')
                commit('setSearchSet', true)
                commit('setHistoryLinks', result.links)
                commit('setHistoryMeta', result.meta)
            })
    },

    selectStatus({commit, state, dispatch}, status) {
        dispatch('selectFilter', {
            type: 'status',
            key: status,
        })
    },
    selectFlow({commit, state, dispatch}, flowIndex) {
        dispatch('selectFilter', {
            type: 'flows',
            key: flowIndex,
        })
    },

    selectFilter({commit, state}, payload) {
        commit('setSearchSet', false);
        let data = [];
        if (state.dropdowns[payload.type].selected.includes(payload.key)) {
            data = _.pull(
                JSON.parse(JSON.stringify(state.dropdowns[payload.type].selected)), payload.key);
        } else {
            data = _.pull(
                JSON.parse(JSON.stringify(state.dropdowns[payload.type].selected)), 'all');

            data.push(payload.key);
        }

        if (data.length === 0) {
            commit('setDropdownSelected', {
                type: payload.type,
                data: ['all'],
            })
        } else {
            commit('setDropdownSelected', {
                type: payload.type,
                data: data,
            })
        }

        commit('setSearchFromDropdown', payload.type)

        return router.push({
            name: 'History',
            query: JSON.parse(JSON.stringify(state.search))
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
