import DefaultLayout from "../components/layouts/DefaultLayout"
import FlowHistoryShow from "../components/views/history/FlowHistoryShow"
import TriggerHistoryShow from "../components/views/history/TriggerHistoryShow"
import TriggerHistoryIndex from "../components/views/history/TriggerHistoryIndex"

let historyRoutes = [

    {
        path: '/histories',
        name: 'History',
        components: {
            default: TriggerHistoryIndex
        },
    },

    // TODO
    // This is the page that shows the flows for the trigger histories
    {
        path: '/histories/:historyId',
        name: 'TriggerHistoryShow',
        components: {
            default: TriggerHistoryShow
        },
    },

    {
        path: '/flow-histories/:flowHistoryId/:jobs?/:jobId?/:panel?/:panelId?/:events?/:eventId?',
        name: 'FlowHistoryShow',
        components: {
            default: FlowHistoryShow
        },
    },

]

historyRoutes = historyRoutes.map(route => {
    route.meta = {
        layout: DefaultLayout,
    }

    return route;
})


export default historyRoutes;
