<template>
    <button :class="combinedClasses"
            :disabled="form.busy || disabled"
            v-html="form.buttonHtml(text, loading)"
            @submit.prevent="save" type="submit"></button>
</template>

<script>
export default {

    name: 'SubmitButton',

    props: {
        form: {
            required: true,
        },
        text: {
            default: 'Save',
        },
        loading: {
            default: 'Saving...',
        },
        classes: {
            type: Array,
            default: () => {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        extra: {
            type: String,
            default: ''
        },
        wide: {
            default: false,
        },
        teal: {
            default: true
        },
        switchAt: {
            type: String,
            default: 'md'
        }
    },

    methods: {
        save() {
            this.$emit('save')
        }
    },

    computed: {
        combinedClasses() {
            let classes = [
                'btn',
                'btn-sm',
                'w-full',
                'text-center',
            ];

            if (this.classes.length > 0) {
                classes = this.classes
            }

            if (this.form.busy || this.disabled) {
                classes.push('disabled')
            }

            if (this.wide) {
                classes.push('btn-wide')
            }

            if (this.extra.length > 0) {
                classes.push(this.extra)
            }

            if (this.teal) {
                classes.push('btn-teal')
            }

            // Switch statement required bc of tailwind purging can't find concatenation class names
            switch (this.switchAt) {
                case 'sm':
                    classes.push('sm:w-auto');
                    break;
                case 'md':
                    classes.push('md:w-auto');
                    break;
            }

            return classes.join(' ')
        }
    }
}
</script>

