<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Trigger a API Campaign Goal inside you Infusionsoft campaign. Below settings are found
            within each goal.
        </p>

        <form-error :form="form"/>

        <input-label label="Integration name">
            <event-input-field v-model="form.settings.integration"
                               :form="form"
                               form-key="settings_integration"/>
        </input-label>

        <input-label label="Call name">
            <event-input-field v-model="form.settings.callName"
                               :form="form"
                               form-key="settings_callName"/>
        </input-label>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"

export default {
    name: 'CampaignGoal',

    components: {
        EventInputField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            searches: {},

            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    integration: '',
                    callName: '',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }
    },

    methods: {

        save() {

            let errors = {};
            _.each([
                {key: 'integration', label: 'Integration name'},
                {key: 'callName', label: 'Call name'},
            ], field => {
                if (this.form.settings[field.key].length < 1) {
                    errors[`settings_${field.key}`] = [`${field.label} is required`]
                }
                if (this.form.settings[field.key].includes(' ')) {
                    errors[`settings_${field.key}`] = [`No spaces allowed in ${field.label}`]
                }
            })

            if (_.size(errors)) {
                this.form.errors.set(errors);
                return;
            }

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

    },

}
</script>

