<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This event will create an order and record the payment or even process the payment.
        </event-sub-text>

        <form-error :form="form"/>

        <input-label :label="false" class="max-w-xl mb-8">

            <template #label>
                <div class="flex justify-between">
                    <default-label>
                        Order Title
                    </default-label>
                    <checkbox-field :id="`order_title_option_${event.id}`"
                                    :font-medium="false"
                                    v-model="form.settings.order_title_option"
                                    true-value="field" false-value="manual">
                        Use Trigger Field
                    </checkbox-field>
                </div>

                <select-field v-if="form.settings.order_title_option === 'field'"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.title"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>

                <event-input-field v-else v-model="form.settings.order_title_manual"/>

            </template>

        </input-label>


        <tab-pills>
            <tab-pill name="Products" :selected="true">
                <order-product-options :settings.sync="form.settings"
                                       :action="action"
                                       :event="event"/>
            </tab-pill>

            <tab-pill name="Additional Charges">
                <order-additional-charges :settings.sync="form.settings"
                                          :action="action"
                                          :event="event"/>
            </tab-pill>

            <tab-pill name="Payment">
                <order-payment-options :settings.sync="form.settings"
                                       :action="action"
                                       :event="event"/>
            </tab-pill>

            <tab-pill name="Referral Partner">
                <order-referral-partners :settings.sync="form.settings"
                                         :action="action"
                                         :event="event"/>
            </tab-pill>

            <tab-pill name="Automation">
                <order-automation :settings.sync="form.settings"
                                  :action="action"
                                  :event="event"/>
            </tab-pill>
        </tab-pills>

        <tab-pills>

            <tab-pill name="Custom Fields" :selected="true">
                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="orderFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Order"
                                      :refreshing="loading.refreshing"/>
            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="orderFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Order"
                                     :refreshing="loading.refreshing"/>
            </tab-pill>

            <tab-pill name="Map Fields">
                <tab-header :hide-sub-text="true" :no-margin-bottom="true">
                    Map Fields
                </tab-header>
                <map-fields :fields="triggerFieldAsObject"
                            :app-fields="allOrderFields"
                            :refreshing="loading.refreshing"
                            :extraction-fields="extractionFields"
                            @updated="extractedFieldsUpdated"
                            @refresh="refreshFields"
                            name="Order"/>
            </tab-pill>

        </tab-pills>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import {mapGetters} from "vuex"
import EventSubText from "../elments/EventSubText"
import FormError from "../../../../elements/alerts/FormError"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventInputField from "../../../../elements/fields/EventInputField"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import DefaultLabel from "../../../../elements/DefaultLabel"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import OrderProductOptions from "./OrderProductOptions"
import EventHelpers from "../mixins/event-helpers"
import OrderAdditionalCharges from "./OrderAdditionalCharges"
import OrderPaymentOptions from "./OrderPaymentOptions"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import OrderAutomation from "./OrderAutomation"
import MapFields from "../partials/MapFields"
import TabHeader from "../../../../elements/tabs/TabHeader"
import OrderReferralPartners from "./OrderReferralPartners"

export default {
    name: 'Order',

    components: {
        OrderReferralPartners,
        TabHeader,
        MapFields,
        OrderAutomation,
        LegacyMagicFields,
        LegacyCustomFields,
        OrderPaymentOptions,
        OrderAdditionalCharges,
        OrderProductOptions,
        TabPill,
        TabPills,
        DefaultLabel,
        CheckboxField,
        EventInputField,
        SelectField,
        InputLabel,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    mixins: [
        EventHelpers
    ],

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            loading: {
                refreshing: false,
            },
            dropdowns: {
                triggerFields: {
                    selected: {
                        title: null,
                    }
                }
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    charge_discount: 0,
                    charge_shipping: 0,
                    charge_tax: 0,
                    custom_fields: {},

                    discount_amount: '',
                    discount_amount_field: '',
                    discount_option: '',
                    discount_percentage: '',
                    dynamic_field: '',
                    dynamic_option: '',

                    extraction_fields: {},
                    find_product_item: '',
                    find_product_item_id: '',
                    find_product_item_name: '',
                    find_product_item_sku: '',

                    lead_affiliate_code: '',
                    lead_affiliate_email: '',
                    lead_affiliate_extraction_key: '',
                    lead_affiliate_field: '',
                    lead_set_affiliate: 'none',

                    magic_fields: {},

                    order_title_option: 'manual',
                    order_title_manual: '',
                    order_title_field: '',

                    payment: {
                        active: 0,
                        card_option: 'recent',
                        date: 0,
                        date_extracted_field: '',
                        manual_note: '',
                        merchant: 'default',
                        merchant_id: '',
                        note: '',
                        type: 2,
                        type_custom: 'Credit Card (Manual)',
                        type_extracted: '',
                    },
                    price: {
                        amount: '',
                        type: 0,
                        field: '',
                        amount_in_cents: 0,
                    },

                    product_array_key: '',
                    product_id: '',
                    product_item_name_field: '',
                    product_item_notes_field: '',
                    product_item_option: 'one_product',
                    product_item_price_field: '',
                    product_item_price_in_cents: 0,
                    product_item_quantity_field: '',
                    product_item_sku_field: '',
                    product_label: '',
                    product_quantity: {
                        amount: '',
                        field: '',
                        option: 0,
                    },

                    sale_affiliate_code: '',
                    sale_affiliate_email: '',
                    sale_affiliate_extraction_key: '',
                    sale_affiliate_field: '',
                    sale_set_affiliate: 'none',

                    shipping_amount: '',
                    shipping_charges_field: '',
                    shipping_option: 0,
                    shipping_percentage: '',
                    shouldAddProducts: 0,
                    tax_amount: '',
                    tax_charges_field: '',
                    tax_option: 0,
                    tax_percentage: '',
                },

            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.setDropdownTrigger('title', 'order_title_field')

        this.getOrderFields();
    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getOrderFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.refreshing = false;
                _.find(this.orderFields, group => {
                    let field = _.find(group.fields, field => {
                        return field.name === this.form.settings.selectedField
                    })
                    if (field) {
                        this.dropdowns.otherField.selected = field;
                        return true;
                    }
                    return false;
                })
            })
        },

        refreshFields() {
            this.loading.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        extractedFieldsUpdated(mappedFields) {
            this.form.settings.extraction_fields = mappedFields
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        extractionFields() {
            let fields = _.get(this.event, 'settings.extraction_fields')
            if (_.size(fields)) {
                return fields;
            }

            return {};
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        orderFields() {
            if (!_.get(this.action, 'fields.order')) {
                return [];
            }

            let fields = this.action.fields.order;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },

        allOrderFields() {
            return _.get(this.action, 'fields.order', []);
        },


    }
}
</script>

