<template>
    <round-icon-button @click="$emit('click')">
        <x-icon/>
    </round-icon-button>
</template>

<script>
import XIcon from "../../svgs/general/XIcon"
import RoundIconButton from "./RoundIconButton"

export default {
    name: 'CloseXButton',

    components: {
        RoundIconButton,
        XIcon
    }
}
</script>