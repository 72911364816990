<template>
    <default-well title="Security" description="Update your current password">

        <form @submit.prevent="save">

            <form-error :form="form"/>

            <success-alert v-if="showSuccess" message="Successfully updated your password"/>

            <input-label label="Current Password">
                <input-field type="password"
                             :form="form"
                             name="current"/>
            </input-label>

            <input-label label="New Password">
                <input-field type="password"
                             :form="form"
                             name="password"/>
            </input-label>

            <input-label label="Confirm Password">
                <input-field type="password"
                             :form="form"
                             name="password_confirmation"/>
            </input-label>

            <div class="mt-8">
                <submit-button :form="form" :wide="true" @save="save"/>
            </div>

        </form>

    </default-well>
</template>

<script>
import DefaultWell from "../../elements/DefaultWell"
import InputField from "../../elements/fields/InputField"
import SubmitButton from "../../elements/buttons/SubmitButton"
import FormError from "../../elements/alerts/FormError"
import SuccessAlert from "../../elements/alerts/SuccessAlert"
import InputLabel from "../../elements/fields/InputLabel"

export default {

    name: 'UserSecurity',

    components: {
        InputLabel,
        SuccessAlert,
        FormError,
        SubmitButton,
        InputField,
        DefaultWell
    },

    data() {
        return {
            form: new ParseyForm({
                current: null,
                password: null,
                password_confirmation: null,
            }),
            showSuccess: false,
        }
    },

    methods: {
        save() {
            Parsey.post(this.$store.state.user.routes.password, this.form)
                .then(() => {
                    this.showSuccess = true
                    this.form.current = null;
                    this.form.password = null;
                    this.form.password_confirmation = null;
                })
        }
    }

}
</script>
