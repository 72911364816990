<template>
    <default-wrapper>
        <div class="grid grid-cols-5 gap-4">
            <div class="hidden md:flex md:col-span-1 items-center">
                <h1 class="font-lead text-2xl font-semibold">Flows</h1>
            </div>
            <div class="relative hidden md:flex md:col-span-2 items-center justify-end text-gray-200">

                <router-link :to="{name: 'CreateLegacyFlow'}"
                             class="mr-7 h-9 w-9 p-2 rounded-full focus:outline-none text-brand-900 hover:bg-red-50">
                    <plus-icon/>
                </router-link>

                <button v-for="view in viewList"
                        @click="switchView(view.key)"
                        :class="{'text-gray-500': viewType === view.view}"
                        class="relative inline-flex items-center p-1 w-9 h-9 hover:text-gray-600 focus:outline-none transition ease-in-out duration-150">
                    <component :is="view.icon"/>
                </button>

            </div>
            <div class="col-span-5 md:col-span-2">

                <flow-search></flow-search>

            </div>
        </div>

        <div class="mt-6">
            <component v-if="showFlows"
                       :flows="flows"
                       :loading="loading"
                       :is="viewType"/>

            <div v-else>
                <default-well>
                    <div class="h-64 flex flex-col items-center justify-center">
                        <p class="text-lg">
                            Create your first flow
                        </p>

                        <router-link :to="{name: 'CreateLegacyFlow'}"
                                     class="btn btn-teal mt-4">
                            Create Flow
                        </router-link>
                    </div>
                </default-well>
            </div>
        </div>

    </default-wrapper>
</template>

<script>


import FlowRows from "./components/FlowRows"
import FlowCards from "./components/FlowCards"
import FlowSearch from "./components/FlowSearch"
import { mapGetters} from 'vuex'
import CenterModal from "../../elements/modals/CenterModal"
import DefaultWrapper from "../../layouts/partials/DefaultWrapper"
import PlusIcon from "../../svgs/menu/PlusIcon"
import CardsIcon from "../../svgs/general/CardsIcon"
import RowsIcon from "../../svgs/general/RowsIcon"
import DefaultWell from "../../elements/DefaultWell"

export default {

    name: "FlowIndex",

    components: {
        DefaultWell,
        CardsIcon,
        RowsIcon,
        PlusIcon,
        DefaultWrapper,
        CenterModal,
        FlowSearch,
        FlowCards,
        FlowRows
    },

    metaInfo: {
        title: 'Flows | Parsey'
    },

    data() {
        return {
            form: new ParseyForm({
                key: 'flow_view',
                value: this.flowView,
            }),

            views: [
                'cards',
                'rows'
            ]

        }
    },


    methods: {

        switchView(type) {
            this.form.value = type;
            this.$store.dispatch('updateMeta', {
                form: this.form
            })
        },

    },

    computed: {
        ...mapGetters('flows', {
            flows: 'filteredFlows',
            loading: 'loadingStatus',
        }),

        flowView() {
            return _.get(this.$store.state, 'user.meta.flow_view', 'cards')
        },

        viewType() {
            let view = this.form.value
            if (!view) {
                view = this.flowView
            }

            return 'Flow' + _.upperFirst(view);
        },

        viewList() {
            return _.map(this.views, view => {
                let cap = _.capitalize(view);
                return {
                    key: view,
                    name: cap,
                    view: `Flow${cap}`,
                    icon: `${cap}Icon`,
                }
            })
        },

        showFlows() {
            return this.loading || _.size(this.flows) > 0
        },


    }
}
</script>
