<template>
    <div class="flex items-center ml-6">

        <div v-for="button in buttons" :key="button.component"
             v-tooltip="button.name"
             @click.prevent="clickButton(button)"
             class="cursor-pointer mr-2 ">

            <spin-icon v-if="button.loading" class="h-6 w-6 p-1 text-gray-400" />
            <component v-else :is="button.component"
                       class="transition duration-300 ease-in-out h-6 w-6 p-1 hover:bg-gray-50 rounded-full transform hover:scale-125 outline-none"/>

        </div>

    </div>
</template>

<script>
import ChevronDoubleRightIcon from "../../../../svgs/general/ChevronDoubleRightIcon"
import ChevronRightIcon from "../../../../svgs/general/ChevronRightIcon"
import ChevronLeftIcon from "../../../../svgs/general/ChevronLeftIcon"
import ChevronDoubleLeftIcon from "../../../../svgs/general/ChevronDoubleLeftIcon"
import SpinIcon from "../../../../svgs/general/SpinIcon"

export default {
    name: 'ToggleTriggerButtons',

    components: {
        SpinIcon,
        ChevronDoubleRightIcon,
        ChevronRightIcon,
        ChevronLeftIcon,
        ChevronDoubleLeftIcon,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        name: {
            type: String,
            default: 'email',
        }
    },

    data() {
        return {
            lastClicked: '',
        }
    },

    methods: {
        clickButton(button) {
            this.lastClicked = button.method;
            this.$emit('change', button.method)
        }
    },

    computed: {
        buttons() {
            return [
                {
                    component: 'ChevronDoubleLeftIcon',
                    name: `Get first ${this.name}`,
                    method: 'first',
                    loading: this.lastClicked === 'first' && this.loading
                },
                {
                    component: 'ChevronLeftIcon',
                    name: `Get previous ${this.name}`,
                    method: 'previous',
                    loading: this.lastClicked === 'previous' && this.loading
                },
                {
                    component: 'ChevronRightIcon',
                    name: `Get next ${this.name}`,
                    method: 'next',
                    loading: this.lastClicked === 'next' && this.loading
                },
                {
                    component: 'ChevronDoubleRightIcon',
                    name: `Get most recent ${this.name}`,
                    method: 'last',
                    loading: this.lastClicked === 'last' && this.loading
                },
            ]
        }
    }
}
</script>