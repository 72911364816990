<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This event will update an existing order if one is found. If none is found it will do
            nothing.
        </event-sub-text>

        <form-error :form="form"/>

        <div :class="{'md:grid-cols-3 gap-x-4': findByOtherField, 'md:grid-cols-2 gap-x-10': !findByOtherField}"
             class="grid grid-cols-1 mb-4">

            <input-label label="Find order using">
                <select-field :options="dropdowns.findBy.options"
                              v-model="dropdowns.findBy.selected"
                              @input="findByUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="findByInvoiceId" label="Trigger Field with Invoice Id">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.invoiceField"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="findByOrderId" label="Trigger Field with Order Id">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.orderField"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="findByOtherField && !radio.findByManual" :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label> Trigger Field</default-label>
                        <label class="text-sm text-teal-400 cursor-pointer"
                               @click="toggleFindByManual">
                            Type Value
                        </label>
                    </div>
                </template>
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.otherField"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="findByOtherField && radio.findByManual" :label="false">
                <template #label>
                    <div class="flex justify-between">
                        <default-label>Type Value</default-label>
                        <label class="text-sm text-teal-400 cursor-pointer"
                               @click="toggleFindByManual">
                            Use Trigger Field
                        </label>
                    </div>
                </template>

                <event-input-field v-model="form.settings.typeOwnValue"/>

            </input-label>

            <input-label v-if="findByOtherField" label="Search Order Field">
                <select-field :options="orderFields"
                              v-model="dropdowns.otherField.selected"
                              :loading="loading.fields || loading.refreshing"
                              @input="updatedOtherField"
                              group-values="fields"
                              group-label="group"
                              track-by="name"
                              label="label"
                              placeholder="Select order field"/>
            </input-label>

        </div>

        <tab-pills>
            <tab-pill name="Products" :selected="true">
                <order-product-options :settings.sync="form.settings"
                                       :action="action"
                                       :event="event"
                                       :allow-skipping-product-item-option="true"/>
            </tab-pill>

            <tab-pill name="Additional Charges">
                <order-additional-charges :settings.sync="form.settings"
                                          :action="action"
                                          :event="event"/>
            </tab-pill>

            <tab-pill name="Payment">
                <order-payment-options :settings.sync="form.settings"
                                       :action="action"
                                       :event="event"/>
            </tab-pill>

            <tab-pill name="Automation">
                <order-automation :settings.sync="form.settings"
                                  :action="action"
                                  :event="event"/>
            </tab-pill>

        </tab-pills>

        <tab-pills>

            <tab-pill name="Custom Fields" :selected="true">
                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="orderFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Order"
                                      :refreshing="loading.refreshing"/>
            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="orderFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Order"
                                     :refreshing="loading.refreshing"/>
            </tab-pill>


        </tab-pills>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import {mapGetters} from "vuex"
import SelectField from "../../../../elements/fields/SelectField"
import InputLabel from "../../../../elements/fields/InputLabel"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import EventInputField from "../../../../elements/fields/EventInputField"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import OrderProductOptions from "./OrderProductOptions"
import DefaultLabel from "../../../../elements/DefaultLabel"
import OrderAdditionalCharges from "./OrderAdditionalCharges"
import OrderPaymentOptions from "./OrderPaymentOptions"
import OrderAutomation from "./OrderAutomation"
import EventSubText from "../elments/EventSubText"

export default {
    name: 'UpdateOrder',

    components: {
        EventSubText,
        OrderAutomation,
        OrderPaymentOptions,
        OrderAdditionalCharges,
        DefaultLabel,
        OrderProductOptions,
        LegacyCustomFields,
        LegacyMagicFields,
        TabPill,
        TabPills,
        EventInputField,
        CheckboxField,
        InputLabel,
        SelectField,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            loading: {
                fields: true,
                refreshing: false,
            },
            radio: {
                findByManual: false,
            },
            dropdowns: {
                triggerFields: {
                    selected: {
                        invoiceField: null,
                        orderField: null,
                        otherField: null,
                    }
                },
                findBy: {
                    selected: null,
                    options: [
                        {
                            value: 0,
                            label: 'The most recently CREATED order',
                        },
                        {
                            value: 1,
                            label: 'The most recently UPDATED order',
                        },
                        {
                            value: 2,
                            label: 'The Order Id',
                        },
                        {
                            value: 3,
                            label: 'The Invoice Id',
                        },
                        {
                            value: 4,
                            label: 'Another field on the order',
                        },
                    ],
                },
                otherField: {
                    selected: null,
                }
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    charge_discount: 0,
                    charge_shipping: 0,
                    charge_tax: 0,
                    custom_fields: {},
                    create_product_item: 0,
                    discount_amount: '',
                    discount_amount_field: '',
                    discount_option: '',
                    discount_percentage: '',
                    dynamic_field: '',
                    dynamic_option: '',
                    findBy: 4,
                    find_product_item: '',
                    find_product_item_id: '',
                    find_product_item_name: '',
                    find_product_item_sku: '',
                    invoiceIdField: '',
                    magic_fields: {},
                    payment: {
                        active: 0,
                        card_option: 'recent',
                        date: 0,
                        date_extracted_field: '',
                        manual_note: '',
                        merchant: 'default',
                        merchant_id: '',
                        note: '',
                        type: 2,
                        type_custom: 'Credit Card (Manual)',
                        type_extracted: '',
                    },
                    price: {
                        amount: '',
                        type: 0,
                        field: '',
                        amount_in_cents: 0,
                    },
                    product_array_key: '',
                    product_id: '',
                    product_item_name_field: '',
                    product_item_notes_field: '',
                    product_item_option: 'one_product',
                    product_item_price_field: '',
                    product_item_price_in_cents: 0,
                    product_item_quantity_field: '',
                    product_item_sku_field: '',
                    product_label: '',
                    product_quantity: {
                        amount: '',
                        field: '',
                        option: 0,
                    },
                    selectedField: 'ProductId',
                    selectedFieldValue: '',
                    shipping_amount: '',
                    shipping_charges_field: '',
                    shipping_option: 0,
                    shipping_percentage: '',
                    shouldAddProducts: 0,
                    tax_amount: '',
                    tax_charges_field: '',
                    tax_option: 0,
                    tax_percentage: '',
                    typeOwnValue: 'Offline',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.dropdowns.findBy.selected = _.find(this.dropdowns.findBy.options, option => {
            return parseInt(option.value) === parseInt(this.form.settings.findBy)
        })

        this.dropdowns.triggerFields.selected.orderField = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.orderIdField)
        })

        this.dropdowns.triggerFields.selected.invoiceField = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.invoiceIdField)
        })

        this.dropdowns.triggerFields.selected.otherField = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.selectedFieldValue)
        })

        if (this.form.settings.selectedFieldValue === 'type_your_own') {
            this.radio.findByManual = true;
        }

        this.getOrderFields();
    },

    methods: {

        save() {

            //console.log('FORM SETTINGS', this.form.settings);

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getOrderFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.fields = false;
                this.loading.refreshing = false;
                _.find(this.orderFields, group => {
                    let field = _.find(group.fields, field => {
                        return field.name === this.form.settings.selectedField
                    })
                    if (field) {
                        this.dropdowns.otherField.selected = field;
                        return true;
                    }
                    return false;
                })
            })
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

        refreshFields() {
            this.loading.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        findByUpdated() {
            this.form.settings.findBy = _.get(this.dropdowns.findBy, 'selected.value')
        },

        updatedOtherField() {
            this.form.settings.selectedField = _.get(this.dropdowns.otherField, 'selected.value')
        },

        toggleFindByManual() {
            this.radio.findByManual = !this.radio.findByManual
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        findByOtherField() {
            return parseInt(this.form.settings.findBy) === 4;
        },

        findByOrderId() {
            return parseInt(this.form.settings.findBy) === 2;
        },

        findByInvoiceId() {
            return parseInt(this.form.settings.findBy) === 3;
        },

        orderFields() {
            if (!_.get(this.action, 'fields.order')) {
                return [];
            }

            let fields = this.action.fields.order;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },

        findByOtherAndManual() {
            return (this.form.settings.selectedFieldValue === 'type_your_own')
        }
    }
}
</script>

