<template>
    <div>

        <tab-header>
            Referral Partner
            <template #sub>
                When an order is created you can set the referral partner based on the settings
                below
            </template>
        </tab-header>

        <div v-if="affiliateFields.length > 2">
            <order-referral-partner type="lead"
                                    :event="event"
                                    :action="action"
                                    :affiliate-fields="affiliateFields"
                                    :settings.sync="settings"/>

            <order-referral-partner type="sale"
                                    :event="event"
                                    :action="action"
                                    :affiliate-fields="affiliateFields"
                                    :settings.sync="settings"/>
        </div>

    </div>
</template>

<script>

import OrderReferralPartner from "./OrderReferralPartner"
import TabHeader from "../../../../elements/tabs/TabHeader"

export default {
    name: 'OrderReferralPartners',

    components: {
        OrderReferralPartner,
        TabHeader,
    },

    props: {
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        }
    },


    mounted() {
        this.getAffiliateFields();
    },

    methods: {
        getAffiliateFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'affiliate'
            })
        },

    },

    computed: {

        affiliateFields() {
            if (!_.get(this.action, 'fields.affiliate')) {
                return [];
            }

            let fields = this.action.fields.affiliate;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },

    }
}
</script>