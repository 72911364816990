<template>
    <button :class="sizeClasses"
            class="inline-flex items-center justify-center text-gray-200 rounded-full bg-transparent hover:text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
        <dots-menu/>
    </button>
</template>

<script>

import DotsMenu from "../../svgs/menu/DotsMenu"

export default {

    name: 'ButtonMenu',

    components: {
        DotsMenu,
    },

    props: {
        size: {
            default: 7,
        },
        spacing: {
            default: 1,
        },
    },

    computed: {

        sizeClasses() {
            return `w-${this.size} h-${this.size} p-${this.spacing}`
        },

    }
}
</script>
