<template>
    <flow-step name="Mailbox"
               :open="`${flow.views.trigger}/mailbox`"
               :close="flow.views.trigger"
               :center-content="true"
               :last="hasNotSelectedMailbox"
               :name-editable="false">
        <template #content>

            <div v-if="mailboxOptions.length > 0">

                <form @submit.prevent="saveMailbox"
                      @keydown.enter.prevent="saveMailbox">

                    <input-label label="Choose Mailbox">
                        <select-field :options="mailboxOptions"
                                      v-model="dropdowns.mailbox.selected"
                                      @input="updateFromDropDownId('mailbox_id', 'mailbox')"
                                      track-by="id"
                                      label="name"/>
                    </input-label>

                    <submit-button v-if="!showCreate"
                                   :form="updateForm"
                                   class="mt-8"
                                   text="Save"
                                   @save="saveMailbox"
                                   loading="Saving..."
                                   switch-at="xl"/>

                </form>
            </div>

            <div v-if="showCreate">

                <form @submit.prevent="createMailbox"
                      @keydown.enter.prevent="createMailbox">

                    <form-error :form="createForm"/>

                    <input-label label="Name">
                        <input-field :form="createForm"
                                     name="name"
                                     placeholder="My mailbox for..."
                                     help="For your reference only"/>
                    </input-label>


                    <input-label label="Forward Emails To">
                        <input-field :form="createForm"
                                     name="forward"
                                     placeholder="me@myemail.com"
                                     help="If you wish to also receive a copy of every email"/>
                    </input-label>


                    <submit-button :form="createForm"
                                   text="Save"
                                   @save="createMailbox"
                                   loading="Saving..."
                                   switch-at="xl"/>

                </form>

            </div>


        </template>
    </flow-step>
</template>

<script>
import FlowStep from "../../components/FlowStep"
import {mapState} from "vuex"
import SelectField from "../../../../elements/fields/SelectField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import EventHelpers from '../mixins/event-helpers'
import InputLabel from "../../../../elements/fields/InputLabel"
import InputField from "../../../../elements/fields/InputField"
import FormError from "../../../../elements/alerts/FormError"

export default {
    name: 'LegacyTriggerMailbox',

    components: {
        FormError,
        InputField,
        InputLabel,
        SubmitButton,
        SelectField,
        FlowStep
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            settingKeyBase: 'updateForm',

            dropdowns: {
                mailbox: {
                    selected: null,
                    options: [],
                }
            },

            updateForm: new ParseyForm({
                mailbox_id: 0,
            }),

            createForm: new ParseyForm({
                name: '',
                forwards: '',
            }),
        }
    },

    mounted() {
        let mailboxId = parseInt(_.get(this.flow, 'trigger.mailbox', 0));
        if (mailboxId) {
            this.updateForm.mailbox_id = mailboxId;
            this.dropdowns.mailbox.selected = _.find(this.mailboxOptions, option => option.id === mailboxId)
        }

    },

    methods: {
        saveMailbox() {
            Parsey.put(this.flow.routes.update, this.updateForm)
                .then(({flow}) => {
                    this.$store.commit('flow/setFlow', flow)
                    this.$router.push({
                        path: this.flow.views.trigger + '/fields'
                    })
                })
        },

        createMailbox() {
            Parsey.post(this.$store.state.routes.mailboxes.store, this.createForm)
                .then(({mailbox}) => {
                    let mailboxObject = {
                        id: mailbox.id,
                        name: mailbox.name,
                    }
                    this.$store.commit('flow/addTriggerMailbox', {
                        mailbox: mailboxObject
                    })
                    this.updateForm.mailbox_id = mailbox.id;
                    this.dropdowns.mailbox.selected = mailboxObject;
                    this.saveMailbox();
                })
        },
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        mailboxOptions() {
            let options = this.flow.trigger.mailboxes;

            options = _.sortBy(options, 'name')

            options.push({
                id: 'new',
                name: 'Create New Mailbox',
            })

            return options;
        },

        showCreate() {
            if (_.size(this.mailboxOptions) === 0) {
                return true;
            }

            return this.updateForm.mailbox_id === 'new';
        },

        hasNotSelectedMailbox() {
            return _.get(this.flow, 'trigger.mailbox') === null;
        },

    },

}
</script>