<template>
    <default-wrapper>

        <history-header />

        <div class="bg-white shadow overflow-hidden sm:rounded-md mb-20">
            <ul :class="{'animate-pulse': $store.state.history.loading}"
                v-if="showRows"
                class="divide-y divide-gray-200">
                <li v-for="row in $store.state.history.data" :key="row.id">
                    <router-link :to="{path: row.routes.view}"
                                 class="block hover:bg-blue-50 cursor-pointer">
                        <history-row :icon="row.app.key"
                                     :title="row.record.name"
                                     :badge-class="row.status.class.main"
                                     :badge="row.status.name"
                                     :description="row.record.preview"
                                     :date="row.date"/>
                    </router-link>
                </li>
            </ul>
            <ul v-else class="divide-y divide-gray-200 animate-pulse">
                <li v-for="row in loadingRange()">
                    <history-row :loading="true"/>
                </li>
            </ul>

            <default-pagination :loading="$store.state.history.loading"
                                :links="$store.state.history.links"
                                route-name="History"
                                :search="$store.state.history.search"
                                :meta="$store.state.history.meta"/>

        </div>

    </default-wrapper>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import HistoryRow from "./partials/HistoryRow"
import HistoryHeader from "./partials/HistoryHeader"
import DefaultPagination from "../../elements/DefaultPagination"
import DefaultWrapper from "../../layouts/partials/DefaultWrapper"

export default {
    name: 'TriggerHistoryIndex',

    components: {
        HistoryHeader,
        HistoryRow,
        DefaultPagination,
        DefaultWrapper
    },

    metaInfo: {
        title: 'Parsey | History'
    },

    watch: {
        '$route.query'() {
            this.$store.commit('history/setSearch')
            this.getHistory()
        }
    },

    mounted() {
        this.$store.commit('history/setSearch')
        this.getHistory()
    },

    methods: {
        ...mapActions('history', [
            'getHistory',
        ]),

        loadingRange() {
            return _.range(10);
        },

    },

    computed: {
        ...mapGetters('history', [
            'selectedStatuses',
            'selectedFlows',
        ]),

        loading() {
            return this.$store.state.history.loading;
        },

        showRows() {
            return _.size(this.$store.state.history.data) > 0 || !this.$store.state.history.loading
        },

    },

}
</script>
