<template>
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span class="text-gray-400 text-sm"><slot/></span>
    </div>
</template>

<script>
export default {
    name: 'LeadingText'
}
</script>