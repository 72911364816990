<template>
    <button @click.prevent="$emit('click')"
            class="h-8 w-8  rounded-full cursor-pointer sm:h-11 sm:w-11 p-2 sm:p-3 ml-2 text-gray-500 hover:text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <spin-icon v-if="loading"/>
        <trash-icon v-else/>
    </button>
</template>

<script>

import TrashIcon from "../../svgs/general/TrashIcon"
import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'TrashButton',

    components: {
        SpinIcon,
        TrashIcon
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>