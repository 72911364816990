module.exports = {

    data() {
        return {
            confirmDelete: 0,
            deleting: 0,
            deleteTimeOut: null,
        }
    },

    methods: {
        deleteItem(itemId, dispatch, payload) {
            if (this.deleting > 0) {
                return false;
            }

            if (this.confirmDelete === 0) {
                this.confirmDelete = itemId;
                this.deleteTimeOut = setTimeout(() => {
                    this.confirmDelete = 0;
                    this.deleting = 0;
                }, 2000);
                return;
            }

            clearTimeout(this.deleteTimeOut)
            this.confirmDelete = 0;
            this.deleting = itemId;
            this.$store.dispatch(dispatch, payload).then(() => {
                this.deleting = 0;
            })
        },

        deleteWithMethod(itemId, method) {
            if (this.deleting > 0) {
                return false;
            }

            if (this.confirmDelete === 0) {
                this.confirmDelete = itemId;
                this.deleteTimeOut = setTimeout(() => {
                    this.confirmDelete = 0;
                    this.deleting = 0;
                }, 2000);
                return;
            }

            clearTimeout(this.deleteTimeOut)
            this.confirmDelete = 0;
            this.deleting = itemId;
            this[method]();
        }
    }

}
