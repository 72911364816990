<template>
    <div class="mt-8 w-full sm:max-w-md">
        <div :class="{'animate-shake': form.errors.hasErrors()}"
             class="bg-white py-8 px-4 sm:shadow-lg sm:rounded sm:px-10 sm:py-12">

            <h2 class="text-lg leading-6 text-gray-900 pb-6">
                Sign in to your account
            </h2>

            <form>
                <div>
                    <input-label label="Email">
                        <input-field name="email"
                                     :form="form"
                                     type="email"
                                     placeholder="your@email.com"/>
                    </input-label>
                </div>

                <div class="mt-6">
                    <input-label :label="false">
                        <template #label>
                            <div class="flex items-start justify-between">
                                <default-label>Password</default-label>
                                <a href="/password/reset"
                                   class="text-sm text-teal-500 hover:text-teal-400 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    Forgot your password?
                                </a>
                            </div>
                        </template>
                        <input-field name="password"
                                     :form="form"
                                     type="password"
                                     placeholder="unique password"/>
                    </input-label>

                </div>

                <div class="mt-8 flex items-center justify-between">
                    <checkbox-field id="parsey_remember" v-model="form.remember">
                        Stay signed in
                    </checkbox-field>
                </div>

                <div class="mt-8">
                    <span class="block w-full rounded-md shadow-sm">
                        <button @click.prevent="login"
                                :disabled="form.busy"
                                :class="{'disabled':form.busy}"
                                v-html="form.buttonHtml('Continue', 'Validating...', 'md')"
                                class="btn btn-teal btn-block">
                        </button>
                    </span>
                </div>
            </form>

        </div>
    </div>
</template>

<script>

import InputField from "../elements/fields/InputField"
import CheckboxField from "../elements/fields/CheckboxField"
import InputLabel from "../elements/fields/InputLabel"
import DefaultLabel from "../elements/DefaultLabel"

export default {

    name: "LoginForm",

    components: {
        DefaultLabel,
        InputLabel,
        CheckboxField,
        InputField
    },

    data() {
        return {
            form: new ParseyForm({
                email: '',
                password: '',
                remember: false,
            })
        }
    },

    methods: {

        login() {
            Parsey.post('login', this.form)
        }

    }
}
</script>

