/**
 * Helper class for search objects for dropdowns
 * On the dropdown use @search-change="searchName.search($event)"
 */
window.ParseySearch = function (data, callBack) {

    this.url = '';
    this.query = '';
    this.type = '';

    this.selected = null;
    this.options = [];
    this.default = {
        name: '',
        label: 'Searching...'
    }

    this.noResult = 'Nothing found with that search'
    this.minimumCharacters = 2

    this.searching = false
    this.waiting = true
    this.timeout = null
    this.appendSearch = '';

    _.extend(this, data)

    this.originalNoResult = JSON.parse(JSON.stringify(this.noResult));

    this.search = (query) => {
        this.query = query

        if (this.query.length < this.minimumCharacters) {
            this.noResult = `Type at last ${this.minimumCharacters} characters`
            return;
        }

        this.noResult = this.originalNoResult;

        if (_.size(this.options) === 0) {
            this.options = [JSON.parse(JSON.stringify(this.default))]
        }

        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        this.searching = true;
        this.timeout = setTimeout(() => {
            this.doSearch();
        }, 500)
    }

    this.doSearch = () => {
        this.searching = true;
        if (this.query.length === 0) {
            this.searching = false;
            return;
        }

        return new Promise((resolve, reject) => {
            Parsey.get(`${this.url}?type=${this.type}&search=${this.query}${this.appendSearch}`)
                .then(results => {
                    this.searching = false;
                    this.options = results;
                    resolve(results)
                    if (typeof callBack === 'function') {
                        callBack();
                    }
                })
                .catch(({errors}) => {
                    reject(errors)
                })
        })
    }

}
