<template>

    <div>
        <default-well title="Contact Information" description="Manage your contact information used for your account.">

            <form @submit.prevent="save">

                <input-label label="First Name">
                    <input-field type="text"
                                 :form="form"
                                 name="first_name"
                                 placeholder="Michael"/>
                </input-label>

                <input-label label="Last Name">
                    <input-field type="text"
                                 :form="form"
                                 name="last_name"
                                 placeholder="Thompson"/>
                </input-label>

                <input-label label="Email Address">
                    <input-field type="email"
                                 :form="form"
                                 name="email"
                                 help="Also used as your login email"
                                 placeholder="your@email.com"/>
                </input-label>

                <input-label label="Phone Number">
                    <input-field type="phone"
                                 :form="form"
                                 name="phone"
                                 placeholder="801-555-1234"/>

                </input-label>

                <div class="mt-8">
                    <submit-button :form="form" :wide="true" @save="save"/>
                </div>


            </form>
        </default-well>


        <default-well title="Profile Photo">

            <div class="inline-block relative"
                 @mouseleave="showDelete = false"
                 @mouseenter="showDelete = true">
                <default-avatar/>
                <div v-show="showDelete && photoUrl"
                     title="Delete Image"
                     @click.prevent="deletePhoto"
                     class="w-4 h-4 absolute top-0 right-0 text-red-600 cursor-pointer">
                    <form>
                        <spin-icon v-if="deleteForm.busy"/>
                        <circle-close-icon v-else/>
                    </form>
                </div>
            </div>

            <div class="mt-3">
                <form>
                    <input type="file"
                           class="hidden"
                           @change.prevent="updatePhoto"
                           ref="photo"/>

                    <button type="button"
                            @click.prevent="openFiles"
                            :disabled="photoForm.busy"
                            :class="{disabled: photoForm.busy}"
                            v-html="photoForm.buttonHtml('Change', 'Uploading...', 'md')"
                            class="btn btn-sm btn-teal-outline w-32">
                    </button>
                </form>

            </div>

        </default-well>
    </div>


</template>

<script>
import DefaultWell from "../../elements/DefaultWell"
import InputField from "../../elements/fields/InputField"
import SubmitButton from "../../elements/buttons/SubmitButton"
import DefaultAvatar from "../../elements/DefaultAvatar"
import CircleCloseIcon from "../../svgs/general/CircleCloseIcon"
import SpinIcon from "../../svgs/general/SpinIcon"
import InputLabel from "../../elements/fields/InputLabel"

export default {
    name: "UserProfile",

    components: {
        InputLabel,
        SpinIcon,
        CircleCloseIcon,
        DefaultAvatar,
        SubmitButton,
        InputField,
        DefaultWell
    },

    metaInfo: {
        title: 'Profile | Settings'
    },

    data() {
        return {
            form: new ParseyForm({
                first_name: this.$store.state.user.first_name,
                last_name: this.$store.state.user.last_name,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
            }),

            showDelete: false,

            deleteForm: new ParseyForm({}),

            photoForm: new ParseyForm({}),
        }
    },

    methods: {
        save() {
            Parsey.post(this.$store.state.user.routes.profile, this.form)
        },

        openFiles() {
            this.$refs.photo.click()
        },

        updatePhoto() {
            const imageInput = this.$refs.photo;

            if (!this.$refs.photo.files.length) {
                return;
            }

            const self = this;

            self.photoForm.startProcessing();

            axios.post(this.$store.state.user.routes.photo, this.gatherFormData())
                .then(() => {
                        self.photoForm.finishProcessing();
                        imageInput.type = 'text';
                        imageInput.type = 'file';
                    },
                    (error) => {
                        self.photoForm.setErrors(error.response.data.errors);
                    }
                );
        },

        gatherFormData() {
            const data = new FormData();
            data.append('photo', this.$refs.photo.files[0]);

            return data;
        },

        deletePhoto() {
            Parsey.delete(this.$store.state.user.routes.photo_delete, this.deleteForm)
        },

    },

    computed: {
        photoUrl() {
            return this.$store.state.user.photo_url;
        },
    }
}
</script>
