<template>
    <div v-if="show" class="fixed z-20 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen py-4 text-center sm:p-4">

            <transition enter-active-class="ease-out duration-300"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0">
                <div v-show="show" @click="close"
                     class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
            </transition>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <transition enter-active-class="ease-out duration-300"
                        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div v-show="show"
                     @click.stop=""
                     :class="`${sizeClass} ${overFlowClass}`"
                     class="inline-block w-full bg-white sm:rounded-lg px-4 py-4 text-left shadow-xl transform transition-all sm:p-6"
                     role="dialog"
                     aria-modal="true">
                    <div>
                        <slot></slot>
                    </div>
                </div>
            </transition>

        </div>
    </div>
</template>

<script>
export default {
    name: "CenterModal",

    props: {
        show: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'sm'
        },

        allowOverFlow: {
            type: Boolean,
            default: false,
        }
    },

    watch: {
        show() {
            if (this.show) {
                document.body.classList.remove('overflow-hidden')
                return
            }

            document.body.classList.add('overflow-hidden')
        },
    },

    methods: {
        close() {
            this.$emit('close')
        }
    },

    computed: {
        overFlowClass() {
            if (this.allowOverFlow) {
                return ''
            }

            return 'overflow-hidden'
        },

        sizeClass() {
            if (this.size == 'md') {
                return 'sm:max-w-md'
            }

            if (this.size == 'lg') {
                return 'sm:max-w-lg'
            }

            if (this.size == '2xl') {
                return 'md:max-w-2xl'
            }

            if (this.size == '3xl') {
                return 'md:max-w-3xl'
            }

            if (this.size == '4xl') {
                return 'md:max-w-4xl'
            }

            if (this.size == '5xl') {
                return 'md:max-w-5xl'
            }

            if (this.size == '6xl') {
                return 'md:max-w-6xl'
            }

            return 'sm:max-w-sm';
        }
    }
}
</script>
