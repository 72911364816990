import {createRouter, createWebHistory} from 'vue-router';
import authRoutes from "./routes/auth"
import appRoutes from "./routes/web"
import errorRoutes from "./routes/errors"

const router = createRouter({

    history: createWebHistory(),

    routes: [
        ...authRoutes,
        ...appRoutes,
        ...errorRoutes
    ],
})

export default router
