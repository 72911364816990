<template>
    <td :class="extraClasses"

        class="py-3 text-sm">
        <slot/>
    </td>
</template>

<script>
export default {
    name: 'TableCell',

    props: {
        bold: {
            default: false,
        },
        align: {
            default: 'left',
        },
        classes: {
            type: Array,
            default: () => {
                return []
            },
        },
        verticalPadding: {
            type: Boolean,
            default: true,
        },

    },

    computed: {
        extraClasses() {
            let classes = JSON.parse(JSON.stringify(this.classes));

            if (this.bold) {
                classes.push('font-medium text-gray-900');
            } else {
                classes.push('text-gray-500')
            }

            switch (this.align) {
                case 'center':
                    classes.push('text-center')
                    break;
                case 'right':
                    classes.push('text-right')
                    break;
                default:
                    classes.push('text-left')
                    break;
            }

            if (this.verticalPadding) {
                classes.push('px-4 md:px-6')
            }

            return classes.join(' ');
        }
    }
}
</script>
