<template>

    <div :class="{'animate-shake': form.errors.hasErrors()}">

        <h1 class="text-2xl leading-8 text-gray-900 pb-6">
            Create you Parsey account
        </h1>

        <form @submit.prevent="register" @keydown.enter.prevent="register">
            <div>
                <input-label label="Full Name">
                    <input-field name="name"
                                 :form="form"
                                 type="text"
                                 placeholder="Your Name"></input-field>
                </input-label>
            </div>
            <div class="mt-8">
                <input-label label="Email">
                    <input-field name="email"
                                 :form="form"
                                 type="email"
                                 placeholder="your@email.com"></input-field>
                </input-label>
            </div>

            <div class="mt-8 flex items-center justify-between">
                <checkbox-field id="register_agree" v-model="form.agree"
                                :form="form" form-key="agree">
                    I agree with the
                    <a href="https://parsey.com/legal/eula/"
                       class="text-gray-600 hover:text-gray-400"
                       target="_blank">EULA</a>
                    and
                    <a href="https://parsey.com/legal/privacy-policy/"
                       class="text-gray-600 hover:text-gray-400"
                       target="_blank">
                        Billing Policy
                    </a>
                </checkbox-field>
            </div>

            <div class="mt-8">
                <button @click="register"
                        type="submit"
                        :disabled="form.busy || missingData"
                        :class="{'disabled':form.busy || missingData}"
                        v-html="form.buttonHtml('Create account', 'Creating...')"
                        class="btn btn-teal btn-block">
                </button>
            </div>
        </form>

    </div>

</template>

<script>

import InputField from "../elements/fields/InputField"
import CheckboxField from "../elements/fields/CheckboxField"
import InputLabel from "../elements/fields/InputLabel"

export default {

    name: "RegisterForm",

    components: {
        InputLabel,
        CheckboxField,
        InputField
    },

    data() {
        return {
            form: new ParseyForm({
                name: '',
                email: '',
                agree: false,
            })
        }
    },

    methods: {
        register() {
            if (this.missingData) {
                if (this.form.errors.hasErrors()) return;

                this.form.errors.addError('form', 'Missing Data')
                return;
            }

            Parsey.post('register', this.form)
        }
    },

    computed: {
        missingData() {
            if (this.form.name.length < 2) return true;

            if (this.form.email.length < 4) return true;

            if (!this.form.email.includes('@')) return true;

            if (!this.form.agree) return true;

            return false;
        }
    }
}
</script>

