<template>

    <div :class="{'cursor-pointer hover:bg-gray-50' : !fromArray && selectable, 'bg-indigo-50': highlight}"
         class="px-2 py-1 text-xs sm:text-sm rounded mb-1 flex items-start "
         @click="toggleField">

        <div v-if="!fromArray" class="inline-block flex-shrink-0 w-4 h-4 mr-3 mt-0.5">
            <div v-if="selectable"
                 :class="{'bg-teal-500' : selected || form.busy}"
                 class="h-full w-full rounded-sm border border-gray-200">
                <spin-icon v-if="form.busy" class="text-white"/>
                <checkmark-icon v-else-if="selected" class="text-white"/>
            </div>
        </div>

        <div v-text="name" class="font-medium text-gray-600"></div>

        <div class="px-1">:</div>

        <div class="font-light text-gray-300"
             :class="{'text-gray-500': highlight}"
             v-text="value"></div>

    </div>

</template>

<script>
import CheckmarkIcon from "../../../../svgs/general/CheckmarkIcon"
import {mapState} from 'vuex'
import SpinIcon from "../../../../svgs/general/SpinIcon"

export default {
    name: 'TreeChild',

    components: {
        SpinIcon,
        CheckmarkIcon
    },

    props: {
        name: {},

        value: {},

        path: {
            type: String,
        },

        isArray: {
            type: Boolean,
            default: false,
        },

        fromArray: {
            type: Boolean,
            default: false,
        },

        selectable: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            form: new ParseyForm({
                path: this.path,
                array: this.isArray,
            }),
        }
    },

    computed: {
        ...mapState('flow', {
            fields: (state) => {
                return _.get(state.data, 'trigger.fields')
            }
        }),

        selected() {
            let found = _.find(this.fields, field => {
                return field.path === this.path
            })

            return found ? true : false;
        },

        highlight() {
            return this.path === _.get(this.$store.state.flow, 'highlight.path')
        }
    },

    methods: {
        toggleField() {
            if (!this.selectable) {
                return true;
            }

            if (this.selected === true) {
                let field = _.find(this.fields, field => {
                    return field.path === this.form.path;
                });

                if (field && field.id) {
                    this.$store.dispatch('flow/deleteLegacyField', {
                        field: field,
                        form: this.form,
                    })
                }
                return;
            }

            this.$store.dispatch('flow/addLegacyField', this.form)
        }
    }
}
</script>
