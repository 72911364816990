<template>
    <div>
        <div class="flex justify-between items-center  mb-5">
            <p v-if="title"
               class="text-xl font-semibold">{{ title }}</p>
            <status-badge v-if="status"
                          class="mr-2 sm:mr-4"
                          :class="status.class.main"> {{ status.name }}
            </status-badge>
        </div>

        <tab-pills>
            <tab-pill :selected="showDetails" name="Details">
                <slot/>
            </tab-pill>
            <tab-pill :selected="showLogs" v-if="hasLogs" name="Logs">
                <div v-for="log in event.logs" class="flex items-center mb-2">
                    <status-badge :class="log.status.class.main">
                        {{ log.status.name }}
                    </status-badge>
                    <code class="block whitespace-pre overflow-x-scroll"
                          v-text="log.message"></code>
                </div>

            </tab-pill>
            <tab-pill v-if="response" name="Response">
                <code class="block whitespace-pre overflow-x-scroll bg-gray-50 p-4 rounded"
                      v-text="response"></code>
            </tab-pill>
        </tab-pills>

    </div>
</template>

<script>
import StatusBadge from "../../../elements/badges/StatusBadge"
import TabPills from "../../../elements/tabs/TabPills"
import TabPill from "../../../elements/tabs/TabPill"

export default {
    name: 'EventHistoryDetails',

    components: {
        TabPill,
        TabPills,
        StatusBadge,
    },

    props: {
        title: {
            type: String,
        },

        status: {
            type: Object,
        },

        response: {
            type: [String, Object],
        },

        event: {
            type: Object,
        }

    },

    computed: {
        showLogs() {
            return !!this.hasLogs;
        },

        showDetails() {
            return !this.hasLogs;
        },

        hasLogs() {
            if (!this.event) {
                return false;
            }

            return _.size(this.event.logs);
        }
    }
}
</script>