<template>
    <div :class="`h-${size} w-${size} ${borderClass}`"
         class="rounded-full overflow-hidden">
        <img :src="photoUrl" v-if="photoUrl" width="100%" height="100%"/>

        <svg width="100%" height="100%" v-else class="text-gray-800" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="24" height="24" fill="currentColor"/>
            <text x="12" y="13" font-size="12" fill="white" text-anchor="middle" dominant-baseline="middle">
                {{ initials }}
            </text>
        </svg>

    </div>
</template>

<script>
export default {

    name: 'DefaultAvatar',

    props: {
        size: {
            default: 32,
        },

        border: {
            default: false,
        }
    },

    computed: {
        photoUrl() {
            return this.$store.state.user.photo_url;
        },

        borderClass() {
            if (this.border) {
                return 'border border-gray-900'
            }

            return '';
        },

        initials() {
            let first = this.$store.state.user.first_name;
            let last = this.$store.state.user.last_name;
            if (last.length > 0) {
                return first.substring(0, 1) + last.substring(0, 1);
            }

            return first.substring(0, 2);
        }


    }
}
</script>
