<template>

    <div>
        <textarea v-bind="$attrs"
                  @input="$emit('update', $event.target.value)"
                  class="p-3 block w-full border border-gray-100 border border-gray-100 rounded focus:ring-blue focus:border-blue-300 transition text-xs sm:text-sm sm:leading-5">
            {{ value }}
        </textarea>
        <p v-if="helpMessage.length > 1"
           :class="helpColor"
           class="mt-1 text-xs">
            {{ helpMessage }}
        </p>
    </div>

</template>

<script>
export default {
    name: 'TextAreaField',

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'update'
    },

    props: {
        id: {
            type: String,
        },

        value: {
            type: [String],
        },

        help: {
            type: String,
            default: '',
        },

        form: {
            type: Object,
        },

        formKey: {
            type: String,
        },

    },

    computed: {
        helpMessage() {
            if (this.form && this.form.errors.has(this.formKey)) {
                return this.form.errors.get(this.formKey);
            }

            if (this.help.length > 1) {
                return this.help;
            }

            return '';
        },

        helpColor() {
            if (this.form && this.form.errors.has(this.formKey)) {
                return 'text-red-600';
            }

            return 'text-gray-300';
        }
    }

}
</script>

