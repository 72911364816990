<template>
    <center-modal :show="show" @close="close">

        <form-error :form="form"/>

        <form @submit.prevent="save" @keydown.enter.prevent="save">
            <default-label>Name</default-label>
            <div>
                <input-field :form="form" name="name"/>
            </div>

            <modal-default-buttons :form="form" @save="save" @close="close" />

        </form>

    </center-modal>
</template>

<script>
import CenterModal from "../../../elements/modals/CenterModal"
import InputField from "../../../elements/fields/InputField"
import FormError from "../../../elements/alerts/FormError"
import ModalDefaultButtons from "../../../elements/modals/ModalDefaultButtons"
import DefaultLabel from "../../../elements/DefaultLabel"

export default {
    name: "RenameFlowModal",

    props: [
        'flow',
        'show',
    ],

    data() {
        return {
            form: new ParseyForm({
                name: this.flow.name,
            })
        }
    },

    components: {
        DefaultLabel,
        ModalDefaultButtons,
        FormError,
        InputField,
        CenterModal
    },

    methods: {
        close() {
            this.$emit('close');
        },

        save() {
            this.$store.dispatch("flows/update", {
                flow: this.flow,
                form: this.form
            }).then(() => {
                this.close();
            })
        },
    }

}
</script>
