<template>

    <div>

        <input-label v-for="field in fields" :key="field.key"
                        :label="field.label">

            <input-field v-if="field.type == 'password'"
                         :form="form"
                         type="password"
                         :help="field.description"
                         :name="`settings[${field.key}]`"/>


            <leading-trailing-field v-else-if="field.type === 'infusionsoft'"
                                    :form="form"
                                    :help="field.description"
                                    lead="https://"
                                    lead-space="16"
                                    trail=".infusionsoft.com"
                                    trail-space="36"
                                    placeholder="your_app_name"
                                    :name="`settings[${field.key}]`"/>

            <input-field v-else
                         :form="form"
                         :help="field.description"
                         :name="`settings[${field.key}]`"/>

        </input-label>

    </div>

</template>

<script>
import InputField from "../../elements/fields/InputField"
import InputLabel from "../../elements/fields/InputLabel"
import LeadingTrailingField from "../../elements/fields/LeadingTrailingField"

export default {
    name: 'LegacyAppAuthFields',

    components: {
        LeadingTrailingField,
        InputLabel,
        InputField
    },

    props: {
        form: {
            type: Object
        },

        fields: {
            type: Array,
        }
    }
}
</script>
