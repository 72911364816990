import router from "../../router"

const state = () => ({
    all: [],
    search: null,
    loading: true,
})

const getFlowId = (flow) => {
    return flow.type + '_' + flow.id;
}

const getters = {

    filteredFlows: (state) => {
        if (state.search === null || state.search.length === 0) {
            return state.all
        }

        const search = state.search.toLowerCase().trim();
        const flows = _.filter(state.all, flow => {
            let results = flow.terms.filter(key => {
                return key.includes(search) || key.replace(' ', '').includes(search)
            })

            return results.length > 0;
        })

        return _.keyBy(flows, (flow) => getFlowId(flow))
    },

    getFlow: (state) => (id, type) => {
        return state.all[`${type}_${id}`];
    },

    loadingStatus: (state) => {
        return state.loading;
    }

}

const mutations = {

    setFlows(state, flows) {
        state.all = _.keyBy(flows, flow => {
            return getFlowId(flow)
        })
    },

    startLoading(state) {
        state.loading = true;
    },

    finishedLoading(state) {
        state.loading = false;
    },

    setSearch(state, search) {
        state.search = search;
    },

    activate(state, flow) {
        state.all[getFlowId(flow)].active = true;
    },

    inactivate(state, flow) {
        state.all[getFlowId(flow)].active = false;
    },

    rename(state, payload) {
        state.all[getFlowId(payload.flow)].name = payload.name;
    },

    update(state, flow) {
        state.all[getFlowId(flow)] = flow;
    },

    add(state, flow) {
        let flowId = getFlowId(flow);
        state.all = {...state.all, [flowId]: flow}
    },

    destroy(state, flow) {
        let flowId = getFlowId(flow);
        delete state.all[flowId]
    }

}

const actions = {

    getAllFlows({commit, state, rootState}) {
        if (_.size(state.all) > 1) {
            return
        }

        commit('startLoading')
        Parsey.get(rootState.routes.flows.index)
            .then(({data}) => {
                commit('finishedLoading')
                commit('setFlows', data)
            })
    },

    refresh({commit, rootState}) {
        Parsey.get(rootState.routes.flows.index)
            .then(({data}) => {
                commit('setFlows', data)
            })
    },

    activate({dispatch}, form) {
        dispatch('changeStatus', {
            form: form,
            path: form.pathOn,
            commit: 'activate',
        })
    },

    inactivate({dispatch}, form) {
        dispatch('changeStatus', {
            form: form,
            path: form.pathOff,
            commit: 'inactivate',
        })
    },

    changeStatus({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            Parsey.put(payload.path, payload.form)
                .then(({flow}) => {
                    resolve(flow)
                    commit(payload.commit, flow)
                })
                .catch(({errors}) => {
                    dispatch('handleFormErrors', {
                        errors: errors,
                        errorName: 'flow',
                        title: 'Error Updating Flow'
                    }, {root: true})
                    reject(errors)
                })
        })
    },

    update(context, payload) {
        return Parsey.put(payload.flow.routes.update, payload.form)
            .then(({flow}) => {
                context.commit('update', flow)
            })
    },

    deleteLegacyFlow({state, commit}, payload) {
        return new Promise((resolve) => {
            Parsey.delete(payload.flow.routes.update, payload.form)
                .then(() => {
                    commit("destroy", payload.flow)
                    resolve()
                    if (router.currentRoute.name !== 'Flows') {
                        router.push({name: 'Flows'})
                    }
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
