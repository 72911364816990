<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            This will create or update a company and add the Contact to this Company record.
        </p>

        <form-error :form="form"/>

        <map-fields :fields="fields"
                    :app-fields="appFields"
                    :refreshing="refreshing"
                    :extraction-fields="extractionFields"
                    @updated="extractedFieldsUpdated"
                    @refresh="refreshFields"
                    name="Company"/>

        <div class="mt-12">
            <tab-pills>

                <tab-pill name="Duplicates" :selected="true">

                    <div class="max-w-lg">
                        <p class="text-sm sm:text-base font-medium mb-3">
                            Check for duplicate company records in Infusionsoft using:
                        </p>

                        <select-field :options="duplicationOptions"
                                      class="mb-6"
                                      v-model="duplicationOption"
                                      label="label"
                                      track-by="key"/>

                    </div>

                </tab-pill>

                <tab-pill name="Custom Fields">
                    <legacy-custom-fields :fields="fields"
                                          :custom-fields="customFields"
                                          :app-fields="appFields"
                                          @update="customFieldsUpdated"
                                          @refresh="refreshFields"
                                          name="Company"
                                          :refreshing="refreshing"/>
                </tab-pill>

                <tab-pill name="Magic Fields">
                    <legacy-magic-fields :fields="fields"
                                         :magic-fields="magicFields"
                                         :app-fields="appFields"
                                         @update="magicFieldsUpdated"
                                         @refresh="refreshFields"
                                         name="Company"
                                         :refreshing="refreshing"/>
                </tab-pill>

                <tab-pill name="Apply Tag">

                    <p class="text-gray-400 text-sm mb-3">
                        Choose which tag you'd like to apply to this company
                    </p>

                    <div class="max-w-lg">
                        <select-field :options="search.options"
                                      v-model="search.selected"
                                      label="name"
                                      placeholder="Type to search"
                                      :internal-search="false"
                                      @search-change="search.search($event)"
                                      :loading="search.searching"
                                      :options-limit="100"
                                      :no-result="search.noResult"
                                      track-by="id"/>
                    </div>

                </tab-pill>

            </tab-pills>
        </div>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import MapFields from "../partials/MapFields"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import SelectField from "../../../../elements/fields/SelectField"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"

export default {
    name: 'Company',

    components: {
        LegacyMagicFields,
        LegacyCustomFields,
        SelectField,
        TabPill,
        TabPills,
        MapFields,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    data() {
        return {
            loading: true,
            refreshing: false,
            search: new ParseySearch({
                url: this.action.routes.search,
                noResult: 'No tags found with that search',
                type: 'tags',
                default: {
                    id: 0,
                    name: 'Searching...'
                }
            }),
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    custom_fields: {},
                    extraction_fields: {},
                    magic_fields: {},
                    duplication: 'Nothing',
                    tags: [
                        {
                            tag: '',
                            tag_id: 0,
                            ratio: 100,
                        }
                    ]
                },
            }),
            duplicationOption: {},
            duplicationMethods: {
                Nothing: "Don't check for duplicates, always add new company",
                CompanyName: 'If Company Name already exists with the same Name only',
                CompanyNameAndCity: 'If Company Name already exists with the same Name and City',
                CompanyNameAndState: 'If Company Name already exists with the same Name and State',
                CompanyId: 'Use a provided Infusionsoft Company Id',
            },
        }
    },

    mounted() {
        this.getActionFields();

        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        if (this.form.settings.duplication) {
            this.duplicationOption = _.find(this.duplicationOptions,
                ['key', this.form.settings.duplication])
        }

        let tagId = _.get(this.form.settings, 'tags.0.tag_id')
        if (tagId) {
            this.search.selected = {
                id: tagId,
                name: _.get(this.form.settings, 'tags.0.tag'),
            }
            this.search.options = [
                this.search.selected
            ]
        }
    },

    methods: {

        save() {
            this.form.settings.tags = [
                {
                    tag_id: this.search.selected.id,
                    tag: this.search.selected.name,
                    ratio: 100,
                }
            ]

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'company'
            }).then(() => {
                this.loading = false;
            })
        },

        refreshFields() {
            this.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'company'
            }).then(() => {
                this.refreshing = false;
            })
        },

        extractedFieldsUpdated(mappedFields) {
            this.form.settings.extraction_fields = mappedFields
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {
        fields() {
            return this.$store.state.flow.data.trigger.fields;
        },

        extractionFields() {
            let fields = _.get(this.event, 'settings.extraction_fields')
            if (_.size(fields)) {
                return fields;
            }

            return {};
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        appFields() {
            if (this.action.fields) {
                return this.action.fields.company;
            }

            return null;
        },

        duplicationOptions() {
            return _.map(this.duplicationMethods, (label, key) => {
                return {
                    label: label,
                    key: key,
                }
            })
        },

    }
}
</script>

