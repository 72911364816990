<template>
    <div class="sm:flex sm:flex-row-reverse mt-3">
        <submit-button :form="form" :text="button"
                       switch-at="sm"
                       extra="sm:ml-3"
                       :loading="`${buttonChanging}...`"
                       @save="$emit('save')"/>

        <button @click.prevent="$emit('close')"
                class="btn btn-sm btn-block btn-white sm:border-none sm:inline-block sm:w-auto mt-4 sm:mt-0">
            Cancel
        </button>
    </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton"

export default {

    name: 'ModalDefaultButtons',

    components: {
        SubmitButton
    },

    props: {
        form: {
            type: Object,
            required: true,
        },
        button: {
            type: String,
            default: 'Update'
        },
        buttonChanging: {
            type: String,
            default: 'Updating'
        }
    },

}
</script>
