<template>
    <div>
        <div v-if="action.events.length === 0" class="p-4 md:p-8">
            <div v-if="action.status.name === 'inactive'">
                <event-history-details title="Inactive Action" :status="action.status">
                    <p> No events were triggered </p>
                </event-history-details>
            </div>

            <div v-if="action.status.name === 'queued'">
                <event-history-details title="Action Queued" :status="action.status">
                    <p> Events haven't fired yet</p>
                </event-history-details>
            </div>

        </div>
        <div v-else>
            <flow-step v-for="event in action.events"
                       :key="event.id"
                       :name="event.event.name"
                       :open="event.views.edit"
                       :close="action.views.edit"
                       :icon="iconLoading ? loadingIcon : eventIcon(event.event.key)"
                       :last="lastEvent(event.id)"
                       :status-class="event.status.class.main"
                       :status-name="event.status.name"
                       :name-editable="false">

                <template #menu>
                    <dropdown-link :to="{path: event.event.views.edit}">
                        Edit Event
                    </dropdown-link>
                </template>


                <template #content>
                    <event-history-details :event="event"
                                           :response="event.result.response">



                        <div v-if="resultsObject(event)">
                            <p v-for="(value, key) in event.result.data">
                                <span class="font-medium">{{ key }}:</span>
                                {{ value }}
                            </p>
                        </div>

                    </event-history-details>
                </template>
            </flow-step>
        </div>

    </div>
</template>

<script>
import FlowStep from "../../flows/components/FlowStep"
import EventHistoryDetails from "./EventHistoryDetails"
import DropdownLink from "../../../elements/DropdownLink"

export default {
    name: 'HistoryEvents',

    components: {
        DropdownLink,
        FlowStep,
        EventHistoryDetails
    },

    props: {
        action: {
            type: Object,
            required: true,
        },

        job: {
            type: Object,
            required: true,
        },

        flowHistory: {
            type: Object,
            required: true,
        },
    },

    methods: {
        lastEvent(eventId) {
            const lastEvent = _.findLast(this.action.events);
            return lastEvent.id === eventId;
        },

        showEvent(eventId) {
            return this.$route.params.events === 'events'
                && parseInt(this.$route.params.eventId) === eventId;
        },

        eventIcon(eventKey) {
            return _.get(this.$store.state.apps, `legacy.${this.action.action.app.key}.events.${eventKey}.icon`)
        },

        resultsObject(event) {
            if (_.isObject(event.result.data)) {
                return event.result.data;
            }

            return null;
        },
    },

    computed: {
        iconLoading() {
            return this.$store.state.apps.loading;
        },

        loadingIcon() {
            return '<svg class="sm:w-5 sm:h-5 md:w-6 md:h-6 text-gray-400 animate-spin-fast" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M460.115 373.846l-6.941-4.008c-5.546-3.202-7.564-10.177-4.661-15.886 32.971-64.838 31.167-142.731-5.415-205.954-36.504-63.356-103.118-103.876-175.8-107.701C260.952 39.963 256 34.676 256 28.321v-8.012c0-6.904 5.808-12.337 12.703-11.982 83.552 4.306 160.157 50.861 202.106 123.67 42.069 72.703 44.083 162.322 6.034 236.838-3.14 6.149-10.75 8.462-16.728 5.011z"></path></svg>'
        }
    }


}
</script>