<template>
    <form @submit.prevent="save">

        <p class="text-lg mb-6 relative">
            {{ title }}

            <close-x-button @click="$emit('close')"
                            class="absolute top-0 right-0 -mt-1.5"/>
        </p>

        <div v-if="product.trigger === 'goals'">
            <p class="mb-6 text-sm text-gray-400">
                Below are the Integration Names and Call Names you will need to add to your
                Keap campaign for each ClickBank notification type.
            </p>

            <div v-for="goal in goals"
                 :class="goal.class"
                 class="p-4 rounded mb-4">
                <p class="font-medium uppercase">{{ goal.type }}</p>
                <div class="ml-2">
                    <p class="text-sm">Integration Name: <span class="font-medium">ParseyCB</span></p>
                    <p class="text-sm">Call Name: <span class="font-medium">{{ goal.type }}{{ product.id }}</span></p>
                </div>
            </div>

        </div>

        <div v-else>

            <p class="mb-6 text-sm text-gray-400">
                Please select an action set for each ClickBank notification type.
            </p>

            <input-label label="SALE">
                <select-field :options="dropdowns.sale.options"
                              v-model="dropdowns.sale.selected"
                              label="label"
                              placeholder="Start typing to search"
                              :internal-search="false"
                              @input="updateSettings('Sale', 'sale.selected')"
                              @search-change="dropdowns.sale.search($event)"
                              :loading="dropdowns.sale.searching"
                              :options-limit="100"
                              :no-result="dropdowns.sale.noResult"
                              track-by="id"/>
            </input-label>

            <input-label label="REFUND">

                <select-field :options="dropdowns.refund.options"
                              v-model="dropdowns.refund.selected"
                              label="label"
                              placeholder="Start typing to search"
                              :internal-search="false"
                              @input="updateSettings('Refund', 'refund.selected')"
                              @search-change="dropdowns.refund.search($event)"
                              :loading="dropdowns.refund.searching"
                              :options-limit="100"
                              :no-result="dropdowns.refund.noResult"
                              track-by="id"/>

            </input-label>
            <input-label label="CHARGE BACK">

                <select-field :options="dropdowns.chargeBack.options"
                              v-model="dropdowns.chargeBack.selected"
                              label="label"
                              @input="updateSettings('ChargeBack', 'chargeBack.selected')"
                              placeholder="Start typing to search"
                              :internal-search="false"
                              @search-change="dropdowns.chargeBack.search($event)"
                              :loading="dropdowns.chargeBack.searching"
                              :options-limit="100"
                              :no-result="dropdowns.chargeBack.noResult"
                              track-by="id"/>

            </input-label>

            <modal-default-buttons :form="form"
                                   @save="save"
                                   @close="$emit('close')"/>

        </div>

    </form>
</template>

<script>
import CloseXButton from "../../../../elements/buttons/CloseXButton"
import DefaultWell from "../../../../elements/DefaultWell"
import SelectField from "../../../../elements/fields/SelectField"
import ModalDefaultButtons from "../../../../elements/modals/ModalDefaultButtons"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventHelpers from "../mixins/event-helpers"

export default {
    name: 'ClickBankActions',

    components: {
        InputLabel,
        ModalDefaultButtons,
        SelectField,
        DefaultWell,
        CloseXButton
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        action: {
            type: Object,
            required: true,
        },

        url: {
            type: String,
        }
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            settingKeyBase: 'form.action_sets',

            goals: [
                {
                    type: 'Sale',
                    class: 'text-green-700 bg-green-100'
                },
                {
                    type: 'Refund',
                    class: 'text-red-700 bg-red-50'
                },
                {
                    type: 'ChargeBack',
                    class: 'text-indigo-700 bg-indigo-50'
                },
            ],

            dropdowns: {
                sale: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'actionSets',
                    appendSearch: '|label',
                    default: {
                        id: 0,
                        label: 'Searching...'
                    }
                }),

                refund: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'actionSets',
                    appendSearch: '|label',
                    default: {
                        id: 0,
                        label: 'Searching...'
                    }
                }),

                chargeBack: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'actionSets',
                    appendSearch: '|label',
                    default: {
                        id: 0,
                        label: 'Searching...'
                    }
                }),
            },

            form: new ParseyForm({
                action_sets: {
                    Sale: null,
                    Refund: null,
                    ChargeBack: null,
                }
            })
        }
    },

    mounted() {
        this.setActionSets()
    },

    methods: {
        setActionSets() {
            let actionSets = _.get(this.product, 'action_sets');
            if (_.size(actionSets) === 0) {
                return;
            }

            this.form.action_sets = actionSets;
            this.setActionSet(actionSets, 'sale')
            this.setActionSet(actionSets, 'refund')
            this.setActionSet(actionSets, 'chargeBack')
        },

        setActionSet(actionSets, type) {
            let cap = type.charAt(0).toUpperCase() + type.slice(1);
            if (parseInt(actionSets[cap].id) > 0) {
                this.dropdowns[type].selected = actionSets[cap];
            }
        },

        save() {
            Parsey.put(`${this.url}/${this.product.id}`, this.form)
                .then(() => {
                    this.$emit('close')
                    this.$emit('update-products')
                })
        }
    },

    computed: {
        title() {
            if (this.product.trigger === 'action_sets') {
                return 'Keap Legacy Action Sets';
            }

            return 'Keap Campaign Goals';
        }
    }
}
</script>