<template>
    <div :class="{'border-t': parseInt(meta.total) > 0}"
         class="app-pagination bg-white px-4 py-3 flex items-center justify-between border-gray-200 sm:px-6">
        <div class="flex-1 flex justify-between sm:hidden">

            <router-link :to="navigate(links.prev)"
                         class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white hover:text-gray-500">
                Previous
            </router-link>
            <router-link :to="navigate(links.next)"
                         class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white hover:text-gray-500">
                Next
            </router-link>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p v-if="loading" class="text-sm">
                    Loading...
                </p>
                <p v-else-if="parseInt(meta.total) === 0">
                    No results
                </p>
                <p v-else class="text-sm">
                    Showing
                    <span class="font-medium">{{ meta.from }}</span>
                    to
                    <span class="font-medium">{{ meta.to }}</span>
                    of
                    <span class="font-medium">{{ meta.total }}</span>
                    results
                </p>
            </div>
            <div>
                <p class="text-sm">
                    View:
                    <template v-for="(limit, index) in pageLimits" :key="limit">
                        <router-link :to="navigatePageLimit(limit)"
                                     custom
                                     v-slot="{navigate, href}">
                            <a :href="href" @click="navigate"
                               class="text-base font-medium"
                               :class="{'text-blue-500 font-bold': href === $router.currentRoute.value.fullPath}">
                                {{ limit }}
                            </a>
                        </router-link>
                        <span v-if="index < (pageLimits.length - 1)"> | </span>
                    </template>

                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px text-gray-400"
                     aria-label="Pagination">

                    <router-link :to="navigate(links.prev)"
                                 :class="navigateClass(links.prev)"
                                 class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ">
                        <span class="sr-only">Previous</span>
                        <chevron-left-icon class="h-5 w-5"/>
                    </router-link>

                    <template v-for="link in metaLinks" :key="link.id">
                        <router-link v-if="link.link"
                                     :to="navigate(link.url)"
                                     custom
                                     v-slot="{navigate, href}">
                            <a :href="href"
                               @click="navigate"
                               :class="{'page-active': href === $router.currentRoute.value.fullPath}"
                               class="page relative inline-flex items-center px-4 border border-gray-300 bg-white text-sm font-medium">
                                {{ link.label }}
                            </a>

                        </router-link>
                        <span v-else
                              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium">...</span>
                    </template>

                    <router-link :to="navigate(links.next)"
                                 :class="navigateClass(links.next)"
                                 class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium">
                        <span class="sr-only">Next</span>
                        <chevron-right-icon class="w-5 h-5"/>
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronLeftIcon from "../svgs/general/ChevronLeftIcon"
import ChevronRightIcon from "../svgs/general/ChevronRightIcon"

export default {
    name: 'DefaultPagination',

    components: {
        ChevronRightIcon,
        ChevronLeftIcon
    },

    props: {
        loading: {
            default: false,
            type: Boolean,
        },

        pageLimits: {
            type: Array,
            default() {
                return [25, 50]
            }
        },

        links: {
            type: Object,
            required: true,
        },

        meta: {
            type: Object,
            required: true,
        },

        routeName: {
            type: String,
            required: true,
        },

        search: {
            type: Object,
        }
    },

    methods: {
        navigate(apiUrl) {
            let query = JSON.parse(JSON.stringify(this.search));
            if (apiUrl) {
                let url = new URL(apiUrl);
                query = _.merge(query, Object.fromEntries(new URLSearchParams(url.search)))
            }

            return {
                name: this.routeName,
                query: query
            }
        },

        navigatePageLimit(limit) {
            let query = JSON.parse(JSON.stringify(this.search));
            query.limit = limit;
            return {
                name: this.routeName, query: query
            }
        },

        navigateClass(apiUrl) {
            if (apiUrl) {
                return 'hover:bg-blue-500 hover:text-white'
            }

            return 'text-gray-200 cursor-not-allowed';
        },
    },

    computed: {

        metaLinks() {
            let metaLinks = this.meta.links;
            let size = _.size(metaLinks);
            if (size < 12) {
                metaLinks.shift()
                metaLinks.pop();
                return _.map(metaLinks, (link, index) => {
                    return {
                        id: index,
                        url: link.url,
                        link: true,
                        label: link.label,
                        active: link.active,
                    }
                })
            }

            let list = [];
            let currentPage = parseInt(this.search.page)
            list.push(_.get(metaLinks, '1'));

            if (currentPage < 4 || currentPage >= (size - 4)) {
                list.push(_.get(metaLinks, '2'));
                list.push(_.get(metaLinks, '3'));
                list.push(_.get(metaLinks, '4'));
                list.push({
                    url: '',
                    label: '...',
                    active: false,
                })

                list.push(_.get(metaLinks, size - 5));
                list.push(_.get(metaLinks, size - 4));
                list.push(_.get(metaLinks, size - 3));
            }

            if (currentPage >= 4 && currentPage < (size - 4)) {
                list.push({
                    url: '',
                    label: '...',
                    active: false,
                })

                list.push(_.get(metaLinks, currentPage - 2));
                list.push(_.get(metaLinks, currentPage - 1));
                list.push(_.get(metaLinks, currentPage));
                list.push(_.get(metaLinks, currentPage + 1));
                list.push(_.get(metaLinks, currentPage + 2));

                list.push({
                    url: '',
                    label: '...',
                    active: false,
                })
            }

            list.push(_.get(metaLinks, size - 2));

            return _.map(list, (link, index) => {
                return {
                    id: index,
                    url: link.url,
                    link: link.label !== '...',
                    label: link.label,
                    active: link.active,
                }
            });
        },

    },
}
</script>
