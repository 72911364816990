<template>
    <div class="border border-gray-100 rounded mb-2">
        <div v-show="edit === false" class="flex justify-between items-center">
            <div class="flex-shrink-0 py-2 px-3">
                <p class="text-gray-700 text-sm">
                    {{ name }}
                </p>
            </div>
            <div class="flex items-center">
                <error-icon v-if="error"
                            v-tooltip="'Error, Edit to fix'"
                            class="w-7 h-7 p-1 m-l text-red-700" />
                <spin-icon v-if="loading" class="w-7 h-7 p-1 text-gray-200"/>
                <eye-icon v-else class="w-7 h-7 p-1 cursor-pointer"
                          @click.native="$emit('click-view')"
                          :class="{'text-gray-200': !viewing, 'text-indigo-300': viewing}"/>
                <default-dropdown>
                    <template #trigger>
                        <dots-menu class="w-7 h-7 p-1 text-gray-200 mr-1"/>
                    </template>

                    <template #content>
                        <slot name="dropdown"/>
                    </template>

                </default-dropdown>
            </div>
        </div>

        <div v-show="edit" class="py-2 px-3">
            <slot name="edit"/>
        </div>
    </div>
</template>

<script>
import DotsMenu from "../../../../svgs/menu/DotsMenu"
import EyeIcon from "../../../../svgs/general/EyeIcon"
import DefaultDropdown from "../../../../elements/DefaultDropdown"
import SpinIcon from "../../../../svgs/general/SpinIcon"
import ErrorIcon from "../../../../svgs/general/ErrorIcon"

export default {
    name: 'LegacyField',

    components: {
        ErrorIcon,
        SpinIcon,
        EyeIcon,
        DotsMenu,
        DefaultDropdown,
    },

    props: {
        name: {
            type: String,
            default: '',
        },

        edit: {
            type: Boolean,
            default: false,
        },

        viewing: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Boolean,
            default: false,
        }


    }
}
</script>