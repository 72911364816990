<template>
    <div>
        <div v-if="showActions">
            <flow-panel v-for="action in actions"
                        :key="action.id"
                        :name-editable="false"
                        :show-content="showAction(action)"
                        :continued="true"
                        :path="action.views.edit"
                        :close-path="closePath"
                        :status-name="action.status.name"
                        :status-class="action.status.class.main"
                        :name-value="action.action.name">

                <template #icon>
                    <div v-html="action.action.app.icon"></div>
                </template>

                <template #menu>
                    <dropdown-link :to="{path: action.action.views.edit}">
                        Edit Action
                    </dropdown-link>
                </template>

                <template #content>
                    <history-events :action="action"
                                    :job="job"
                                    :flow-history="flowHistory"/>
                </template>

            </flow-panel>
        </div>
        <flow-panel v-else
                    :name-editable="false"
                    :continued="true"
                    :clickable="false"
                    name-value="No Actions Were Setup">
            <template #icon>
                <action-icon class="text-brand-900"/>
            </template>
        </flow-panel>

    </div>
</template>

<script>
import FlowPanel from "../../flows/components/FlowPanel"
import HistoryEvents from "./HistoryEvents"
import ActionIcon from "../../../svgs/general/ActionIcon"
import DropdownLink from "../../../elements/DropdownLink"

export default {
    name: 'HistoryActions',

    components: {
        DropdownLink,
        ActionIcon,
        HistoryEvents,
        FlowPanel
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
        flowHistory: {
            type: Object,
            required: true,
        }
    },

    methods: {
        showAction(action) {
            return this.$route.params.panel === 'actions'
                && parseInt(this.$route.params.panelId) === action.id;
        },

        actionPath(action) {
            let url = action.views.history
            return _.replace(_.replace(url, '0', this.flowHistory.id), '0', this.job.id);
        },
    },

    computed: {
        actions() {
            return _.get(this.job, 'actions');
        },


        showActions() {
            return this.actions && _.size(this.actions) > 0
        },

        closePath() {
            return _.replace(this.flowHistory.views.edit, 0, this.job.id);
        },

    }
}
</script>