import router from "../../router"
import {get} from 'lodash'

const state = () => ({
    id: 0,
    data: {},
    loading: true,
    updating: false,
    title: 'Loading Flow...',
    legacy: false,
    sidebar: {
        open: get(ParseyState, 'user.meta.flow_sidebar', 'open') === 'open',
    },
    type: 'webhooks',
    highlight: {
        path: '',
    }
})

const flowUrl = (rootState, state) => {
    if (state.legacy) {
        return _.replace(rootState.routes.flows.legacy[state.type].show, '0', state.id);
    }

    return _.replace(rootState.routes.flows[state.type].show, '0', state.id);
}

const getters = {

    eventName: (state) => (actionId, eventId) => {
        return get(state.data, `actions[${actionId}].events[${eventId}].name`)
    },

    sidebarWidth(state) {
        return state.sidebar.open ? '18rem' : '1.25rem';
    },

    triggerFieldList(state) {
        return _.map(_.get(state.data, 'trigger.fields', []), field => {
            return field
        });
    },

    triggerArrayKeyList(state) {
        let fields = _.map(_.get(state.data, 'trigger.fields', []), field => {
            return field
        });

        return _.filter(fields, 'array');
    },

}

const mutations = {

    resetProperties(state) {
        state.id = 0;
        state.data = {};
        state.loading = true;
        state.updating = false;
        state.title = 'Loading Flow...';
        state.legacy = false;
    },

    setProperties(state, data) {
        if (data.legacy) {
            state.legacy = true;
        }
        state.id = parseInt(data.id);
        state.type = data.type
    },

    setUpdating(state) {
        state.updating = true;
    },

    setLoading(state) {
        state.loading = true;
    },

    gettingFlow(state) {
        state.loading = true;
    },

    setFlow(state, flow) {
        state.data = flow;
        state.loading = false;
        state.updating = false;
        state.title = flow.name ? flow.name : 'Edit Flow';
    },

    setFlowStatus(state, flow) {
        state.data.active = flow.active;
    },

    setFlowTrigger(state, trigger) {
        state.data.trigger = trigger
    },

    addLegacyTriggerField(state, field) {
        state.data.trigger.fields = {...state.data.trigger.fields, [field.id]: field}
    },

    updateLegacyTriggerField(state, field) {
        state.data.trigger.fields[field.id] = field
    },

    removeLegacyField(state, fieldId) {
        delete state.data.trigger.fields[fieldId]
    },

    setFlowSettings(state, settings) {
        state.data.settings = settings
    },

    highlightPath(state, path) {
        state.highlight.path = path
    },

    unHighlightPath(state, path) {
        state.highlight.path = ''
    },

    addAction(state, action) {
        state.data.actions = {...state.data.actions, [action.id]: action}
    },

    setAction(state, action) {
        state.data.actions[action.id] = action;
    },

    setActionStatus(state, action) {
        state.data.actions[action.id].active = action.active;
    },

    setActionName(state, action) {
        state.data.actions[action.id].name = action.name;
    },

    updateActionConfigured(state, action) {
        state.data.actions[action.id].configured = true;
    },

    setActionFields(state, payload) {
        let path = `fields.${payload.type}`;
        _.set(state.data.actions[payload.actionId], path, payload.fields)

        let fields = _.get(state.data.actions[payload.actionId], 'fields')
        state.data.actions[payload.actionId] = {
            ...state.data.actions[payload.actionId], fields: fields
        }
    },

    setEvent(state, event) {
        let eventFound = _.get(state.data, `actions.${event.action_id}.events.${event.id}`)
        if (eventFound) {
            state.data.actions[event.action_id].events[event.id] = event
        } else {
            state.data.actions[event.action_id].events = {
                ...state.data.actions[event.action_id].events,
                [event.id]: event,
            }
        }
    },

    deleteEvent(state, event) {
        delete state.data.actions[event.action_id].events[event.id]
    },

    deleteAction(state, action) {
        delete state.data.actions[action.id]
    },

    updateFilterCount(state, payload) {
        if ((payload.type === 'emails' || payload.type === 'attachments') && payload.typeId === state.id) {
            state.data.filters = payload.count;
        }

        if (payload.type === 'actions') {
            state.data.actions[payload.typeId].filters = payload.count;
        }

        if (payload.type === 'events') {
            const action = _.find(state.data.actions, action => {
                let event = _.find(action.events, event => {
                    return event.id === payload.typeId;
                })
                return !!event;
            })
            if (action) {
                state.data.actions[action.id].events[payload.typeId].filters = payload.count;
            }
        }

        if (payload.type === 'attachment') {
            const action = _.find(state.data.actions, action => {
                let event = _.find(action.events, event => {
                    return event.id === payload.typeId;
                })
                return !!event;
            })
            if (action) {
                state.data.actions[action.id].events[payload.typeId].settings.filters_count = payload.count;
            }
        }

    },

    openSidebar(state) {
        state.sidebar.open = true;
    },

    closeSidebar(state) {
        state.sidebar.open = false;
    },

    addTriggerMailbox(state, payload) {
        state.data.trigger.mailboxes.push(payload.mailbox)
    },

}

const actions = {

    getFlow({state, commit, rootState}) {
        commit('setUpdating')
        if (state.id !== state.data.id) {
            commit('setLoading')
        }
        Parsey.get(flowUrl(rootState, state))
            .then(({flow}) => {
                commit('setFlow', flow)
            })
    },

    updateFlow({state, commit, rootState}, payload) {
        if (parseInt(state.id) !== parseInt(payload.id)) {
            return true;
        }

        Parsey.get(flowUrl(rootState, state))
            .then(({flow}) => {
                commit('setFlow', flow)
                commit('flows/update', flow, {root: true})
            })
    },

    activate({state, dispatch}, form) {
        dispatch('changeStatus', {
            status: 'activate',
            form: form,
        })
    },

    inactivate({dispatch}, form) {
        dispatch('changeStatus', {
            status: 'inactivate',
            form: form,
        })
    },

    toggleSidebar({state, commit, dispatch}) {
        let form = new ParseyForm({
            key: 'flow_sidebar',
            value: '',
        })

        if (state.sidebar.open) {
            commit('closeSidebar')
            form.value = 'close'
            return dispatch('updateMeta', {
                form: form
            }, {root: true})
        }

        commit('openSidebar')
        form.value = 'open'
        return dispatch('updateMeta', {
            form: form
        }, {root: true})
    },

    changeStatus({state, commit, dispatch}, payload) {
        if (parseInt(payload.form.flowId) !== state.id) {
            return false
        }

        return new Promise((resolve, reject) => {
            Parsey.put(state.data.routes[payload.status], payload.form)
                .then(({flow}) => {
                    resolve(flow)
                    commit('setFlowStatus', flow)
                    commit('flows/update', flow, {root: true})
                })
                .catch(({errors}) => {
                    dispatch('handleFormErrors', {
                        errors: errors,
                        errorName: 'flow',
                        title: 'Error Updating Flow'
                    }, {root: true})
                    reject(errors)
                })
        })
    },

    addLegacyField({state, commit}, form) {
        return new Promise((resolve, reject) => {
            Parsey.post(state.data.trigger.routes.fields, form)
                .then(({field}) => {
                    commit('addLegacyTriggerField', field)
                    resolve(field)
                }).catch(error => reject(error))
        })
    },

    updateLegacyField({state, commit}, form) {
        return new Promise((resolve, reject) => {
            Parsey.put(`${state.data.trigger.routes.fields}/${form.id}`, form)
                .then(({field}) => {
                    commit('updateLegacyTriggerField', field)
                    resolve(field)
                }).catch(error => reject(error))
        })
    },

    deleteLegacyField({state, commit}, payload) {
        Parsey.delete(payload.field.routes.update, payload.form)
            .then(() => {
                commit('removeLegacyField', payload.field.id)
            })
    },

    addLegacyAction() {
        router.push({params: {panel: 'actions', panelId: 'new', eventId: null, events: null}})
    },

    activateLegacyAction({dispatch}, form) {
        form.status = 'active';
        dispatch('changeLegacyActionStatus', {
            form: form,
        })
    },

    inactivateLegacyAction({dispatch}, form) {
        form.status = 'inactive';
        dispatch('changeLegacyActionStatus', {
            form: form,
        })
    },

    changeLegacyActionStatus({state, commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            Parsey.put(state.data.actions[payload.form.actionId].routes.update, payload.form)
                .then(({action}) => {
                    resolve(action)
                    commit('setActionStatus', action)
                })
                .catch(({errors}) => {
                    dispatch('handleFormErrors', {
                        errors: errors,
                        errorName: 'status',
                        title: 'Error Updating Action'
                    }, {root: true})
                    reject(errors)
                })
        })
    },

    deleteLegacyAction({state, commit}, payload) {
        return new Promise((resolve) => {
            Parsey.delete(payload.action.routes.update, new ParseyForm())
                .then(() => {
                    commit('deleteAction', payload.action)
                    resolve()
                    if (payload.path !== router.currentRoute.path) {
                        router.push({path: payload.path})
                    }
                })
        })
    },

    getActionFields({state, commit, dispatch}, payload) {
        // Get Fields
        let currentFields = _.get(state.data, `actions[${payload.action.id}].fields[${payload.type}]`);
        if (currentFields) {
            return true;
        }

        payload.cache = true;

        return dispatch('refreshActionFields', payload);
    },

    refreshActionFields({commit}, payload) {
        return new Promise((resolve, reject) => {
            let cache = payload.cache ? 1 : 0;
            Parsey.get(`${payload.action.routes.fields}?type=${payload.type}&cache=${cache}`)
                .then(({fields}) => {
                    let commitPayload = {
                        fields: fields,
                        actionId: payload.action.id,
                        type: payload.type,
                    }
                    commit('setActionFields', commitPayload)
                    resolve()
                })
        })
    },

    saveLegacyEvent({commit, dispatch}, payload) {
        if (!payload.event) {
            return dispatch('createLegacyEvent', payload)
        }

        Parsey.put(payload.event.routes.update, payload.form)
            .then(({event}) => {
                commit('setEvent', event)
                if (payload.action && payload.action.views) {
                    router.push({path: payload.action.views.edit})
                }
            })
    },

    createLegacyEvent({commit}, payload) {
        Parsey.post(payload.action.routes.events, payload.form)
            .then(({event}) => {
                if (payload.action.configured === false) {
                    commit('updateActionConfigured', payload.action)
                }
                commit('setEvent', event)
                if (payload.redirect) {
                    router.push({path: payload.redirect})
                    return;
                }
                router.push({path: event.views.edit})
            })
    },

    deleteLegacyEvent({commit}, payload) {
        return new Promise((resolve, reject) => {
            Parsey.delete(payload.event.routes.update, new ParseyForm())
                .then(() => {
                    commit('deleteEvent', payload.event)
                    resolve();
                    if (payload.path !== router.currentRoute.path) {
                        router.push({path: payload.path})
                    }
                })
        })
    },

    openFilter({dispatch, state}) {
        dispatch('filters/openFilter', {
            type: `${state.data.type}s`,
            typeId: state.id,
            property: {
                type: state.data.type,
                name: state.data.name,
            },
            filters: state.data.routes.filters.index,
            options: state.data.routes.filters.options,
            count: state.data.filters,
        }, {root: true})
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
