<template>
    <div class="flex justify-center mt-10">
        <div class="w-full max-w-xs">
            <submit-button switch-at="lg"
                           :text="text"
                           :loading="loadingText"
                           :form="form"/>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../../../../elements/buttons/SubmitButton"

export default {
    name: 'LegacyEventSaveButton',

    components: {
        SubmitButton
    },

    props: [
        'event',
        'form',
    ],

    computed: {
        text() {
            // if (this.event) {
            //     return 'Update'
            // }
            return 'Save'
        },

        loadingText() {
            // if (this.event) {
            //     return 'Updating...'
            // }

            return 'Saving...'
        }
    }
}
</script>
