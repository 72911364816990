<template>
    <center-modal :show="show" @close="close" size="lg">

        <form-error :form="form"/>

        <form @submit.prevent="save" @keydown.enter.prevent="save">

            <mailbox-edit :mailbox="mailbox" :form="form"/>

            <modal-default-buttons @save="save"
                                   @close="close"
                                   :button="button"
                                   :button-changing="buttonChanging"
                                   :form="form"/>

        </form>

    </center-modal>
</template>

<script>
import CenterModal from "../../elements/modals/CenterModal"
import MailboxEdit from "./MailboxEdit"
import ModalDefaultButtons from "../../elements/modals/ModalDefaultButtons"
import FormError from "../../elements/alerts/FormError"

export default {
    name: 'MailboxModal',

    components: {
        FormError,
        ModalDefaultButtons,
        MailboxEdit,
        CenterModal
    },

    props: [
        'mailbox',
        'show'
    ],

    data() {
        return {
            form: new ParseyForm({
                name: this.mailbox.name,
                forwards: this.mailbox.forward_addresses.join(', ')
            })
        }
    },

    computed: {
        button() {
            return this.mailbox.id ? 'Update' : 'Create';
        },

        buttonChanging() {
            return this.mailbox.id ? 'Updating' : 'Creating';
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        save() {
            if (this.mailbox.id) {
                Parsey.put(this.mailbox.routes.update, this.form)
                    .then(({mailbox}) => {
                        this.$emit('updated', mailbox)
                        this.form.name = mailbox.name
                        this.form.forwards = mailbox.forward_addresses.join(', ')
                        this.close();
                    })

                return true;
            }

            Parsey.post(this.$store.state.routes.mailboxes.store, this.form)
                .then(({mailbox}) => {
                    this.$emit('added', mailbox)
                    this.form.name = ''
                    this.form.forwards = ''
                    this.close();
                })
        }
    }
}
</script>
