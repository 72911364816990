<template>
    <default-dropdown>
        <template #trigger>
            <button-menu />
        </template>

        <template #content>
            <dropdown-link :to="{path: flow.views.edit}">
                Edit
            </dropdown-link>
            <dropdown-link :to="{path: flow.views.edit}">
                Duplicate
            </dropdown-link>

            <form @submit.prevent="renameFlow">
                <dropdown-link as="button">
                    Rename
                </dropdown-link>
            </form>

            <dropdown-link :to="{path: flow.views.history}">
                History
            </dropdown-link>

            <horizontal-line />

            <form @submit.prevent="deleteFlow">
                <dropdown-link as="button">
                    Delete
                </dropdown-link>
            </form>

        </template>

    </default-dropdown>
</template>

<script>

import DefaultDropdown from "../../../../elements/DefaultDropdown"
import DropdownLink from "../../../../elements/DropdownLink"
import DotsMenu from "../../../../svgs/menu/DotsMenu"
import ButtonMenu from "../../../../elements/buttons/ButtonMenu"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {

    name: 'FlowsMenu',

    components: {
        HorizontalLine,
        ButtonMenu,
        DotsMenu,
        DropdownLink,
        DefaultDropdown
    },

    props: [
        'flow',
    ],

    methods: {

        renameFlow() {
            this.open = false;
            this.$emit('rename-flow')
        },

        deleteFlow() {
            this.open = false;
            this.$emit('delete-flow')
        },

    },

}
</script>
