<template>

    <button class="mt-2 group flex items-center px-3 py-3 text-base font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none transition ease-in-out duration-150 w-full"
            v-if="as == 'button'">
        <div class="flex items-center w-full">
            <div v-if="hasIconSlot" class="w-5 h-5 mr-2">
                <slot name="icon"></slot>
            </div>
            <span class="truncate">
                <slot></slot>
            </span>
        </div>

    </button>

    <router-link :to="to" active-class="bg-gray-50" class="mt-2 group flex items-center px-3 py-3 text-base font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none transition ease-in-out duration-150" v-else>
        <div class="flex items-center w-full">
            <div v-if="hasIconSlot" class="w-5 h-5 mr-2">
                <slot name="icon"></slot>
            </div>
            <span class="truncate">
                <slot></slot>
            </span>
        </div>
    </router-link>

</template>

<script>
export default {
    props: ['to', 'as'],

    computed: {
        hasIconSlot(){
            return !!this.$slots['icon']
        }
    }
}
</script>
