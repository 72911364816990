<template>

    <div class="flex flex-col items-center justify-center">

        <div class="h-10 w-10 mt-12 mb-6 rounded-full border-3 border-teal-400 text-teal-400 p-1">
            <checkmark-icon/>
        </div>

        <p class="font-semibold text-lg text-gray-700" v-text="confirmMessage"></p>

        <p class="text-gray-400 mb-8">
            Continue setup process.
        </p>

        <form @submit.prevent="save" @keydown.enter="save"
              class="w-full flex justify-center mb-24 md:mb-40">

            <submit-button @save="save" :form="form"
                           switch-at="lg"
                           :teal="false"
                           :classes="['btn', 'btn-continue']"
                           text="Continue"/>
        </form>

    </div>

</template>

<script>
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import CheckmarkIcon from "../../../../svgs/general/CheckmarkIcon"
import {mapState} from "vuex"

export default {
    name: 'LegacyTriggerConfirm',

    components: {
        CheckmarkIcon,
        SubmitButton,
    },

    data() {
        return {
            form: new ParseyForm({
                name: 'confirmed',
                value: 1,
            })
        }
    },

    methods: {
        save() {
            Parsey.post(this.flow.routes.settings, this.form)
                .then(({settings}) => {
                    this.$store.commit('flow/setFlowSettings', settings)
                })
        }
    },

    computed: {

        ...mapState('flow', {
            flow: state => state.data,
        }),

        confirmMessage() {
            return _.capitalize(this.flow.type) + ' Received!';
        }
    }
}
</script>
