<template>
    <div>
        <div class="bg-white border-gray-100 border sm:rounded-md">
            <ul>
                <li v-if="!loading"
                    v-for="(flow, key) in flows"
                    :key="key"
                    class="border-t first:border-0 border-gray-100">
                    <flow-row :show-menu="showMenu"
                              :flow="flow"/>
                </li>

                <li v-if="loading"
                    v-for="row in defaultRows"
                    :key="row"
                    class="border-t first:border-0 border-gray-100 animate-pulse">

                    <div class="flex items-center justify-between py-2 pl-4">
                        <div class="h-4 bg-gray-100 max-w-sm w-full rounded"></div>
                        <div class="w-32 flex justify-around items-center">
                            <div class="h-8 w-8 rounded-full bg-gray-100"></div>
                            <div class="h-8 w-8 rounded-full bg-gray-100"></div>
                        </div>
                        <div class="w-24 flex justify-end items-center">
                            <div class="h-5 w-5 rounded-full bg-gray-100"></div>
                            <div class="w-7 h-7 p-1 text-gray-100">
                                <dots-menu />
                            </div>
                        </div>
                    </div>

                </li>

            </ul>

        </div>
    </div>

</template>

<script>
import FlowRow from "./FlowRow"
import DotsMenu from "../../../svgs/menu/DotsMenu"

export default {
    name: "FlowRows",

    components: {
        DotsMenu,
        FlowRow
    },

    props: [
        'flows',
        'loading',
        'showMenu',
    ],

    computed: {
        defaultRows() {
            return _.range(10);
        }
    }

}
</script>
