<template>
    <ul class="flex items-center overflow-hidden">
        <li v-for="(app, index) in appIcons"
            class="relative flex-1 last:flex-none" style="max-width: 3.5rem; min-width: 1.25rem;">
            <div v-if="index < appIconsLength - 1"
                 class="absolute inset-0 flex items-center">
                <div class="h-px w-full bg-gray-100"></div>
            </div>
            <div class="relative w-8 h-8 flex items-center bg-white p-1.5 border border-gray-100  rounded-full">
                <app-icon :app="app" />
            </div>
        </li>
    </ul>
</template>

<script>
import AppIcon from "./AppIcon"

export default {

    name: "FlowActionsList",

    props: {
        flow: {
            type: Object,
            required: true,
        }
    },

    components: {
        AppIcon,
    },

    computed: {
        appIcons() {
            let apps = [
                this.flow.trigger.type,
                ...this.flow.apps
            ];

            if (apps.length < 13) {
                return apps;
            }

            let firstGroup = _.take(apps, 5);
            let lastGroup = _.takeRight(apps, 5);

            return [
                ...firstGroup,
                'continue',
                ...lastGroup
            ]
        },

        appIconsLength() {
            return this.appIcons.length
        },

    }

}
</script>

