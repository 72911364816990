module.exports = {

    computed: {
        getId() {
            if (this.id) return this.id;

            return this.name;
        },

        classes() {
            let extraClasses = _.get(this, 'extraClasses', [])

            if (!Array.isArray(extraClasses)) {
                extraClasses = extraClasses.split(' ')
            }

            if (this.form.errors.has(this.name)) {
                extraClasses.push('border-red-600')
            }

            return _.uniq(extraClasses.concat(this.defaultClasses)).join(' ')
        },

        defaultClasses() {
            switch (this.type) {
                case 'checkbox':
                    return [
                        'h-4 w-4',
                        'border-gray-300',
                        'rounded-sm',
                        'focus:ring-transparent',
                        'text-indigo-600',
                    ];
                default:
                    return [
                        'appearance-none',
                        'block', 'w-full',
                        'p-2 sm:p-3',
                        'border border-gray-100 rounded placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition text-xs sm:text-sm sm:leading-5'
                    ];
            }
        }
    }

}
