const checkWidth = (el, binding) => {
    let mirror = document.querySelector(".parsey-autowidth-mirror-".concat(el.dataset.uuid));

    let defaults = {
        minWidth: "none",
        comfortZone: 0
    };

    let options = Object.assign({}, defaults, binding.value);
    el.style.minWidth = options.minWidth;

    var val = el.value;
    if (!val) {
        val = el.placeholder || '';
    }

    while (mirror.childNodes.length) {
        mirror.removeChild(mirror.childNodes[0]);
    }

    mirror.appendChild(document.createTextNode(val));

    let newWidth = mirror.scrollWidth + options.comfortZone + 2;

    if (newWidth != el.scrollWidth) {
        el.style.maxWidth = `${newWidth}px`;
    }
}

const directive = {
    beforeMount(el) {
        if (el.tagName.toLowerCase() !== 'input') {
            throw new Error("Autowidth can only be used on input elements.");
        }
        el.dataset.uuid = Math.random().toString(36).slice(-5);
        el.style.boxSizing = "content-box";
    },

    mounted(el, binding) {
        el.mirror = document.createElement("span");
        let classes = el.classList;

        Object.assign(el.mirror.style, {
            position: "absolute",
            top: "0",
            left: "0",
            visibility: "hidden",
            height: "0",
            overflow: "hidden",
            whiteSpace: "pre",
        });

        classes.forEach(className => {
            if (className.includes('text') || className.includes('font')) {
                el.mirror.classList.add(className)
            }
        })

        el.mirror.classList.add("parsey-autowidth-mirror-".concat(el.dataset.uuid));
        el.mirror.setAttribute("aria-hidden", "true");

        document.body.appendChild(el.mirror);
        checkWidth(el, binding);
    },

    updated(el, binding) {
        checkWidth(el, binding);
    },

    unmounted(el) {
        document.body.removeChild(el.mirror);
    }
}

export default directive;
