<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Choose the tags and the ratio percentage for each
        </p>

        <form-error :form="form"/>

        <div>
            <div class="flex text-sm sm:text-base font-medium">
                <div class="flex-1">
                    <p> Tag </p>
                </div>
                <div class="flex-shrink-0 w-32 sm:w-64">
                    <p> Ratio </p>
                </div>
            </div>
            <div v-if="showRatios" class="mb-2">
                <div class="flex mt-4" v-for="(tag, index) in form.settings.tags">
                    <div class="flex-1 mr-4">
                        <select-field :options="searches[index].options"
                                      v-model="searches[index].selected"
                                      label="name"
                                      placeholder="Type to search"
                                      :internal-search="false"
                                      @search-change="searches[index].search($event)"
                                      :loading="searches[index].searching"
                                      :options-limit="100"
                                      :no-result="searches[index].noResult"
                                      track-by="id"/>
                    </div>
                    <div class="flex-shrink-0 w-32 sm:w-64 flex">
                        <div class="flex-1">
                            <event-input-field v-model="form.settings.tags[index].ratio"
                                               maxlength="3"
                                               placeholder="50"/>
                        </div>
                        <div class="flex-shrink-0 flex items-center ml-4">
                            <div @click="removeTag(index)"
                                 class="w-10 h-10 rounded-full bg-gray-50 p-2 text-gray-400 cursor-pointer">
                                <trash-icon/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <p @click="addTag" class="text-gray-300 text-sm cursor-pointer mb-4">
                + Add another tag
            </p>

            <checkbox-field :id="`check_tags_${event.id}`"
                            value="1"
                            true-value="1" false-value="0"
                            v-model="form.settings.checkCurrentTags">
                Do NOT run this event if any of the tags above are currently applied to the contact.
            </checkbox-field>

        </div>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import RadioField from "../../../../elements/fields/RadioField"
import SelectField from "../../../../elements/fields/SelectField"
import InputField from "../../../../elements/fields/InputField"
import EventInputField from "../../../../elements/fields/EventInputField"
import TrashIcon from "../../../../svgs/general/TrashIcon"
import FormError from "../../../../elements/alerts/FormError"
import CheckboxField from "../../../../elements/fields/CheckboxField"

export default {
    name: 'Tag',

    components: {
        CheckboxField,
        FormError,
        TrashIcon,
        EventInputField,
        InputField,
        SelectField,
        RadioField,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    data() {
        return {
            searches: {},

            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    checkCurrentTags: 0,
                    tags: {
                        1: {
                            ratio: '100',
                            tag: null,
                            tag_id: null,
                        }
                    },
                },
            }),
        }
    },

    mounted() {
        if (_.size(this.event.settings) === 0) {
            this.searches = {
                1: this.newSearch()
            }
        }

        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));

            _.forOwn(this.form.settings.tags, (tag, index) => {
                let search = this.newSearch()
                if (tag.tag_id) {
                    search.selected = {
                        id: tag.tag_id,
                        name: tag.tag
                    }
                    search.options = [
                        search.selected
                    ]
                }

                this.searches = {
                    ...this.searches,
                    [index]: search,
                }
            })

        }

    },

    methods: {

        newSearch() {
            return new ParseySearch({
                url: this.action.routes.search,
                type: 'tags',
                default: {
                    id: 0,
                    name: 'Searching...'
                }
            })
        },

        addTag() {
            let count = _.size(this.form.settings.tags);
            count++;
            this.form.settings.tags = {
                ...this.form.settings.tags,
                [count]: {
                    ratio: '0',
                    tag: null,
                    tag_id: null,
                }
            }

            this.searches = {
                ...this.searches,
                [count]: this.newSearch(),
            }
        },

        removeTag(index) {
            this.$delete(this.form.settings.tags, index);
            this.$delete(this.searches, index);
            if (_.size(this.form.settings.tags) === 0) {
                this.addTag()
                return;
            }
            let count = 0;
            this.form.settings.tags = _.mapKeys(this.form.settings.tags, () => {
                count++;
                return count;
            });
            count = 0;
            this.searches = _.mapKeys(this.searches, () => {
                count++;
                return count;
            });
        },

        save() {
            let ratioTotal = _.sumBy(Object.keys(this.form.settings.tags), index => {
                return parseInt(this.form.settings.tags[index].ratio)
            });

            if (ratioTotal !== 100) {
                this.form.errors.addError('form', `Ratio values have to add up to 100 currently at ${ratioTotal}`)
                return;
            }

            _.forEach(this.form.settings.tags, (tag, index) => {
                this.form.settings.tags[index].tag = _.get(this.searches[index], `selected.name`)
                this.form.settings.tags[index].tag_id = _.get(this.searches[index], `selected.id`)
            })

            if (_.find(this.form.settings.tags).tag_id === undefined) {
                this.form.errors.addError('form', `Please select at least one tag`)
                return;
            }

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

    },

    computed: {
        showRatios() {
            return _.size(this.searches) > 0;
        },
    }
}
</script>

