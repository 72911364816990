import DefaultLayout from "../components/layouts/DefaultLayout"
import NotFound from "../components/errors/NotFound"
import DefaultSidebar from "../components/layouts/partials/DesktopSidebar"

export default [
    {
        path: '/errors/lost',
        name: '404',
        meta: {
            layout: DefaultLayout,
        },
        components: {
            default: NotFound,
            sidebar: DefaultSidebar,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        meta: {
            layout: DefaultLayout,
        },
        components: {
            default: NotFound,
            sidebar: DefaultSidebar,
        }
    }
]
