module.exports = {

    data() {
        return {
            pages: [
                {
                    name: 'UserProfile',
                    label: 'Profile',
                    icon: 'ProfileIcon',
                },
                {
                    name: 'UserSecurity',
                    label: 'Security',
                    icon: 'ShieldIcon',
                },
                {
                    name: 'UserBilling',
                    label: 'Plan & Usage',
                    icon: 'CardIcon',
                },
                {
                    name: 'Mailboxes',
                    label: 'Mailboxes',
                    icon: 'MailboxIcon',
                },
                {
                    name: 'UserApps',
                    label: 'Your Apps',
                    icon: 'ViewGridIcon',
                },
            ]
        }
    },

}
