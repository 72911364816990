<template>
    <div>

        <input-label label="Name">
            <input-field :form="form" name="name"/>
        </input-label>

        <input-label label="Forwarding Addresses">
            <input-field :form="form" name="forwards"
                         help="Separate with commas for multiple emails"/>
        </input-label>

    </div>
</template>

<script>
import InputField from "../../elements/fields/InputField"
import InputLabel from "../../elements/fields/InputLabel"

export default {
    name: 'MailboxEdit',

    components: {
        InputLabel,
        InputField
    },

    props: [
        'mailbox',
        'form',
    ],

}
</script>

