<template>
    <button @click.prevent="$emit('click')"
            class="h-9 w-9 p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none">
        <slot />
    </button>
</template>

<script>
export default {
    name: 'RoundIconButton',
}
</script>