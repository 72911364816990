module.exports = {

    data() {
        return {
            formSettings: {
                keys: {
                    findBy: 'set_affiliate',
                    manualCode: 'affiliate_code',
                    manualEmail: 'affiliate_email',
                    affiliateField: 'affiliate_field',
                    triggerField: 'affiliate_extraction_key',
                }
            },

            dropdowns: {

                triggerFields: {
                    selected: {
                        affiliate: null,
                        leadAffiliate: null,
                    }
                },

                findAffiliateMethods: {
                    loading: false,
                    selected: null,
                    options: [
                        {
                            label: 'Choose affiliate from a list',
                            value: 'manual_code',
                        },
                        {
                            label: 'Find affiliate using a trigger field',
                            value: 'use_field',
                        },
                    ],
                },

                affiliates: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'affiliates',
                    default: {
                        id: 0,
                        name: 'Searching...'
                    }
                }),

                affiliateFields: {
                    loading: true,
                    selected: null,
                    options: [],
                }

            }
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = _.merge(this.form.settings, JSON.parse(JSON.stringify(this.event.settings)));
        }

        this.dropdowns.findAffiliateMethods.selected = _.find(this.dropdowns.findAffiliateMethods.options, method => {
            let setting = _.get(this.form.settings, this.formSettings.keys.findBy);
            if (setting === 'manual_email') {
                return method.value === 'manual_code';
            }
            return method.value == setting;
        })

        this.dropdowns.triggerFields.selected.affiliate = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(_.get(this.form.settings, this.formSettings.keys.triggerField));
        })

        this.getAffiliateFields();

    },

    methods: {

        getAffiliateFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'affiliate'
            }).then(() => {
                this.loading = false;
                this.dropdowns.affiliateFields.options = JSON.parse(JSON.stringify(this.affiliateFields))
                _.find(this.dropdowns.affiliateFields.options, group => {
                    let field = _.find(group.fields, field => {
                        return field.name === _.get(this.form.settings, this.formSettings.keys.affiliateField)
                    })
                    if (field) {
                        this.dropdowns.affiliateFields.selected = field
                        return true;
                    }
                    return false
                })
            })
        },

        findAffiliate() {
            let type = _.get(this.form.settings, this.formSettings.keys.findBy);
            let typeSearchKey = 'code'
            let search = _.get(this.form.settings, this.formSettings.keys.manualCode);
            if (type === 'manual_email') {
                typeSearchKey = 'email'
                _.set(this.form.settings, this.formSettings.keys.findBy, 'manual_code');
                search = _.get(this.form.settings, this.formSettings.keys.manualEmail);
            }

            if (!search || search.length === 0) {
                return true;
            }
            this.dropdowns.affiliates.searching = true;
            Parsey.get(`${this.action.routes.search}?type=affiliates&search=${search}|${typeSearchKey}`)
                .then(results => {
                    this.dropdowns.affiliates.selected = _.find(results)
                    this.dropdowns.affiliates.searching = false;
                    this.dropdowns.affiliates.options = results;
                    this.affiliateListUpdated();
                })
        },

        findAffiliateMethodsUpdated() {
            _.set(this.form.settings, this.formSettings.keys.findBy, _.get(this.dropdowns.findAffiliateMethods, 'selected.value'));
            this.affiliateListUpdated()
        },

        affiliateListUpdated() {
            if (this.usingSelectFromList) {
                _.set(this.form.settings, this.formSettings.keys.findBy, 'manual_code')
                _.set(this.form.settings, this.formSettings.keys.manualCode, _.get(this.dropdowns.affiliates, 'selected.code'))
            }
        },

        affiliateFieldUpdated() {
            _.set(this.form.settings, this.formSettings.keys.affiliateField, _.get(this.dropdowns.affiliateFields, 'selected.name'));
        },

        affiliateTriggerFieldUpdate() {
            _.set(this.form.settings, this.formSettings.keys.triggerField, _.get(this.dropdowns.triggerFields.selected, 'affiliate.id'));
        },


    },

    computed: {

        usingSelectFromList() {
            return ['manual_code', 'manual_email'].includes(_.get(this.dropdowns.findAffiliateMethods, 'selected.value'));
        },

        usingFindUsingTriggerField() {
            return _.get(this.dropdowns.findAffiliateMethods, 'selected.value') === 'use_field';
        },

        affiliateFields() {
            if (!_.get(this.action, 'fields.affiliate')) {
                return [];
            }

            let fields = this.action.fields.affiliate;

            return _.reject(fields, group => group.group === 'Special Field Options');
        }

    }


}
