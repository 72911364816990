<template>

    <div class="flex items-center justify-between mb-6">

        <div class="flex-1 flex items-center">
            <button @click="$router.push({name: 'History'})"
                    class="flex items-center w-8 h-8 p-2 rounded-full bg-teal-400 text-white focus:ring-4 focus:ring-teal-400 focus:ring-opacity-40 focus:outline-none">
                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"></path>
                </svg>
            </button>

            <p class="mx-5">
                <span class="font-semibold uppercase">
                    Processed at:
                </span>
                {{ processDate }}
            </p>

            <button v-if="showReprocess"
                    v-tooltip="'Reprocess'"
                    @click="reprocess"
                    :disabled="reprocessing"
                    :class="{'animate-spin': reprocessing}"
                    class="flex items-center w-8 h-8 p-2 rounded-full bg-gray-50 text-gray-500 focus:ring-4 focus:ring-gray-200 focus:outline-none">
                <redo-icon />
            </button>

            <default-dropdown v-else-if="!details.loading" align="center" width="60">
                <template #trigger>
                    <button type="button"
                            class="flex items-center w-8 h-8 p-1.5 rounded-full bg-gray-50 text-gray-500 focus:ring-4 focus:ring-gray-200 focus:outline-none">
                        <history-icon/>
                    </button>
                </template>

                <template #content>
                    <div class="p-4">
                        <button @click="reprocess"
                                :class="{disabled: reprocessing}"
                                :disabled="reprocessing"
                                class="btn btn-teal btn-sm w-full flex items-center mb-3">
                            <template v-if="reprocessing">
                                <spin-icon class="w-5 h-5 mr-2"/>
                                Reprocessing...
                            </template>
                            <template v-else>
                                <refresh-icon class="w-5 h-5 mr-2"/>
                                Reprocess
                            </template>
                        </button>

                        <router-link v-for="job in details.flowHistory.jobs"
                                     class="text-sm py-2 block text-center rounded-md hover:bg-gray-50 flex items-center justify-center"
                                     :class="{'font-semibold': job.id === selectedJob.id}"
                                     :key="job.id"
                                     :to="{path: job.views.edit}">
                            {{ dateFormat(job.processed_at) }}
                        </router-link>

                    </div>

                </template>
            </default-dropdown>

            <button @click="refresh"
                    v-tooltip="'Refresh Page'"
                    class="ml-4 flex items-center w-8 h-8 p-1.5 rounded-full bg-gray-50 text-gray-500 focus:ring-4 focus:ring-gray-200 focus:outline-none">
                <refresh-icon :class="{'animate-spin': details.loading}" />
            </button>

        </div>

        <div class="flex-shrink-0 flex items-center">

            <spin-icon v-if="loading" class="h-6 w-6 mr-5"/>
            <p v-else class="mr-5">
                <span class="font-semibold">{{ currentNumber }}</span>
                of
                <span class="font-semibold">{{ totalNumber }}</span>
            </p>

            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px text-gray-400">
                <router-link :to="navigate(links.prev)"
                             :class="navigateClass(links.prev)"
                             class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium">
                    <span class="sr-only">Previous</span>
                    <chevron-left-icon class="h-5 w-5"/>
                </router-link>

                <router-link :to="navigate(links.next)"
                             :class="navigateClass(links.next)"
                             class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium">
                    <span class="sr-only">Next</span>
                    <chevron-right-icon class="w-5 h-5"/>
                </router-link>
            </nav>


        </div>

    </div>

</template>

<script>
import RefreshIcon from "../../../svgs/general/RefreshIcon"
import RightArrowIcon from "../../../svgs/general/RightArrowIcon"
import ChevronLeftIcon from "../../../svgs/general/ChevronLeftIcon"
import ChevronRightIcon from "../../../svgs/general/ChevronRightIcon"
import {mapActions} from "vuex"
import SpinIcon from "../../../svgs/general/SpinIcon"
import dayjs from "dayjs"
import DefaultDropdown from "../../../elements/DefaultDropdown"
import HistoryIcon from "../../../svgs/menu/HistoryIcon"
import RedoIcon from "../../../svgs/general/RedoIcon"

export default {
    name: 'HistoryDetailsHeader',

    components: {
        RedoIcon,
        HistoryIcon,
        DefaultDropdown,
        SpinIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
        RefreshIcon,
        RightArrowIcon,
    },

    props: {
        currentNumber: {
            type: [Number, String],
            default: 0,
        },
        links: {
            type: Object,
        },
        details: {
            type: Object,
        },
        loading: {
            type: Boolean,
            default: true,
        },
        selectedJob: {
            type: [Object, String]
        },
        reprocessing: {
            type: Boolean,
            default: false,
        }
    },

    mounted() {

    },

    methods: {
        navigate(url) {
            if (!url) {
                return {
                    path: this.$route.path
                }
            }

            return {
                path: url
            }
        },
        navigateClass(apiUrl) {
            if (apiUrl) {
                return 'hover:bg-blue-500 hover:text-white'
            }

            return 'text-gray-200 cursor-not-allowed';
        },

        dateFormat(date) {
            return dayjs(date).format('MMM DD, YYYY h:mm A EST')
        },

        reprocess() {
            this.$emit('reprocess')
        },

        refresh() {
            this.$emit('refresh')
        },
    },

    computed: {
        historyLoading() {
            return this.$store.state.history.loading;
        },

        showReprocess() {
            if (this.details.loading) {
                return false;
            }

            let jobs = _.get(this.details, 'flowHistory.jobs');
            if (!jobs || _.size(jobs) === 1) {
                return true;
            }

            return false;
        },

        processDate() {
            if (this.details.loading) {
                return 'Loading...'
            }

            let date = _.get(this.details, 'flowHistory.jobs.0.processed_at');
            if (!date) {
                return 'Unknown date';
            }
            // Oct 02, 2021 03:02 PM EST
            return this.dateFormat(date)
        },

        totalNumber() {
            if (this.historyLoading) {
                return 0;
            }

            return this.$store.state.history.meta.total;
        },
    }
}
</script>