<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This event allow you to create an appointment and assign it to a specific user.
        </event-sub-text>

        <form-error :form="form"/>

        <div class="grid md:grid-cols-2 gap-y-4 gap-x-10 mb-4">

            <input-label label="Title" :right-label="true">
                <template #right>
                    <checkbox-field :id="`appointment_title_${event.id}`"
                                    :font-medium="false"
                                    v-model="form.settings.title.type"
                                    true-value="1" false-value="0">
                        Use trigger field
                    </checkbox-field>
                </template>

                <select-field v-if="parseInt(form.settings.title.type) === 1"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.title"
                              @input="updateFromTriggerField('title.field', 'title')"
                              label="label"
                              track-by="id"
                              placeholder="Choose trigger field"/>

                <event-input-field v-else
                                   v-model="form.settings.title.value"
                                   placeholder="Title of Appointment"/>

            </input-label>

            <input-label label="Type">
                <select-field :options="dropdowns.type.options"
                              v-model="dropdowns.type.selected"
                              :loading="dropdowns.type.loading"
                              @input="updateFromDropDownValue('type', 'type')"
                              label="label"
                              track-by="value"/>
            </input-label>

        </div>


        <div :class="{'md:grid-cols-3 gap-x-4': usingFindUsingTriggerField, 'md:grid-cols-2 gap-x-10': !usingFindUsingTriggerField}"
             class="grid grid-cols-1 mb-4">

            <input-label label="Assign Appointment to User">
                <select-field :options="dropdowns.findUserMethods.options"
                              v-model="dropdowns.findUserMethods.selected"
                              @input="findUserMethodsUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="usingSelectFromList" label="Users">
                <select-field :options="dropdowns.users.options"
                              v-model="dropdowns.users.selected"
                              :loading="dropdowns.users.loading"
                              @input="userListUpdated"
                              label="label"
                              track-by="id"
                              placeholder="Select user"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.user"
                              @input="userTriggerFieldUpdate"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Search User Field">
                <select-field :options="dropdowns.searchUserField.options"
                              v-model="dropdowns.searchUserField.selected"
                              @input="userSearchFieldUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select user field"/>
            </input-label>

        </div>

        <input-label class="max-w-xl mb-8" :label="userTimezoneLabel">
            <select-field :options="timezone.options"
                          v-model="dropdowns.userTimezone.selected"
                          @input="updateFromDropDownValue('user_timezone', 'userTimezone')"
                          group-label="group"
                          group-values="fields"
                          label="label"
                          track-by="value"
                          :loading="timezone.loading"/>
        </input-label>


        <div class="grid md:grid-cols-3 gap-x-4 mb-4">

            <input-label label="Start Date">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.date"
                              @input="updateFromTriggerField('date_field', 'date')"
                              label="label"
                              track-by="id"
                              placeholder="Choose trigger field"/>
            </input-label>

            <input-label label="Start Time">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.time"
                              @input="updateFromTriggerField('time_field', 'time')"
                              label="label"
                              track-by="id"
                              placeholder="Choose trigger field"/>
            </input-label>

            <input-label label="Timezone Of Trigger Date">
                <select-field :options="timezone.options"
                              v-model="dropdowns.triggerTimezone.selected"
                              @input="updateFromDropDownValue('timezone', 'triggerTimezone')"
                              group-label="group"
                              group-values="fields"
                              label="label"
                              track-by="value"
                              :loading="timezone.loading"/>
            </input-label>

        </div>

        <div class="grid md:grid-cols-3 gap-x-4 mb-4">

            <input-label label="Duration">
                <select-field :options="dropdowns.durationType.options"
                              v-model="dropdowns.durationType.selected"
                              @input="updateFromDropDownValue('duration_type', 'durationType')"
                              label="label"
                              track-by="value"/>
            </input-label>

            <input-label v-if="parseInt(form.settings.duration_type) === 1" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.duration"
                              @input="updateFromTriggerField('duration_field', 'duration')"
                              label="label"
                              track-by="id"
                              placeholder="Choose trigger field"/>
            </input-label>

            <input-label v-if="parseInt(form.settings.duration_type) === 2" label="Duration Time">
                <event-input-field placeholder="60" v-model="form.settings.duration_value"/>
            </input-label>

            <input-label v-if="parseInt(form.settings.duration_type) > 0" label="Time Interval">
                <select-field :options="dropdowns.durationInterval.options"
                              v-model="dropdowns.durationInterval.selected"
                              @input="updateFromDropDownValue('duration_interval', 'durationInterval')"
                              label="label"
                              track-by="value"/>
            </input-label>
        </div>

        <input-label class="mb-8" label="Appointment Note" :right-label="true">
            <template #right>
                <checkbox-field :id="`appointment_note_${event.id}`"
                                :font-medium="false"
                                v-model="form.settings.note.type"
                                true-value="1" false-value="0">
                    Use trigger field
                </checkbox-field>
            </template>

            <select-field v-if="parseInt(form.settings.note.type) === 1"
                          :options="triggerFields"
                          v-model="dropdowns.triggerFields.selected.note"
                          @input="updateFromTriggerField('note.field', 'note')"
                          label="label"
                          track-by="id"
                          placeholder="Choose trigger field"/>

            <text-area-field v-else
                             rows="3"
                             v-model="form.settings.note.value"></text-area-field>
        </input-label>


        <tab-pills>
            <tab-pill name="Confirm Date/Time" :selected="true">
                <tab-header>
                    Confirm Date
                    <template #sub>
                        Choose a date and time format that displays your correct date and time from
                        the trigger dates
                    </template>
                </tab-header>

                <div class="grid md:grid-cols-3 gap-4">

                    <input-label label="Trigger Date/Time">
                        <fake-input>
                            <template v-if="noTriggerDateAndTime">
                                Select Start Date and Time fields
                            </template>
                            <template v-else>
                                {{ triggerDateAndTime.date }} {{ triggerDateAndTime.time }}
                            </template>

                        </fake-input>
                    </input-label>

                    <input-label label="Date Conversion Format">
                        <select-field :options="dropdowns.parseFormats.options"
                                      v-model="dropdowns.parseFormats.selected"
                                      @input="updateFromDropDownValue('parse_method', 'parseFormats')"
                                      label="label"
                                      track-by="value"/>
                    </input-label>

                    <input-label label="Converted Date">
                        <fake-input :loading="convertedDateTime.loading">
                            {{ convertedDateTime.value }}
                        </fake-input>
                    </input-label>

                </div>

            </tab-pill>

            <tab-pill name="Custom Fields">
                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="noteFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Appointment"
                                      :refreshing="dropdowns.noteFields.loading"/>
            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="noteFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Appointment"
                                     :refreshing="dropdowns.noteFields.loading"/>
            </tab-pill>

            <tab-pill name="Map Fields">
                <tab-header :hide-sub-text="true" :no-margin-bottom="true">
                    Map Fields
                </tab-header>
                <map-fields :fields="triggerFieldAsObject"
                            :app-fields="noteFields"
                            :refreshing="dropdowns.noteFields.loading"
                            :extraction-fields="extractionFields"
                            @updated="extractedFieldsUpdated"
                            @refresh="refreshFields"
                            name="Appointment"/>
            </tab-pill>

        </tab-pills>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventSubText from "../elments/EventSubText"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import {mapGetters} from "vuex"
import EventInputField from "../../../../elements/fields/EventInputField"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import FindUserOptions from "../mixins/find-user-options"
import EventHelpers from "../mixins/event-helpers"
import TextAreaField from "../../../../elements/fields/TextAreaField"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import MapFields from "../partials/MapFields"
import TabHeader from "../../../../elements/tabs/TabHeader"
import FakeInput from "../../../../elements/fields/FakeInput"

export default {
    name: 'Appointment',

    components: {
        FakeInput,
        TabHeader,
        MapFields,
        LegacyCustomFields,
        LegacyMagicFields,
        TabPill,
        TabPills,
        TextAreaField,
        CheckboxField,
        EventInputField,
        SelectField,
        InputLabel,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        FindUserOptions,
        EventHelpers
    ],

    data() {
        return {
            formSettings: {
                keys: {
                    triggerField: 'user_field',
                    userType: 'user_type',
                    manual: 'user_value',
                    searchType: 'user_search',
                },

                findUserByTriggerValue: 0,
                findUserCurrent: 8,
                findUserSkip: 6,
                findUserSelectOne: 1,

                searchByEmail: 0,
                searchByUserId: 1,
                searchByFirst: 2,
                searchByFirstLast: 3,
            },
            dropdowns: {
                noteFields: {
                    loading: true,
                },
                triggerFields: {
                    selected: {
                        title: null,
                        note: null,
                        date: null,
                        time: null,
                        duration: null,
                    }
                },
                findUserMethods: {
                    loading: false,
                    options: [
                        {
                            label: 'Choose user from a list',
                            value: 1,
                        },
                        {
                            label: "Use current contact owner",
                            value: 8,
                        },
                        {
                            label: 'Find user using a trigger field',
                            value: 0,
                        },
                        {
                            label: 'Skip, do not assign user',
                            value: 6,
                        }
                    ],
                    selected: null,
                },

                type: {
                    selected: null,
                    loading: true,
                    options: [],
                },

                triggerTimezone: {
                    selected: null,
                },

                userTimezone: {
                    selected: null,
                },

                searchUserField: {
                    loading: false,
                    options: [
                        {
                            label: 'Email',
                            value: 0,
                        },
                        {
                            label: 'User Id',
                            value: 1,
                        },
                        {
                            label: 'First Name',
                            value: 2,
                        },
                        {
                            label: 'First & Last Name',
                            value: 3,
                        },
                    ],
                    selected: null,
                },

                durationType: {
                    selected: null,
                    options: [
                        {
                            label: 'Do not set a duration',
                            value: 0,
                        },
                        {
                            label: "Use a trigger field",
                            value: 1,
                        },
                        {
                            label: 'Define manually',
                            value: 2,
                        },
                    ],
                },

                durationInterval: {
                    selected: null,
                    options: [
                        {
                            label: 'Minutes',
                            value: 0,
                        },
                        {
                            label: "Hours",
                            value: 1,
                        },
                    ],
                },

                parseFormats: {
                    selected: null,
                    options: [
                        {
                            label: 'Standard date conversion',
                            value: 0,
                        },
                        {
                            label: "MM/DD/YYYY",
                            value: 1,
                        },
                        {
                            label: "DD/MM/YYYY",
                            value: 2,
                        },
                        {
                            label: "MM/DD/YY",
                            value: 3,
                        },
                        {
                            label: "DD/MM/YY",
                            value: 4,
                        },
                        {
                            label: "MM-DD-YYYY",
                            value: 5,
                        },
                        {
                            label: "DD-MM-YYYY",
                            value: 6,
                        },
                        {
                            label: "MM-DD-YY",
                            value: 7,
                        },
                        {
                            label: "DD-MM-YY",
                            value: 8,
                        },
                    ],
                },
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    parsey_2: 1,
                    date_field: '',
                    duration_field: '',
                    duration_interval: 0,
                    duration_type: 0,
                    duration_value: '',
                    parse_method: 0,
                    time_field: '',
                    timezone: 'America/New_York',
                    type: 'Other',
                    user_field: 1,
                    user_search: 1,
                    user_timezone: 'America/New_York',
                    user_type: 1,
                    user_value: '',
                    title: {
                        type: 0,
                        field: '',
                        value: '',
                    },
                    note: {
                        type: 0,
                        field: '',
                        value: '',
                    },
                    custom_fields: {},
                    extraction_fields: {},
                    magic_fields: {},
                },
            }),
            cancelTokenSource: null,
            convertedDateTime: {
                loading: false,
                value: 'converting...',
            }
        }
    },

    watch: {
        'form.settings.date_field'() {
            this.updateConvertedDateTime();
        },
        'form.settings.time_field'() {
            this.updateConvertedDateTime();
        },
        'form.settings.parse_method'() {
            this.updateConvertedDateTime();
        },
        'form.settings.timezone'() {
            this.updateConvertedDateTime();
        },
        'form.settings.user_timezone'() {
            this.updateConvertedDateTime();
        },
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = _.merge(this.form.settings, JSON.parse(JSON.stringify(this.event.settings)));
        }
        // Update this first
        this.updateTimezoneFromOldValues('user_timezone');
        this.updateTimezoneFromOldValues('timezone');

        this.getAppointmentTypes();
        this.getActionFields();
        this.setTimezones();

        this.setOptions();

    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getAppointmentTypes() {
            Parsey.get(`${this.action.routes.search}?type=noteTypes&search=all`)
                .then(results => {
                    this.dropdowns.type.loading = false;
                    this.dropdowns.type.options = results;
                    this.dropdowns.type.selected = _.find(results, type => {
                        return type.value === this.form.settings.type;
                    })
                })
        },

        updateTimezoneFromOldValues(formSettingsKey) {
            let oldTimezones = {
                'US/Hawaii': 'Pacific/Honolulu',
                'US/Pacific': 'America/Los_Angeles',
                'US/Arizona': 'America/Phoenix',
                'US/Mountain': 'America/Denver',
                'US/Central': 'America/Chicago',
                'US/Eastern': 'America/New_York',
                'UTC': 'Europe/London',
            };

            if (Object.keys(oldTimezones).includes(_.get(this.form.settings, formSettingsKey))) {
                _.set(this.form.settings, formSettingsKey, oldTimezones[_.get(this.form.settings, formSettingsKey)])
            }
        },

        setTimezones() {
            this.$store.dispatch('loadLegacyTimezones')
                .then(() => {
                    this.setDropdownValueFromGroups(this.timezone.options, 'fields', 'value', 'userTimezone', 'user_timezone')
                    this.setDropdownValueFromGroups(this.timezone.options, 'fields', 'value', 'triggerTimezone', 'timezone')
                })
        },

        setOptions() {
            this.setDropdownTrigger('date', 'date_field')
            this.setDropdownTrigger('time', 'time_field')

            this.setDropdownValue('durationType', 'duration_type', true)
            this.setDropdownValue('durationInterval', 'duration_interval', true)
            this.setDropdownTrigger('duration', 'duration_field')

            this.setDropdownValue('parseFormats', 'parse_method', true)

            this.updateConvertedDateTime()
        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'note'
            }).then(() => {
                this.dropdowns.noteFields.loading = false;
            })
        },

        refreshFields() {
            this.dropdowns.noteFields.loading = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'note'
            }).then(() => {
                this.dropdowns.noteFields.loading = false;
            })
        },

        extractedFieldsUpdated(mappedFields) {
            this.form.settings.extraction_fields = mappedFields
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

        updateConvertedDateTime() {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }

            if (this.noTriggerDateAndTime) {
                this.convertedDateTime.value = 'no date'
                return;
            }

            this.cancelTokenSource = axios.CancelToken.source()
            this.convertedDateTime.loading = true;
            let url = '/api/v1/flows/legacy/date-conversion';
            axios.get(url, {
                params: {
                    date: this.triggerDateAndTime.date,
                    time: this.triggerDateAndTime.time,
                    method: this.form.settings.parse_method,
                    timezone: this.form.settings.timezone,
                    user_timezone: this.form.settings.user_timezone,
                },
                cancelToken: this.cancelTokenSource.token,
            }).then(({data}) => {
                this.convertedDateTime.value = data.date;
                this.convertedDateTime.loading = false;
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('canceled request')
                }
            })
        },

    },


    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        timezone() {
            return this.$store.state.flowOptions.legacy.timezone;
        },

        userTimezoneLabel() {
            if (parseInt(this.form.settings.user_type) === this.formSettings.findUserSkip) {
                return 'Timezone Appointment should be saved';
            }

            return 'Timezone of User';
        },

        extractionFields() {
            let fields = _.get(this.event, 'settings.extraction_fields')
            if (_.size(fields)) {
                return fields;
            }

            return {};
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        noteFields() {
            if (!_.get(this.action, 'fields.note')) {
                return [];
            }

            let fields = this.action.fields.note;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },

        noTriggerDateAndTime() {
            return this.triggerDateAndTime.date.length === 0 && this.triggerDateAndTime.time.length === 0;
        },

        triggerDateAndTime() {
            let date = '';
            let time = '';
            let flow = this.$store.state.flow.data;
            let triggerData = _.get(flow.trigger.data, `${flow.type}.data`);
            if (parseInt(this.form.settings.date_field) > 0) {
                let triggerDateField = _.get(this.triggerFieldAsObject, this.form.settings.date_field);

                date = _.get(triggerData, triggerDateField.path, '')
            }

            if (parseInt(this.form.settings.time_field) > 0) {
                let triggerTimeField = _.get(this.triggerFieldAsObject, this.form.settings.time_field);

                time = _.get(triggerData, triggerTimeField.path, '')
            }

            return {
                date,
                time,
            }
        },

    }
}
</script>

