<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100" fill="currentColor">
        <path d="M26.4201 75.604C21.4045 75.604 16.9541 74.5304 12.9275 72.2401C8.9009 70.0214 5.72201 67.0154 3.46146 63.0789C1.13027 59.1425 0 54.7766 0 49.8381C0 44.8997 1.13027 40.5338 3.46146 36.5973C5.79265 32.6609 8.97154 29.6549 12.9981 27.3646C17.0247 25.1458 21.4752 24.0723 26.4908 24.0723C30.5174 24.0723 34.2614 24.788 37.5816 26.2194C40.9017 27.6509 43.7274 29.7264 46.0586 32.4462L40.1247 38.1003C36.5219 34.1639 32.1421 32.2315 26.9146 32.2315C23.5238 32.2315 20.4862 32.9472 17.8018 34.4502C15.1174 35.9532 12.9981 38.1003 11.5147 40.7485C10.0312 43.3967 9.25411 46.4743 9.25411 49.8381C9.25411 53.202 10.0312 56.2796 11.5147 58.9278C12.9981 61.5759 15.1174 63.6515 17.8018 65.1545C20.4862 66.6575 23.5238 67.4448 26.9146 67.4448C32.1421 67.4448 36.5219 65.4408 40.1247 61.5043L46.0586 67.2301C43.7274 69.9498 40.9017 72.0254 37.5816 73.4568C34.1907 74.8883 30.4467 75.604 26.4201 75.604Z" fill="#312B8A"/>
        <path d="M91.5579 48.8406C94.1661 49.7711 96.1725 51.2741 97.7107 53.3496C99.2489 55.4968 99.9846 58.145 99.9846 61.2941C99.9846 65.66 98.4464 68.9523 95.3031 71.3142C92.1598 73.676 87.6121 74.8928 81.6599 74.8928H58.3862V24.7925H80.3223C85.8732 24.7925 90.0866 25.9376 93.0961 28.2279C96.1056 30.5898 97.5769 33.739 97.5769 37.8186C97.5769 40.3236 97.0419 42.4707 95.9719 44.4032C94.9018 46.3356 93.4305 47.767 91.5579 48.8406ZM67.0804 32.0928V45.9062H79.386C82.3956 45.9062 84.7363 45.3336 86.4083 44.1169C88.0133 42.9717 88.8159 41.254 88.8159 39.0353C88.8159 36.745 88.0133 35.0273 86.4083 33.8105C84.7363 32.6654 82.3956 32.0928 79.386 32.0928H67.0804ZM81.1249 67.5924C87.8796 67.5924 91.2235 65.159 91.2235 60.3637C91.2235 55.5684 87.8796 53.1349 81.1249 53.1349H67.0804V67.5924H81.1249Z" fill="#ABACB0"/>
    </svg>
</template>

<script>
export default {
    name: "ClickBankIcon"
}
</script>
