<template>
    <div>
        <default-well title="Your Apps" description="Manage your connected Applications security credentials">

            <form>


            </form>

        </default-well>
    </div>
</template>

<script>
import DefaultWell from "../../elements/DefaultWell"

export default {
    name: "UserApps",

    components: {
        DefaultWell
    },

    metaInfo: {
        title: 'Your Apps | Settings'
    }
}
</script>
