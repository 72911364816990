<template>
    <div>
        <p class="font-semibold text-lg flex items-center" :class="hideSubText && !noMarginBottom ? 'mb-6': 'mb-1'">
            <slot/>
        </p>
        <p v-if="!hideSubText" class="text-sm text-gray-400 mb-6">
            <slot name="sub"/>
        </p>
    </div>
</template>

<script>
export default {
    name: 'TabHeader',

    props: {
        hideSubText: {
            type: Boolean,
            default: false,
        },

        noMarginBottom: {
            type: Boolean,
            default: false,
        }
    }
}
</script>