<template>

    <component :is="this.$route.meta.layout || 'div'">
        <router-view/>
    </component>

</template>

<script>
export default {
    name: 'App',

    metaInfo() {
        return {
            titleTemplate: '%s',
            changed: (newInfo) => {
                this.$store.dispatch('changePage', newInfo.title)
            }
        }
    },

    data() {
        return {}
    },

    created() {
        if (this.user) {
            this.loadState();
        }
    },

    mounted() {
        if (this.userChannel) {
            this.userChannel
                .listen('Users\\EmailIsVerified', (e) => {
                    window.location = '/'
                })
                .listen('Users\\UserUpdated', ({user}) => {
                    this.$store.commit('updateUser', user)
                })
                .listen('Users\\BroadcastNotification', (payload) => {
                    this.$store.commit('pushNotification', payload)
                })
                .listen('Flows\\BroadcastFlowUpdated', (payload) => {
                    this.$store.dispatch('flow/updateFlow', {
                        id: payload.id,
                        type: payload.type,
                    })
                })
        }
    },

    methods: {
        loadState() {
            this.$store.dispatch('flows/getAllFlows')
        }
    },

    computed: {

        user() {
            return this.$store.state.user
        },

        userChannel() {
            if (this.user) {
                return window.Echo.private(`parsey.user.${this.user.id}`)
            }

            return false;
        }
    }
}
</script>
