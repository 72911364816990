<template>
    <div>

        <legacy-trigger-mailbox/>

        <flow-step v-if="hasMailbox"
                   name="Email Trigger"
                   :open="`${flow.views.trigger}/fields`"
                   :close="flow.views.trigger"
                   :last="true"
                   :name-editable="false">

            <template #content>

                <legacy-trigger-waiting v-if="isWaiting"/>

                <legacy-trigger-confirm v-if="showConfirmation"/>

                <legacy-email-fields v-if="!isWaiting && showFields"/>

            </template>

        </flow-step>


    </div>
</template>

<script>
import {mapState} from "vuex"
import LegacyTriggerWaiting from "../partials/LegacyTriggerWaiting"
import LegacyTriggerMailbox from "./LegacyTriggerMailbox"
import LegacyTriggerConfirm from "../partials/LegacyTriggerConfirm"
import LegacyEmailFields from "./LegacyEmailFields"
import FlowStep from "../../components/FlowStep"

export default {
    name: 'LegacyEmailTrigger',

    components: {
        FlowStep,
        LegacyEmailFields,
        LegacyTriggerConfirm,
        LegacyTriggerMailbox,
        LegacyTriggerWaiting
    },

    mounted() {
        if (_.get(this.flow, 'trigger.mailbox') === null && !this.$route.path.includes('/mailbox')) {
            this.$router.push({
                path: this.$route.path + '/mailbox'
            })
            return;
        }
    },

    computed: {
        ...mapState('flow', {
            updating: state => state.updating,
            flow: state => state.data,
        }),

        isWaiting() {
            return _.get(this.flow, 'trigger.state') === 'waiting';
        },

        hasMailbox() {
            return _.get(this.flow, 'trigger.mailbox') > 0;
        },

        showConfirmation() {
            if (_.get(this.flow, 'trigger.state') !== 'received') {
                return false;
            }

            if (_.get(this.flow, 'settings.confirmed')) {
                return false;
            }

            return true;
        },

        showFields() {
            if (_.get(this.flow, 'settings.confirmed')) {
                return true;
            }

            return false;
        },
    }
}
</script>