<template>

    <div class="flex items-center mr-8 mb-1">
        <input :id="id"
               :checked="value === modelValue"
               :value="value"
               @change="$emit('change', value)"
               type="radio"
               class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
        <label :for="id" class="ml-3 block text-sm font-medium">
            <slot/>
        </label>
    </div>

</template>

<script>
export default {
    name: 'RadioField',

    model: {
        prop: 'modelValue',
        event: 'change'
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        value: {
            type: String,
        },

        modelValue: {
            default: ''
        }
    },

}
</script>

