<template>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
        <div class="flex flex-col w-20">

            <div class="flex flex-col h-0 flex-1 bg-gray-700">
                <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div class="flex items-center justify-center flex-shrink-0">
                        <div class="w-14 h-14">
                            <router-link :to="{name: 'Home'}">
                                <parsey-icon class="text-white"/>
                            </router-link>
                        </div>
                    </div>

                    <nav class="mt-10 flex-1 flex flex-col items-center">

                        <router-link v-for="nav in mainNavigation"
                                     :key="nav.route"
                                     :to="{name: nav.route}"
                                     custom
                                     v-slot="{href, navigate, isActive}">
                            <a :href="href" @click="navigate"
                               class="block hover:bg-gray-800 text-xl w-14 h-14 p-3.5 mb-5 rounded-lg flex items-center justify-center">
                                <component :is="nav.icon"
                                           :class="{'text-white': isActive, 'text-gray-200': !isActive}"/>
                            </a>
                        </router-link>

                    </nav>

                </div>

                <user-gravatar/>

            </div>
        </div>
    </div>

</template>

<script>
import ParseyIcon from "../../svgs/ParseyIcon"
import PlusIcon from "../../svgs/menu/PlusIcon"
import FlowIcon from "../../svgs/menu/FlowIcon"
import HistoryIcon from "../../svgs/menu/HistoryIcon"
import UserGravatar from "./UserGravatar"

export default {
    name: 'DesktopSidebar',

    components: {
        UserGravatar,
        ParseyIcon,
        PlusIcon,
        FlowIcon,
        HistoryIcon,
    },

    props: [
        'mainNavigation'
    ],

}
</script>


