<template>
    <center-modal :allow-over-flow="true" :size="modalSize" :show="show" @close="close">

        <div class="md:p-2">
            <div class="relative mb-4">
                <h2 class="text-2xl font-semibold pr-12 truncate">
                    {{ title }}
                </h2>
                <close-x-button @click="close"
                                :disabled="changed"
                                :class="{disabled: changed}"
                                class="absolute top-0 right-0 -mr-1 -mt-1"/>
            </div>

            <div v-if="loading">
                <div v-for="row in count" :key="row" class="grid grid-cols-3 gap-4 animate-pulse">
                    <div v-for="col in [1,2,3]" :key="col" class="h-12 bg-gray-100 w-full mb-4 rounded-lg"></div>
                </div>
            </div>

            <div v-else>

                <div v-if="count === 0" class="flex flex-col items-center justify-center">
                    <p class="text-gray-600">
                        Filters allow you to set conditions on whether this {{ property.type }}
                        should run or be skipped.
                    </p>

                    <button class="btn btn-teal w-full mt-4 max-w-sm"
                            @click.prevent="addFilter"
                            :disabled="form.busy"
                            :class="{disabled: form.busy}"
                            v-html="form.buttonHtml('Create Filter', 'Creating...')"></button>

                </div>
                <div v-else>

                    <p class="text-sm text-gray-400 pb-3">
                        Only run this {{ property.type }} if...
                    </p>

                    <legacy-filter v-for="(filter, key, index) in filters" :key="filter.id"
                                   :filter="filter" :options="options"
                                   :last="index === Object.keys(filters).length - 1"/>

                </div>

            </div>
        </div>

    </center-modal>
</template>

<script>
import CenterModal from "../../elements/modals/CenterModal"
import {mapMutations, mapState, mapGetters} from 'vuex'
import FormError from "../../elements/alerts/FormError"
import LegacyFilter from "./LegacyFilter"
import ModalDefaultButtons from "../../elements/modals/ModalDefaultButtons"
import SpinIcon from "../../svgs/general/SpinIcon"
import XIcon from "../../svgs/general/XIcon"
import CloseXButton from "../../elements/buttons/CloseXButton"

export default {
    name: 'LegacyFilterModal',

    components: {
        CloseXButton,
        XIcon,
        SpinIcon,
        ModalDefaultButtons,
        LegacyFilter,
        FormError,
        CenterModal
    },

    data() {
        return {
            form: new ParseyForm({}),
        }
    },

    methods: {
        ...mapMutations('filters', [
            'close',
        ]),

        addFilter() {
            this.$store.dispatch('filters/addFilter', {
                form: this.form
            })
        },
    },

    computed: {
        ...mapState('filters', [
            'show',
            'filters',
            'options',
            'property',
            'changed',
            'count',
        ]),

        ...mapGetters('filters', [
            'loading'
        ]),

        modalSize() {
            if (this.count === 0) {
                return 'md'
            }

            return '6xl'
        },

        title() {
            if (this.count === 0) {
                return `Filters for ${this.property.type}`;
            }

            return `Filters for ${this.property.type}: ${this.property.name}`;
        },
    },
}
</script>
