<template>
    <center-modal :show="show" @close="close" size="lg">

        <form-error :form="form"/>

        <form @submit.prevent="destroy" @keydown.enter.prevent="destroy">

            <alert-error>
                Are you sure you want to delete this flow? This will delete all actions and
                history that is connected to this flow, as well as stop any incoming data that was
                previously directed down this path. An alternative to deleting is just to inactivate
                this flow, which will stop the processing of any data.
            </alert-error>

            <input-label :label="`Please type the flow name: <strong>${flow.name}</strong> to confirm deletion`">
                <input-field :form="form" name="confirm"/>
            </input-label>

            <div class="sm:flex sm:flex-row-reverse mt-3">

                <submit-button :disabled="deleteDisabled"
                               text="Delete"
                               :teal="false"
                               :form="form"
                               extra="bg-red-600 text-white"
                               @save="destroy"
                               loading="Deleting..."/>

                <button @click.prevent="close"
                        class="btn btn-sm btn-block btn-white sm:border-none sm:inline-block sm:w-auto mt-4 sm:mt-0">
                    Cancel
                </button>

            </div>

        </form>


    </center-modal>
</template>

<script>
import CenterModal from "../../../../elements/modals/CenterModal"
import FormError from "../../../../elements/alerts/FormError"
import AlertError from "../../../../elements/alerts/AlertError"
import InputField from "../../../../elements/fields/InputField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import InputLabel from "../../../../elements/fields/InputLabel"

export default {
    name: "DeleteLegacyFlow",

    components: {
        InputLabel,
        SubmitButton,
        InputField,
        AlertError,
        FormError,
        CenterModal
    },

    props: {
        flow: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            form: new ParseyForm({
                confirm: '',
            })
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        destroy() {
            this.$store.dispatch('flows/deleteLegacyFlow', {
                flow: this.flow,
                form: this.form,
            }).then(() => {
                this.close();
            })
        }
    },

    computed: {
        deleteDisabled() {
            return this.form.confirm !== this.flow.name;
        }
    }
}
</script>
