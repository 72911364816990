<template>
    <default-wrapper>

        // TODO


    </default-wrapper>
</template>

<script>
import DefaultWrapper from "../../layouts/partials/DefaultWrapper"


export default {
    name: 'TriggerHistoryShow',

    components: {
        DefaultWrapper
    },

    data() {
        return {}
    },

    mounted() {
        this.getDetails()
    },

    methods: {
        getDetails() {
            // let url = _.replace(_.replace(
            //     this.$store.state.routes.history.show, '0', this.$route.params.historyId
            // ), '0', this.$route.params.flowId);
            //
            // Parsey.get(url).then(({history}) => {
            //
            // });
            //
            // console.log('URL', url);
        }
    }


}
</script>