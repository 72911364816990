<template>
    <div class="bg-white shadow-lg rounded-lg pointer-events-auto mb-6">
        <div class="rounded-lg shadow-xs overflow-hidden">
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0" v-html="svg"></div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p v-if="notification.title"
                           v-html="notification.title"
                           class="text-sm leading-5 font-medium text-gray-900"></p>
                        <p v-if="notification.message"
                           :class="{'mt-1': notification.title}"
                           class="text-sm leading-5 text-gray-500"
                           v-html="notification.message"></p>
                        <router-link :to="{path: '/settings/billing'}">Account</router-link>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                        <button @click="close"
                                class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                            <x-icon class="h-5 w-5 text-gray-400"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import XIcon from "../../svgs/general/XIcon"

export default {
    name: 'DefaultNotification',

    components: {
        XIcon
    },

    props: {
        notificationId: {
            type: Number,
        },

        notification: {
            type: Object,
            required: true,
        },

        icon: {
            type: String,
            default: '',
        },
    },

    methods: {
        close() {
            this.$store.commit('closeNotification', {id: this.notificationId})
        },
    },

    computed: {
        svg() {
            if (this.icon.length > 1) {
                return this.icon;
            }

            return '<svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>';
        }
    }
}
</script>
