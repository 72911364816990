<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 184 184" fill="currentColor">
        <path d="M48.9391 14.2917C66.103 4.23847 86.1127 0.178823 105.83 2.75474C125.548 5.33061 143.848 14.3946 157.866 28.5158C171.883 42.6361 180.826 61.0146 183.305 80.7682C183.321 80.9 183.335 81.0315 183.346 81.1627C183.78 84.7436 184 88.3652 184 92.0092C184 103.82 181.681 115.516 177.174 126.431C172.666 137.346 166.059 147.266 157.725 155.625C149.392 163.983 139.496 170.617 128.601 175.143C117.707 179.67 106.028 182 94.2326 182C82.4373 182 70.7586 179.67 59.8638 175.143C48.9691 170.617 39.0732 163.983 30.7398 155.625C22.4066 147.266 15.7989 137.346 11.2916 126.431C9.30882 121.629 11.6027 116.133 16.4151 114.155C21.2275 112.177 26.7361 114.465 28.7189 119.267C32.1985 127.693 37.2572 135.365 43.6158 141.869C43.7495 141.989 43.8805 142.113 44.0088 142.242C55.091 153.398 69.5409 160.546 85.0932 162.578C100.645 164.609 116.433 161.412 129.986 153.48C130.071 153.43 130.157 153.381 130.244 153.334C135.379 150.296 140.125 146.612 144.362 142.362C150.95 135.754 156.178 127.907 159.746 119.267C163.314 110.627 165.151 101.364 165.151 92.0092C165.151 73.1138 157.668 55.0023 144.362 41.6563C131.058 28.3121 113.025 20.8244 94.2326 20.8244C94.0451 20.8244 93.8588 20.819 93.6739 20.8082C81.3586 20.905 69.218 24.2209 58.4813 30.5096C48.9815 36.0739 40.4956 43.6137 34.2381 52.3863H58.5251C63.73 52.3863 67.9494 56.5962 67.9494 61.7893C67.9494 66.9824 63.73 71.1923 58.5251 71.1923H18.3534C13.9118 71.1923 10.0732 68.0982 9.13963 63.7656L0.212471 22.3346C-0.881497 17.2574 2.35681 12.2568 7.44544 11.1653C12.5341 10.0738 17.5461 13.3048 18.64 18.3819L22.5776 36.6562C30.0089 27.6096 39.1549 20.0224 48.9391 14.2917ZM94.2326 46.7895C99.4375 46.7895 103.657 50.9993 103.657 56.1924V88.129L127.695 112.241C131.366 115.922 131.35 121.876 127.66 125.538C123.97 129.201 118.003 129.185 114.332 125.503L87.551 98.6405C85.7944 96.8785 84.8083 94.4944 84.8083 92.0092V56.1924C84.8083 50.9993 89.0277 46.7895 94.2326 46.7895Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "HistoryIcon"
}
</script>
