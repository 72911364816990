<template>

    <div class="flex">

        <div class="flex-1">
            <div :class="maxWidthClass"
                class="mx-auto px-4 sm:px-6 md:px-8 py-4 sm:py-6 md:py-8">
                <router-view/>
            </div>
        </div>

    </div>

</template>

<script>

import MenuLink from "../../elements/MenuLink"

export default {
    name: 'SettingsIndex',

    components: {
        MenuLink
    },

    computed: {
        maxWidthClass(){
            if(['Mailboxes'].includes(this.$route.name)) {
                return 'max-w-6xl'
            }

            return 'max-w-4xl';
        }
    }
}
</script>
