<template>
    <div>
        <div class="flex items-center">
            <tab-header class="flex-1">
                ClickBank Products
                <template #sub>
                    Here you can add your ClickBank products and setup the actions within Keap
                    that you want to trigger once the product has been purchased.
                </template>
            </tab-header>

            <div class="flex-shrink-0">
                <button @click="modals.add.show = true"
                        class="btn btn-teal-outline btn-xs flex items-center ml-6 mb-4">
                    <plus-icon class="w-3 h-3 mr-1"/>
                    Add Product
                </button>
            </div>

        </div>

        <default-table>
            <template #head>
                <table-header class="w-48 sm:w-56 xl:w-64">
                    Name
                </table-header>
                <table-header class="hidden sm:table-cell">
                    Sku
                </table-header>
                <table-header class="hidden sm:table-cell text-center">
                    Actions
                </table-header>
                <table-header class="py-1.5 text-right">
                    <event-input-field v-if="allProducts.length > 5"
                                       v-model="searchProducts"
                                       input-class="py-2 text-xs"
                                       class="hidden md:block"
                                       placeholder="Search"/>
                </table-header>
            </template>

            <click-bank-product-row v-if="products.length > 0"
                                    v-for="product in products"
                                    :product="product"
                                    :url="routeUrl"
                                    @actions="showActions(product)"
                                    @edit="editProduct(product)"
                                    @update-products="getProducts"
                                    :key="product.id"/>

            <tr v-if="loading.products" :key="row" v-for="row in [1,2,3,4]">
                <table-cell>
                    <div class="h-4 bg-gray-100 rounded"></div>
                </table-cell>
                <table-cell v-for="cell in [1,2]" :key="cell" class="hidden sm:table-cell">
                    <div class="h-4 bg-gray-100 rounded"></div>
                </table-cell>
                <table-cell class="pr-1.5" align="right" :vertical-padding="false">
                    <button-menu size="9" spacing="2"/>
                </table-cell>
            </tr>

            <tr v-if="!loading.products && products.length === 0">
                <table-cell v-if="this.searchProducts.length > 0" colspan="10">
                    No products found with that search term
                </table-cell>
                <table-cell v-else colspan="10">
                    <a href="#" @click.prevent="modals.add.show = true">
                        Add your ClickBank product
                    </a>

                </table-cell>
            </tr>

        </default-table>

        <center-modal :show="modals.actions.show"
                      size="lg"
                      :allow-over-flow="true"
                      @close="modals.actions.show = false">

            <click-bank-actions :product="modals.actions.product"
                                :action="action"
                                :url="routeUrl"
                                @update-products="getProducts"
                                @close="modals.actions.show = false"/>

        </center-modal>

        <center-modal :show="modals.add.show"
                      size="3xl"
                      :allow-over-flow="true"
                      @close="modals.add.show = false">

            <click-bank-product :product="false"
                                :action="action"
                                :url="routeUrl"
                                @add="addProduct"
                                @update-products="getProducts"
                                @close="modals.add.show = false"/>

        </center-modal>

        <center-modal :show="modals.edit.show"
                      size="3xl"
                      :allow-over-flow="true"
                      @close="modals.edit.show = false">

            <click-bank-product :product="modals.edit.product"
                                :action="action"
                                :url="routeUrl"
                                @update-products="getProducts"
                                @close="modals.edit.show = false"/>

        </center-modal>

    </div>
</template>

<script>
import RoundIconButton from "../../../../elements/buttons/RoundIconButton"
import PlusIcon from "../../../../svgs/menu/PlusIcon"
import ClickBankProductRow from "./ClickBankProductRow"
import DefaultTable from "../../../../elements/tables/DefaultTable"
import TableHeader from "../../../../elements/tables/TableHeader"
import TabHeader from "../../../../elements/tabs/TabHeader"
import TableCell from "../../../../elements/tables/TableCell"
import ButtonMenu from "../../../../elements/buttons/ButtonMenu"
import EventInputField from "../../../../elements/fields/EventInputField"
import CenterModal from "../../../../elements/modals/CenterModal"
import ClickBankActions from "./ClickBankActions"
import ClickBankProduct from "./ClickBankProduct"

export default {
    name: 'ClickBankProducts',

    components: {
        ClickBankProduct,
        ClickBankActions,
        CenterModal,
        EventInputField,
        ButtonMenu,
        TableCell,
        TabHeader,
        TableHeader,
        DefaultTable,
        ClickBankProductRow,
        PlusIcon,
        RoundIconButton
    },

    props: {
        action: {
            type: Object,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: {
                products: true,
            },
            modals: {
                add: {
                    show: false,
                },

                actions: {
                    show: false,
                    product: null,
                },

                edit: {
                    show: false,
                    product: null,
                },
            },
            searchProducts: '',
            allProducts: [],
        }
    },

    mounted() {
        this.getProducts();
    },

    methods: {
        getProducts() {
            Parsey.get(this.routeUrl).then(({data}) => {
                this.loading.products = false;
                this.allProducts = data;
            })
        },

        addProduct(product) {
            this.allProducts.push(product);
        },

        editProduct(product) {
            this.modals.edit.show = true;
            this.modals.edit.product = product;
        },

        showActions(product) {
            this.modals.actions.show = true;
            this.modals.actions.product = product;
        },
    },

    computed: {
        routeUrl() {
            let route = _.get(this.$store.state.flow, 'data.trigger.routes.clickbank.products');
            return _.replace(route, 0, this.event.id);
        },

        products() {
            if (this.searchProducts.length > 0) {
                let search = this.searchProducts.toLowerCase();
                return _.sortBy(this.allProducts.filter(product => {
                    return product.sku.toLowerCase().includes(search) ||
                        product.name.toLowerCase().includes(search);
                }), 'name')
            }

            return _.sortBy(this.allProducts, 'name');
        }
    }
}
</script>