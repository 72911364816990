<template>
    <form @submit.prevent="save">

        <event-sub-text>
            Sub Text
        </event-sub-text>

        <form-error :form="form"/>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventSubText from "../elments/EventSubText"

export default {
    name: 'StarterEvent',

    components: {
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            form: new ParseyForm({
                key: this.event.key,
                settings: {},
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }
    },

    methods: {

        save() {

            console.log('FORM SETTINGS', JSON.parse(JSON.stringify(this.form.settings)));

            // this.$store.dispatch("flow/saveLegacyEvent", {
            //     action: this.action,
            //     event: this.event,
            //     form: this.form,
            // })
        },

    },

    computed: {}
}
</script>

