<template>
    <div class="min-h-screen flex">
        <div class="hidden md:block relative w-0" style="flex-grow: 45; flex-basis: 380px">

        </div>
        <div style="flex-grow: 55; flex-basis: 464px"
             class="bg-white md:border-l border-gray-200 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div class="w-full max-w-sm">
                <register-form/>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterForm from "./RegisterForm"

export default {
    name: "Register",

    components: {
        RegisterForm
    }
}
</script>
