<template>
    <div class="overflow-y-auto font-sans" style="max-height: 500px">

<!--        Leave the empty path-->
        <tree-parent v-for="(value, name) in webhook.data"
                     path=""
                     :key="name"
                     :name="name"
                     :value="value"/>
    </div>
</template>

<script>
import TreeParent from "./TreeParent"
import {mapState} from "vuex"

export default {
    name: 'WebhookTree',

    components: {
        TreeParent
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
            webhook: (state) =>  {
                return _.get(state.data, 'trigger.data.webhook')
            }
        }),
    },

}
</script>
