<template>

    <div class="flex flex-col items-center justify-center">
        <div class="w-full sm:max-w-md">
            <div class="h-16">
                <parsey-logo/>
            </div>
        </div>

        <div class="mt-8 w-full sm:max-w-md">

            <div class="bg-white py-8 px-4 sm:shadow-lg sm:rounded sm:px-10 sm:py-12">

                <p class="text-center text-2xl mb-4">
                    Welcome to Parsey!
                </p>

                <form-error :form="form" />

                <div class="text-gray-400">
                    <p class="mb-4">
                        Before you get started we just sent you an email to confirm your address of:
                    </p>
                    <p class="mb-4"><strong>{{ emailAddress }}</strong></p>

                    <p class="mb-4">Click the link within the email to activate your account.</p>

                    <p>
                        Don't see the email,
                        <a class="text-indigo-300 hover:text-indigo-900 cursor-pointer"
                           @click.prevent="sendEmail(true)"
                           v-text="linkText"></a>
                    </p>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

import PasswordMeter from 'vue-simple-password-meter'
import ParseyIcon from "../svgs/ParseyIcon"
import InputField from "../elements/fields/InputField"
import ParseyLogo from "../svgs/ParseyLogo"
import FormError from "../elements/alerts/FormError"

export default {
    components: {
        FormError,
        ParseyLogo,
        PasswordMeter,
        ParseyIcon,
        InputField
    },

    metaInfo: {
        title: 'Confirm Email'
    },

    data() {
        return {
            form: new ParseyForm({
                force: false,
            })
        }
    },

    mounted() {
        this.sendEmail(false)
    },

    methods: {
        sendEmail(force) {
            if (force) {
                this.form.force = true;
            }

            Parsey.post(this.$store.state.user.routes.verification, this.form)
        }
    },

    computed: {
        emailAddress() {
            return this.$store.state.user.email;
        },

        linkText() {
            if (this.form.busy) {
                return 'sending...'
            }

            return 'click here to resend';
        }
    }
}
</script>
