<template>
    <div class="mb-8">
        <div v-if="rightLabel" class="flex justify-between">
            <default-label v-html="label"></default-label>
            <slot name="right"/>
        </div>
        <default-label v-else-if="label" v-html="label"></default-label>
        <slot v-else name="label"/>
        <div>
            <slot/>
        </div>
    </div>
</template>

<script>
import DefaultLabel from "../DefaultLabel"

export default {
    name: 'InputLabel',

    components: {
        DefaultLabel
    },

    props: {
        label: {
            type: [String, Boolean],
            required: true,
            default: true,
        },

        rightLabel: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

