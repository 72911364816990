<template>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"/>
    </svg>
</template>

<script>
export default {
    name: 'DotsMenu'
}
</script>
