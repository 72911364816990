<template>

    <div>

        <div v-if="continued"
             class="flex justify-center flex-col items-center">
            <div class="h-6 sm:h-10 border-l border-gray-100"></div>
        </div>

        <div class="bg-white border-t border-b sm:border border-gray-100 sm:rounded-md md:rounded-lg">

            <flow-panel-header :loading="loading"
                               :path="path"
                               :close-path="closePath"
                               :show-content="showContent"
                               :name-value="nameValue"
                               :name-placeholder="namePlaceholder"
                               :name-editable="nameEditable"
                               :show-toggle="showToggle"
                               :toggle-form="toggleForm"
                               :badge-count="badgeCount"
                               :badge-name="badgeName"
                               :clickable="clickable"
                               :status-class="statusClass"
                               :status-name="statusName"
                               v-bind="$attrs">

                <template #icon>
                    <slot name="icon"/>
                </template>

                <template #menu>
                    <slot name="menu"/>
                </template>

            </flow-panel-header>

            <div v-if="showContent">

                <div v-if="loading" class="pt-6 px-6">
                    <div v-for="row in [1,2,3]"
                         :key="row"
                         class="bg-gray-100 h-6 w-full rounded mb-12"></div>
                </div>

                <slot v-else name="content"/>

            </div>

        </div>

        <div v-if="continueOn"
             class="flex justify-center flex-col items-center">
            <div class="h-6 sm:h-10 border-l border-gray-100"></div>

            <button type="button"
                    class="btn btn-teal-outline bg-white"
                    @click.prevent="addAction">
                Add action
            </button>
        </div>

        <slot name="modals" />

    </div>
</template>

<script>
import FlowPanelHeader from "./FlowPanelHeader"

export default {

    name: 'FlowPanel',

    components: {
        FlowPanelHeader,
    },

    props: {
        loading: {
            type: Boolean,
        },
        continued: {
            type: Boolean,
        },
        continueOn: {
            type: Boolean,
        },
        showContent: {
            type: Boolean,
        },
        badgeName: {
            type: String,
        },
        badgeCount: {
            type: Number,
            default: 0,
        },
        showToggle: {
            type: Boolean,
            default: false,
        },
        toggleForm: {
            type: Object,
        },
        path: {
            type: String,
            default: '',
        },
        closePath: {
            type: String,
            default: '',
        },
        nameValue: {
            type: String,
        },
        namePlaceholder: {
            type: String,
        },
        nameEditable: {
            type: Boolean,
            default: true,
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        statusName: {
            type: String,
        },
        statusClass: {
            type: String,
        },
    },

    methods: {

        addAction() {
            this.$emit('add-action')
        }

    },


}
</script>
