<template>
    <div class="text-sm p-3 border rounded border-gray-100 truncate relative">
        <slot/>
        <div v-if="loading" class="h-4 w-4 absolute top-0 right-0 text-gray-400 flex items-center h-input mr-3">
            <spin-icon/>
        </div>
    </div>
</template>

<script>

import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'FakeInput',

    components: {
        SpinIcon
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>