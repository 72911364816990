<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This will add the email attachments to the contacts file box inside Keap.
        </event-sub-text>

        <form-error :form="form"/>

        <input-label label="Add all attachments from email">

            <div class="flex items-center">

                <select-field :options="dropdowns.allFiles.options"
                              v-model="dropdowns.allFiles.selected"
                              @input="updateFromDropDownValue('attachment_all_files', 'allFiles')"
                              label="name"
                              class="flex-1"
                              track-by="value"/>

                <button v-if="parseInt(dropdowns.allFiles.selected.value) === 0"
                        @click.prevent="openFilters"
                        class="flex-shrink-0 ml-6 btn btn-sm btn-teal-outline">
                    {{ filterButtonName }}
                </button>
            </div>

        </input-label>

        <checkbox-field :id="`email_attachment_webhook_${event.id}`"
                        :font-medium="false"
                        class="mb-6"
                        v-model="form.settings.sendWebhook"
                        value="1" true-value="1" false-value="0">
            Send a Webhook for each attachment after if has uploaded to Keap
        </checkbox-field>

        <input-label v-if="parseInt(form.settings.sendWebhook) === 1"
                     label="Webhook URL">

            <event-input-field v-model="form.settings.webhookUrl"
                               help="Webhook will contain file id, contact id as well as fields and values that have been selected from this email"
                               placeholder="https://my-website.com/webhook-url"/>

        </input-label>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import EventSubText from "../elments/EventSubText"
import EventHelpers from "../mixins/event-helpers"
import FormError from "../../../../elements/alerts/FormError"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import EventInputField from "../../../../elements/fields/EventInputField"

export default {
    name: 'FileBox',

    components: {
        CheckboxField,
        SelectField,
        InputLabel,
        EventInputField,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            dropdowns: {
                allFiles: {
                    selected: {
                        value: 1,
                        name: 'Yes'
                    },
                    options: [{
                        value: 0,
                        name: 'No, use filter',
                    }, {
                        value: 1,
                        name: 'Yes'
                    }]
                }
            },

            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    attachment_all_files: 1,
                    sendWebhook: 0,
                    webhookUrl: '',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.setDropdownValue('allFiles', 'attachment_all_files', true);
    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        openFilters() {
            this.$store.dispatch('filters/openFilter', {
                type: 'attachment',
                typeId: this.event.id,
                property: {
                    type: 'upload attachment',
                    name: 'Incoming Email Attachments',
                },
                filters: this.event.routes.filters.attachment,
                options: this.event.routes.filters.attachment_options,
                count: this.filterCount,
            })
        },

    },

    computed: {
        filterCount() {
            return parseInt(_.get(this.event, 'settings.filters_count', 0))
        },

        filterButtonName() {
            if (this.filterCount === 1) {
                return 'Edit Filter'
            }
            if (this.filterCount > 1) {
                return `Edit Filters (${this.filterCount})`;
            }

            return 'Add Filter';
        }
    }
}
</script>

