<template>
    <div class="flex flex-col h-full">

    </div>
</template>

<script>
import {mapState} from "vuex"

export default {
    name: 'FlowSteps',

    computed: {
        ...mapState('flow', {
            loading: state => state.loading,
            flow: state => state.data,
        }),

    }
}
</script>
