<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" :stroke-width="stroke" d="M6 18L18 6M6 6l12 12" />
    </svg>
</template>

<script>
export default {
    name: 'XIcon',

    props: {
        stroke: {
            default: 2
        }
    }
}
</script>
