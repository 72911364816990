<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            This will empty the value of the selected field for the contact.
        </p>

        <form-error :form="form"/>


        <input-label :label="false">
            <template #label>
                <div class="flex items-center">
                    <default-label>
                        Choose Field
                    </default-label>
                    <refresh-button @refresh="refreshFields" :refreshing="loading || refreshing"/>
                </div>
            </template>
            <select-field :options="contactFields"
                          v-model="form.field"
                          :loading="loading || refreshing"
                          label="label"
                          group-values="fields"
                          group-label="group"
                          track-by="name"
                          placeholder="Select field"/>
        </input-label>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import RefreshButton from "../../../../elements/buttons/RefreshButton"
import DefaultLabel from "../../../../elements/DefaultLabel"

export default {
    name: 'EmptyContactField',

    components: {
        DefaultLabel,
        RefreshButton,
        SelectField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            loading: true,
            refreshing: false,
            form: new ParseyForm({
                key: this.event.key,
                field: null,
                settings: {
                    emptyContactField: ''
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.getActionFields()
    },

    methods: {

        save() {
            this.form.settings.emptyContactField = _.get(this.form, 'field.name')

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'contact'
            }).then(() => {
                this.loading = false;
                _.find(this.contactFields, group => {
                    let field = _.find(group.fields, field => {
                        return field.name === this.form.settings.emptyContactField
                    })
                    if (field) {
                        this.form.field = field;
                        return true;
                    }
                    return false;
                })
            })
        },

        refreshFields() {
            this.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'contact'
            }).then(() => {
                this.refreshing = false;
            })
        },

    },

    computed: {
        contactFields() {
            if (!_.get(this.action, 'fields.contact')) {
                return [];
            }

            let fields = this.action.fields.contact;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },
    }
}
</script>

