<template>

    <flow-panel v-if="showNewAction"
                :loading="flowLoading"
                :show-content="true"
                :continued="true"
                :close-path="closePath"
                :only-placeholder="true"
                name-value="Choose an action">

        <template #icon>
            <div class="text-gray-400">
                <action-icon/>
            </div>
        </template>

        <template #content>
            <div class="flex flex-col items-center justify-center ">

                <p class="text-xl text-gray-700 font-medium mt-8 mb-8">
                    Choose an App
                </p>

                <div class="max-w-2xl w-full mb-6">
                    <div class="grid grid-cols-2 sm:grid-cols-4 gap-y-10">

                        <legacy-app v-if="loading"
                                    v-for="field in [1,2,3,4,5,6,7,8]"
                                    :key="field"/>

                        <legacy-app v-if="!loading"
                                    v-for="app in apps"
                                    @click.native="selectApp(app.key)"
                                    :key="app.key"
                                    :app="app"
                                    :creating="form.busy && form.key == app.key"
                                    :loading="loading"/>
                    </div>
                </div>

            </div>
        </template>

    </flow-panel>

</template>

<script>
import LegacyApp from "./partials/LegacyApp"
import ActionIcon from "../../../svgs/general/ActionIcon"
import FlowPanel from "../components/FlowPanel"
import {mapState} from "vuex"

export default {
    name: 'LegacyNewAction',

    components: {
        FlowPanel,
        ActionIcon,
        LegacyApp
    },

    data() {
        return {
            activeApp: '',
            form: new ParseyForm({
                key: '',
            })
        }
    },

    created() {
        this.getApps();
    },

    methods: {
        getApps() {
            if (_.isEmpty(this.apps)) {
                this.$store.dispatch('apps/getLegacyApps')
            }
        },

        selectApp(appKey) {
            this.form.key = appKey
            Parsey.post(this.$store.state.flow.data.routes.actions, this.form)
                .then(({action}) => {
                    this.$store.commit('flow/addAction', action)

                    const app = _.get(this.$store.state.apps, `legacy.${action.app.key}`)
                    if (_.size(app.auth_fields) === 0) {
                        this.$router.push({path: `${action.views.edit}/events/create`})
                        return true;
                    }

                    this.$router.push({path: `${action.views.edit}/auth`})
                })
        },

    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
            flowLoading: state => state.loading,
        }),

        loading() {
            return this.$store.state.apps.loading;
        },

        apps() {
            return this.$store.state.apps.legacy;
        },

        showNewAction() {
            return _.get(this.$route, 'params.panel') === 'actions'
                && _.get(this.$route, 'params.panelId') === 'new';
        },

        closePath() {
            return _.get(this.flow, 'views.edit');
        },

    }

}
</script>
