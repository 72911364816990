<template>
    <div>

        <tab-header>
            Order Automation
            <template #sub>
                When an order is created you can trigger specific API Goals inside your Keap
                campaign for each Product or Product Category
            </template>
        </tab-header>

        <checkbox-field class="mb-6"
                        :text-small="false"
                        :id="`activate_campaign_api_goals_${event.id}`"
                        v-model="settings.triggerCampaignPurchaseGoals"
                        true-value="1" false-value="0">
            Turn on campaign API goals
        </checkbox-field>

        <div v-if="parseInt(settings.triggerCampaignPurchaseGoals) === 1"
             class="grid md:grid-cols-2 gap-x-10 gap-y-6">

            <div v-for="trigger in triggers"
                 class="bg-indigo-50 p-3 px-5 text-indigo-700 text-sm rounded">
                <p class="font-medium uppercase">
                    {{ trigger }} Purchased Trigger
                </p>
                <p class="pl-4">Integration Name: <span class="font-semibold">ParseyOrder</span></p>
                <p class="pl-4">Call Name: <span class="font-semibold">{{ trigger }}{Id}</span></p>
                <p class="text-xs text-indigo-300 mt-1">
                    * Replace {Id} with the Id of the
                    <span class="lowercase">{{ trigger }}</span> - i.e. {{ trigger }}15
                </p>
            </div>

        </div>

    </div>
</template>

<script>

import EventHelpers from "../mixins/event-helpers"
import TabHeader from "../../../../elements/tabs/TabHeader"
import InputLabel from "../../../../elements/fields/InputLabel"
import CheckboxField from "../../../../elements/fields/CheckboxField"

export default {
    name: 'OrderAutomation',

    components: {
        TabHeader,
        CheckboxField,
        InputLabel,
    },

    mixins: [
        EventHelpers
    ],

    props: {
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            triggers: ['Product', 'Category'],
        }
    },

    methods: {},

    computed: {}
}
</script>