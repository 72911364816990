<template>
    <div @click="toggle" role="checkbox"
         :class="animation"
         class="h-7 w-7 flex items-center justify-center cursor-pointer">
        <span :class="{'bg-green-200': form.on, 'bg-gray-100': !form.on}" class="absolute h-5 w-5 group-hover:w-7 group-hover:h-7 rounded-full"></span>
        <span v-if="form.busy" :class="{'bg-green-200': form.on, 'bg-gray-100': !form.on}" class="absolute h-5 w-5 rounded-full animate-ping"></span>
        <span :class="{'bg-green-500': form.on, 'bg-gray-400': !form.on}"
              class="relative block w-2 h-2 group-hover:w-3 group-hover:h-3 rounded-full "></span>
    </div>
</template>

<script>
export default {
    name: "ToggleBullet",

    props: {
        form: {
            type: Object,
        }
    },

    methods: {
        toggle() {
            if (this.form.on) {
                this.turnOff()
                return;
            }
            this.turnOn()
        },

        turnOn() {
            if (this.form.dispatch) {
                this.$store.dispatch(this.form.dispatch.on, this.form)
            }

            if (this.form.method) {
                this.$emit(this.form.method.on)
            }
        },

        turnOff() {
            if (this.form.dispatch) {
                this.$store.dispatch(this.form.dispatch.off, this.form)
            }

            if (this.form.method) {
                this.$emit(this.form.method.off)
            }
        }
    },

    computed: {
        animation() {
            if (this.form.busy) {
                return '';
            }

            return 'group hover:animate-pulse';
        }
    }

}
</script>
