<template>

    <tr>
        <table-cell class="truncate">
            {{ product.name }}
        </table-cell>

        <table-cell class="truncate hidden sm:table-cell">
            {{ product.sku }}
        </table-cell>

        <table-cell align="center" :vertical-padding="false"
                    class="hidden sm:table-cell">
            <default-badge :clickable="true"
                           :name="trigger"
                           @click="$emit('actions')"/>
        </table-cell>

        <table-cell class="pr-2" :vertical-padding="false">

            <div class="flex items-center justify-end">

                <toggle-bullet :form="activationForm"
                               @activate="toggleActive"
                               @inactivate="toggleActive"
                               class="mr-1"/>

                <default-dropdown>
                    <template #trigger>
                        <button-menu size="9" spacing="2"/>
                    </template>
                    <template #content>

                        <form @submit.prevent="$emit('edit')">
                            <dropdown-link as="button">
                                Edit
                            </dropdown-link>
                        </form>

                        <horizontal-line/>

                        <form @submit.prevent.stop="deleteWithMethod(product.id, 'deleteProduct')">
                            <dropdown-link as="button" :close-on-click="false">
                                <span v-if="confirmDelete === product.id" class="text-red-700">Click to Confirm</span>
                                <span v-else-if="deleting === product.id" class="text-red-700">Deleting...</span>
                                <span v-else>Delete</span>
                            </dropdown-link>
                        </form>
                    </template>
                </default-dropdown>

            </div>

        </table-cell>
    </tr>

</template>

<script>
import RoundIconButton from "../../../../elements/buttons/RoundIconButton"
import PlusIcon from "../../../../svgs/menu/PlusIcon"
import TabHeader from "../../../../elements/tabs/TabHeader"
import TableCell from "../../../../elements/tables/TableCell"
import DefaultBadge from "../../../../elements/badges/DefaultBadge"
import ButtonMenu from "../../../../elements/buttons/ButtonMenu"
import DefaultDropdown from "../../../../elements/DefaultDropdown"
import HorizontalLine from "../../../../elements/HorizontalLine"
import DropdownLink from "../../../../elements/DropdownLink"
import DeleteItems from "../mixins/delete-items"
import ToggleBullet from "../../../../elements/ToggleBullet"

export default {
    name: 'ClickBankProductRow',

    components: {
        ToggleBullet,
        DropdownLink,
        HorizontalLine,
        DefaultDropdown,
        ButtonMenu,
        DefaultBadge,
        TableCell,
        TabHeader,
        PlusIcon,
        RoundIconButton
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        url: {
            type: String,
        }
    },

    mixins: [
        DeleteItems,
    ],

    data() {
        return {
            activationForm: new ParseyForm({
                active: this.product.active,
                on: this.product.active,
                method: {
                    on: 'activate',
                    off: 'inactivate',
                },
            }),
            deleteForm: new ParseyForm({}),
        }
    },

    methods: {
        toggleActive() {
            this.activationForm.active = !this.activationForm.active;
            Parsey.put(`${this.url}/${this.product.id}`, this.activationForm)
                .then(({product}) => {
                    this.activationForm.on = product.active;
                    this.$emit('update-products')
                })
        },

        deleteProduct() {
            Parsey.delete(`${this.url}/${this.product.id}`, this.deleteForm)
                .then(() => {
                    this.$emit('update-products')
                })
        }
    },

    computed: {
        trigger() {
            return _.startCase(this.product.trigger);
        }
    }
}
</script>