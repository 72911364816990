<template>
    <div class="h-9 flex items-center">

        <div class="relative z-10 w-8 h-8 flex items-center justify-center border-2 border-gray-100 bg-white rounded-full">
            <div class="w-4.5 h-4.5">
                <action-icon class="text-gray-400" v-if="icon === 'action'"/>
                <div v-else-if="icon !== 'trigger'" v-html="icon"></div>
                <flow-icon class="text-gray-400" v-else/>
            </div>

            <div :class="statusClass"
                 class="absolute top-0 right-0 w-3 h-3 -mr-1.5 -mt-0.5 rounded-full flex items-center justify-center">
                <div class="w-2.5 h-2.5">
                    <checkmark-icon v-if="['active', 'complete'].includes(status)"/>
                    <x-icon v-else/>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import CheckmarkIcon from "../../svgs/general/CheckmarkIcon"
import FlowIcon from "../../svgs/menu/FlowIcon"
import XIcon from "../../svgs/general/XIcon"
import ActionIcon from "../../svgs/general/ActionIcon"

export default {
    name: 'StepCircle',

    components: {
        ActionIcon,
        XIcon,
        FlowIcon,
        CheckmarkIcon
    },

    props: {
        icon: {
            type: [String],
            default: 'trigger',
        },
        status: {
            default: 'current',
            validator: value => {
                return ['current', 'complete', 'incomplete', 'active'].includes(value);
            }
        }
    },

    computed: {
        statusClass() {
            let classes = [];
            switch (this.status) {
                case 'active':
                    classes.push('bg-green-300')
                    classes.push('text-green-700')
                    break;
                case 'complete':
                case 'incomplete':
                default:
                    classes.push('bg-gray-200');
                    classes.push('text-gray-500')
                    break;
            }
            return classes.join(' ');
        }
    }
}
</script>
