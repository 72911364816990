<template>
    <div class="border border-gray-100 p-4 mb-6 rounded" :class="{'animate-shake': errorMessage}">

        <p v-if="!showExtraFields" class="mb-5 font-medium cursor-pointer"
           @click.prevent="showExtraFields = !showExtraFields">
            {{ field.label }}
        </p>

        <alert-error v-if="errorMessage" :show-icon="false">
            {{ errorMessage }}
        </alert-error>

        <form @submit.prevent="save" @keydown.enter="save">
            <input-label v-show="showExtraFields" label="Field label">
                <input-field name="label"
                             :id="`email_field_label_${field.id}`"
                             :form="form"
                             placeholder="Field label"/>
            </input-label>

            <input-label v-show="showExtraFields" label="Search Email">
                <select-field label="name"
                              @input="setEmailSection"
                              v-model="dropdowns.emailContent.selected"
                              :options="dropdowns.emailContent.options"
                              :allow-empty="false"
                              track-by="value"/>
            </input-label>

            <input-label label="Start search">
                <input-field name="start"
                             :id="`email_field_start_${field.id}`"
                             :form="form"
                             help="Type the characters or pattern to look for"
                             placeholder="Search starting with"/>
            </input-label>

            <input-label label="Stop search">
                <input-field name="end"
                             :id="`email_field_end_${field.id}`"
                             :form="form"
                             help="Stop the search when the following is found"
                             placeholder="Search starting with"/>
            </input-label>

            <checkbox-field v-show="showExtraFields" class="mb-8" v-model="form.offset"
                            :id="`email-field-offset-${field.id}`">
                Begin from the previous one left off.
            </checkbox-field>

            <p class=" mb-4 mt-1">
                <a href="#" @click.prevent="showExtraFields = !showExtraFields"
                   v-show="!showExtraFields"
                   class="text-teal-400 hover:text-teal-600 cursor-pointer text-sm">
                    Show All Available Options
                </a>
            </p>

            <div class="flex justify-between">
                <div class="flex">
                    <submit-button class="mr-4" :form="form" @save="save"/>
                    <button type="button"
                            @click="viewField"
                            class="btn btn-teal-outline btn-sm">
                        View
                    </button>
                </div>

                <button @click.prevent="closeEditField"
                        class="btn btn-sm border-none btn-white">
                    Cancel
                </button>
            </div>

        </form>
    </div>
</template>

<script>
import LegacyField from "../elments/LegacyField"
import DropdownLink from "../../../../elements/DropdownLink"
import HorizontalLine from "../../../../elements/HorizontalLine"
import InputLabel from "../../../../elements/fields/InputLabel"
import InputField from "../../../../elements/fields/InputField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import SelectField from "../../../../elements/fields/SelectField"
import {mapState} from "vuex"
import AlertError from "../../../../elements/alerts/AlertError"

export default {
    name: 'LegacyEmailEditField',

    components: {
        AlertError,
        SelectField,
        CheckboxField,
        SubmitButton,
        InputField,
        InputLabel,
        HorizontalLine,
        DropdownLink,
        LegacyField
    },

    props: {
        field: {
            type: Object,
        },
    },

    data() {
        return {
            showExtraFields: false,
            dropdowns: {
                emailContent: {
                    selected: null,
                    options: [
                        {
                            name: 'Plain Text Body',
                            value: 'body_plain',
                        },
                        {
                            name: 'HTML Body',
                            value: 'body_html',
                        },
                        {
                            name: 'Subject',
                            value: 'subject',
                        }
                    ]
                }
            },
            form: new ParseyForm({
                id: this.field.id,
                label: this.field.label,
                start: this.field.start,
                end: this.field.end,
                offset: this.field.offset,
                section: null,
            }),
        }
    },

    watch: {
        field(field) {
            this.form.id = field.id;
            this.form.label = field.label;
            this.form.start = field.start;
            this.form.end = field.end;
            this.form.offset = field.offset;
            this.setEmailSection();
        }
    },

    mounted() {
        this.setEmailSection();
    },

    methods: {

        save() {
            window.getSelection().removeAllRanges();
            this.form.status = 'confirmed';
            Parsey.put(this.field.routes.update, this.form)
                .then(({field}) => {
                    this.$store.commit('flow/updateLegacyTriggerField', field)
                    this.closeEditField();
                });
        },

        viewField() {
            window.getSelection().removeAllRanges();
            Parsey.put(this.flow.trigger.routes.parse, this.form)
                .then(({parse, field}) => {
                    this.$emit('highlight-field', field, parse)
                    this.$store.commit('flow/updateLegacyTriggerField', field)
                })
        },

        closeEditField() {
            this.showExtraFields = false;
            this.$emit('cancel-edit-field')
        },

        setEmailSection() {
            const emailContentOption = _.find(this.dropdowns.emailContent.options, option => {
                return this.field.email_source === option.value;
            });
            this.form.section = emailContentOption.value;
            this.dropdowns.emailContent.selected = emailContentOption;
        },
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        errorMessage() {
            if (this.field.status !== 'error') {
                return null;
            }

            if (this.field.start === '') {
                return 'Parsey failed to discover a unique starting point for this field.'
            }

            return 'Parsey attempted to discover a starting point but it did not find what you had selected.'
        },
    }
}
</script>