<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Use the settings below to set a referral partner for this contact.
        </p>

        <form-error :form="form"/>

        <div :class="{'md:grid-cols-3 gap-x-4': usingFindUsingTriggerField, 'md:grid-cols-2 gap-x-10': !usingFindUsingTriggerField}"
             class="grid grid-cols-1  mb-4">
            <input-label label="Set Referral Partner">
                <select-field :options="dropdowns.findAffiliateMethods.options"
                              v-model="dropdowns.findAffiliateMethods.selected"
                              @input="findAffiliateMethodsUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="usingSelectFromList" label="Referral Partners">
                <select-field :options="dropdowns.affiliates.options"
                              v-model="dropdowns.affiliates.selected"
                              label="name"
                              track-by="id"
                              placholder="Type to search for Referral Partner"
                              :internal-search="false"
                              @input="affiliateListUpdated"
                              @search-change="dropdowns.affiliates.search($event)"
                              :loading="dropdowns.affiliates.searching"
                              :options-limit="100"
                              :no-result="dropdowns.affiliates.noResult"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.affiliate"
                              @input="affiliateTriggerFieldUpdate"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Search Referral Partner Field">
                <select-field :options="dropdowns.affiliateFields.options"
                              v-model="dropdowns.affiliateFields.selected"
                              @input="affiliateFieldUpdated"
                              label="label"
                              group-values="fields"
                              group-label="group"
                              track-by="name"
                              placeholder="Select Referral Partner field"/>
            </input-label>

        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10">
            <input-label label="Source">
                <event-input-field placeholder="Via the API - Parsey"
                                   v-model="form.settings.source"/>
            </input-label>

            <input-label label="Referral Type">

                <select-field :options="dropdowns.referralTypes.options"
                              v-model="dropdowns.referralTypes.selected"
                              label="label"
                              track-by="value"
                              placeholder="Select referral type"/>

                <div class="flex hidden">
                    <radio-field :id="`referral_click_${event.id}`" value="0" v-model="form.settings.referral_type">
                        Click
                    </radio-field>

                    <radio-field :id="`referral_manual_${event.id}`" value="2" v-model="form.settings.referral_type">
                        Manual
                    </radio-field>

                    <radio-field :id="`referral_permanent_${event.id}`" value="1" v-model="form.settings.referral_type">
                        Permanent
                    </radio-field>

                </div>

            </input-label>
        </div>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"
import RadioField from "../../../../elements/fields/RadioField"
import FindAffiliateOptions from "../mixins/find-affiliate-options"
import {mapGetters} from "vuex"
import SelectField from "../../../../elements/fields/SelectField"

export default {
    name: 'Referral',

    components: {
        SelectField,
        RadioField,
        EventInputField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        FindAffiliateOptions
    ],

    data() {
        return {
            dropdowns: {
                referralTypes: {
                    selected: null,
                    options: [
                        {
                            label: 'Click',
                            value: '0',
                        },
                        {
                            label: 'Manual',
                            value: '2',
                        },
                        {
                            label: 'Permanent',
                            value: '1',
                        },
                    ],
                }
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    affiliate_code: '',
                    affiliate_email: '',
                    affiliate_extraction_key: '',
                    affiliate_field: '',
                    set_affiliate: 'manual_code',
                    referral_type: '0',
                    source: '',
                },
            }),
        }
    },

    mounted() {
        if (this.usingSelectFromList) {
            this.findAffiliate()
        }

        this.dropdowns.referralTypes.selected = _.find(this.dropdowns.referralTypes.options, option => {
            return parseInt(option.value) === parseInt(this.form.settings.referral_type);
        })
    },

    methods: {

        save() {
            this.form.settings.referral_type = this.dropdowns.referralTypes.selected.value;

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),
    }
}
</script>

