<template>

    <svg width="100%" height="100%" viewBox="0 0 1000 1000" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="currentColor" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(31.8302,0,0,31.8302,124.293,38.006)">
            <path d="M8.68,0C8.68,-0.659 8.145,-1.194 7.485,-1.194L0,-1.194C-0.659,-1.194 -1.194,-0.659 -1.194,0L-1.194,7.485C-1.194,8.145 -0.659,8.68 -0,8.68L7.485,8.68C8.145,8.68 8.68,8.145 8.68,7.485L8.68,0ZM1.194,1.194L1.194,6.291L6.291,6.291L6.291,1.194L1.194,1.194Z"/>
        </g>
        <g transform="matrix(43.4353,0,0,43.4353,50.4417,-35.8443)">
            <path d="M3.568,7.186L3.568,16L3.568,15.974C3.568,15.974 3.503,18.596 5.35,20.172C6.138,20.844 7.269,21.348 8.916,21.348L14,21.348L14,19.598L8.916,19.598C7.799,19.598 7.02,19.297 6.486,18.841C5.252,17.788 5.318,16.026 5.318,16.026L5.318,7.186C5.318,6.703 4.926,6.311 4.443,6.311C3.96,6.311 3.568,6.703 3.568,7.186Z"/>
        </g>
        <g transform="matrix(43.4353,0,0,43.4353,50.4417,-35.8443)">
            <path d="M3.568,7.186L3.568,16L3.568,15.974C3.568,15.974 3.503,18.596 5.35,20.172C6.138,20.844 7.269,21.348 8.916,21.348L14,21.348L14,19.598L8.916,19.598C7.799,19.598 7.02,19.297 6.486,18.841C5.252,17.788 5.318,16.026 5.318,16.026L5.318,7.186C5.318,6.703 4.926,6.311 4.443,6.311C3.96,6.311 3.568,6.703 3.568,7.186Z"/>
        </g>
        <g transform="matrix(43.4353,0,0,43.4353,50.4417,-382.157)">
            <path d="M3.568,15.186L3.568,16L3.568,15.974C3.568,15.974 3.503,18.596 5.35,20.172C6.138,20.844 7.269,21.348 8.916,21.348L14,21.348L14,19.598L8.916,19.598C7.799,19.598 7.02,19.297 6.486,18.841C5.252,17.788 5.318,16.026 5.318,16.026L5.318,15.186C5.318,14.703 4.926,14.311 4.443,14.311C3.96,14.311 3.568,14.703 3.568,15.186Z"/>
        </g>
        <g transform="matrix(43.4353,0,0,43.4353,50.4417,6.42178)">
            <path d="M16.5,16.125C14.637,16.125 13.125,17.637 13.125,19.5C13.125,21.363 14.637,22.875 16.5,22.875C18.363,22.875 19.875,21.363 19.875,19.5C19.875,17.637 18.363,16.125 16.5,16.125ZM16.5,17.875C17.397,17.875 18.125,18.603 18.125,19.5C18.125,20.397 17.397,21.125 16.5,21.125C15.603,21.125 14.875,20.397 14.875,19.5C14.875,18.603 15.603,17.875 16.5,17.875Z"/>
        </g>
        <g transform="matrix(43.4353,0,0,43.4353,50.4417,-339.892)">
            <path d="M16.5,16.125C14.637,16.125 13.125,17.637 13.125,19.5C13.125,21.363 14.637,22.875 16.5,22.875C18.363,22.875 19.875,21.363 19.875,19.5C19.875,17.637 18.363,16.125 16.5,16.125ZM16.5,17.875C17.397,17.875 18.125,18.603 18.125,19.5C18.125,20.397 17.397,21.125 16.5,21.125C15.603,21.125 14.875,20.397 14.875,19.5C14.875,18.603 15.603,17.875 16.5,17.875Z"/>
        </g>
    </svg>

</template>

<script>
export default {
    name: "FlowIcon"
}
</script>
