<template>

    <div>

        <div v-if="Array.isArray(value) === true">

            <tree-child :path="`${path}[${name}]`"
                        @toggle-field="toggleField"
                        :selectable="selectable"
                        :is-array="true"
                        :value="`[${size}]`"
                        :name="name"/>

            <div class="ml4 sm:ml-8">
                <tree-parent v-for="(childValue, child) in value"
                             @toggle-field="toggleField"
                             :key="child"
                             :selectable="selectable"
                             :path="`${path}[${name}]`"
                             :from-array="true"
                             :name="child"
                             :value="childValue"/>
            </div>

        </div>

        <tree-child v-else-if="typeof value === 'string'"
                    :selectable="selectable"
                    @toggle-field="toggleField"
                    :path="`${path}[${name}]`"
                    :value="value"
                    :name="name"/>

        <div v-else>

            <tree-child :selectable="false"
                        :from-array="fromArray"
                        :path="`${path}[${name}]`"
                        :value="`{${size}}`"
                        :name="name"/>

            <div class="ml-4 sm:ml-8">

                <tree-parent v-for="(childValue, child) in value"
                             @toggle-field="toggleField"
                             :selectable="selectable"
                             :key="child"
                             :path="`${path}[${name}]`"
                             :name="child"
                             :value="childValue"/>

            </div>

        </div>

    </div>


</template>

<script>
import TreeChild from "./TreeChild"

export default {
    name: 'TreeParent',

    components: {
        TreeChild
    },

    props: {
        name: {
            type: [String, Number],
        },
        value: {
            type: [String, Array, Object],
        },
        path: {
            type: String,
        },
        fromArray: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        size() {
            return _.size(this.value);
        }
    },

    methods: {

        toggleField(data) {
            this.$emit('toggle-field', data)
        }
    }
}
</script>
