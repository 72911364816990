<template>

    <flow-layout :legacy="true">

        <flow-panel name-value="Flow Trigger"
                    :show-content="true"
                    :name-editable="false"
                    :show-toggle="false">
            <template #icon>
                <div class="text-brand-900">
                    <flow-icon/>
                </div>
            </template>

            <template #content>

                <form @submit.prevent="save">
                    <form-error :form="form"/>

                    <div class="flex flex-col justify-center items-center mt-8">
                        <p class="font-medium text-xl mb-6">
                            Choose Flow Trigger
                        </p>

                        <div class="flex justify-between w-full max-w-md">
                            <div class="flex items-center justify-center flex-col" v-for="app in typesList">
                                <div class="h-15 w-15 border-gray-100 rounded-full p-3 cursor-pointer hover:border-gray-300"
                                     :class="{'border-2 border-brand-700' :form.app === app.key, 'border' : form.app !== app.key}"
                                     @click="selectType(app.key)">
                                    <component :is="app.icon"/>
                                </div>
                                <p class="text-sm text-gray-500 mt-2">
                                    {{ app.name }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-8 mb-32 flex justify-around ">
                        <button type="submit"
                                @submit.prevent="save"
                                :disabled="form.busy || form.app == null"
                                :class="{disabled: form.busy || form.app == null}"
                                class="btn btn-teal btn-block md:w-1/2"
                                v-html="form.buttonHtml('Continue', 'Saving...')"></button>
                    </div>

                </form>
            </template>

        </flow-panel>

    </flow-layout>

</template>

<script>
import EmailIcon from "../../../svgs/parsers/EmailIcon"
import WebhookIcon from "../../../svgs/parsers/WebhookIcon"
import AttachmentIcon from "../../../svgs/parsers/AttachmentIcon"
import FlowIcon from "../../../svgs/menu/FlowIcon"
import FormError from "../../../elements/alerts/FormError"
import FlowLayout from "../FlowLayout"
import FlowPanel from "../components/FlowPanel"
import ClickBankIcon from "../../../svgs/parsers/ClickBankIcon"

export default {

    name: 'CreateLegacyFlow',

    components: {
        ClickBankIcon,
        FlowPanel,
        FlowLayout,
        FormError,
        FlowIcon,
        EmailIcon,
        WebhookIcon,
        AttachmentIcon,
    },

    data() {
        return {
            form: new ParseyForm({
                app: null,
            }),

            types: [
                'Webhook',
                'Email',
                'Attachment',
                'ClickBank',
            ]
        }
    },

    metaInfo() {
        return {
            title: 'Create Flow | Parsey'
        }
    },

    mounted() {
        this.$store.commit('flow/resetProperties')
    },

    methods: {
        selectType(type) {
            this.form.app = type;
        },

        save() {
            let routeName = `${this.form.app}s.store`;
            if (this.form.app === 'clickBank') {
                routeName = 'webhooks.store';
            }

            const postUrl = _.get(this.$store.state.routes.flows.legacy, routeName);

            Parsey.post(postUrl, this.form)
                .then(({flow}) => {
                    this.$store.commit('flows/add', flow)
                    this.$router.push(flow.views.trigger)
                })
        }
    },

    computed: {

        typesList() {
            return _.map(this.types, type => {
                let icon = _.camelCase(type) + 'Icon';

                return {
                    key: _.camelCase(type),
                    name: type,
                    icon
                }
            })
        }
    }
}
</script>

