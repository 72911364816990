<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This will create a Credit Card inside Keap. If one is found with the same last four for this contact it will update the card data. This will only run once when processed and will remove all credit card details from within Parsey.
        </event-sub-text>

        <form-error :form="form"/>

        <div class="grid md:grid-cols-2 gap-y-4 gap-x-10 mb-4">

            <input-label v-for="field in fields"
                         :key="field.key"
                         :label="field.label"
                         :right-label="true">
                <template #right>
                    <checkbox-field :id="`${field.key}_${event.id}`"
                                    :font-medium="false"
                                    v-model="form.settings[field.key].type"
                                    true-value="1" false-value="0">
                        Use trigger field
                    </checkbox-field>
                </template>

                <select-field v-if="parseInt(form.settings[field.key].type) === 1"
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected[field.key]"
                              @input="updateFromTriggerField(`${field.key}.extracted`, field.key)"
                              label="label"
                              track-by="id"
                              placeholder="Choose trigger field"/>

                <event-input-field v-else
                                   v-model="form.settings[field.key].manual"
                                   :maxlength="field.max"
                                   :placeholder="field.placeholder"/>

            </input-label>

        </div>

        <tab-pills>

            <tab-pill name="Custom Fields" :selected="true">
                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="creditCardFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Credit Card"
                                      :refreshing="dropdowns.creditCardFields.loading"/>
            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="creditCardFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Credit Card"
                                     :refreshing="dropdowns.creditCardFields.loading"/>
            </tab-pill>

        </tab-pills>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventSubText from "../elments/EventSubText"
import {mapGetters} from "vuex"
import EventHelpers from "../mixins/event-helpers"
import SelectField from "../../../../elements/fields/SelectField"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"

export default {
    name: 'CreditCard',

    components: {
        LegacyMagicFields,
        LegacyCustomFields,
        TabPill,
        TabPills,
        EventInputField,
        InputLabel,
        CheckboxField,
        SelectField,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            fields: [
                {
                    label: 'Card Type',
                    key: 'cardType',
                    placeholder: 'Visa',
                    max: 32,
                },
                {
                    label: 'Card Number',
                    key: 'cardNumber',
                    placeholder: 'Card number',
                    max: 16,
                },
                {
                    label: 'Expiration Month',
                    key: 'exMonth',
                    placeholder: '05',
                    max: 2,
                },
                {
                    label: 'Expiration Year',
                    key: 'exYear',
                    placeholder: new Date().getFullYear() + 1,
                    max: 4,
                },
            ],

            dropdowns: {
                triggerFields: {
                    selected: {
                        cardType: null,
                        cardNumber: null,
                        exMonth: null,
                        exYear: null,
                    }
                },
                creditCardFields: {
                    loading: true,
                },
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    cardType: {
                        type: '1',
                        manual: '',
                        extracted: '',
                    },
                    cardNumber: {
                        type: '1',
                        manual: '',
                        extracted: '',
                    },
                    exMonth: {
                        type: '1',
                        manual: '',
                        extracted: '',
                    },
                    exYear: {
                        type: '1',
                        manual: '',
                        extracted: '',
                    },
                    custom_fields: {},
                    magic_fields: {},
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        _.each(this.fields, field => {
            this.setDropdownTrigger(field.key, `${field.key}.extracted`);
        })

        this.getActionFields();

    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'creditCard'
            }).then(() => {
                this.dropdowns.creditCardFields.loading = false;
            })
        },

        refreshFields() {
            this.dropdowns.creditCardFields.loading = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'creditCard'
            }).then(() => {
                this.dropdowns.creditCardFields.loading = false;
            })
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        creditCardFields() {
            if (!_.get(this.action, 'fields.creditCard')) {
                return [];
            }

            let fields = this.action.fields.creditCard;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },
    }
}
</script>

