<template>
    <div>

        <tab-header>
            Product Details
            <template #sub>
                Below you can configure the different products that can be added to this order.
            </template>
        </tab-header>

        <div :class="{'md:grid-cols-3 gap-x-4': findByTriggerField, 'md:grid-cols-2 gap-x-10': !findByTriggerField}"
             class="grid grid-cols-1 mb-4">
            <input-label label="Product Options">
                <select-field :options="dropdowns.productItemOptions.options"
                              v-model="dropdowns.productItemOptions.selected"
                              @input="updateProductItemOptions"
                              label="label"
                              track-by="value"/>
            </input-label>

            <input-label v-if="findByOneProduct" label="Select Product">
                <select-field :options="dropdowns.products.options"
                              v-model="dropdowns.products.selected"
                              :loading="dropdowns.products.searching"
                              label="name"
                              track-by="id"
                              :internal-search="false"
                              @input="selectedProductUpdated"
                              @search-change="dropdowns.products.search($event)"
                              :no-result="dropdowns.products.noResult"
                              :options-limit="100"
                              placeholder="Start typing to search"/>
            </input-label>

            <input-label v-if="findByTriggerField" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.product"
                              @input="updateFromTriggerField('dynamic_field', 'product')"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="findByTriggerField" label="Search Product Field">
                <select-field :options="dropdowns.searchProductField.options"
                              v-model="dropdowns.searchProductField.selected"
                              @input="updateFromDropDownValue('dynamic_option', 'searchProductField')"
                              label="label"
                              track-by="value"
                              placeholder="Select product field"/>
            </input-label>

            <input-label v-if="useWebhookArray" label="Webhook Key">
                <select-field :options="triggerArrayKeys"
                              v-model="dropdowns.triggerFields.selected.webhook"
                              @input="updateFromTriggerField('product_array_key', 'webhook')"
                              label="label"
                              track-by="id"
                              placeholder="Select array key"/>
            </input-label>

        </div>

        <div v-if="findOrCreateOneProduct">

            <div class="grid md:grid-cols-2 gap-x-10 gap-y-4 mb-4">

                <input-label label="Product Price">
                    <select-field :options="dropdowns.productPriceType.options"
                                  v-model="dropdowns.productPriceType.selected"
                                  @input="updateFromDropDownValue('price.type', 'productPriceType')"
                                  label="label"
                                  track-by="value"
                                  placeholder="Select option"/>
                </input-label>

                <input-label v-if="useTriggerFieldForPrice" :label="false">
                    <template #label>
                        <div class="flex justify-between">
                            <default-label>
                                Trigger Field
                            </default-label>
                            <checkbox-field :id="`trigger_price_in_cents_${event.id}`"
                                            :font-medium="false"
                                            v-model="settings.price.amount_in_cents"
                                            value="1" true-value="1" false-value="0">
                                Amount is in cents
                            </checkbox-field>
                        </div>
                    </template>
                    <select-field :options="triggerFields"
                                  v-model="dropdowns.triggerFields.selected.productPrice"
                                  @input="updateFromTriggerField('price.field', 'productPrice')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label v-if="useManualPrice" label="Price">
                    <event-input-field v-model="settings.price.amount"
                                       placeholder="99.00"/>
                </input-label>

            </div>

            <div class="grid md:grid-cols-2 gap-x-10 gap-y-4 mb-4">

                <input-label label="Product Quantity">
                    <select-field :options="dropdowns.productQuantityType.options"
                                  v-model="dropdowns.productQuantityType.selected"
                                  @input="updateFromDropDownValue('product_quantity.option', 'productQuantityType')"
                                  label="label"
                                  track-by="value"
                                  placeholder="Select option"/>
                </input-label>

                <input-label v-if="useTriggerFieldForQuantity" label="Trigger Field">
                    <select-field :options="triggerFields"
                                  v-model="dropdowns.triggerFields.selected.productQuantity"
                                  @input="updateFromTriggerField('product_quantity.field', 'productQuantity')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select field"/>
                </input-label>

                <input-label v-else label="Quantity">
                    <event-input-field v-model="settings.product_quantity.amount"
                                       placeholder="1"/>
                </input-label>

            </div>

        </div>

        <div v-if="useWebhookArray">
            <div class="grid md:grid-cols-3 gap-4 mb-4">
                <input-label label="Locate product inside Keap using">
                    <select-field :options="dropdowns.findProductWebhook.options"
                                  v-model="dropdowns.findProductWebhook.selected"
                                  @input="updateFromDropDownValue('find_product_item', 'findProductWebhook')"
                                  label="label"
                                  track-by="value"
                                  placeholder="Select option"/>
                </input-label>

                <input-label v-if="settings.find_product_item === 'name'"
                             label="Product Name Trigger Field">
                    <select-field :options="triggerFieldsForWebhookKey"
                                  v-model="dropdowns.triggerFields.selected.webhook_product_name"
                                  @input="updateFromTriggerField('find_product_item_name', 'webhook_product_name')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label v-if="settings.find_product_item === 'sku'"
                             label="Product Sku Trigger Field">
                    <select-field :options="triggerFieldsForWebhookKey"
                                  v-model="dropdowns.triggerFields.selected.webhook_product_sku"
                                  @input="updateFromTriggerField('find_product_item_sku', 'webhook_product_sku')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label v-if="settings.find_product_item === 'id'"
                             label="Product Id Trigger Field">
                    <select-field :options="triggerFieldsForWebhookKey"
                                  v-model="dropdowns.triggerFields.selected.webhook_product_id"
                                  @input="updateFromTriggerField('find_product_item_id', 'webhook_product_id')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label label="Create product inside Keap">
                    <checkbox-field v-model="settings.create_product_item"
                                    :id="`create_product_item_${event.id}`"
                                    class="flex h-input"
                                    value="1" true-value="1" false-value="0">
                        Yes, create if doesn't exist
                    </checkbox-field>
                </input-label>

            </div>

            <div class="grid md:grid-cols-2 gap-x-10 gap-y-4">

                <input-label label="Product Name Trigger Field">
                    <select-field :options="triggerFieldsForWebhookKey"
                                  v-model="dropdowns.triggerFields.selected.product_item_name"
                                  @input="updateFromTriggerField('product_item_name_field', 'product_item_name')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label :label="false">
                    <template #label>
                        <div class="flex justify-between">
                            <default-label>
                                Product Price Trigger Field
                            </default-label>
                            <checkbox-field :id="`trigger_item_price_in_cents_${event.id}`"
                                            :font-medium="false"
                                            v-model="settings.product_item_price_in_cents"
                                            value="1" true-value="1" false-value="0">
                                Amount is in cents
                            </checkbox-field>

                        </div>
                    </template>
                    <select-field :options="triggerFieldsForProductPrice"
                                  v-model="dropdowns.triggerFields.selected.product_item_price"
                                  @input="updateFromTriggerField('product_item_price_field', 'product_item_price')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label label="Product Sku Trigger Field">
                    <select-field :options="triggerFieldsForProductSku"
                                  v-model="dropdowns.triggerFields.selected.product_item_sku"
                                  @input="updateFromTriggerField('product_item_sku_field', 'product_item_sku')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

                <input-label label="Product Quantity Trigger Field">
                    <select-field :options="triggerFieldsForProductQty"
                                  v-model="dropdowns.triggerFields.selected.product_item_quantity"
                                  @input="updateFromTriggerField('product_item_quantity_field', 'product_item_quantity')"
                                  label="label"
                                  track-by="id"
                                  placeholder="Select trigger field"/>
                </input-label>

            </div>
        </div>

    </div>
</template>

<script>
import EventInputField from "../../../../elements/fields/EventInputField"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import {mapGetters} from "vuex"
import DefaultLabel from "../../../../elements/DefaultLabel"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import EventHelpers from "../mixins/event-helpers"
import TabHeader from "../../../../elements/tabs/TabHeader"

export default {
    name: 'OrderProductOptions',

    components: {
        TabHeader,
        CheckboxField,
        DefaultLabel,
        SelectField,
        InputLabel,
        EventInputField
    },

    mixins: [
        EventHelpers
    ],

    props: {
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        allowSkippingProductItemOption: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            settingKeyBase: 'settings',
            dropdowns: {
                triggerFields: {
                    selected: {
                        product: null,
                        productPrice: null,
                        productQuantity: null,
                        webhook: null,
                        webhook_product_name: null,
                        webhook_product_sku: null,
                        webhook_product_id: null,
                        product_item_name: null,
                        product_item_price: null,
                        product_item_sku: null,
                        product_item_quantity: null,
                    }
                },
                productItemOptions: {
                    selected: null,
                    options: [
                        {
                            label: 'Choose One product from Keap',
                            value: 'one_product'
                        },
                        {
                            label: 'Use trigger field to find or create product',
                            value: 'dynamic'
                        },
                    ],
                },
                products: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'products',
                    default: {
                        id: 0,
                        name: 'Searching...'
                    }
                }),
                searchProductField: {
                    selected: null,
                    options: [
                        {
                            label: 'Product Name',
                            value: 'name',
                        },
                        {
                            label: 'Product Sku',
                            value: 'sku',
                        },
                        {
                            label: 'Product Id',
                            value: 'id',
                        },
                    ],
                },
                productPriceType: {
                    selected: null,
                    options: [
                        {
                            label: 'Use the Keap product price',
                            value: 0,
                        },
                        {
                            label: 'Use trigger field that contains price',
                            value: 1,
                        },
                        {
                            label: 'Manually set price',
                            value: 2,
                        },
                    ],
                },

                productQuantityType: {
                    selected: null,
                    options: [
                        {
                            label: 'Set quantity',
                            value: 2,
                        },
                        {
                            label: 'Use trigger field that contains quantity',
                            value: 1,
                        },
                    ],
                },

                findProductWebhook: {
                    selected: null,
                    options: [
                        {
                            label: 'Product Name',
                            value: 'name',
                        },
                        {
                            label: 'Product Sku',
                            value: 'sku',
                        },
                        {
                            label: 'Product Id',
                            value: 'id',
                        },
                    ],
                }
            },
        }
    },

    mounted() {
        if (this.allowSkippingProductItemOption) {
            this.dropdowns.productItemOptions.options = [
                {
                    label: 'Do NOT add additional products',
                    value: 'skip',
                },
                ...this.dropdowns.productItemOptions.options,
            ]
        }
        if (this.flow.type === 'webhook') {
            this.dropdowns.productItemOptions.options = [
                ...this.dropdowns.productItemOptions.options,
                {
                    label: 'Use multiple products from webhook array',
                    value: 'multiple_products_array'
                }
            ]
        }

        this.$nextTick(() => {
            this.setOptions();
        })


    },

    methods: {
        setOptions() {
            this.setDropdownValue('productItemOptions', 'product_item_option')

            if (this.allowSkippingProductItemOption && parseInt(this.settings.shouldAddProducts) === 0) {
                this.dropdowns.productItemOptions.selected = _.find(this.dropdowns.productItemOptions.options, option => {
                    return option.value === 'skip';
                })
                this.updateProductItemOptions()
            }

            if (parseInt(this.settings.product_id) > 0) {
                this.dropdowns.products.selected = {
                    id: this.settings.product_id,
                    name: this.settings.product_label,
                }
                this.dropdowns.products.options = [
                    this.dropdowns.products.selected
                ]
            }

            this.setDropdownValue('searchProductField', 'dynamic_option')
            this.setDropdownTrigger('product', 'dynamic_field');
            this.setDropdownValue('productPriceType', 'price.type', true);
            this.setDropdownTrigger('productPrice', 'price.field');

            this.dropdowns.productQuantityType.selected = _.find(this.dropdowns.productQuantityType.options, option => {
                if (parseInt(this.settings.product_quantity.option) === 0) {
                    this.settings.product_quantity.amount = 1;
                    return option.value === 2;
                }

                return parseInt(option.value) === parseInt(this.settings.product_quantity.option);
            })

            this.setDropdownTrigger('productQuantity', 'product_quantity.field')
            this.setDropdownTrigger('webhook', 'product_array_key', 'triggerArrayKeys')

            // Find by webhook items
            this.setDropdownValue('findProductWebhook', 'find_product_item');
            this.setDropdownTrigger('webhook_product_id', 'find_product_item_id');
            this.setDropdownTrigger('webhook_product_name', 'find_product_item_name');
            this.setDropdownTrigger('webhook_product_sku', 'find_product_item_sku');
            this.setDropdownTrigger('product_item_name', 'product_item_name_field', 'triggerFieldsForWebhookKey')
            this.setDropdownTrigger('product_item_price', 'product_item_price_field', 'triggerFieldsForProductPrice')
            this.setDropdownTrigger('product_item_quantity', 'product_item_quantity_field', 'triggerFieldsForProductQty')
            this.setDropdownTrigger('product_item_sku', 'product_item_sku_field', 'triggerFieldsForProductSku')

        },

        updateProductItemOptions() {
            if (this.allowSkippingProductItemOption) {
                this.settings.shouldAddProducts = _.get(this.dropdowns.productItemOptions, 'selected.value') === 'skip' ? 0 : 1;
            }
            this.updateFromDropDownValue('product_item_option', 'productItemOptions')
        },

        selectedProductUpdated() {
            this.updateSettings('product_label', 'products.selected.name')
            this.updateSettings('product_id', 'products.selected.id')
        },

        triggerFieldsForWebhookKeyWithSkip(skipLabel, skipValue) {
            return [
                {
                    label: skipLabel,
                    id: skipValue,
                },
                ...this.triggerFieldsForWebhookKey,
            ]
        }

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList',
            triggerArrayKeys: 'triggerArrayKeyList',
        }),

        flow() {
            return _.get(this.$store.state.flow, 'data');
        },

        findByOneProduct() {
            return _.get(this.settings, 'product_item_option') === 'one_product'
        },

        findByTriggerField() {
            return _.get(this.settings, 'product_item_option') === 'dynamic';
        },

        useWebhookArray() {
            return _.get(this.settings, 'product_item_option') === 'multiple_products_array';
        },

        findOrCreateOneProduct() {
            return this.findByOneProduct || this.findByTriggerField;
        },

        useTriggerFieldForPrice() {
            return parseInt(this.settings.price.type) === 1;
        },

        useManualPrice() {
            return parseInt(this.settings.price.type) === 2;
        },

        useTriggerFieldForQuantity() {
            return parseInt(this.settings.product_quantity.option) === 1;
        },

        triggerFieldsForWebhookKey() {
            return _.filter(this.triggerFields, field => {
                let parentField = _.find(this.triggerArrayKeys, key => {
                    return parseInt(key.id) === parseInt(_.get(this.dropdowns.triggerFields.selected, 'webhook.id'))
                })

                return parentField && _.startsWith(field.path, `${parentField.path}[0]`)
            })
        },

        triggerFieldsForProductPrice() {
            return this.triggerFieldsForWebhookKeyWithSkip('No field, use Keap Price', 0);
        },

        triggerFieldsForProductSku() {
            return this.triggerFieldsForWebhookKeyWithSkip('None, skip this field', 0);
        },

        triggerFieldsForProductQty() {
            return this.triggerFieldsForWebhookKeyWithSkip('No field, just use 1', 1);
        }

    }
}
</script>