<template>

    <div>

        <div class="grid grid-cols-2 gap-4">
            <div class="hidden md:flex md:col-span-1 items-center justify-between">
                <h1 class="font-lead text-2xl font-semibold text-gray-700">
                    Mailboxes
                </h1>
                <a @click="showAdd = true"
                   class="h-9 w-9 p-2 rounded-full text-brand-900 hover:bg-red-50 focus:outline-none">
                    <plus-icon/>
                </a>
            </div>

            <div class="col-span-2 md:col-span-1">

                <div class="relative">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <div class="h-6 w-6 text-gray-300">
                            <search-icon/>
                        </div>
                    </div>
                    <input id="mailbox-search"
                           v-model="search"
                           type="text"
                           class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-lg border-gray-100 rounded-md"
                           placeholder="Search ">
                </div>

            </div>
        </div>

        <mailbox-modal :show="showAdd"
                       @added="added"
                       :mailbox="{name: '', forward_addresses: []}"
                       @close="showAdd = false"/>

        <div class="mt-6" v-if="loading || mailboxes.length > 0">
            <default-table>
                <template #head>
                    <table-header>
                        <span @click="adjustOrder('name')" class="cursor-pointer">Name</span>
                    </table-header>
                    <table-header>
                        <span @click="adjustOrder('address')" class="cursor-pointer">Address</span>
                    </table-header>
                    <table-header class="hidden lg:table-cell text-center">
                        Forward
                    </table-header>
                    <table-header class="hidden lg:table-cell text-center">
                        Flows
                    </table-header>
                    <table-header class="text-right"></table-header>
                </template>

                <mailbox-row v-if="mailboxes.length > 0"
                             v-for="mailbox in mailboxes"
                             :key="mailbox.id"
                             @updated="updateMailbox"
                             @deleted="deleteMailbox"
                             :mailbox="mailbox"/>

                <tr v-if="loading" class="animate-pulse" :key="row" v-for="row in loadingBoxes">
                    <table-cell v-for="cell in [1,2]" :key="cell">
                        <div class="h-4 bg-gray-100 rounded"></div>
                    </table-cell>
                    <table-cell v-for="cell in [3,4]"
                                :classes="['hidden lg:table-cell']"
                                :key="cell">
                        <div class="flex items-center justify-center">
                            <div class="h-6 bg-gray-100 rounded-full inline-block w-24"></div>
                        </div>
                    </table-cell>
                    <table-cell>
                        <div class="flex justify-end items-center">
                            <button-menu size="6" spacing="0"/>
                        </div>
                    </table-cell>
                </tr>

            </default-table>
        </div>

        <div v-else class="mt-6">

            <default-well>
                <div class="h-64 flex flex-col items-center justify-center">
                    <p class="text-lg">
                        Create your first email address to Parsey.
                    </p>

                    <button @click="showAdd = true"
                            class="btn btn-teal mt-4">
                        Create Mailbox
                    </button>
                </div>
            </default-well>

        </div>
    </div>


</template>

<script>
import DefaultWell from "../../elements/DefaultWell"
import DefaultTable from "../../elements/tables/DefaultTable"
import TableHeader from "../../elements/tables/TableHeader"
import TableCell from "../../elements/tables/TableCell"
import SearchIcon from "../../svgs/general/SearchIcon"
import DotsMenu from "../../svgs/menu/DotsMenu"
import NumberedBadge from "../../elements/badges/NumberedBadge"
import MailboxRow from "./MailboxRow"
import PlusIcon from "../../svgs/menu/PlusIcon"
import MailboxModal from "./MailboxModal"
import ButtonMenu from "../../elements/buttons/ButtonMenu"

export default {
    name: 'MailboxIndex',

    components: {
        ButtonMenu,
        MailboxModal,
        PlusIcon,
        MailboxRow,
        NumberedBadge,
        DotsMenu,
        SearchIcon,
        TableCell,
        TableHeader,
        DefaultTable,
        DefaultWell,
    },

    metaInfo: {
        title: 'Mailboxes | Parsey'
    },

    data() {
        return {
            loading: true,
            all: [],
            search: null,
            sortBy: 'name',
            asc: true,
            showAdd: false,
        }
    },

    created() {
        this.getMailboxes();
    },

    methods: {
        adjustOrder(field) {
            if (field == this.sortBy) {
                this.asc = !this.asc
            } else {
                this.asc = true;
            }
            this.sortBy = field
        },

        getMailboxes() {
            Parsey.get(this.$store.state.routes.mailboxes.index)
                .then(({data}) => {
                    this.loading = false;
                    this.all = _.keyBy(data, 'id');
                })
        },

        updateMailbox(mailbox) {
            this.all[mailbox.id] = mailbox;
        },

        added(mailbox) {
            this.$set(this.all, mailbox.id, mailbox)
        },

        deleteMailbox(mailboxId) {
            this.$delete(this.all, mailboxId)
        }
    },

    computed: {
        loadingBoxes() {
            if (this.$store.state.user.mailbox_count) {
                return _.range(this.$store.state.user.mailbox_count)
            }

            return _.range(5);
        },

        mailboxes() {
            let mailboxes = _.sortBy(this.all, mailbox => {
                return mailbox[this.sortBy].toLowerCase()
            })

            if (!this.asc) {
                mailboxes = _.reverse(mailboxes)
            }

            if (this.search === null || this.search.length === 0) {
                return mailboxes;
            }

            const search = this.search.toLowerCase().trim();
            return _.filter(mailboxes, mailbox => {
                let results = mailbox.terms.filter(key => {
                    return key.includes(search) || key.replace(' ', '').includes(search)
                })

                return results.length > 0;
            })
        }
    }
}
</script>
