<template>
    <default-wrapper>

        <history-details-header :links="pagination.links"
                                :loading="dataLoading"
                                :details="details"
                                :selected-job="selectedJob"
                                @reprocess="reprocess"
                                @refresh="refresh"
                                :reprocessing="reprocessForm.form.busy"
                                :current-number="currentNumber"/>


        <div v-if="details.loading">
            <flow-panel :loading="true" :continued="false"/>
            <flow-panel :loading="true" :continued="true"/>
        </div>

        <div v-else>

            <history-flow-trigger :flow-history="details.flowHistory"
                                  :job="selectedJob"/>

            <history-actions :flow-history="details.flowHistory"
                             :job="selectedJob"/>

        </div>

    </default-wrapper>
</template>

<script>
import {mapActions} from "vuex"
import FlowPanel from "../flows/components/FlowPanel"
import DefaultWrapper from "../../layouts/partials/DefaultWrapper"
import HistoryDetailsHeader from "./partials/HistoryDetailsHeader"
import HistoryFlowTrigger from "./partials/HistoryFlowTrigger"
import HistoryActions from "./partials/HistoryActions"

export default {
    name: 'FlowHistoryShow',

    components: {
        HistoryActions,
        HistoryFlowTrigger,
        FlowPanel,
        HistoryDetailsHeader,
        DefaultWrapper
    },

    data() {
        return {
            details: {
                loading: true,
                flowHistory: null,
            },
            currentNumber: 0,
            pagination: {
                loading: true,
                links: {
                    prev: null,
                    next: null,
                }
            },
            reprocessForm: {
                checking: false,
                form: new ParseyForm()
            },

            checks: 0,
        }
    },

    mounted() {
        this.$store.commit('history/setSearch')
        this.getHistory()
        this.getDetails()
        this.getPagination()
        this.$store.dispatch('apps/getLegacyApps')
    },

    watch: {
        '$route.params.flowHistoryId'() {
            this.getDetails()
            this.getPagination()
        },
    },

    methods: {
        ...mapActions('history', [
            'getHistory'
        ]),

        getPagination() {
            let url = _.replace(
                this.$store.state.routes.history.pagination, '0', this.$route.params.flowHistoryId
            );
            this.pagination.loading = true;

            const query = new URLSearchParams(this.$store.state.history.search).toString();

            Parsey.get(`${url}?${query}`).then(({pagination}) => {
                this.currentNumber = pagination.meta.current
                this.pagination.links = pagination.links;
                this.pagination.loading = false;
            });
        },

        getDetails() {
            let url = _.replace(
                this.$store.state.routes.history.show, '0', this.$route.params.flowHistoryId
            );
            this.details.loading = true;

            Parsey.get(url).then((data) => {
                this.details.flowHistory = data.flow_history;
                this.details.loading = false;
            });
        },

        reprocess() {
            let url = _.replace(
                this.$store.state.routes.history.process, '0', this.$route.params.flowHistoryId
            );
            this.checks = 0;

            Parsey.post(url, this.reprocessForm.form)
                .then(() => {
                    this.reprocessForm.form.busy = true;
                    setTimeout(() => {
                        this.checkForUpdatedJob()
                    }, 5000)

                })
        },

        checkForUpdatedJob() {
            let url = _.replace(
                this.$store.state.routes.history.show, '0', this.$route.params.flowHistoryId
            );

            Parsey.get(url).then((data) => {
                const firstJob = _.first(_.get(data.flow_history, 'jobs'));
                const originalFirstJob = _.first(_.get(this.details.flowHistory, 'jobs'));

                if (!firstJob || !originalFirstJob) {
                    this.reprocessForm.form.busy = false;
                    return;
                }

                if (originalFirstJob.id < firstJob.id) {
                    this.details.flowHistory = data.flow_history;
                    let params = {
                        flowHistoryId: this.$route.params.flowHistoryId,
                        jobs: 'jobs',
                        jobId: firstJob.id,
                    };
                    this.$router.push({name: 'FlowHistoryShow', params})
                    this.reprocessForm.form.busy = false;
                    return;
                }

                this.checks += 1;

                if (this.checks > 8) {
                    return;
                }

                setTimeout(() => {
                    this.checkForUpdatedJob()
                }, 4000)
            });
        },

        refresh() {
            this.getDetails();
        },
    },

    computed: {
        dataLoading() {
            return this.$store.state.history.loading || this.pagination.loading;
        },

        selectedJob() {
            const jobs = _.get(this.details.flowHistory, 'jobs');
            if (!jobs) {
                return '';
            }

            if (this.$route.params.jobId) {
                return _.find(jobs, job => {
                    return job.id === parseInt(this.$route.params.jobId)
                })
            }

            return _.first(jobs);
        },

    }


}
</script>