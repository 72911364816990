<template>
    <div class="bg-white overflow-x-hidden sm:rounded-lg border border-gray-100 mb-6 md:mb-8">
        <div v-if="title" class="px-4 py-5 border-b border-gray-100 sm:px-6">
            <h3 class="text-lg leading-6 font-semibold text-gray-600">
                {{ title }}
            </h3>
            <p v-if="description" class="mt-1 text-sm leading-5 text-gray-500">
                {{ description }}
            </p>
        </div>
        <div class="px-4 py-5 sm:px-6 text-gray-500">
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {

    name: "DefaultWell",

    props: [
        'title',
        'description'
    ]
}
</script>
