<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Configure the note details below
        </p>

        <form-error :form="form"/>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 mb-4">

            <input-label :label="false">
                <template #label>
                    <div class="flex items-center justify-between">
                        <default-label>
                            Title
                        </default-label>
                        <checkbox-field :id="`note_title_${event.id}`"
                                        :font-medium="false"
                                        v-model="form.settings.title.type"
                                        value="1" true-value="1" false-value="0">
                            Use trigger field
                        </checkbox-field>
                    </div>
                </template>

                <event-input-field v-if="form.settings.title.type === '0'"
                                   placeholder="Type title for note"
                                   v-model="form.settings.title.manual"/>

                <select-field v-else
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.title"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>

            </input-label>

            <input-label :label="false">
                <template #label>
                    <div class="flex items-center justify-between">
                        <default-label>
                            Type
                        </default-label>
                        <checkbox-field :id="`note_type_${event.id}`"
                                        :font-medium="false"
                                        v-model="form.settings.type.type"
                                        value="1" true-value="1" false-value="0">
                            Use trigger field
                        </checkbox-field>
                    </div>
                </template>


                <select-field v-if="form.settings.type.type === '0'"
                              :options="dropdowns.noteTypes.options"
                              v-model="dropdowns.noteTypes.selected"
                              :loading="dropdowns.noteTypes.loading"
                              label="label"
                              track-by="value"
                              placeholder="Select field"/>

                <select-field v-else
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.type"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>

            </input-label>
        </div>

        <div :class="{'md:grid-cols-3 gap-x-4': usingFindUsingTriggerField, 'md:grid-cols-2 gap-x-10': !usingFindUsingTriggerField}"
             class="grid grid-cols-1  mb-4">
            <input-label label="Assign Note to User">
                <select-field :options="dropdowns.findUserMethods.options"
                              v-model="dropdowns.findUserMethods.selected"
                              @input="findUserMethodsUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="usingSelectFromList" label="Users">
                <select-field :options="dropdowns.users.options"
                              v-model="dropdowns.users.selected"
                              :loading="dropdowns.users.loading"
                              @input="userListUpdated"
                              label="label"
                              track-by="id"
                              placeholder="Select user"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.user"
                              @input="userTriggerFieldUpdate"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Search User Field">
                <select-field :options="dropdowns.searchUserField.options"
                              v-model="dropdowns.searchUserField.selected"
                              @input="userSearchFieldUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select user field"/>
            </input-label>

        </div>

        <input-label :label="false">
            <template #label>
                <div class="flex items-center justify-between">
                    <default-label>
                        Description
                    </default-label>
                    <div>
                        <checkbox-field :id="`note_description_${event.id}`"
                                        :font-medium="false"
                                        v-model="form.settings.description.type"
                                        value="1" true-value="1" false-value="0">
                            Use trigger field
                        </checkbox-field>
                    </div>
                </div>
            </template>

            <text-area-field v-if="form.settings.description.type === '0'"
                             rows="5" placeholder="Type note description"
                             v-model="form.settings.description.manual"/>

            <select-field v-else
                          :options="triggerFields"
                          v-model="dropdowns.triggerFields.selected.description"
                          label="label"
                          track-by="id"
                          placeholder="Select trigger field"/>

        </input-label>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventInputField from "../../../../elements/fields/EventInputField"
import InputLabel from "../../../../elements/fields/InputLabel"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import SelectField from "../../../../elements/fields/SelectField"
import RadioField from "../../../../elements/fields/RadioField"
import TextAreaField from "../../../../elements/fields/TextAreaField"
import {mapGetters} from "vuex"
import FindUserOptions from '../mixins/find-user-options'
import DefaultLabel from "../../../../elements/DefaultLabel"

export default {
    name: 'Note',

    components: {
        DefaultLabel,
        TextAreaField,
        RadioField,
        SelectField,
        CheckboxField,
        InputLabel,
        EventInputField,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        FindUserOptions
    ],

    data() {
        return {

            dropdowns: {
                triggerFields: {
                    loading: false,
                    selected: {
                        title: null,
                        type: null,
                        description: null,
                    }
                },

                noteTypes: {
                    loading: true,
                    options: [],
                    selected: null,
                },
            },

            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    title: {
                        extracted: '',
                        manual: '',
                        type: '0',
                    },
                    type: {
                        extracted: '',
                        manual: 'Other',
                        type: '0',
                    },
                    user: {
                        search_type: '1', // Email
                        type: '4', // Assign to contact owner
                        manual: '',
                        extracted: '',
                    },
                    description: {
                        type: '0',
                        manual: '',
                        extracted: '',
                    }
                },
            }),
        }
    },

    mounted() {
        this.dropdowns.triggerFields.selected.title = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.title.extracted);
        })

        this.dropdowns.triggerFields.selected.type = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.type.extracted);
        })

        this.dropdowns.triggerFields.selected.description = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.description.extracted);
        })

        this.getNoteTypes();
    },

    methods: {

        save() {

            this.form.settings.title.extracted = _.get(this.dropdowns.triggerFields.selected, 'title.id');
            this.form.settings.type.extracted = _.get(this.dropdowns.triggerFields.selected, 'type.id');
            this.form.settings.description.extracted = _.get(this.dropdowns.triggerFields.selected, 'description.id');
            this.form.settings.type.manual = _.get(this.dropdowns.noteTypes, 'selected.value');

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getNoteTypes() {
            Parsey.get(`${this.action.routes.search}?type=noteTypes&search=all`)
                .then(results => {
                    this.dropdowns.noteTypes.loading = false;
                    this.dropdowns.noteTypes.options = results;
                    this.dropdowns.noteTypes.selected = _.find(results, type => {
                        return type.value === this.form.settings.type.manual;
                    })
                })
        },

    },

    computed: {

        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

    }
}
</script>

