<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">

        <div v-if="!loading"
            v-for="(flow, key) in flows"
             :key="key"
             class="bg-white focus:outline-none transition duration-150 ease-in-out border border-gray-100 rounded-md cursor-pointer col-span-1">

            <flow-card :flow="flow"/>

        </div>

        <div v-if="loading"
             v-for="row in defaultRows"
             :key="row"
             class="animate-pulse bg-white focus:outline-none border border-gray-100 rounded-md col-span-1">
            <div class="flex items-center justify-between py-3 pl-4">
                <div class="h-6 bg-gray-100 mr-6 w-full rounded"></div>
                <div class="w-24 pr-1 flex justify-end items-center">
                    <div class="h-5 w-5 rounded-full bg-gray-100"></div>
                    <div class="w-7 h-7 p-1 text-gray-100">
                        <dots-menu />
                    </div>
                </div>
            </div>
            <div class="w-40 pl-4 pr-3 pb-3 flex justify-around items-center">
                <div class="h-8 w-8 rounded-full bg-gray-100"></div>
                <div class="h-8 w-8 rounded-full bg-gray-100"></div>
                <div class="h-8 w-8 rounded-full bg-gray-100"></div>
            </div>
        </div>

    </div>
</template>

<script>

import FlowCard from "./FlowCard"
import DotsMenu from "../../../svgs/menu/DotsMenu"

export default {
    name: "FlowCards",

    components: {
        DotsMenu,
        FlowCard,
    },

    props: [
        'flows',
        'loading',
    ],

    computed: {
        defaultRows() {
            return _.range(10);
        }
    }

}
</script>


