import ConfirmEmail from "../components/auth/ConfirmEmail"
import RouterMultiGuard from "vue-router-multiguard"
import FinishRegistration from "../components/auth/FinishRegistration"
import {emailConfirmed, finishedRegistration} from '../middlewares'
import Login from "../components/auth/Login"
import Register from "../components/auth/Register"

export default [
    {
        path: '/confirm',
        name: 'ConfirmEmail',
        meta: {
            layout: 'FocusedLayout',
        },
        component: ConfirmEmail,
        beforeEnter: RouterMultiGuard([emailConfirmed])
    },
    {
        path: '/security',
        name: 'FinishRegistration',
        meta: {
            layout: 'FocusedLayout',
        },
        component: FinishRegistration,
        beforeEnter: RouterMultiGuard([finishedRegistration]),
    },
    {path: '/login', component: Login},
    {path: '/register', component: Register},
]
