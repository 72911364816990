<template>

    <div :class="{'md:grid-cols-3 gap-x-4': usingTriggerField, 'md:grid-cols-2 gap-x-10': !usingTriggerField}"
         class="grid grid-cols-1  mb-4">
        <input-label :label="affiliateLabel">
            <select-field :options="dropdowns.findBy.options"
                          v-model="dropdowns.findBy.selected"
                          @input="updateFromDropDownValue(`${type}_set_affiliate`, 'findBy')"
                          label="label"
                          track-by="value"/>
        </input-label>

        <input-label v-if="usingList" label="Referral Partners">
            <select-field :options="dropdowns.affiliate.options"
                          v-model="dropdowns.affiliate.selected"
                          :loading="dropdowns.affiliate.searching"
                          @search-change="dropdowns.affiliate.search($event)"
                          @input="affiliateUpdated"
                          :internal-search="false"
                          label="name"
                          track-by="id"
                          :options-limit="100"
                          :no-result="dropdowns.affiliate.noResult"
                          placeholder="Type to search for affiliate"/>
        </input-label>

        <input-label v-if="usingTriggerField" label="Trigger Field">
            <select-field :options="triggerFields"
                          v-model="dropdowns.triggerFields.selected.affiliate"
                          @input="updateFromTriggerField(`${type}_affiliate_extraction_key`, 'affiliate')"
                          label="label"
                          track-by="id"
                          placeholder="Select trigger field"/>
        </input-label>

        <input-label v-if="usingTriggerField" label="Search Referral Partner Field">
            <select-field :options="dropdowns.field.options"
                          v-model="dropdowns.field.selected"
                          :loading="dropdowns.field.loading"
                          @input="updateFromDropDownValue(`${type}_affiliate_field`, 'fields')"
                          label="label"
                          track-by="name"
                          group-values="fields"
                          group-label="group"
                          placeholder="Select Referral Partner field"/>
        </input-label>

    </div>

</template>

<script>

import {mapGetters} from "vuex"
import EventHelpers from "../mixins/event-helpers"
import TabHeader from "../../../../elements/tabs/TabHeader"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import CheckboxField from "../../../../elements/fields/CheckboxField"

export default {
    name: 'OrderReferralPartner',

    components: {
        SelectField,
        TabHeader,
        CheckboxField,
        InputLabel,
    },

    mixins: [
        EventHelpers,
    ],

    props: {
        type: {
            type: String,
            default: 'lead',
        },
        event: {
            type: Object,
            required: true,
        },
        action: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        affiliateFields: {
            type: Array,
        }
    },

    data() {
        return {
            settingKeyBase: 'settings',
            dropdowns: {
                triggerFields: {
                    selected: {
                        affiliate: null,
                    }
                },

                findBy: {
                    selected: null,
                    options: [
                        {
                            label: 'Do NOT set Referral Partner',
                            value: 'none',
                        },
                        {
                            label: 'Use current assigned Referral Partner',
                            value: 'current_affiliate',
                        },
                        {
                            label: 'Choose Referral Partner from list',
                            value: 'manual_code',
                        },
                        {
                            label: 'Use trigger field to find Referral Partner',
                            value: 'use_field',
                        },
                    ]
                },

                field: {
                    selected: null,
                    options: this.affiliateFields,
                },

                affiliate: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'affiliates',
                    default: {
                        id: 0,
                        name: 'Searching...'
                    }
                }),


            },
        }
    },

    mounted() {

        this.$nextTick(() => {
            this.setOptions();
        })


    },

    methods: {
        setOptions() {
            this.dropdowns.findBy.selected = _.find(this.dropdowns.findBy.options, method => {
                let setting = _.get(this.settings, `${this.type}_set_affiliate`);
                if (setting === 'manual_email') {
                    return method.value === 'manual_code';
                }
                return method.value === setting;
            })

            this.setDropdownTrigger('affiliate', `${this.type}_affiliate_extraction_key`);

            _.find(this.affiliateFields, group => {
                let field = _.find(group.fields, field => {
                    return field.name === _.get(this.settings, `${this.type}_affiliate_field`);
                })
                if (field) {
                    this.dropdowns.field.selected = field;
                    return true;
                }

                return false;
            })


            if (this.usingList) {
                this.findAffiliate();
            }
        },

        findAffiliate() {
            let type = _.get(this.settings, `${this.type}_set_affiliate`);
            let typeSearchKey = 'code'
            let search = _.get(this.settings, `${this.type}_affiliate_code`);
            if (type === 'manual_email') {
                typeSearchKey = 'email'
                _.set(this.settings, `${this.type}_set_affiliate`, 'manual_code');
                search = _.get(this.settings, `${this.type}_affiliate_email`);
            }

            if (!search || search.length === 0) {
                return true;
            }
            this.dropdowns.affiliate.searching = true;
            Parsey.get(`${this.action.routes.search}?type=affiliates&search=${search}|${typeSearchKey}`)
                .then(results => {
                    this.dropdowns.affiliate.selected = _.find(results)
                    this.dropdowns.affiliate.searching = false;
                    this.dropdowns.affiliate.options = results;
                    this.affiliateUpdated();
                })
        },

        affiliateUpdated() {
            if (this.usingList) {
                _.set(this.settings, `${this.type}_set_affiliate`, 'manual_code');
                _.set(this.settings, `${this.type}_affiliate_code`, _.get(this.dropdowns, 'affiliate.selected.code'));
            }
        },
    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        usingList() {
            return ['manual_code', 'manual_email'].includes(_.get(this.settings, `${this.type}_set_affiliate`));
        },

        usingTriggerField() {
            return _.get(this.settings, `${this.type}_set_affiliate`) === 'use_field';
        },

        affiliateLabel() {
            let type = this.type.toUpperCase();

            return `Set ${type} Referral Partner`
        }
    }
}
</script>