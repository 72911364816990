<template>
    <div class="flex flex-col h-full">
        <h2 class="flex-shrink-0 text-xl font-semibold text-gray-800">
            Account Settings
        </h2>

        <div class="flex-1 flex flex-col pt-3">
            <div class="flex-1 flex flex-col overflow-y-auto">
                <menu-link v-for="page in pages" :key="page.name"
                           :to="{name: page.name}">

                    <template v-if="page.icon" #icon>
                        <component :is="page.icon"/>
                    </template>

                    {{ page.label }}
                </menu-link>
            </div>

            <div class="flex-shrink-0">
                <form @submit.prevent="logout">
                    <menu-link as="button">
                        <template #icon>
                            <logout-icon />
                        </template>
                        Logout
                    </menu-link>
                </form>
            </div>

        </div>
    </div>

</template>

<script>
import MenuLink from "../../elements/MenuLink"
import ProfileIcon from "../../svgs/general/ProfileIcon"
import ShieldIcon from "../../svgs/general/ShieldIcon"
import MailboxIcon from "../../svgs/menu/MailboxIcon"
import CardIcon from "../../svgs/general/CardIcon"
import ViewGridIcon from "../../svgs/general/ViewGridIcon"
import LogoutIcon from "../../svgs/general/LogoutIcon"

export default {

    name: 'SettingsSidebar',

    components: {
        LogoutIcon,
        ViewGridIcon,
        MailboxIcon,
        ShieldIcon,
        ProfileIcon,
        CardIcon,
        MenuLink,
    },

    mixins: [
        require('./settings-menu')
    ],

    methods: {
        logout() {
            axios.post('/logout').then(response => {
                window.location = '/';
            })
        }
    }
}
</script>

