<template>
    <div class="w-6 h-6 ml-1 p-1 rounded-full text-gray-500 bg-gray-50 cursor-pointer"
         v-tooltip="tooltip"
         @click="refresh">
        <spin-icon v-if="refreshing"/>
        <refresh-icon v-else/>
    </div>
</template>

<script>
import RefreshIcon from "../../svgs/general/RefreshIcon"
import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'RefreshButton',

    components: {
        SpinIcon, RefreshIcon
    },

    props: {
        refreshing: {
            default: false,
            type: Boolean,
        },
    },

    methods: {
        refresh() {
            this.$emit('refresh');
        }
    },

    computed: {
        tooltip() {
            if (this.refreshing) {
                return 'Refreshing...'
            }

            return 'Refresh'
        }
    }
}
</script>
