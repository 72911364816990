<template>
    <div class="grid grid-cols-1 md:grid-cols-5 m-4 md:m-8 gap-x-4">
        <div class="col-span-2">
            <p class="text-lg font-medium mb-4">Data</p>

            <default-table>
                <template #head>
                    <table-header>
                        Field
                    </table-header>
                    <table-header>
                        Data
                    </table-header>
                </template>
                <tr v-for="(row) in job.data"
                    class="even:bg-gray-25"
                    :key="row.id">
                    <table-cell>
                        {{ row.data.label }}
                    </table-cell>
                    <table-cell>
                        {{ row.data.value }}
                    </table-cell>
                </tr>
            </default-table>

        </div>
        <div class="col-span-3">
            <p class="text-lg font-medium mb-4">Trigger</p>

            <div class="rounded border border-gray-100 p-2 sm:p-4">
                <div class="border-b border-gray-100 pb-3 px-2 mb-4">
                    <p class="text-sm ">
                        <span class="font-semibold">Received At:</span>
                        <span class="font-light">
                            {{ recordDate }}
                        </span>
                    </p>
                </div>

                <code>
                    <tree-parent v-for="(value, name) in flowHistory.record.data"
                                 path=""
                                 :key="name"
                                 :name="name"
                                 :selectable="false"
                                 :value="value"/>
                </code>

            </div>

        </div>
    </div>
</template>

<script>
import dayjs from "dayjs"
import TreeParent from "../../../flows/legacy/partials/TreeParent"
import DefaultTable from "../../../../elements/tables/DefaultTable"
import TableHeader from "../../../../elements/tables/TableHeader"
import TableCell from "../../../../elements/tables/TableCell"

export default {
    name: 'WebhookHistoryTrigger',
    components: {
        TableCell,
        TableHeader,
        DefaultTable,
        TreeParent
    },

    props: {
        flowHistory: {
            type: Object,
            required: true,
        },
        job: {
            type: Object,
        }
    },

    computed: {
        recordDate() {
            let date = this.flowHistory.record.created_at;

            return dayjs(date).format('MMM DD, YYYY h:mm A EST')
        }
    }
}
</script>