<template>
    <flow-panel :name-value="flowHistory.flow.name"
                :name-editable="false"
                :show-content="showTrigger"
                :path="openPath"
                :close-path="closePath"
                :status-name="job.status.name"
                :status-class="job.status.class.main">

        <template #icon>
            <div v-html="flowHistory.flow.trigger.icon"></div>
        </template>

        <template #menu>
            <dropdown-link :to="{path: flowHistory.flow.views.trigger}">
                Edit Trigger
            </dropdown-link>
        </template>

        <template #content>
            <component :is="trigger" :job="job"
                       :flow-history="flowHistory"/>
        </template>


    </flow-panel>
</template>

<script>
import FlowPanel from "../../flows/components/FlowPanel"
import WebhookHistoryTrigger from "./triggers/WebhookHistoryTrigger"
import DropdownLink from "../../../elements/DropdownLink"

export default {
    name: 'HistoryFlowTrigger',

    components: {
        DropdownLink,
        FlowPanel,
        WebhookHistoryTrigger,
    },


    props: {
        job: {
            type: Object,
            required: true,
        },
        flowHistory: {
            type: Object,
            required: true,
        }
    },

    computed: {
        showTrigger() {
            return this.$route.params.panel === 'trigger'
        },

        trigger() {
            switch (this.flowHistory.flow.trigger.type) {
                case 'webhook':
                    return 'WebhookHistoryTrigger';
            }
        },

        closePath() {
            return _.replace(this.flowHistory.views.edit, 0, this.job.id);
        },

        openPath() {
            return _.replace(this.flowHistory.views.trigger, 0, this.job.id);
        },
    }
}
</script>