<template>
    <center-modal :show="show" @close="close" size="lg">

        <div v-if="canDelete">
            <form-error :form="form"/>

            <form @submit.prevent="destroy" @keydown.enter.prevent="destroy">

                <alert-error>
                    Are you sure you want to delete this mailbox? This will delete any history of
                    emails that have come into this mailbox and stop any incoming emails with this
                    address.
                </alert-error>

                <input-label :label="`Please type the address: <strong>${mailbox.address}</strong> to confirm deletion`">
                    <input-field :form="form"
                                 name="confirm"/>
                </input-label>

                <div class="sm:flex sm:flex-row-reverse mt-3">

                    <submit-button :disabled="deleteDisabled"
                                   text="Delete"
                                   :teal="false"
                                   :form="form"
                                   extra="bg-red-600 text-white"
                                   @save="destroy"
                                   loading="Deleting..."/>

                    <button @click.prevent="close"
                            class="btn btn-sm btn-block btn-white sm:border-none sm:inline-block sm:w-auto mt-4 sm:mt-0">
                        Cancel
                    </button>

                </div>

            </form>
        </div>

        <div v-else>

            <warning-alert message="This mailbox can not be deleted because it is currently attached to the following flows"/>

            <div class="mt-4">
                <flow-rows :flows="mailbox.flows" :show-menu="false"/>
            </div>

            <div class="sm:flex sm:flex-row-reverse mt-4">
                <button @click.prevent="close"
                        class="btn btn-sm btn-block btn-white sm:border-none sm:inline-block sm:w-auto mt-4 sm:mt-0">
                    Close
                </button>
            </div>

        </div>


    </center-modal>
</template>

<script>
import CenterModal from "../../elements/modals/CenterModal"
import FormError from "../../elements/alerts/FormError"
import SubmitButton from "../../elements/buttons/SubmitButton"
import AlertError from "../../elements/alerts/AlertError"
import InputLabel from "../../elements/fields/InputLabel"
import InputField from "../../elements/fields/InputField"
import WarningAlert from "../../elements/alerts/WarningAlert"
import FlowCard from "../flows/components/FlowCard"
import FlowRows from "../flows/components/FlowRows"

export default {
    name: 'MailboxDeleteModal',

    components: {
        FlowRows,
        FlowCard,
        WarningAlert,
        InputField,
        InputLabel,
        AlertError,
        SubmitButton,
        FormError,
        CenterModal
    },

    props: {
        mailbox: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            form: new ParseyForm({
                confirm: '',
            })
        }
    },

    computed: {
        canDelete() {
            return this.mailbox.flows.length === 0;
        },

        deleteDisabled() {
            return this.form.confirm !== this.mailbox.address;
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        destroy() {
            Parsey.delete(this.mailbox.routes.delete, this.form)
                .then(() => {
                    this.$emit('deleted', this.mailbox.id)
                    this.close();
                })
        }
    }
}
</script>
