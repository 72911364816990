<template>
    <div>

        <legacy-trigger-waiting v-if="showWaiting"/>

        <legacy-trigger-confirm v-if="showConfirmation"/>

        <div v-if="showFields">

            <div v-if="collapse" @click="collapse = ! collapse"
                 class="flex items-center cursor-pointer py-2">
                <div class="mx-1 w-9 h-9 p-2 sm:w-10 sm:h-10 text-gray-300 transform rotate-90">
                    <chevron-right-icon/>
                </div>
                <p class="truncate text-sm sm:text-base md:text-lg font-medium">
                    {{ collapsedTitle }}
                </p>
            </div>

            <div v-if="collapse === false" class="py-3 px-2 sm:py-6 sm:px-8">

                <legacy-click-bank-trigger v-if="isClickBank"/>

                <div v-else>
                    <div class="pb-2 mb-1 sm:mb-4 flex justify-between">
                        <div @click="collapse = ! collapse" class="cursor-pointer">
                            <h3 class="text-lg sm:text-xl font-medium">
                                Webhook
                            </h3>
                        </div>
                        <div class="hidden sm:block">
                            <button @click="copyData" v-tooltip="copyTooltip"
                                    class="btn btn-xs btn-light-indigo flex items-center">
                                <div class="h-4 w-4 mr-1">
                                    <clipboard-icon/>
                                </div>
                                Webhook URL
                            </button>
                            <input type="hidden" :id="`webhook_url_${flow.id}`" :value="flow.trigger.address"/>
                        </div>
                    </div>

                    <div class="grid grid-cols-6 gap-5">
                        <div class="col-span-6 md:col-span-4">
                            <div class="flex items-center">
                                <p class="text-gray-500 sm:text-lg mr-2">Recent Webhook</p>
                                <refresh-button @refresh="refreshFlow" :refreshing="updating"/>
                            </div>
                            <p class="text-gray-300 text-sm mb-2">
                                Choose the fields your want to use from the webhook below.
                            </p>

                            <div class="rounded border border-gray-100 p-2 sm:p-4">
                                <div class="border-b border-gray-100 pb-3 px-2 mb-4">

                                    <p v-for="header in headers" :key="header.name"
                                       class="text-sm text-gray-700">
                                        <span class="font-semibold" v-text="header.name"></span>
                                        <span class="font-light" v-text="header.value"></span>
                                    </p>

                                </div>
                                <div class="px-2">
                                    <webhook-tree/>
                                </div>
                            </div>


                        </div>
                        <div v-if="fields.length > 0"
                             class="col-span-6 md:col-span-2">

                            <p class="text-gray-500 text-lg">Trigger Fields</p>
                            <p class="text-gray-300 text-sm mb-2">
                                These fields are used for all your actions.
                            </p>


                            <legacy-webhook-field v-for="field in fields"
                                                  :key="field.id"
                                                  :field="field"/>

                        </div>
                    </div>
                </div>


                <div v-if="showContinue" class="mt-6 w-full flex justify-center">

                    <button class="btn btn-continue" type="submit"
                            @click="addAction">Continue
                    </button>

                </div>

                <div v-else-if="showSave" class="mt-6 w-full flex justify-center">
                    <button class="btn btn-continue" type="submit"
                            @click="editFlow">
                        Save
                    </button>
                </div>

            </div>

        </div>

    </div>
</template>

<script>

import LegacyTriggerWaiting from "../partials/LegacyTriggerWaiting"
import LegacyTriggerConfirm from "../partials/LegacyTriggerConfirm"
import ChevronRightIcon from "../../../../svgs/general/ChevronRightIcon"
import WebhookTree from "../partials/WebhookTree"
import RefreshIcon from "../../../../svgs/general/RefreshIcon"
import dayjs from 'dayjs'
import {mapState, mapActions} from 'vuex';
import SpinIcon from "../../../../svgs/general/SpinIcon"
import LegacyWebhookField from "./LegacyWebhookField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import RefreshButton from "../../../../elements/buttons/RefreshButton"
import ClipboardIcon from "../../../../svgs/general/ClipboardIcon"
import LegacyClickBankTrigger from "./LegacyClickBankTrigger"
import CopyMixin from "../../../../mixins/copy"

export default {
    name: 'LegacyWebhookTrigger',

    components: {
        LegacyClickBankTrigger,
        ClipboardIcon,
        RefreshButton,
        SubmitButton,
        LegacyWebhookField,
        SpinIcon,
        RefreshIcon,
        WebhookTree,
        ChevronRightIcon,
        LegacyTriggerConfirm,
        LegacyTriggerWaiting,
    },

    mixins: [
        CopyMixin
    ],

    data() {
        return {
            collapse: false,
            copy: {
                inputId: `webhook_url_${this.$route.params.id}`,
            }
        }
    },

    computed: {
        ...mapState('flow', {
            updating: state => state.updating,
            flow: state => state.data,
        }),

        showWaiting() {
            return _.get(this.flow, 'trigger.state') === 'new';
        },

        showConfirmation() {
            if (_.get(this.flow, 'trigger.state') !== 'received') {
                return false;
            }

            if (_.get(this.flow, 'settings.confirmed')) {
                return false;
            }

            return true;
        },

        showFields() {
            if (_.get(this.flow, 'settings.confirmed')) {
                return true;
            }

            return false;
        },

        showArea() {
            if (!this.showFields) {
                return false;
            }

            return this.collapse;
        },

        showContinue() {
            if (this.isClickBank) {
                return this.hasClickBankSecretKey;
            }

            return this.fields.length > 0 && _.get(this.flow, 'actions', '').length === 0
        },

        showSave() {
            if (this.isClickBank) {
                return this.hasClickBankSecretKey;
            }

            return this.fields.length > 0 && _.size(_.get(this.flow, 'actions')) > 0
        },

        receivedAt() {
            let createdAt = _.get(this.flow, 'trigger.data.webhook.created_at');
            if (!createdAt) {
                return ''
            }

            return dayjs(createdAt).format('DD MMM YYYY, H:mm A EST')
        },

        headers() {
            return [
                {
                    name: 'Received at:',
                    value: this.receivedAt,
                },
            ]
        },

        fields() {
            return _.sortBy(_.get(this.flow, 'trigger.fields'), field => {
                return field.label
            })
        },

        isClickBank() {
            return _.get(this.flow, 'trigger.type') === 'clickbank';
        },

        hasClickBankSecretKey() {
            return _.has(this.flow, 'settings.clickbank_secret_key');
        },

        collapsedTitle() {
            if (this.isClickBank) {
                return 'ClickBank Trigger';
            }

            return 'Webhook Trigger';
        },

    },

    methods: {
        ...mapActions('flow', {
            refreshFlow: 'getFlow',
            addAction: 'addLegacyAction'
        }),

        editFlow() {
            this.$router.push({path: this.flow.views.edit})
        },

        toggleField(path) {
            this.pendingFields.push(path);
        },
    }
}
</script>
