<template>

    <div class="md:hidden" v-show="menuOpen">
        <div class="fixed inset-0 flex z-40">

            <transition
                enter-active-class="transition-opacity ease-linear duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0">
                <div @click="$store.commit('closeMenu')" class="fixed inset-0">
                    <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
                </div>
            </transition>

            <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="translate-x-0"
                leave-to-class="-translate-x-full">
                <div v-show="menuOpen"
                     style="max-width: 4rem"
                     class="relative flex-1 flex flex-col w-16 bg-white">

                    <div class="absolute top-0 right-0 -mr-14 p-1">
                        <button v-show="menuOpen" @click="$store.commit('closeMenu')"
                                class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
                            <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>

                    <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">

                        <div class="flex items-center justify-center flex-shrink-0">
                            <div class="w-11 h-11">
                                <router-link :to="{name: 'Home'}">
                                    <parsey-icon/>
                                </router-link>
                            </div>
                        </div>
                        <nav class="mt-5 flex-1 px-2 bg-white flex flex-col items-center">

                            <router-link v-for="nav in mainNavigation"
                                         :key="nav.route"
                                         :to="{name: nav.route}"
                                         :title="nav.name"
                                         exact
                                         active-class="text-gray-600"
                                         class="text-gray-300 hover:text-gray-500 block hover:bg-gray-50 text-xl w-8 h-8 p-1.5 mb-4 transition-colors ease-in-out duration-200 rounded-full flex items-center justify-center">
                                <component :is="nav.icon"/>
                            </router-link>

                        </nav>
                    </div>

                    <user-gravatar/>
                </div>
            </transition>
            <div class="flex-shrink-0 w-14">
                <!-- Force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </div>
</template>

<script>
import ParseyIcon from "../../svgs/ParseyIcon"
import PlusIcon from "../../svgs/menu/PlusIcon"
import FlowIcon from "../../svgs/menu/FlowIcon"
import HistoryIcon from "../../svgs/menu/HistoryIcon"
import UserGravatar from "./UserGravatar"

export default {
    name: "MobileSidebar",

    components: {
        UserGravatar,
        ParseyIcon,
        PlusIcon,
        FlowIcon,
        HistoryIcon,
    },

    props: [
        'mainNavigation',
        'menuOpen'
    ],

    watch: {
        '$route'() {
            this.$store.commit('closeMenu')
        }
    }
}
</script>
