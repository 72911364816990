<template>
    <div class="rounded-md bg-green-100 p-4 mb-4">
        <div class="flex">
            <div class="flex-shrink-0">
                <div class="w-5 h-5 text-green-600">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                </div>
            </div>
            <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-green-700">
                    {{ message }}
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessAlert',

    props: [
        'message'
    ]

}
</script>
