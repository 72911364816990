<template>
    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span class="text-gray-400 text-sm"><slot/></span>
    </div>
</template>

<script>
export default {
    name: 'TrailingText'
}
</script>