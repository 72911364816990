<template>
    <div class="border-gray-100 relative flex" :class="{'border-b': showContent}">

        <div @click.prevent="togglePanel"
             :class="{'cursor-pointer': clickable}"
             class="flex-shrink-0 p-2 sm:p-3">
            <div class="flex items-center justify-center rounded-full border border-gray-100 p-1 p-2 w-10 h-10 md:p-3 md:w-15 md:h-15">
                <div v-if="loading"
                     class="animate-pulse bg-gray-100 rounded-full h-full w-full"></div>

                <slot v-else name="icon"/>

            </div>
        </div>

        <div @click="togglePanel"
             :class="{'cursor-pointer': clickable}"
             class="flex-1 flex items-center overflow-hidden">
            <div v-if="loading" class="animate-pulse bg-gray-100 h-6 rounded-lg max-w-full w-64"></div>

            <div v-else class="w-full overflow-hidden">
                <form class="w-full"
                      v-if="showContent && nameEditable"
                      @submit.prevent="updateName"
                      @keydown.enter.prevent="updateName">
                    <input v-model="name"
                           maxlength="60"
                           @focus="editing = true"
                           :class="customClasses"
                           @blur="updateName"
                           v-autowidth="{minWidth: '120px', comfortZone: 10}"
                           class="border-dashed border-gray-400 text-base sm:text-lg md:text-2xl font-semibold focus:outline-none placeholder-gray-500 w-full truncate"
                           :placeholder="namePlaceholder"/>
                </form>
                <p v-else
                   class="text-base sm:text-lg md:text-2xl font-semibold truncate">
                    <span v-if="!name.length" class="text-gray-400">{{ namePlaceholder }}</span>
                    <span v-else>{{ name }}</span>
                </p>

            </div>

        </div>


        <div v-if="!loading" class="flex-shrink-0 sm:mr-2 flex items-center">

            <status-badge v-if="statusName" class="mr-2 sm:mr-4" :class="statusClass">
                {{ statusName }}
            </status-badge>

            <filter-badge :badge-name="badgeName" v-bind="$attrs" :badge-count="badgeCount"/>

            <toggle-bullet v-if="showToggle" :form="toggleForm"/>
            <default-dropdown v-if="showMenu">
                <template #trigger>
                    <button-menu size="8" spacing="1"/>
                </template>
                <template #content>
                    <slot name="menu"></slot>
                </template>
            </default-dropdown>
        </div>

    </div>
</template>

<script>
import ToggleBullet from "../../../elements/ToggleBullet"
import DotsMenu from "../../../svgs/menu/DotsMenu"
import AutoWidth from "../../../directives/AutoWidth"
import DefaultDropdown from "../../../elements/DefaultDropdown"
import ButtonMenu from "../../../elements/buttons/ButtonMenu"
import FilterBadge from "../../filters/FilterBadge"
import StatusBadge from "../../../elements/badges/StatusBadge"

export default {
    name: 'FlowPanelHeader',

    inheritAttrs: false,

    components: {
        StatusBadge,
        FilterBadge,
        ButtonMenu,
        DefaultDropdown,
        DotsMenu,
        ToggleBullet
    },

    props: {
        loading: {
            type: Boolean,
        },
        showContent: {
            type: Boolean,
        },
        badgeName: {
            type: String,
        },
        badgeCount: {
            type: Number,
            default: 0,
        },
        showToggle: {
            type: Boolean,
            default: false,
        },
        toggleForm: {
            type: Object,
        },
        path: {
            type: String,
        },
        closePath: {
            type: String,
        },
        nameValue: {
            type: String,
        },
        namePlaceholder: {
            type: String,
        },
        nameEditable: {
            type: Boolean,
            default: true,
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        statusName: {
            type: String,
        },
        statusClass: {
            type: String,
        },
    },

    data() {
        return {
            editing: false,
            name: this.nameValue,
        }
    },

    watch: {
        nameValue(value) {
            this.name = value
        }
    },

    directives: {
        autowidth: AutoWidth,
    },

    created() {
        const self = this;
        window.addEventListener('resize', self.handleResize)
    },

    mounted() {
        this.$nextTick(() => {
            this.handleResize()
        })
    },

    methods: {
        handleResize() {
            if (this.name && this.name.length > 2) {
                this.name = `${this.name} `;
                this.name = this.name.trim();
                return;
            }

            this.name = ' ';
            this.name = '';
        },

        togglePanel(event) {
            if (this.loading || !this.clickable) {
                return true;
            }

            if (event.target.tagName === 'INPUT') {
                return true;
            }

            // if they are clicking out from the input field don't toogle panel
            if (this.editing) {
                return true;
            }

            this.$router.push({path: this.clickPath})
        },

        updateName(event) {
            event.target.blur()
            this.$emit('name-updated', this.name)
            setTimeout(() => {
                this.editing = false
            }, 500)
        },
    },

    computed: {
        customClasses() {
            if (this.name) {
                return 'hover:border-b focus:border-b active:border-b'
            }

            return 'border-b'
        },

        clickPath() {
            return this.showContent ? this.closePath : this.path;
        },

        showMenu() {
            return !!this.$slots['menu'];
        }
    }


}
</script>
