<template>

    <div>

        <div v-if="loading" class="animate-pulse">
            <div class="h-6 bg-gray-100 w-40 rounded-full mb-3"></div>
            <div class="h-12 bg-gray-100 w-full rounded mb-6"></div>
            <div class="h-12 bg-gray-100 w-full rounded"></div>
        </div>
        <div v-else>
            <div v-if="app.user_apps.length > 0">

                <form @submit.prevent="saveSettings"
                      @keydown.enter.prevent="saveSettings">

                    <default-label>Select Application</default-label>

                    <select-field :options="userAppsDropdown"
                                  track-by="id"
                                  label="name"
                                  placeholder="Select App"
                                  v-model="appSettingForm.setting"/>

                    <span class="text-red-600 text-xs"
                          v-show="showDropdownSettingsError"
                          v-text="appSettingForm.errors.get('setting')"></span>

                    <div class="mt-6">
                        <submit-button v-if="!showNewApp"
                                       text="Save"
                                       @save="saveSettings"
                                       loading="Saving..."
                                       switch-at="xl"
                                       :form="appSettingForm"/>
                    </div>

                </form>

            </div>

            <div v-if="showNewApp" class="mt-2">
                <form @submit.prevent="createApp"
                      @keydown.enter.prevent="createApp">

                    <form-error :form="appForm"/>

                    <input-label label="Name">
                        <input-field :form="appForm"
                                     name="name"
                                     help="For your reference only"/>
                    </input-label>

                    <legacy-app-auth-fields :fields="app.auth_fields"
                                            :form="appForm"/>

                    <div class="mt-6">
                        <submit-button
                            text="Save"
                            @save="createApp"
                            loading="Saving..."
                            switch-at="xl"
                            :form="appForm"/>
                    </div>

                </form>

            </div>
        </div>


    </div>

</template>

<script>
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import InputField from "../../../../elements/fields/InputField"
import LegacyAppAuthFields from "../../../../app_auth/partials/LegacyAppAuthFields"
import FormError from "../../../../elements/alerts/FormError"
import SelectField from "../../../../elements/fields/SelectField"
import DefaultLabel from "../../../../elements/DefaultLabel"

export default {
    name: 'LegacyAuthentication',

    components: {
        DefaultLabel,
        SelectField,
        FormError,
        LegacyAppAuthFields,
        InputField,
        InputLabel,
        SubmitButton
    },

    props: {
        action: {
            type: Object,
        },

        redirect: {
            type: String,
        }
    },

    data() {
        return {
            appSettingForm: new ParseyForm({
                setting: null,
            }),

            appForm: new ParseyForm({
                name: '',
                key: this.action.app.key,
            })
        }
    },

    created() {
        if (this.action.auth) {
            this.appSettingForm.setting = {
                id: this.action.auth.id,
                name: this.action.auth.name
            }
        }
    },

    computed: {
        apps() {
            return this.$store.state.apps.legacy;
        },

        loading() {
            return this.$store.state.apps.loading;
        },

        app() {
            return _.get(this.$store.state.apps, `legacy.${this.action.app.key}`);
        },

        showNewApp() {
            if (this.app.user_apps.length === 0) {
                return true;
            }

            if (_.get(this.appSettingForm, 'setting.id') === 0) {
                return true;
            }

            return false;
        },

        userAppsDropdown() {
            let list = _.sortBy(_.clone(_.get(this.app, 'user_apps')), 'name');

            list.push({
                id: 0,
                name: "Create New"
            })

            return list;
        },

        showDropdownSettingsError() {
            if (this.appSettingForm.errors.has('setting') && !_.get(this.appSettingForm, 'setting.id')) {
                return true;
            }

            return false;
        }
    },

    methods: {

        saveSettings() {
            if (!_.get(this.appSettingForm, 'setting.id')) {
                this.appSettingForm.errors.set({setting: ['Please select an existing application or create a new one']})
                return
            }
            this.appSettingForm.setting_id = this.appSettingForm.setting.id;
            Parsey.put(this.action.routes.update, this.appSettingForm)
                .then(({action}) => {
                    this.$store.commit('flow/setAction', action)
                    this.$router.push({path: this.redirect})
                })
        },

        createApp() {
            this.$store.dispatch('apps/createLegacyApp', this.appForm)
                .then(appSetting => {
                    this.appSettingForm.setting = {
                        id: appSetting.id,
                        name: appSetting.name
                    }
                    this.saveSettings();
                })
        },

    }

}
</script>

