<template>

    <div>

        <tab-header>
            Magic Fields
            <template #sub>
                Magic fields allow you to format and otherwise modify a field's value before being
                added to your system.
            </template>
        </tab-header>

        <map-fields-grid class="hidden md:grid" :columns="3">
            <div class="block">
                <p class="text-sm font-medium">Fields & Available Data</p>
            </div>
            <div class="block">
                <p class="text-sm font-medium">Magic Method</p>
            </div>
            <div class="flex items-center">
                <p class="text-sm font-medium">{{ name }} Fields</p>
                <refresh-button @refresh="refreshFields" :refreshing="loading || refreshing"/>
            </div>
        </map-fields-grid>

        <map-fields-grid v-for="(field, index) in fieldMap" :key="index" :columns="3">

            <div>

                <horizontal-line class="md:hidden" v-if="fieldMapSize > 1"/>

                <default-label class="md:hidden">
                    Fields
                </default-label>
                <select-field :options="parserFields"
                              v-model="fieldMap[index].your_field"
                              @input="updateField"
                              label="label"
                              group-values="fields"
                              group-label="group"
                              placeholder="Choose your field"
                              track-by="name"/>

            </div>

            <div>
                <default-label class="md:hidden">
                    Method
                </default-label>
                <div v-if="loadingMagicMethods"
                     class="flex-1 animate-pulse">
                    <div class="w-full h-input rounded-lg bg-gray-100"></div>
                </div>
                <div v-else>
                    <select-field :options="magicMethods"
                                  v-model="fieldMap[index].magic"
                                  @input="updateField"
                                  label="label"
                                  group-values="fields"
                                  group-label="group"
                                  placeholder="Choose magic method"
                                  track-by="name"/>
                </div>

            </div>

            <div>
                <default-label class="md:hidden">
                    {{ name }} Fields
                </default-label>

                <div class="flex items-center">
                    <div v-if="loading"
                         class="flex-1 animate-pulse">
                        <div class="w-full h-input rounded-lg bg-gray-100"></div>
                    </div>
                    <div v-else class="flex-1 min-w-0">
                        <select-field :options="appFields"
                                      v-model="fieldMap[index].field"
                                      @input="updateField"
                                      :loading="refreshing"
                                      label="label"
                                      group-values="fields"
                                      group-label="group"
                                      :placeholder="`Choose ${name} field`"
                                      track-by="name"/>
                    </div>
                    <div class="flex-shrink-0 ml-4">
                        <div class="h-6 w-6 text-gray-400 cursor-pointer"
                             @click="deleteField(index)">
                            <trash-icon/>
                        </div>
                    </div>
                </div>
            </div>
        </map-fields-grid>

        <p class="text-sm">
            <a class="text-gray-300  cursor-pointer" @click.prevent="addField">
                + Add another magic field
            </a>
        </p>


    </div>

</template>

<script>
import RefreshButton from "../../../../elements/buttons/RefreshButton"
import MapFieldsGrid from "../../../../elements/tables/MapFieldsGrid"
import TrashIcon from "../../../../svgs/general/TrashIcon"
import RightArrowIcon from "../../../../svgs/general/RightArrowIcon"
import SelectField from "../../../../elements/fields/SelectField"
import InputField from "../../../../elements/fields/InputField"
import TabHeader from "../../../../elements/tabs/TabHeader"
import DefaultLabel from "../../../../elements/DefaultLabel"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {

    name: 'LegacyMagicFields',

    components: {
        HorizontalLine,
        DefaultLabel,
        TabHeader,
        InputField,
        SelectField,
        RightArrowIcon,
        TrashIcon,
        MapFieldsGrid,
        RefreshButton
    },

    props: {

        name: {
            type: String,
            default: 'App'
        },

        refreshing: {
            type: Boolean,
            default: false,
        },

        fields: {
            type: Object,
        },

        appFields: {
            type: Array,
            default: () => {
                return []
            }
        },

        magicFields: {}
    },

    data() {
        return {
            fieldMap: null,
        }
    },

    watch: {
        appFields() {
            this.updateMap()
        },

        magicMethods() {
            this.updateMap()
        }
    },

    created() {
        this.updateMap()
        this.$store.dispatch('loadMagicMethods')
    },

    methods: {
        updateMap() {
            if (_.isEmpty(this.magicFields)) {
                return this.resetFieldMap()
            }

            this.fieldMap = _.mapValues(this.magicFields, magicField => {
                return {
                    field: this.fieldFromGrouped(this.appFields, magicField.field, 'name'),
                    magic: this.fieldFromGrouped(this.magicMethods, magicField.magic, 'name'),
                    your_field: this.fieldFromGrouped(this.parserFields, magicField.your_field, 'name')
                }
            })
        },

        resetFieldMap() {
            return this.fieldMap = this.defaultField();
        },

        defaultField() {
            let time = Date.now();
            let defaultMagicMethod = {
                label: 'Just import as is to this field',
                name: 'none'
            }
            return {[time]: {field: '', magic: defaultMagicMethod, your_field: ''}};
        },

        fieldFromGrouped(fields, fieldNameValue, fieldNameKey) {
            let value = null;

            _.find(fields, field => {
                let foundField = _.find(field.fields, groupField => {
                    return groupField[fieldNameKey] == fieldNameValue;
                })
                if (foundField) {
                    value = foundField;
                    return true;
                }

                return false;
            })

            return value
        },

        updateField() {
            let index = 0;
            let fields = _(this.fieldMap)
                .pickBy(field => {
                    return _.get(field, 'field.name') && _.get(field, 'magic.name') && _.get(field, 'your_field.name')
                })
                .mapValues(field => {
                    return {
                        field: field.field.name,
                        your_field: field.your_field.name,
                        magic: field.magic.name,
                    }
                })
                .mapKeys(() => {
                    index++;
                    return index;
                })

            this.$emit('update', fields.value())
        },

        addField() {
            this.fieldMap = {...this.fieldMap, ...this.defaultField()}
        },

        deleteField(index) {
            delete this.fieldMap[index]
            this.updateField()
            if (_.size(this.fieldMap) === 0) {
                return this.resetFieldMap()
            }

        },

        refreshFields() {
            this.$emit('refresh')
        }
    },


    computed: {
        loading() {
            if (!this.appFields) {
                return true;
            }

            return _.size(this.appFields) === 0;
        },

        customValuesFields() {
            return _.map(this.fields, field => {
                return {
                    id: field.id,
                    shortcode: `{{${field.label}}`,
                    label: field.label,
                }
            })
        },

        magicMethods() {
            let methods = this.$store.state.flowOptions.legacy.magicMethods;
            if (_.size(methods) === 0) {
                return methods;
            }

            return _(methods)
                .map(method => {
                    return {
                        label: method.name,
                        name: method.key,
                        group: method.group,
                    }
                })
                .groupBy('group')
                .map((fields, group) => {
                    return {
                        group: group,
                        fields: fields,
                    }
                })
                .value()
        },

        loadingMagicMethods() {
            return _.size(this.magicMethods) === 0;
        },

        fieldMapSize() {
            return _.size(this.fieldMap);
        },

        parserFields() {
            let extractedFields = _.map(this.fields, field => {
                return {
                    label: field.label,
                    name: field.id,
                }
            })

            let fieldData = [];
            switch (this.$store.state.flow.data.type) {
                case 'attachment':
                case 'email':
                    fieldData = [
                        {to: 'To Email Address'},
                        {to_name: 'To Name (If available)'},
                        {from_name: 'From Name (Not Email)'},
                        {from_address: 'From Email Address'},
                        {reply_to: 'Reply To Address'},
                        {reply_name: 'Reply Name (If available)'},
                        {subject: 'Email Subject'},
                        {body_plain: 'Plain Text Body'},
                        {body_html: 'HTML Body (If available)'},
                        {sent_at: 'Date Email was sent'},
                    ]
                    break;
                case 'webhook':
                default:
                    fieldData = [
                        {received_at: 'Date Received Webhook'},
                        {request_data: 'Webhook Data (Serialized)'},
                    ];
                    break;

            }

            let parserFields = _.map(fieldData, (obj) => {
                let key = Object.keys(obj)[0]
                return {
                    label: obj[key],
                    name: key,
                }
            })

            return [
                {
                    fields: parserFields,
                    group: 'Source Fields',
                },
                {
                    fields: extractedFields,
                    group: 'Trigger Fields'
                }
            ]
        }
    }

}
</script>
