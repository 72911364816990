<template>
    <div>
        <tab-header>
            ClickBank Settings
            <template #sub>
                Configure your ClickBank settings below
            </template>
        </tab-header>

        <input-label class="mb-8" label="ClickBank Secret Key">
            <event-input-field class="max-w-xl"
                               v-model="secretKey.value"
                               @update="saveKey"
                               :spinning="secretKey.updating"
                               placeholder="MySecretKey"/>
        </input-label>

        <div class="grid md:grid-cols-2 gap-x-10 gap-y-4">
            <input-label label="Instant Notification URL">
                <input type="hidden" :id="copy.inputId" :value="flow.trigger.address"/>

                <button @click="copyData" v-tooltip="copyTooltip"
                        class="mr-4 btn btn-light-indigo flex items-center w-full">
                    {{ flow.trigger.address }}
                </button>

            </input-label>

            <input-label label="Version">
                <select-field :options="dropdowns.version.options"
                              v-model="dropdowns.version.selected"
                              :allow-empty="false"
                              @input="saveVersion"
                              :loading="dropdowns.version.updating"
                              label="label"
                              track-by="value"/>
            </input-label>
        </div>

    </div>
</template>

<script>
import CopyMixin from "../../../../mixins/copy"
import EventHelpers from "../mixins/event-helpers"
import TabHeader from "../../../../elements/tabs/TabHeader"
import FakeInput from "../../../../elements/fields/FakeInput"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import ClipboardIcon from "../../../../svgs/general/ClipboardIcon"
import EventInputField from "../../../../elements/fields/EventInputField"

export default {
    name: 'LegacyClickBankTrigger',

    components: {
        SelectField,
        ClipboardIcon,
        FakeInput,
        InputLabel,
        EventInputField,
        TabHeader
    },

    mixins: [
        CopyMixin,
        EventHelpers
    ],

    data() {
        return {
            settingKeyBase: 'flow.settings',
            copy: {
                inputId: `webhook_url_${this.$route.params.id}`,
            },
            dropdowns: {
                version: {
                    updating: false,
                    selected: {
                        label: 'Version 6.0',
                        value: 6,
                    },
                    options: [
                        {
                            label: 'Version 6.0',
                            value: 6,
                        },
                        {
                            label: 'Version 7.0',
                            value: 7,
                        },
                    ]
                }
            },
            secretKey: {
                db_name: 'clickbank_secret_key',
                updating: false,
                timeout: null,
                value: '',
            },
            form: new ParseyForm({
                name: '',
                value: '',
            })
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.setOptions();
        })
    },

    methods: {
        save() {
            let method = 'post';
            if (_.get(this.flow, `settings.${this.form.name}`)) {
                method = 'put';
            }

            if (this.form.name === this.secretKey.db_name) {
                this.secretKey.updating = true;
            }

            if (this.form.name === 'clickbank_version') {
                this.dropdowns.version.updating = true;
            }

            Parsey[method](this.flow.routes.settings, this.form)
                .then(({settings}) => {
                    this.handleUpdate(settings);
                })

        },

        setOptions() {
            this.secretKey.value = _.get(this.flow, `settings.${this.secretKey.db_name}`);
            if (_.get(this.flow, 'settings.clickbank_version')) {
                this.setDropdownValue('version', 'clickbank_version', true)
            }
        },

        saveVersion() {
            this.form.name = 'clickbank_version';
            this.form.value = _.get(this.dropdowns, 'version.selected.value');
            this.save();
        },

        saveKey() {

            if (this.secretKey.timeout) {
                clearTimeout(this.secretKey.timeout)
            }

            this.secretKey.timeout = setTimeout(() => {
                this.form.name = this.secretKey.db_name;
                this.form.value = this.secretKey.value;
                this.save();
            }, 1000);
        },

        handleUpdate(settings) {
            this.$store.commit('flow/setFlowSettings', settings)
            this.handleSecretKey(settings);
            this.secretKey.updating = false
            this.dropdowns.version.updating = false
        },

        handleSecretKey(settings) {
            if (this.form.name === this.secretKey.db_name) {
                this.secretKey.updating = false
                if (this.secretKey.value === _.get(settings, this.secretKey.db_name)) {
                    this.secretKey.timeout = null;
                }
            }
        },
    },

    computed: {
        flow() {
            return this.$store.state.flow.data;
        },
    }
}
</script>