<template>
    <div class="pt-4 px-2 sm:px-4 mb-2">

        <p v-for="header in headers" :key="header.name"
           class="text-sm text-gray-700 pb-1">
            <span class="font-semibold" v-text="header.name"></span>
            <span class="font-light" v-text="header.value"></span>
        </p>

        <horizontal-line/>

    </div>
</template>

<script>
import dayjs from "dayjs"
import {mapState} from "vuex"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {
    name: 'LegacyEmailContentHeaders',

    components: {
        HorizontalLine
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        sentAt() {
            let sentAt = _.get(this.flow, 'trigger.data.email.sent_at');
            if (!sentAt) {
                return ''
            }

            return dayjs(sentAt).format('DD MMM YYYY, H:mm A EST')
        },

        headers() {
            return [
                {
                    name: 'Sent at: ',
                    value: this.sentAt
                }
            ]
        },
    }
}
</script>
