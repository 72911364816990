<template>
    <div class="relative">
        <div @click="toggleButton"
             class="flex items-center justify-center cursor-pointer">
            <slot name="trigger"></slot>
        </div>

        <!-- Full Screen Dropdown Overlay -->
        <div v-show="open" class="fixed inset-0 z-20"
             @click="close"></div>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <div v-show="open"
                 class="absolute z-30 mt-2 border border-gray-50 rounded-md shadow-lg max-h-96 overflow-scroll"
                 :class="[widthClass, alignmentClasses]">
                <div class="rounded-md shadow-xs" :class="contentClasses">
                    <slot name="content"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {

    props: {
        align: {
            default: 'right'
        },
        direction: {
            default: 'down'
        },
        offset: {
            default: 0
        },
        width: {
            default: '48'
        },
        contentClasses: {
            default: () => ['py-1', 'bg-white']
        },
        buttonWidth: {
            default: 8,
        },
    },

    data() {
        return {
            open: false,
        }
    },

    created() {
        const closeOnEscape = (e) => {
            if (this.open && e.keyCode === 27) {
                this.open = false
            }
        }

        // this.$once('hook:destroyed', () => {
        //     document.removeEventListener('keydown', closeOnEscape)
        // })

        document.addEventListener('keydown', closeOnEscape)
    },

    methods: {
        toggleButton() {
            if (this.open) {
                return this.close();
            }

            this.open = true;
            this.$emit('opened')
        },

        close() {
            this.$emit('closed')
            this.open = false
        }
    },

    computed: {
        widthClass() {
            return {
                48: 'w-48',
                56: 'w-56',
                60: 'w-60',
                64: 'w-64',
                80: 'w-80',
            }[this.width.toString()]
        },

        alignmentClasses() {
            let classes = [];

            switch (this.align) {
                case 'left':
                    classes.push('origin-top-left left-0')
                    break;
                case 'right':
                    classes.push('origin-top-right right-0')
                    break;
                case 'center':
                    classes.push('origin-center left-0')
                    break;
                default:
                    classes.push('origin-top')
            }

            if(this.align === 'center') {
                const half = parseInt((this.width / 2)) - parseInt((this.buttonWidth / 2));
                /**
                 * -left-26
                 */
                classes.push(`-left-${half}`)
            }

            switch (this.direction) {
                case 'up':
                    classes.push('bottom-0')
                    break;
            }

            if (this.offset > 0 && this.align === 'left') {
                classes.push(`ml-${this.offset}`)
            }

            if (this.offset > 0 && this.align === 'right') {
                classes.push(`mr-${this.offset}`)
            }

            return classes.join(' ');
        },
    }

}
</script>
