import DefaultLayout from "../components/layouts/DefaultLayout"
import RouterMultiGuard from "vue-router-multiguard"
import {registered} from "../middlewares"
import DashboardIndex from "../components/views/dashboard/DashboardIndex"
import flowRoutes from './flows'
import historyRoutes from './history'
import settingRoutes from './settings'

let routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Dashboard | Parsey',
            layout: DefaultLayout,
        },
        components: {
            default: DashboardIndex,
        }
    },
    ...flowRoutes,
    ...historyRoutes,
    ...settingRoutes,
]

routes = routes.map(route => {
    route.components = {
        ...route.components,
    }

    let middlewares = [
        registered,
    ];

    if (route.meta.middlewares) {
        route.meta.middlewares.forEach(middleware => middlewares.push(middleware))
    }

    route.beforeEnter = RouterMultiGuard(middlewares)

    return route
})


export default routes;
