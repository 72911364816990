<template>
    <div>
        <div v-if="badgeCount > 0" class="mr-2 sm:mr-4">
            <div class="hidden sm:block">
                <numbered-badge v-if="badgeCount > 0"
                                :name="badgeName"
                                @clicked="badgeClicked"
                                color="blue"
                                :number="badgeCount"/>
            </div>
            <div @click="badgeClicked"
                 class="sm:hidden w-9 h-9 p-2 text-indigo-300 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                </svg>
            </div>
        </div>
    </div>

</template>

<script>
import NumberedBadge from "../../elements/badges/NumberedBadge"

export default {
    name: 'FilterBadge',

    components: {
        NumberedBadge
    },

    props: {
        badgeName: {
            type: String,
        },
        badgeCount: {
            type: Number,
            default: 0,
        },
    },

    methods: {
        badgeClicked() {
            this.$emit('badge-clicked')
        },
    }
}
</script>
