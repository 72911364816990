// Load Misc libraries
require('./bootstrap')

// Load Vue App
import {createApp} from 'vue';
import router from './router';
import store from './store/index';

import Login from "./components/auth/Login"
import Register from './components/auth/Register'

// import VueMeta from 'vue-meta';
import VTooltip from 'v-tooltip';
import App from './components/App';

const authApp = {
    components: {
        App,
        Login,
        Register,
    }
}

const vueApp = createApp(authApp)

vueApp.config.unwrapInjectedRef = true

vueApp.use(router)
    .use(store)
    .use(VTooltip)
    .mount('#parsey')
