module.exports = {

    data() {
        return {
            copy: {
                inputId: '',
                copied: false,
                text: 'Click to copy',
                placement: 'bottom',
            }
        }
    },

    computed: {
        copyTooltip() {
            let content = this.copy.text
            if (this.copy.copied) {
                content = 'Copied!'
            }

            return {
                content: content,
                hideOnTargetClick: false,
                placement: this.copy.placement,
                delay: {show: 500, hide: 100}
            }
        }
    },

    methods: {
        copyData() {
            let inputField = document.getElementById(this.copy.inputId)
            inputField.setAttribute('type', 'text')
            inputField.select()

            try {
                document.execCommand('copy');
                this.copy.copied = true
            } catch (err) {
                // Show error
            }

            inputField.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()

            setTimeout(() => {
                this.copy.copied = false
            }, 5000);
        }
    }
}
