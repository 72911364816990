<template>
    <form @submit.prevent="save">

        <event-sub-text>
            Sub Text
        </event-sub-text>

        <form-error :form="form"/>

        <input-label label="Webhook URL" class="mb-8">
            <event-input-field v-model="form.settings.webhookURL"
                               placeholder="https://mywebsite.com/webhook_url"/>
        </input-label>


        <p class="font-medium mb-3">Webhook Data</p>

        <div class="grid sm:grid-cols-5 gap-4">

            <div class="hidden sm:grid col-span-2">
                <default-label>
                    Data Key
                </default-label>
            </div>
            <div class="hidden sm:grid col-span-3">
                <default-label>
                    Data Value
                </default-label>
            </div>
        </div>


        <div v-for="(data, index) in form.settings.webhookPairs"
             class="grid sm:grid-cols-5 gap-4 mb-4">

            <div class="col-span-5 sm:col-span-2">
                <default-label class="sm:hidden">
                    Data Key
                </default-label>
                <event-input-field v-model="form.settings.webhookPairs[index].name"
                                   placeholder="first name"/>
            </div>
            <div class="col-span-5 sm:col-span-3 ">
                <default-label class="sm:hidden">
                    Data Value
                </default-label>
                <div class="flex items-center">
                    <event-input-field v-model="form.settings.webhookPairs[index].value"
                                       class="flex-1 min-w-0"
                                       placeholder="John"/>

                    <trash-button @click="deleteRow(index)" class="ml-4 flex-shrink-0"/>
                </div>

            </div>

            <horizontal-line class="col-span-5 sm:hidden"/>
        </div>

        <p class="text-sm">
            <a class="text-gray-300 cursor-pointer"
               @click.prevent="addWebhookData">
                + Add another
            </a>
        </p>


        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventSubText from "../elments/EventSubText"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"
import DefaultLabel from "../../../../elements/DefaultLabel"
import TrashButton from "../../../../elements/buttons/TrashButton"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {
    name: 'SendWebhook',

    components: {
        HorizontalLine,
        TrashButton,
        DefaultLabel,
        EventInputField,
        InputLabel,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            webhookData: [],
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    webhookURL: null,
                    webhookPairs: [
                        {
                            name: 'InfusionsoftContactId',
                            value: '{{Infusionsoft_Contact_Id}}'
                        },
                    ],
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        deleteRow(index) {
            this.$delete(this.form.settings.webhookPairs, index)
            if (_.size(this.form.settings.webhookPairs) === 0) {
                return this.resetData();
            }
        },

        resetData() {
            this.form.settings.webhookPairs = [{
                name: '',
                value: '',
            }]
        },

        addWebhookData() {
            this.form.settings.webhookPairs.push({
                name: '',
                value: '',
            })
        },

    },

    computed: {}
}
</script>

