<template>
    <div class="flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8"
         style="min-width: 250px">

        <div class="h-16">
            <parsey-logo />
        </div>

        <login-form></login-form>

        <div class="text-center mt-8">
            <p class="text-sm text-gray-500">
                Don't have an account?
                <a href="/register"
                   class="text-orange-600 hover:text-orange-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Sign up
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import LoginForm from "./LoginForm"
import ParseyLogo from "../svgs/ParseyLogo"

export default {
    name: "Login",

    components: {
        ParseyLogo,
        LoginForm
    }
}
</script>
