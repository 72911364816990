<template>

    <div>
        <event-sub-text>
            ClickBank Sub text
        </event-sub-text>

        <form-error :form="form"/>

        <form @submit.prevent="save">
            <div class="grid md:grid-cols-2 gap-x-8 gap-x-10">
                <input-label label="Save Order Details Inside Keap">
                    <select-field :options="dropdowns.saveOrder.options"
                                  v-model="dropdowns.saveOrder.selected"
                                  @input="updateFromDropDownValue('create_order', 'saveOrder')"
                                  label="label"
                                  track-by="value"/>
                </input-label>

                <input-label v-if="saveOrder"
                             label="Order Title">

                    <event-input-field v-model="form.settings.order_title"
                                       placeholder="ClickBank Order"/>

                </input-label>

            </div>

            <div v-if="saveOrder">

                <div class="grid md:grid-cols-2 gap-x-8 gap-x-10">
                    <input-label label="Save Tax line item inside Keap">

                        <select-field :options="dropdowns.tax.options"
                                      v-model="dropdowns.tax.selected"
                                      @input="updateFromDropDownValue('record_tax', 'tax')"
                                      label="label"
                                      track-by="value"/>

                    </input-label>

                    <input-label label="Save Shipping line item inside Keap">

                        <select-field :options="dropdowns.shipping.options"
                                      v-model="dropdowns.shipping.selected"
                                      @input="updateFromDropDownValue('record_shipping', 'shipping')"
                                      label="label"
                                      track-by="value"/>

                    </input-label>
                </div>

            </div>

        </form>

        <tab-pills>

            <tab-pill name="Products" :selected="true">

                <click-bank-products :event="event" :action="action"/>

            </tab-pill>

            <tab-pill :available="saveOrder" name="Custom Fields">
                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="orderFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Order"
                                      :refreshing="loading.refreshing"/>
            </tab-pill>

            <tab-pill :available="saveOrder" name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="orderFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Order"
                                     :refreshing="loading.refreshing"/>
            </tab-pill>
        </tab-pills>

        <form @submit.prevent="save">
            <legacy-event-save-button :event="event" :form="form"/>
        </form>
    </div>

</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import EventSubText from "../elments/EventSubText"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"
import EventHelpers from "../mixins/event-helpers"
import EventInputField from "../../../../elements/fields/EventInputField"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import TabHeader from "../../../../elements/tabs/TabHeader"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import RoundIconButton from "../../../../elements/buttons/RoundIconButton"
import PlusIcon from "../../../../svgs/menu/PlusIcon"
import ClickBankProducts from "../partials/ClickBankProducts"

export default {
    // Don't capitalize B since it's a key that matches the db
    name: 'Clickbank',

    components: {
        ClickBankProducts,
        PlusIcon,
        RoundIconButton,
        LegacyMagicFields,
        LegacyCustomFields,
        TabHeader,
        TabPill,
        TabPills,
        EventInputField,
        SelectField,
        InputLabel,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            loading: {
                refreshing: true,
            },
            dropdowns: {
                saveOrder: {
                    selected: null,
                    options: [
                        {
                            label: 'Yes, save order details within Keap',
                            value: '1',
                        },
                        {
                            label: 'No, I only want to run the actions',
                            value: '0',
                        },
                    ],
                },
                tax: {
                    selected: null,
                    options: [
                        {
                            label: 'Yes',
                            value: '1',
                        },
                        {
                            label: 'No',
                            value: '0',
                        },
                    ],
                },
                shipping: {
                    selected: null,
                    options: [
                        {
                            label: 'Yes',
                            value: '1',
                        },
                        {
                            label: 'No',
                            value: '0',
                        },
                    ],
                },
            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    custom_fields: {},
                    magic_fields: {},
                    create_order: '0',
                    order_title: '',
                    record_tax: '1',
                    record_shipping: '1',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.setDropdownValue('saveOrder', 'create_order', true)
        this.setDropdownValue('tax', 'record_tax', true)
        this.setDropdownValue('shipping', 'record_shipping', true)

        this.getOrderFields();
    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getOrderFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        refreshFields() {
            this.loading.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'order'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {

        saveOrder() {
            return parseInt(this.form.settings.create_order) === 1;
        },

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        orderFields() {
            if (!_.get(this.action, 'fields.order')) {
                return [];
            }

            let fields = this.action.fields.order;

            return _.reject(fields, group => group.group === 'Special Field Options');
        },

    }
}
</script>

