import {createStore} from 'vuex'
import flows from './modules/flows'
import flow from './modules/flow'
import apps from './modules/apps'
import filters from './modules/filters'
import history from './modules/history'

const store = createStore({

    state: {
        user: ParseyState.user,
        routes: ParseyState.routes,
        config: ParseyState.config,
        menuOpen: false,
        page: 'Parsey',
        flowOptions: {
            legacy: {
                magicMethods: {},
                timezone: {
                    loading: false,
                    options: [],
                },
            }
        },
        notifications: {},
    },

    modules: {
        apps,
        flows,
        flow,
        filters,
        history,
    },

    mutations: {
        updateUser(state, user) {
            state.user = user;
        },

        openMenu(state) {
            state.menuOpen = true;
        },

        closeMenu(state) {
            state.menuOpen = false;
        },

        setPage(state, page) {
            state.page = page
        },

        setMagicMethods(state, methods) {
            state.flowOptions.legacy.magicMethods = methods
        },

        setLegacyTimezonesLoading(state, status) {
            state.flowOptions.legacy.timezone.loading = status
        },

        setLegacyTimezones(state, timezones) {
            state.flowOptions.legacy.timezone.loading = false
            state.flowOptions.legacy.timezone.options = timezones
        },

        pushNotification(state, payload) {
            let notification = {
                show: false,
                message: payload.message,
                type: 'default',
            }
            if (payload.type) {
                notification.type = payload.type;
            }

            if (payload.title) {
                notification.title = payload.title;
            }

            let time = Date.now();
            state.notifications = {
                ...state.notifications,
                [time]: notification,
            }

            setTimeout(() => {
                state.notifications[time].show = true;
            }, 500)

        },

        closeNotification(state, payload) {
            state.notifications[payload.id].show = false;
        }
    },

    getters: {},

    actions: {

        changePage({commit}, page) {
            if (!page) {
                return
            }
            let pieces = page.split('|');
            let last = _.last(pieces)
            commit('setPage', last)
        },

        updateMeta({commit, state}, payload) {
            Parsey.post(state.user.routes.meta, payload.form)
                .then(({user}) => {
                    commit('updateUser', user)
                })
        },

        loadMagicMethods({commit, state, rootState}) {
            if (_.size(state.flowOptions.legacy.magicMethods) > 0) {
                return true //Already loaded
            }

            Parsey.get(rootState.routes.flows.legacy.magic_methods)
                .then(({methods}) => {
                    commit('setMagicMethods', methods)
                })
        },

        loadLegacyTimezones({commit, state, rootState}) {
            commit('setLegacyTimezonesLoading', true);
            if (_.size(state.flowOptions.legacy.timezones) > 0) {
                commit('setLegacyTimezonesLoading', false);
                return new Promise((resolve) => {
                    resolve(state.flowOptions.legacy.timezone);
                })
            }

            return new Promise((resolve, reject) => {
                Parsey.get(rootState.routes.flows.legacy.timezones)
                    .then(({timezones}) => {
                        commit('setLegacyTimezones', timezones)
                        resolve(state.flowOptions.legacy.timezone)
                    }).catch(error => reject(error))
            });
        },

        handleFormErrors({commit}, payload) {
            let notification = {
                type: 'error',
                title: 'Error',
                message: 'Sorry an error happened try again',
            }
            if (payload.title) {
                notification.title = payload.title
            }
            if (payload.errors && payload.errors[payload.errorName]) {
                notification.message = payload.errors[payload.errorName][0]
            }
            commit('pushNotification', notification)
        },


    }

})

export default store