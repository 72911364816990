<template>
    <multiselect :class="{'grouped': groupLabel}"
                 v-bind="allBindings"
                 :options="options"
                 :block-keys="['Tab']"
                 :show-labels="false">

        <template #noResult>
            {{ noResult }}
        </template>

    </multiselect>
</template>

<script>
import Multiselect from "./vue-multiselect/Multiselect"
import MultiselectMixin from "vue-multiselect/src/multiselectMixin"
import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'SelectField',

    components: {
        SpinIcon,
        Multiselect
    },

    props: {
        ...MultiselectMixin.props,

        noResult: {
            type: String,
            default: 'No elements found. Consider changing the search query.',
        }
    },

    computed: {
        allBindings() {
            return {...this.$props, ...this.$attrs}
        }
    },

}
</script>
