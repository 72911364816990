<template>
    <li class="relative pb-10">

        <div v-if="!lastStep"
             :class="lineColor"
             class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full"></div>

        <div class="relative flex items-start space-x-3 group focus:outline-none">
            <step-circle :icon="step.icon" :status="step.status"/>
            <div class="truncate">
                <router-link :to="{path: step.path}"
                             class="text-sm leading-6 block font-semibold uppercase truncate tracking-wider">
                    {{ step.name }}
                </router-link>
                <router-link v-for="sub in step.steps"
                             :key="sub.id"
                             :to="{path: sub.path}"
                             class="text-sm leading-5 block text-gray-400 truncate">
                    {{ sub.name }}
                </router-link>
            </div>
        </div>

    </li>
</template>

<script>
import StepCircle from "./StepCircle"

export default {
    name: 'VerticalStep',

    components: {
        StepCircle
    },

    props: {
        previousStep: {
            type: Object,
        },

        lastStep: {
            type: Boolean,
            default: true,
        },

        lineActive: {
            type: Boolean,
            default: false,
        },

        step: {
            default: {
                status: 'incomplete',
                name: 'Step 1',
                path: '',
                steps: [],
            }
        }
    },

    computed: {
        lineColor() {
            if (this.lineActive) {
                return 'bg-green-200'
            }

            return 'bg-gray-100'
        }
    }
}
</script>
