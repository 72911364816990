import RenameFlowModal from "../RenameFlowModal"
import FlowsMenu from "../partials/FlowsMenu"
import ToggleBullet from "../../../../elements/ToggleBullet"
import EllipsisMiddleText from "../../../../elements/EllipsisMiddleText"

export const flowIndexMixin = {

    components: {
        RenameFlowModal,
        FlowsMenu,
        ToggleBullet,
        EllipsisMiddleText,
    },

    props: {
        flow: {
            type: Object
        },
        showMenu: {
            default: true,
        }
    },

    data() {
        return {
            showRenameForm: false,
            showDeleteForm: false,
            toggleForm: new ParseyForm({
                dispatch: {
                    on: 'flows/activate',
                    off: 'flows/inactivate',
                },
                on: this.flow.active,
                pathOn: this.flow.routes.activate,
                pathOff: this.flow.routes.inactivate,
            }),
        }
    },

    watch: {
        'flow.active'(){
            this.toggleForm.on = !!this.flow.active
        }
    },

    methods: {

        editFlow(flow) {
            this.$router.push({path: flow.views.edit});
        },

        flowHistory(flow) {
            this.$router.push({path: flow.views.history})
        },

        activateFlow(flow) {
            this.$store.dispatch("flows/activate", flow)
        },

        inactivateFlow(flow) {
            this.$store.dispatch("flows/inactivate", flow)
        },
    },

    computed: {
        flowName() {
            return this.flow.name ? this.flow.name : 'Unnamed Flow';
        },

        flowNameCss(){
            return this.flow.name ? '' : 'text-gray-400';
        }
    }

}
