<template>

    <flow-panel :loading="loading"
                :show-content="show"
                :name-value="action.name"
                :continued="true"
                :close-path="closePath"
                :path="action.views.edit"
                :continue-on="continueOn"
                @name-updated="saveActionName"
                @add-action="$store.dispatch('flow/addLegacyAction')"
                :show-toggle="action.configured"
                :toggle-form="toggleForm"
                badge-name="Filter"
                :badge-count="action.filters"
                @badge-clicked="openFilter"
                name-placeholder="Name your action">

        <template #icon>
            <div v-html="action.app.icon"></div>
        </template>

        <template #content>

            <div v-if="appsLoading" class="animate-pulse">
                <div v-for="row in [1,2,3]" :key="row"
                     class="flex items-center cursor-pointer py-4 border-b border-gray-100">
                    <div class="h-5 w-64 bg-gray-100 rounded-lg ml-12"></div>
                </div>
                <div class="h-14"></div>
            </div>

            <legacy-events v-else :action="action"/>

        </template>

        <template #menu>
            <dropdown-link :to="{path: action.views.edit}">
                Edit
            </dropdown-link>

            <form @submit.prevent="openFilter">
                <dropdown-link as="button">
                    Filters
                </dropdown-link>
            </form>

            <horizontal-line />

            <form @submit.prevent="deleteAction">
                <dropdown-link as="button" :close-on-click="false">
                    <span v-if="confirmDelete === action.id" class="text-red-700">Click to Confirm</span>
                    <span v-else-if="deleting === action.id" class="text-red-700">Deleting...</span>
                    <span v-else>Delete</span>
                </dropdown-link>
            </form>
        </template>

    </flow-panel>
</template>

<script>
import FlowSteps from "../components/FlowStep"
import LegacyAuthentication from "./partials/LegacyAuthentication"
import LegacyAddEvents from "./LegacyAddEvents"
import DropdownLink from "../../../elements/DropdownLink"
import LegacyEvent from "./LegacyEvent"
import {mapActions, mapState} from "vuex"
import FlowPanel from "../components/FlowPanel"
import LegacyEvents from "./LegacyEvents"
import router from "../../../../router"
import HorizontalLine from "../../../elements/HorizontalLine"

export default {
    name: 'LegacyAction',

    components: {
        HorizontalLine,
        LegacyEvents,
        FlowPanel,
        LegacyEvent,
        DropdownLink,
        LegacyAddEvents,
        LegacyAuthentication,
        FlowSteps,
    },

    mixins: [
        require('./mixins/delete-items')
    ],

    props: {
        action: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            nameForm: new ParseyForm({
                name: '',
            }),
            toggleForm: new ParseyForm({
                dispatch: {
                    on: 'flow/activateLegacyAction',
                    off: 'flow/inactivateLegacyAction',
                },
                on: this.action.active,
                actionId: parseInt(this.action.id),
            }),
        }
    },

    watch: {
        'action.active'() {
            this.toggleForm.on = !!this.action.active
        }
    },

    methods: {

        saveActionName(name) {
            this.nameForm.name = name;
            Parsey.put(this.action.routes.update, this.nameForm)
                .then(({action}) => {
                    this.$store.commit('flow/setActionName', action)
                })
        },

        deleteAction() {
            this.deleteItem(this.action.id, 'flow/deleteLegacyAction', {
                action: this.action,
                path: this.closePath,
            })
        },

        openFilter() {
            this.$store.dispatch('filters/openFilter', {
                type: 'actions',
                typeId: this.action.id,
                property: {
                    type: 'action',
                    name: this.action.name,
                },
                filters: this.action.routes.filters.index,
                options: this.action.routes.filters.options,
                count: this.action.filters,
            })
        }
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
            loading: state => state.loading,
        }),

        ...mapState('apps', {
            appsLoading: state => state.loading,
        }),

        closePath() {
            return _.get(this.flow, 'views.edit');
        },

        show(){
            if (_.get(this.$route, 'params.panel') !== 'actions') {
                return false;
            }

            return parseInt(_.get(this.$route, 'params.panelId')) === parseInt(this.action.id);
        },

        continueOn() {
            if (_.get(this.$route, 'params.panelId') === 'new') {
                return false;
            }

            let lastActionId = _.findLastKey(_.get(this.flow, 'actions'));

            return parseInt(lastActionId) === parseInt(this.action.id);
        },
    }

}
</script>
