<template>
    <div class="flex flex-col items-center justify-center">

        <div class="h-10 w-10 mt-12 mb-6 text-gray-400">
            <spin-icon speed="normal"/>
        </div>

        <p class="font-semibold text-lg text-gray-700"
           v-text="waitingMessage"></p>

        <p class="text-gray-400 mb-8">
            Send {{ messageType }} to the {{ locationType }} below to get started...
        </p>

        <button class="btn btn-teal"
                @click.prevent="copy">
            {{ flow.trigger.address }}
        </button>

        <input type="hidden"
               :id="addressId"
               :value="flow.trigger.address"/>

        <p class="text-sm text-gray-300 mt-1 mb-32"
           @click.prevent="copy"
           v-text="copyText"></p>
    </div>

</template>

<script>
import SpinIcon from "../../../../svgs/general/SpinIcon"
import {mapState} from "vuex"

export default {
    name: 'LegacyTriggerWaiting',

    components: {
        SpinIcon
    },

    data() {
        return {
            copied: false,
            checker: null,
            counter: 0,
        }
    },

    mounted() {
        const self = this;
        this.checker = setInterval(() => {
            self.refreshFlow()
        }, 8000)
    },

    beforeDestroy() {
        clearInterval(this.checker)
    },

    methods: {
        refreshFlow() {
            this.counter++;
            switch (this.flow.type) {
                case 'email':
                    Parsey.put(this.flow.routes.update, new ParseyForm({
                        direction: 'first'
                    })).then(({flow}) => {
                        this.$store.commit('flow/setFlow', flow)
                    })
                    break;
                case 'webhook':
                    this.$store.dispatch('flow/getFlow')
                    break;
            }


            if (this.counter > 15) {
                clearInterval(this.checker)
            }
        },

        copy() {
            let inputField = document.querySelector(`#${this.addressId}`)
            inputField.setAttribute('type', 'text')
            inputField.select()

            try {
                document.execCommand('copy');
                this.copied = true
            } catch (err) {
                // Show error
            }

            inputField.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()

            const self = this;
            setTimeout(() => {
                self.copied = false
            }, 5000);
        },
    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        waitingMessage() {
            return `Waiting to receive ${this.messageType}...`;
        },

        messageType() {
            let message = '';
            switch (this.flow.type) {
                case 'webhook':
                    message = 'a webhook';
                    break;
                case 'email':
                    message = 'an email';
                    break;
                case 'attachment':
                    message = 'an email with an attachment';
                    break;
            }

            return message;
        },

        locationType() {
            let message = 'address';
            if (this.flow.type === 'webhook') {
                message = 'url';
            }

            return message;
        },

        copyText() {
            if (this.copied) {
                return 'Copied!'
            }

            return 'Click to copy';
        },

        addressId() {
            return `flowAddress_${this.flow.type}_${this.flow.id}`;
        }
    },

}
</script>
