<template>

    <div>

        <tab-header>
            Custom Fields
            <template #sub>
                Type a custom value that will be used for the field you map to.
            </template>
        </tab-header>

        <map-fields-grid>
            <div class="hidden md:block">
                <p class="text-sm font-medium">Custom Value</p>
            </div>
            <div class="hidden md:flex items-center">
                <p class="text-sm font-medium">{{ name }} Fields</p>
                <refresh-button @refresh="refreshFields" :refreshing="loading || refreshing"/>
            </div>
        </map-fields-grid>

        <map-fields-grid v-for="(field, index) in fieldMap" :key="index">

            <div>
                <horizontal-line class="md:hidden" v-if="fieldMapSize > 1" />
                <default-label class="md:hidden">
                    Custom Value
                </default-label>
                <div class="flex items-center">
                    <div class="flex-1 min-w-0">
                        <event-input-field v-model="fieldMap[index].value"
                                           placeholder="Type you value"/>

                    </div>
                    <div class="flex-shrink-0 ml-4">
                        <div class="h-6 w-6 text-gray-400">
                            <right-arrow-icon/>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <default-label class="md:hidden">
                    {{ name }} Fields
                </default-label>

                <div class="flex items-center">
                    <div v-if="loading"
                         class="flex-1 animate-pulse">
                        <div class="w-full h-input rounded-lg bg-gray-100"></div>
                    </div>
                    <div v-else class="flex-1 min-w-0">

                        <select-field :options="appFields"
                                      v-model="fieldMap[index].field"
                                      @input="updateField"
                                      :loading="refreshing"
                                      label="label"
                                      group-values="fields"
                                      group-label="group"
                                      :placeholder="`Select one ${name} field`"
                                      track-by="name"/>
                    </div>
                    <div class="flex-shrink-0 ml-4">
                        <div class="h-6 w-6 text-gray-400 cursor-pointer"
                             @click="deleteField(index)">
                            <trash-icon/>
                        </div>
                    </div>
                </div>

            </div>


        </map-fields-grid>

        <p class="text-sm">
            <a class="text-gray-300 cursor-pointer" @click.prevent="addField">
                + Add another custom field
            </a>
        </p>


    </div>

</template>

<script>
import RefreshButton from "../../../../elements/buttons/RefreshButton"
import MapFieldsGrid from "../../../../elements/tables/MapFieldsGrid"
import RightArrowIcon from "../../../../svgs/general/RightArrowIcon"
import SelectField from "../../../../elements/fields/SelectField"
import InputField from "../../../../elements/fields/InputField"
import DefaultLabel from "../../../../elements/DefaultLabel"
import TabHeader from "../../../../elements/tabs/TabHeader"
import TrashIcon from "../../../../svgs/general/TrashIcon"
import EventInputField from "../../../../elements/fields/EventInputField"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {

    name: 'LegacyCustomFields',

    components: {
        HorizontalLine,
        EventInputField,
        DefaultLabel,
        TabHeader,
        InputField,
        SelectField,
        RightArrowIcon,
        TrashIcon,
        MapFieldsGrid,
        RefreshButton
    },

    props: {

        name: {
            type: String,
            default: 'App'
        },

        refreshing: {
            type: Boolean,
            default: false,
        },

        fields: {
            type: Object,
        },

        appFields: {
            type: Array,
            default: () => {
                return []
            }
        },

        customFields: {}
    },

    data() {
        return {
            fieldMap: null,
        }
    },

    watch: {
        appFields() {
            this.updateMap()
        },

        customFields() {
            this.updateMap()
        }
    },

    created() {
        this.updateMap()
    },

    methods: {
        updateMap() {
            if (_.isEmpty(this.customFields)) {
                return this.resetFieldMap()
            }

            this.fieldMap = _.mapValues(this.customFields, customField => {
                return {field: this.mapValue(customField.field), value: customField.value}
            })
        },

        resetFieldMap() {
            let time = Date.now();
            return this.fieldMap = {[time]: {field: '', value: ''}}
        },

        mapValue(fieldName) {
            let value = null;

            _.find(this.appFields, appField => {
                let foundField = _.find(appField.fields, groupField => {
                    return groupField.name === fieldName;
                })
                if (foundField) {
                    value = foundField;
                    return true;
                }

                return false;
            })

            return value
        },

        updateField() {
            let index = 0;
            let fields = _(this.fieldMap)
                .pickBy(field => {
                    return _.get(field, 'value') && _.get(field, 'field.name')
                })
                .mapValues(field => {
                    return {
                        field: field.field.name,
                        value: field.value,
                    }
                })
                .mapKeys(() => {
                    index++;
                    return index;
                })

            this.$emit('update', fields.value())
        },

        addField() {
            let time = Date.now();
            this.fieldMap = {...this.fieldMap, [time]: {field: '', value: ''}}
        },

        deleteField(index) {
            delete this.fieldMap[index]
            this.updateField()
            if (_.size(this.fieldMap) === 0) {
                return this.resetFieldMap()
            }

        },

        refreshFields() {
            this.$emit('refresh')
        }
    },


    computed: {
        loading() {
            if (!this.appFields) {
                return true;
            }

            return _.size(this.appFields) === 0;
        },

        fieldMapSize(){
            return _.size(this.fieldMap);
        },

        customValuesFields() {
            return _.map(this.fields, field => {
                return {
                    id: field.id,
                    shortcode: `{{${field.label}}`,
                    label: field.label,
                }
            })
        }
    }

}
</script>
