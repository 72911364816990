<template>
    <default-notification :notification="notification"
                          :notification-id="notificationId"
                          :icon="errorIcon"/>
</template>

<script>
import DefaultNotification from "./DefaultNotification"

export default {
    name: 'ErrorNotification',

    props: {
        notificationId: {
            type: Number,
        },
        notification: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            errorIcon: '<svg class="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
        }
    },

    components: {
        DefaultNotification
    }
}
</script>
