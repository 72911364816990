<template>
    <div :class="gridClass" class="grid gap-4 mb-2 ">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'MapFieldsGrid',

    props: {
        columns: {
            default: 2,
            type: Number,
        }
    },

    computed: {
        gridClass() {
            switch (this.columns) {
                case 3:
                    return 'md:grid-cols-3';
                default:
                    return 'md:grid-cols-2';
            }
        }
    }
}
</script>
