<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" :stroke-width="stroke" d="M19 9l-7 7-7-7" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronDownIcon',

    props: {
        stroke: {
            type: Number,
            default: 2,
        }
    }
}
</script>
