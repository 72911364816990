<template>
    <div @mousedown="clearViewing">

        <div class="grid grid-cols-6 gap-5">
            <div class="col-span-6 md:col-span-4">

                <tab-header>
                    Recent Email

                    <toggle-trigger-buttons :loading="newEmailForm.busy"
                                            @change="getNewEmail"/>

                    <template #sub>
                        Highlight the text you would like extracted and give each one a name.
                    </template>
                </tab-header>

                <tab-pills @change="updateTab">
                    <div v-if="newField.show"
                         class="fixed top-0 left-0 w-full h-full z-20"
                         @click="hideNewField"></div>


                    <div v-if="newField.show"
                         :style="newField.position"
                         class="fixed shadow-lg border border-gray-100 py-3 px-5 bg-white max-w-md rounded-lg z-40">
                        <form @keydown.enter.prevent="createField" @submit.prevent="createField">
                            <form-error :form="newField.form"/>
                            <div class="flex items-start">
                                <event-input-field v-model="newField.form.label"
                                                   :form="newField.form"
                                                   form-key="label"
                                                   class="mr-4"
                                                   :focus="true"
                                                   placeholder="Field label"/>
                                <submit-button :form="newField.form"
                                               text="Save" loading="Saving..."/>
                            </div>
                        </form>

                    </div>


                    <div v-if="highlight.show"
                         class="fixed top-0 left-0 z-30 bg-white font-sans whitespace-pre-wrap"
                         :style="highlight.style">
                        <span class="py-2px bg-teal-600 text-white"
                              v-text="highlight.text"></span>
                    </div>

                    <tab-pill name="Plain Text">
                        <legacy-email-content-wrap>
                            <legacy-email-content @start="startSelection"
                                                  @end="endSelection($event, 'body_plain')"
                                                  ref="body_plain"
                                                  v-text="emailPlainText"/>
                        </legacy-email-content-wrap>
                    </tab-pill>
                    <tab-pill name="HTML">
                        <legacy-email-content-wrap>
                            <legacy-email-content @start="startSelection"
                                                  @end="endSelection($event, 'body_html')"
                                                  ref="body_html"
                                                  v-text="emailHtml"/>
                        </legacy-email-content-wrap>

                    </tab-pill>
                    <tab-pill name="Subject">
                        <legacy-email-content-wrap>
                            <legacy-email-content @start="startSelection"
                                                  @end="endSelection($event, 'subject')"
                                                  ref="subject"
                                                  :height="150"
                                                  v-text="emailSubject"/>
                        </legacy-email-content-wrap>
                    </tab-pill>
                </tab-pills>

            </div>

            <div class="col-span-6 md:col-span-2">
                <tab-header>
                    Trigger Fields
                    <template #sub>
                        These fields are used for all your actions.
                    </template>
                </tab-header>

                <legacy-email-edit-field v-if="edit.show"
                                         @cancel-edit-field="cancelEditField"
                                         @highlight-field="highlightField"
                                         :field="edit.field"/>

                <legacy-email-field v-for="field in fields"
                                    :field="field"
                                    :viewing="highlight.id"
                                    :loading="highlight.loading"
                                    @edit-field="editField"
                                    @show-field="getField"
                                    @hide-field="removeHighlight"
                                    :class="{'border-blue-600 shadow-lg bg-blue-50': newField.created === field.id}"
                                    :key="field.id"/>

            </div>
        </div>

        <div v-if="showContinue || showSave" class="mt-6 w-full flex justify-center">

            <button class="btn btn-continue" type="submit"
                    @click="continueOn">Continue
            </button>

        </div>

    </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import TabHeader from "../../../../elements/tabs/TabHeader"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import EventInputField from "../../../../elements/fields/EventInputField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import FormError from "../../../../elements/alerts/FormError"
import LegacyEmailField from "./LegacyEmailField"
import LegacyEmailContentHeaders from "./LegacyEmailContentHeaders"
import LegacyEmailContent from "./LegacyEmailContent"
import LegacyEmailContentWrap from "./LegacyEmailContentWrap"
import LegacyEmailEditField from "./LegacyEmailEditField"
import ToggleTriggerButtons from "../../components/partials/ToggleTriggerButtons"

export default {
    name: "LegacyEmailFields",

    components: {
        ToggleTriggerButtons,
        LegacyEmailEditField,
        LegacyEmailContentWrap,
        LegacyEmailContent,
        LegacyEmailContentHeaders,
        LegacyEmailField,
        FormError,
        SubmitButton,
        EventInputField,
        TabPill,
        TabPills,
        TabHeader
    },

    data() {
        return {
            tab: 'Plain Text',

            edit: {
                show: false,
                field: {
                    id: null,
                    email_source: 'body_plain',
                    end: '',
                    label: '',
                    offset: false,
                    start: '',
                    routes: {},
                    status: '',
                },
            },

            newField: {
                show: false,
                position: {
                    top: '0px',
                    left: '0px',
                },
                created: 0,
                form: new ParseyForm({
                    section: '',
                    label: '',
                    selected: {
                        text: '',
                        range: {
                            start: 0,
                            end: 0,
                        }
                    },
                })
            },

            highlight: {
                loading: false,
                show: false,
                id: 0,
                text: '',
                style: {},
                cleared: false,
            },

            newEmailForm: new ParseyForm({
                direction: 'next',
            }),


        }
    },

    methods: {
        ...mapActions('flow', {
            addAction: 'addLegacyAction',
        }),

        updateTab(tabName) {
            this.startSelection()
            return this.tab = tabName
        },

        startSelection() {
            this.newField.show = false;
            this.newField.form.selected.range.start = 0;
            this.newField.form.selected.range.end = 0;
        },

        endSelection(event, type) {
            if (this.highlight.show || this.highlight.cleared) {
                this.highlight.show = false;
                this.highlight.cleared = false;
                window.getSelection().removeAllRanges();
                return
            }

            const selection = window.getSelection()
            const selectedText = selection.toString();
            if (!selectedText || selectedText.length === 0) {
                return this.hideNewField()
            }

            this.highlight.show = true;
            this.showFieldForm(type);
            this.showHighlight();

        },

        showFieldForm(type) {
            const selection = window.getSelection()
            const selectedText = selection.toString();
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const start = range.startOffset
            const end = range.endOffset
            const width = rect.width;
            const left = rect.x;

            // 154 is half the width of the popup box
            const inputLeftPosition = left + (width / 2) - 154;

            this.newField.position.top = `${rect.y - 100}px`;
            this.newField.position.left = `${inputLeftPosition}px`;
            this.newField.form.selected.text = selectedText;
            this.newField.form.selected.range = {
                start: start,
                end: end,
            }

            this.newField.show = true;
            this.newField.form.section = type;
        },

        showHighlight() {
            const selection = window.getSelection()
            const range = selection.getRangeAt(0);

            const rect = range.getBoundingClientRect();
            const top = rect.y - 2;
            const left = rect.x;
            const width = rect.width;
            const height = rect.height;

            this.highlight.text = selection.toString()
            this.highlight.style = {
                top: `${top}px`,
                left: `${left}px`,
                width: `${width}px`,
                height: `${height}px`,
            }
        },

        clearViewing() {
            if (this.highlight.loading) {
                return;
            }

            if (this.highlight.id) {
                this.highlight.cleared = true;
                this.highlight.id = 0;
            }
        },

        hideNewField() {
            this.newField.show = false;
            this.newField.position.top = '0px';
            this.newField.position.left = '0px';
            this.newField.form.selected.text = '';
            this.newField.form.label = '';
            this.newField.form.selected.range.start = 0;
            this.newField.form.selected.range.end = 0;

            this.highlight.show = false;

            return true;
        },

        replaceAtPosition(string, replace, position) {
            let firstPart = string.substr(0, position);
            let lastPart = string.substr(position);

            return firstPart + replace + lastPart;
        },

        createField() {
            this.$store.dispatch('flow/addLegacyField', this.newField.form)
                .then((field) => {
                    this.hideNewField()
                    this.handleNewField(field)
                })
        },

        handleNewField(field) {
            if (field.status === 'confirmed') {
                this.newField.created = field.id
                const self = this;
                setTimeout(() => {
                    self.newField.created = 0
                }, 1500)
                return true;
            }

            if (field.status === 'error') {
                return this.editField(field)
            }
        },

        getField(field) {
            if (this.highlight.loading || this.highlight.cleared) {
                this.highlight.cleared = false;
                return;
            }

            if (this.highlight.id) {
                this.removeHighlight();
                return
            }

            this.highlight.id = field.id;
            this.highlight.loading = true;
            Parsey.get(this.flow.trigger.routes.parse + '?update_positions=1')
                .then(({data}) => {
                    this.highlight.loading = false;
                    return this.highlightField(field, data);
                })
        },

        highlightField(field, data) {
            const result = _.get(data, `${field.email_source}.${field.id}`)
            if (typeof result !== 'object') {
                return true;
            }

            const extractedValue = result.value;
            const extraction = result.extraction;

            this.tab = this.tabNameFromEmailType(extraction.email_source);

            const selection = window.getSelection();
            selection.removeAllRanges();

            const range = document.createRange();
            range.setStart(this.$refs[extraction.email_source].$el.firstChild, extractedValue.startPosition);
            range.setEnd(this.$refs[extraction.email_source].$el.firstChild, extractedValue.endPosition);
            selection.addRange(range);

        },

        tabNameFromEmailType(type) {
            if (type === 'body_plain') {
                return 'Plain Text'
            }

            if (type === 'body_html') {
                return 'HTML';
            }

            return 'Subject';
        },

        removeHighlight() {
            this.highlight.show = false;
            this.highlight.id = 0;
            this.highlight.text = '';
            this.highlight.style = {};
        },

        editField(field) {
            this.edit.field = field;
            this.edit.show = true;
        },

        cancelEditField() {
            this.edit.show = false;
        },

        continueOn() {
            if (this.showContinue) {
                return this.addAction()
            }

            this.$router.push({path: this.flow.views.edit})
        },

        getNewEmail(direction) {
            this.newEmailForm.direction = direction

            Parsey.put(this.flow.routes.update, this.newEmailForm)
                .then(({flow}) => {
                    this.$store.commit('flow/setFlow', flow)
                })
        }

    },

    computed: {
        ...mapState('flow', {
            flow: state => state.data,
        }),

        email() {
            return _.get(this.flow, 'trigger.data.email');
        },

        fields() {
            return _.reject(_.sortBy(_.get(this.flow, 'trigger.fields'), ['sort.type', 'sort.position', 'label']), field => {
                return this.edit.show === true && field.id === this.edit.field.id
            })
        },

        emailPlainText() {
            return this.email.body.plain;
        },

        emailHtml() {
            return this.email.body.html;
        },

        emailSubject() {
            return this.email.subject;
        },

        showContinue() {
            return _.size(_.get(this.flow, 'trigger.fields')) > 0 && _.size(_.get(this.flow, 'actions')) === 0
        },

        showSave() {
            return _.size(_.get(this.flow, 'trigger.fields')) > 0 && _.size(_.get(this.flow, 'actions')) > 0
        },

    }

}
</script>
