<template>

    <div class="block transition duration-150 ease-in-out">
        <div :class="showMenu ? 'gap-3' : 'gap-1'"
             class="grid grid-cols-5">

            <router-link class="col-span-4 sm:col-span-2 truncate"
                         :to="{path: flow.views.edit}">

                <div class="flex items-center h-12 pl-4">
                    <ellipsis-middle-text :classes="`flex-shrink truncate ${flowNameCss}`"
                                          :text="flowName"/>
<!--                    <span v-if="flow.legacy && showMenu"-->
<!--                          class="leading-relaxed flex-shrink-0 inline rounded-full ml-2 md:ml-3 bg-gray-50 text-gray-600 px-2 md:px-2 md:pb-2px text-2xs md:text-1xs">Legacy</span>-->
                </div>

            </router-link>

            <router-link class="hidden sm:flex sm:col-span-2 items-center"
                         :to="{path: flow.views.edit}">
                <div class="w-full">
                    <flow-actions-list :flow="flow"/>
                </div>

            </router-link>

            <div class="col-span-1 flex justify-end items-center">
                <toggle-bullet :form="toggleForm" v-if="showMenu"/>

                <flows-menu :flow="flow"
                            v-if="showMenu"
                            @rename-flow="showRenameForm = true"/>

                <div v-if="showMenu === false" class="h-5 w-5 mr-1">
                    <router-link :to="{path: flow.views.edit}">
                        <chevron-right-icon/>
                    </router-link>

                </div>
            </div>
        </div>

        <rename-flow-modal :show="showRenameForm"
                           @close="showRenameForm = false"
                           :flow="flow"/>

    </div>


</template>

<script>
import {flowIndexMixin} from "./mixins/flow-mixins"
import FlowActionsList from "./partials/FlowActionsList"
import ChevronRightIcon from "../../../svgs/general/ChevronRightIcon"

export default {
    name: 'FlowRow',

    components: {
        ChevronRightIcon,
        FlowActionsList
    },

    mixins: [
        flowIndexMixin
    ],

}
</script>
