import {get} from "lodash"

const state = () => ({
    legacy: {},
    search: null,
    loading: true,
})

const getters = {

    legacyEvents: (state) => (appKey) => {
        let events = _.get(state.legacy, `${appKey}.events`, [])
        if (events.length === 0) {
            return [];
        }

        return _(events)
            .reject('primary', true)
            .map(event => {
                event.integration = event.name.includes('Integration')
                event.first = !event.integration
                return event
            })
            .sortBy(['first', 'name'])
            .value();
    },

    legacyPrimaryStep: (state) => (appKey) => {
        let events = _.get(state.legacy, `${appKey}.events`, [])
        if (events.length === 0) {
            return {};
        }

        return _.find(events, event => {
            return event.primary === true
        });
    },

    showAuthentication: (state) => (action) => {
        return _.size(_.get(state, `legacy.${action.app.key}.auth_fields`)) > 0
    }

}

const mutations = {

    setLoading(state) {
        state.loading = true;
    },

    setLegacyApps(state, apps) {
        state.legacy = apps;
        state.loading = false;
    },

    addUserApp(state, payload) {
        state.legacy[payload.key].user_apps = [...state.legacy[payload.key].user_apps, payload.app]
    }

}

const actions = {

    getLegacyApps({commit, rootState, state}) {
        if (_.size(state.legacy) > 0) {
            return true //Already loaded
        }
        commit('setLoading')
        Parsey.get(rootState.routes.apps.legacy.index)
            .then(({data}) => {
                commit('setLegacyApps', data)
            })
    },

    createLegacyApp({state, commit}, form) {
        return new Promise((resolve, reject) => {
            Parsey.post(state.legacy[form.key].routes.store, form)
                .then(({app_setting}) => {
                    resolve(app_setting)
                    commit('addUserApp', {
                        key: form.key,
                        app: {
                            id: app_setting.id,
                            name: app_setting.name,
                        }
                    })
                });
        })
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
