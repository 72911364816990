<template>
    <div>

        <div class="mt-1 relative rounded-md shadow-sm">
            <div v-if="lead.length"
                 class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm sm:leading-5">{{ lead }}</span>
            </div>

            <input :id="getId"
                   v-model="form[name]"
                   :type="type"
                   @keyup="form.checkErrors(name)"
                   :class="`${updatedClasses} ${leadingClass} ${trailingClass}`"
                   :placeholder="placeholder">

            <div v-if="trail.length"
                 class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm sm:leading-5">{{ trail }}</span>
            </div>

        </div>

        <span v-if="help && ! form.errors.hasErrors()"
              class="text-gray-300 text-xs">{{ help }}</span>
        <span class="text-red-600 text-xs"
              v-show="form.errors.has(name)"
              v-text="form.errors.get(name)"></span>

    </div>
</template>

<script>
export default {

    name: "LeadingTrailingField",

    mixins: [
        require('./mixins/form-helpers')
    ],

    props: [
        'id',
        'placeholder',
        'type',
        'form',
        'name',
        'help',
        'extraClasses',
        'lead',
        'leadSpace',
        'trail',
        'trailSpace',
    ],

    computed: {
        updatedClasses() {
            let classes = this.classes;

            return classes.replace('sm:p-3', 'sm:py-3')
        },

        leadingClass() {
            if (this.lead.length === 0) {
                return ''
            }

            /**
             * pl-16
             */
            return 'pl-' + this.leadSpace;
        },

        trailingClass() {
            if (this.trail.length === 0) {
                return ''
            }

            /**
             * pr-36
             */

            return 'pr-' + this.trailSpace;
        }
    }


}
</script>

