<template>
    <form @submit.prevent="save">

        <map-fields :fields="fields"
                    :app-fields="appFields"
                    :refreshing="refreshing"
                    :extraction-fields="extractionFields"
                    @updated="extractedFieldsUpdated"
                    @refresh="refreshFields"
                    name="Infusionsoft"/>

        <div class="mt-12">
            <tab-pills>
                <tab-pill name="Duplicates" :selected="true">

                    <div class="max-w-lg">

                        <p class="text-sm sm:text-base font-medium mb-3">
                            Check for duplicate contact records in Infusionsoft using:
                        </p>

                        <select-field :options="duplicationOptions"
                                      v-model="dropdowns.duplication.selected"
                                      class="mb-2"
                                      group-values="fields"
                                      group-label="group"
                                      :allow-empty="false"
                                      :loading="loading"
                                      label="label"
                                      track-by="name"/>

                        <p class="text-xs text-gray-400 mb-8">
                            The field or fields you select for the duplication method must be mapped
                            above.
                        </p>

                        <checkbox-field id="`contact_update_only_${action.id}`"
                                        v-model="form.settings.onlyUpdateDoNotAdd">
                            Only update an existing contact, never create a new one.
                        </checkbox-field>

                    </div>

                </tab-pill>

                <tab-pill name="Custom Fields">

                    <legacy-custom-fields :fields="fields"
                                          :custom-fields="customFields"
                                          :app-fields="appFields"
                                          @update="customFieldsUpdated"
                                          @refresh="refreshFields"
                                          name="Infusionsoft"
                                          :refreshing="refreshing"/>

                </tab-pill>

                <tab-pill name="Magic Fields">
                    <legacy-magic-fields :fields="fields"
                                         :magic-fields="magicFields"
                                         :app-fields="appFields"
                                         @update="magicFieldsUpdated"
                                         @refresh="refreshFields"
                                         name="Infusionsoft"
                                         :refreshing="refreshing"/>
                </tab-pill>
            </tab-pills>
        </div>

        <legacy-event-save-button :form="form" :event="event"/>

    </form>
</template>

<script>
import MapFields from "../partials/MapFields"
import EventHelpers from "../mixins/event-helpers"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import SelectField from "../../../../elements/fields/SelectField"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"

export default {
    name: 'CreateContact',

    components: {
        LegacyEventSaveButton,
        LegacyMagicFields,
        LegacyCustomFields,
        CheckboxField,
        SelectField,
        TabPill,
        TabPills,
        MapFields
    },

    mixins: [
        EventHelpers
    ],

    props: {

        action: {
            type: Object,
        },

        event: {
            type: Object,
        },
    },

    data() {
        return {
            newEvent: false,
            loading: true,
            refreshing: false,
            form: new ParseyForm({
                onlyUpdateDoNotAdd: false,
                key: 'CreateContact',
                settings: {
                    onlyUpdateDoNotAdd: false,
                    custom_fields: {},
                    extraction_fields: {},
                    magic_fields: {},
                    duplication: 'Email',
                },
            }),
            dropdowns: {
                duplication: {
                    selected: null,
                }
            },

        }
    },

    mounted() {
        this.getActionFields();

        if (this.event && this.event.settings) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        if (this.form.settings.onlyUpdateDoNotAdd) {
            this.form.settings.onlyUpdateDoNotAdd = true
        }
    },

    methods: {

        save() {
            this.form.settings.duplication = _.get(this.dropdowns.duplication, 'selected.name');
            if (!this.form.settings.onlyUpdateDoNotAdd) {
                _.unset(this.form, 'settings.onlyUpdateDoNotAdd')
            }

            // Submit Event Settings
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
                redirect: this.action.views.edit,
            })

        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'contact'
            }).then(() => {
                this.loading = false;
                this.setDuplicationMethod()
            })
        },

        setDuplicationMethod() {
            if (this.form.settings.duplication) {
                this.setDropdownValueFromGroups(this.duplicationOptions, 'fields', 'name', 'duplication', 'duplication')
            }
        },

        refreshFields() {
            this.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'contact'
            }).then(() => {
                this.refreshing = false;
            })
        },

        extractedFieldsUpdated(mappedFields) {
            this.form.settings.extraction_fields = mappedFields
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

        mapOptions(options) {
            return _.map(options, (label, key) => {
                return {
                    label: label,
                    name: key,
                }
            })
        },

        clickBankExtractionFields() {
            let map = {
                email: this.findTriggerFieldByPath('[customer][shipping][email]'),
                phone: this.findTriggerFieldByPath('[customer][shipping][phoneNumber]'),
            }
            return {
                [map['email']]: "Email",
                [map['phone']]: "Phone1",
                698: "Address2Street1",
                699: "Address2Street2",
                700: "City2",
                701: "Country2",
                702: "State2",
                703: "PostalCode2",
                704: "Country2",
                709: "State",
                710: "PostalCode",
                711: "Country",
            };

        },

        clickBankMagicFields() {
            let map = {
                first: this.findTriggerFieldByPath('[customer][shipping][firstName]'),
                last: this.findTriggerFieldByPath('[customer][shipping][lastName]'),
            }
            return {
                1: {
                    field: 'FirstName',
                    magic: 'uppercaseEach',
                    your_field: map['first'],
                },
                2: {
                    field: 'LastName',
                    magic: 'uppercaseEach',
                    your_field: map['last'],
                }
            }
        },

        findTriggerFieldByPath(path) {
            let field = _.find(this.fields, field => {
                return field.path === path;
            })

            if (field) {
                return field.id;
            }

            return null;
        }

    },

    computed: {
        trigger() {
            return this.$store.state.flow.data.trigger;
        },

        fields() {
            return this.$store.state.flow.data.trigger.fields;
        },

        extractionFields() {
            if (this.newClickBankEvent) {
                let fields = this.clickBankExtractionFields();
                this.extractedFieldsUpdated(fields)
                return fields;
            }

            return _.get(this.event, 'settings.extraction_fields', {});
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            if (this.newClickBankEvent) {
                let fields = this.clickBankMagicFields();
                this.magicFieldsUpdated(fields);
                return fields;
            }

            return _.get(this.event, 'settings.magic_fields', {})
        },

        appFields() {
            if (this.action.fields) {
                return this.action.fields.contact;
            }

            return null;
        },

        duplicationOptions() {
            let defaultOptions = {
                Email: 'Primary Email Address',
                ContactId: 'A Provided Infusionsoft Contact Id',
                Nothing: "Do NOT check for duplicates, always add new contact",
            };

            let specialOptions = {
                FirstLastName: 'First and Last Name only (most recently updated)',
                FirstLastEmail: 'First and Last Name and Primary Email (most recently updated)',
                FirstNameEmail: 'First Name and Primary Email (most recently updated)',
                Company: 'Company Name only (most recently updated)',
                AnyEmail: 'All three Email Address fields (most recently updated)',
            }

            let options = [
                {
                    group: 'Default Options',
                    fields: this.mapOptions(defaultOptions),
                },
                {
                    group: 'Special Options',
                    fields: this.mapOptions(specialOptions),
                }
            ];

            if (this.appFields) {
                let general = [{
                    group: 'General',
                    fields: _.reject(_.find(this.appFields, group => {
                        return group.group === 'General';
                    }).fields, field => {
                        return ['Email', 'Company'].includes(field.name)
                    })
                }];

                let filtered = _.reject(this.appFields, group => {
                    return ['Special Field Options', 'General'].includes(group.group);
                })
                options = _.concat(options, general, filtered);
            }

            return options
        },

        newClickBankEvent() {
            return typeof this.event === "undefined" && _.get(this.trigger, 'type') === 'clickbank';
        },

    },
}
</script>
