<template>
    <th class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
        <slot/>
    </th>
</template>

<script>
export default {
    name: 'TableHeader',
}
</script>
