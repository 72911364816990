import DefaultLayout from "../components/layouts/DefaultLayout"
import SettingsIndex from "../components/views/settings/SettingsIndex"
import UserProfile from "../components/views/settings/UserProfile"
import UserBilling from "../components/views/settings/UserBilling"
import UserApps from "../components/views/settings/UserApps"
import SettingsSidebar from "../components/views/settings/SettingsSidebar"
import SettingsDropdown from "../components/views/settings/SettingsDropdown"
import UserSecurity from "../components/views/settings/UserSecurity"
import MailboxIndex from "../components/views/mailboxes/MailboxIndex"

let settingRoutes = [

    {
        path: '/settings',
        name: 'Settings',
        components: {
            default: SettingsIndex
        },
        redirect: {name: 'UserProfile'},
        children: [
            {
                path: 'profile',
                component: UserProfile,
                name: 'UserProfile',
            },
            {
                path: 'security',
                component: UserSecurity,
                name: 'UserSecurity',
            },
            {
                path: 'billing',
                component: UserBilling,
                name: 'UserBilling',
            },
            {
                path: 'mailboxes',
                component: MailboxIndex,
                name: 'Mailboxes',
            },
            {
                path: 'apps',
                component: UserApps,
                name: 'UserApps',
            },
        ]
    }

]


const meta = {
    layout: DefaultLayout,
    sidebar: SettingsSidebar,
    dropdown: SettingsDropdown,
}

settingRoutes = settingRoutes.map(route => {
    route.meta = {}

    if (route.children) {
        route.children = route.children.map(child => {
            child.meta = meta
            return child
        })
    }

    return route;
})

export default settingRoutes;
