<template>
    <div>
        <div class="hidden md:flex md:col-span-1 items-center mb-3 justify-between">
            <h1 class="flex-1 font-lead text-2xl font-semibold">History</h1>

            <div class="flex-shrink-0 flex items-center">

                <refresh-button @refresh="getHistory(true)"
                                :refreshing="$store.state.history.loading"
                                class="mr-4"/>

                <default-dropdown width="80"
                                  @closed="flowSearch = ''"
                                  @opened="focusFlowSearch">

                    <template #trigger>
                        <button type="button"
                                class="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                                aria-expanded="false" aria-haspopup="true">
                            Flows
                            <chevron-down-icon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"/>

                        </button>
                    </template>

                    <template #content>

                        <div class="w-full px-4 py-2">
                            <form>
                                <input type="text"
                                       v-model="flowSearch"
                                       ref="flow_search_input"
                                       placeholder="Search..."
                                       class="w-full placeholder-gray-400 p-2 text-sm text-gray-600 border border-gray-100 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1 transition"/>
                            </form>
                        </div>

                        <form v-for="(flow, flowIndex) in flowList"
                              @submit.prevent="selectFlow(flowIndex)">
                            <dropdown-link as="selectable"
                                           :close-on-click="false"
                                           :selected="$store.state.history.dropdowns.flows.selected.includes(flowIndex)">
                                <div class="flex items-center flex-1 min-w-0">
                                    <div class="relative w-6 h-6 flex items-center bg-white p-1 border border-gray-100 mr-2 rounded-full">
                                        <app-icon :app="flow.trigger.type"/>
                                    </div>
                                    <p class="truncate">
                                        {{ flow.name ? flow.name : `Unnamed Flow Id: ${flow.id}` }}
                                    </p>
                                </div>
                            </dropdown-link>
                        </form>

                    </template>

                </default-dropdown>

                <default-dropdown>
                    <template #trigger>
                        <button type="button"
                                class="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                aria-expanded="false" aria-haspopup="true">
                            Status
                            <chevron-down-icon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"/>

                        </button>
                    </template>

                    <template #content>
                        <form v-for="status in $store.state.config.history.statuses"
                              @submit.prevent="selectStatus(status.name)">
                            <dropdown-link as="selectable"
                                           :close-on-click="false"
                                           :selected="$store.state.history.dropdowns.status.selected.includes(status.name)">
                                {{ status.name }}
                            </dropdown-link>
                        </form>

                    </template>

                </default-dropdown>

            </div>
        </div>

        <div class="hidden md:flex justify-end mb-3">

            <filter-badge v-for="flow in selectedFlows"
                          :key="flow.key"
                          class="bg-white text-gray-600 border border-gray-50"
                          @click.native.prevent="selectFlow(flow.key)"
                          circle-class="bg-gray-50"
                          :name="flow.name"/>

            <filter-badge v-for="status in selectedStatuses"
                          :key="status.id"
                          :class="status.class.main"
                          class=""
                          @click.native.prevent="selectStatus(status.name)"
                          :circle-class="status.class.close_bg"
                          :name="status.name "/>

        </div>
    </div>
</template>

<script>

import DefaultDropdown from "../../../elements/DefaultDropdown"
import DropdownLink from "../../../elements/DropdownLink"
import ChevronDownIcon from "../../../svgs/general/ChevronDownIcon"
import RefreshButton from "../../../elements/buttons/RefreshButton"
import AppIcon from "../../flows/components/partials/AppIcon"
import FilterBadge from "../../../elements/badges/FilterBadge"
import {mapActions, mapGetters} from "vuex"

export default {
    name: "HistoryHeader",

    components: {
        AppIcon,
        RefreshButton,
        ChevronDownIcon,
        DropdownLink,
        DefaultDropdown,
        FilterBadge
    },

    data() {
        return {
            flowSearch: '',
        }
    },

    methods: {
        ...mapActions('history', [
            'getHistory',
            'selectStatus',
            'selectFlow',
        ]),

        getFlowId(flow) {
            return flow.type + '_' + flow.id;
        },

        focusFlowSearch() {
            const self = this
            setTimeout(() => {
                self.$refs.flow_search_input.focus();
            }, 100);
        },
    },

    computed: {
        ...mapGetters('history', [
            'selectedStatuses',
            'selectedFlows',
        ]),

        flowList() {
            if (this.flowSearch.length === 0) {
                return this.$store.state.flows.all;
            }

            const search = this.flowSearch.toLowerCase().trim();

            const flows = _.filter(this.$store.state.flows.all, flow => {
                let results = flow.terms.filter(key => {
                    return key.includes(search) || key.replace(' ', '').includes(search)
                })

                return results.length > 0;
            })

            return _.keyBy(flows, (flow) => this.getFlowId(flow))
        },
    }
}
</script>