<template>
    <div :class="classes" class="flex ellipsis-middle-text">
        <span class="truncate" v-text="firstPart"/>
        <span class="flex-shrink-0" v-text="lastPart"/>
    </div>
</template>

<script>
export default {
    name: "EllipsisMiddleText",

    props: {
        text: {
            required: true,
        },
        endLength: {
            type: Number,
            default: 6,
        },
        classes: {
            type: String,
        }
    },

    computed: {
        textIsString() {
            return typeof this.text === 'string'
        },
        firstPart() {
            if (!this.textIsString) {
                return ''
            }

            if (this.text.length < 10) {
                return this.text;
            }

            return this.text.slice(0, -this.endLength);
        },

        lastPart() {
            if (!this.textIsString) {
                return ''
            }

            if (this.text.length < 10) {
                return '';
            }

            return this.text.substr(this.text.length - this.endLength)
        }
    }
}
</script>
