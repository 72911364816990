<template>
    <div>
        <input :id="getId"
               v-model="form[name]"
               :type="type"
               @keyup="form.checkErrors(name)"
               @blur="sendBlur"
               :placeholder="placeholder"
               :class="classes">
        <span v-if="help && ! form.errors.hasErrors()"
              class="text-gray-300 text-xs">{{ help }}</span>
        <span class="text-red-600 text-xs"
              v-show="form.errors.has(name)"
              v-text="form.errors.get(name)"></span>
    </div>
</template>

<script>
export default {

    name: "InputField",

    mixins: [
        require('./mixins/form-helpers')
    ],

    props: [
        'id',
        'placeholder',
        'type',
        'form',
        'name',
        'help',
        'extraClasses'
    ],

    methods: {
        sendBlur() {
            this.$emit('blur')
        }
    }

}
</script>

