<template>

    <div class="overflow-y-auto font-sans whitespace-pre-wrap selection:text-white selection:bg-teal-600 px-2 sm:px-4 pb-4"
         @mousedown="$emit('start')"
         @mouseup.prevent="$emit('end')"
         :style="`height: ${height}px`"></div>

</template>

<script>
export default {
    name: 'LegacyEmailContent',

    props: {
        height: {
            type: Number,
            default: 500,
        }
    }
}
</script>