<template>
    <form @submit.prevent="save">

        <event-sub-text>
            This will add incoming emails to the contacts email history.
        </event-sub-text>

        <success-alert message="There are no settings to configure for this event" />

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import EventSubText from "../elments/EventSubText"
import FormError from "../../../../elements/alerts/FormError"
import SuccessAlert from "../../../../elements/alerts/SuccessAlert"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"

export default {
    name: 'AttachEmail',

    components: {
        SuccessAlert,
        EventSubText,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    data() {
        return {
            form: new ParseyForm({
                key: this.event.key,
                settings: {},
            }),
        }
    },

    methods: {

        save() {
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

    },
}
</script>

