<template>
    <form @submit.prevent="save">

        <p class="text-lg mb-6 relative">
            {{ title }}

            <close-x-button @click="$emit('close')"
                            class="absolute top-0 right-0 -mt-1.5"/>
        </p>

        <div class="grid md:grid-cols-2 gap-x-8">
            <input-label label="Product Name">
                <event-input-field v-model="form.name"
                                   :form="form"
                                   form-key="name"
                                   placeholder="Name for reference"/>
            </input-label>

            <input-label label="Product Type">
                <select-field :options="dropdowns.productType.options"
                              v-model="dropdowns.productType.selected"
                              :allow-empty="false"
                              @input="updateFromDropDownValue('product_type', 'productType')"
                              label="label"
                              track-by="value"/>
            </input-label>
        </div>

        <div class="grid md:grid-cols-2 gap-x-8">
            <input-label label="ClickBank Sku">
                <event-input-field v-model="form.sku"
                                   :form="form"
                                   form-key="sku"
                                   placeholder="Sku found in ClickBank settings"/>
            </input-label>

            <input-label label="Keap Product Type">
                <select-field :options="dropdowns.keapProduct.options"
                              v-model="dropdowns.keapProduct.selected"
                              :loading="dropdowns.keapProduct.searching"
                              @input="updateFromDropDownId('keap_product_id', 'keapProduct')"
                              :internal-search="false"
                              @search-change="dropdowns.keapProduct.search($event)"
                              :no-result="dropdowns.keapProduct.noResult"
                              :options-limit="100"
                              :allow-empty="false"
                              label="name"
                              track-by="id"
                              placeholder="Start typing to search"/>
                <p v-if="form.errors.has('keap_product_id')"
                   class="mt-1 text-xs text-red-600">
                    Please choose a product
                </p>
            </input-label>
        </div>

        <div :class="priceClass" class="grid">

            <input-label label="Price Option">
                <select-field :options="dropdowns.priceType.options"
                              v-model="dropdowns.priceType.selected"
                              :allow-empty="false"
                              @input="updateFromDropDownValue('price_type', 'priceType')"
                              label="label"
                              track-by="value"/>
            </input-label>

            <input-label v-if="form.price_type === 'manual'"
                         :label="form.product_type === 'recurring' ? 'Initial Price' : 'Price'">
                <event-input-field v-model="form.price"
                                   :form="form"
                                   form-key="price"
                                   placeholder="49.00"/>
            </input-label>

            <input-label v-if="form.price_type === 'manual' && form.product_type === 'recurring'"
                         label="Rebill Price">
                <event-input-field v-model="form.rebill_price"
                                   :form="form"
                                   form-key="rebill_price"
                                   placeholder="29.00"/>
            </input-label>

        </div>

        <input-label label="Keap Purchase Actions">
            <select-field :options="dropdowns.trigger.options"
                          v-model="dropdowns.trigger.selected"
                          @input="updateFromDropDownValue('trigger', 'trigger')"
                          :allow-empty="false"
                          label="label"
                          track-by="value"/>
        </input-label>

        <modal-default-buttons :form="form"
                               @save="save"
                               :button="button"
                               :button-changing="buttonChanging"
                               @close="$emit('close')"/>

    </form>
</template>

<script>
import CloseXButton from "../../../../elements/buttons/CloseXButton"
import DefaultWell from "../../../../elements/DefaultWell"
import SelectField from "../../../../elements/fields/SelectField"
import ModalDefaultButtons from "../../../../elements/modals/ModalDefaultButtons"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventHelpers from "../mixins/event-helpers"
import EventInputField from "../../../../elements/fields/EventInputField"

export default {
    name: 'ClickBankProduct',

    components: {
        EventInputField,
        InputLabel,
        ModalDefaultButtons,
        SelectField,
        DefaultWell,
        CloseXButton
    },

    props: {
        product: {
            type: [Boolean, Object],
            required: true,
        },

        action: {
            type: Object,
            required: true,
        },

        url: {
            type: String,
        }
    },

    mixins: [
        EventHelpers
    ],

    data() {
        return {
            settingKeyBase: 'form',

            dropdowns: {
                productType: {
                    selected: null,
                    options: [
                        {
                            label: 'Single Charge (default)',
                            value: 'default',
                        },
                        {
                            label: 'Recurring Charge (subscription)',
                            value: 'recurring',
                        },
                    ]
                },

                priceType: {
                    selected: null,
                    options: [
                        {
                            label: 'Set Price Manually',
                            value: 'manual',
                        },
                        {
                            label: 'Use Price from ClickBank (USD)',
                            value: 'net_price',
                        },
                    ]
                },

                trigger: {
                    selected: null,
                    options: [
                        {
                            label: 'Trigger Campaign Goals',
                            value: 'goals',
                        },
                        {
                            label: 'Trigger Legacy Action Sets',
                            value: 'action_sets',
                        },
                    ]
                },

                keapProduct: new ParseySearch({
                    url: this.action.routes.search,
                    type: 'products',
                    default: {
                        id: '',
                        name: 'Searching...'
                    },
                }, () => {
                    this.appendProduct()
                })
            },

            form: new ParseyForm({
                name: '',
                product_type: 'default',
                sku: '',
                keap_product_id: '',
                price: '',
                price_type: 'net_price',
                rebill_price: '',
                trigger: 'goals',
            })
        }
    },

    mounted() {
        if (this.product && _.size(this.product) > 0) {
            this.form = _.merge(this.form, JSON.parse(JSON.stringify(this.product)));
            delete this.form.action_sets; // Action Sets are handled in another modal
            delete this.form.active; // Activation is handled outside this form
        }

        this.setDropdownValue('productType', 'product_type')
        this.setDropdownValue('priceType', 'price_type')
        this.setDropdownValue('trigger', 'trigger')
        this.getKeapProducts();
    },

    methods: {

        save() {
            let url = this.url;
            let method = 'post'
            if (this.product && this.product.id) {
                url = `${this.url}/${this.product.id}`;
                method = 'put'
            }

            Parsey[method](url, this.form)
                .then(({product}) => {
                    this.$emit('close')
                    if (this.product === false) {
                        this.$emit('add', product)
                        return
                    }
                    this.$emit('update-products')
                })
        },

        skipProductOption() {
            return {
                id: 0,
                name: 'Skip, not saving order inside Keap'
            }
        },

        appendProduct() {
            this.dropdowns.keapProduct.options.push(this.skipProductOption())
        },

        getKeapProducts() {
            if (this.product === false) {
                return true;
            }

            if (parseInt(this.product.keap_product_id) === 0) {
                this.dropdowns.keapProduct.selected = this.skipProductOption()
            }

            if (parseInt(this.product.keap_product_id) > 0) {
                this.dropdowns.keapProduct.query = this.product.keap_product_id;
                this.dropdowns.keapProduct.doSearch()
                    .then((results) => {
                        if (_.size(results) > 0) {
                            this.dropdowns.keapProduct.selected = results[0];
                        }
                    })
            }

        }
    },

    computed: {
        title() {
            if (this.product === false) {
                return 'Add Your ClickBank Product';
            }

            return 'Edit Your ClickBank Product';
        },

        button() {
            if (this.product === false) {
                return 'Save';
            }

            return 'Update'
        },

        buttonChanging() {
            if (this.product === false) {
                return 'Saving';
            }

            return 'Updating'
        },

        priceClass() {
            return {
                'md:grid-cols-3 gap-x-4': this.form.price_type === 'manual' && this.form.product_type === 'recurring',
                'md:grid-cols-2 gap-x-8': this.form.price_type === 'manual' && this.form.product_type !== 'recurring',
            }
        }
    }
}
</script>