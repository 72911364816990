<template>
    <div>
        <component v-if="loadComponent" :is="componentSvg"/>
        <div v-else v-html="app.icon"></div>
    </div>

</template>

<script>

import EmailIcon from "../../../../svgs/parsers/EmailIcon"
import ContinueIcon from "../../../../svgs/parsers/ContinueIcon"
import AttachmentIcon from "../../../../svgs/parsers/AttachmentIcon"
import WebhookIcon from "../../../../svgs/parsers/WebhookIcon"
import ClickBankIcon from "../../../../svgs/parsers/ClickBankIcon"

export default {
    name: 'AppIcon',

    components: {
        ClickBankIcon,
        EmailIcon,
        ContinueIcon,
        AttachmentIcon,
        WebhookIcon,
    },

    props: {
        app: {
            type: [String, Object],
            required: true,
        },
    },

    computed: {

        loadComponent() {
            return typeof this.app !== 'object';
        },

        componentSvg() {
            switch (this.app) {
                case 'clickbank':
                    return 'ClickBankIcon';
                case 'continue':
                case 'email':
                case 'webhook':
                case 'attachment':
                    return _.capitalize(this.app) + 'Icon';
            }
            return null;
        }
    }

}
</script>
