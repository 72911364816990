<template>

    <div v-if="showNotifications"
         class="fixed inset-0 flex flex-col items-start justify-end px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start">
        <div v-for="(notification, id) in notifications"
             class="max-w-sm w-full">
            <transition enter-active-class="transform ease-out duration-500 transition"
                        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                        leave-active-class="transition ease-in duration-100"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0">
                <component v-if="notification.show"
                           :is="componentName(notification)"
                           :notification-id="parseInt(id)"
                           :notification="notification"/>
            </transition>

        </div>
    </div>

</template>

<script>
import DefaultNotification from "./DefaultNotification"
import ErrorNotification from "./ErrorNotification"

export default {
    name: 'NotificationList',

    components: {
        DefaultNotification,
        ErrorNotification
    },

    methods: {
        componentName(notification) {
            return _.capitalize(notification.type) + 'Notification';
        }
    },

    computed: {
        showNotifications() {
            return _.size(this.notifications) > 0;
        },

        notifications() {
            return this.$store.state.notifications;
        }
    }
}
</script>

