<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            This will run the selected Action Set on the contact.
        </p>

        <form-error :form="form"/>

        <input-label label="Choose Action Set">
            <select-field :options="search.options"
                          v-model="search.selected"
                          label="name"
                          placeholder="Start typing to search"
                          :internal-search="false"
                          @search-change="search.search($event)"
                          :loading="search.searching"
                          :options-limit="100"
                          :no-result="search.noResult"
                          track-by="id"/>
        </input-label>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import SelectField from "../../../../elements/fields/SelectField"

export default {
    name: 'ActionSet',

    components: {
        SelectField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    data() {
        return {
            search: new ParseySearch({
                url: this.action.routes.search,
                type: 'actionSets',
                default: {
                    id: 0,
                    name: 'Searching...'
                }
            }),
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    action_set_id: 0,
                    name: '',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = _.merge(this.form.settings, JSON.parse(JSON.stringify(this.event.settings)));
        }

        this.setActionName();
    },

    methods: {

        save() {
            this.form.settings.action_set_id = this.search.selected.id;
            this.form.settings.name = this.search.selected.name;

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        setActionName() {
            if (parseInt(this.form.settings.action_set_id) === 0) {
                return;
            }

            if (this.form.settings.name.length > 0) {
                this.search.selected = {
                    id: this.form.settings.action_set_id,
                    name: this.form.settings.name,
                }
                this.search.options = [
                    this.search.selected
                ]
                return;
            }

            this.getActionSetName();
        },

        getActionSetName() {
            this.search.searching = true
            Parsey.get(`${this.action.routes.search}?type=actionSets&search=${this.form.settings.action_set_id}`)
                .then(results => {
                    let actionSet = _.find(results, set => {
                        return set.id === parseInt(this.form.settings.action_set_id)
                    })
                    this.search.selected = actionSet;
                    this.search.options = [
                        actionSet
                    ]
                    this.search.searching = false
                })
        }

    },

}
</script>

