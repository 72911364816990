module.exports = {

    data() {
        return {
            formSettings: {
                keys: {
                    triggerField: 'user.extracted',
                    userType: 'user.type',
                    manual: 'user.manual',
                    searchType: 'user.search_type',
                },
                findUserByTriggerValue: 2,
                findUserCurrent: 4,
                findUserSkip: 0,
                findUserSelectOne: 1,

                searchByEmail: 1,
                searchByUserId: 0,
                searchByFirst: 2,
                searchByFirstLast: 3,
            },

            dropdowns: {

                triggerFields: {
                    selected: {
                        user: null,
                    }
                },

                findUserMethods: {
                    loading: false,
                    options: [
                        {
                            label: 'Choose user from a list',
                            value: 1,
                        },
                        {
                            label: "Use current contact owner",
                            value: 4,
                        },
                        {
                            label: 'Find user using a trigger field',
                            value: 2,
                        },
                        {
                            label: 'Skip, do not assign user',
                            value: 0,
                        }
                    ],
                    selected: null,
                },

                users: {
                    loading: true,
                    options: [],
                    selected: null,
                },

                searchUserField: {
                    loading: false,
                    options: [
                        {
                            label: 'Email',
                            value: 1,
                        },
                        {
                            label: 'User Id',
                            value: 0,
                        },
                        {
                            label: 'First Name',
                            value: 2,
                        },
                        {
                            label: 'First & Last Name',
                            value: 3,
                        },
                    ],
                    selected: null,
                }

            }
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = _.merge(this.form.settings, JSON.parse(JSON.stringify(this.event.settings)));
        }

        this.dropdowns.triggerFields.selected.user = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(_.get(this.form.settings, this.formSettings.keys.triggerField));
        })

        this.dropdowns.findUserMethods.selected = _.find(this.dropdowns.findUserMethods.options, type => {
            return parseInt(type.value) === parseInt(_.get(this.form.settings, this.formSettings.keys.userType));
        })

        this.dropdowns.searchUserField.selected = _.find(this.dropdowns.searchUserField.options, type => {
            return parseInt(type.value) === parseInt(_.get(this.form.settings, this.formSettings.keys.searchType));
        })

        this.getUsers();
    },

    methods: {

        getUsers() {
            Parsey.get(`${this.action.routes.search}?type=users&search=all`)
                .then(results => {
                    this.dropdowns.users.loading = false;
                    this.dropdowns.users.options = results;
                    this.setUser();
                })
        },

        setUser() {
            let manual = _.get(this.form.settings, this.formSettings.keys.manual, '').trim();
            if (!manual || manual.length === 0) {
                return;
            }

            let user = this.findUser(manual)
            if (user) {
                this.dropdowns.users.selected = user;
            }

            return true;
        },

        findUser(manual) {
            let searchType = parseInt(_.get(this.form.settings, this.formSettings.keys.searchType, 0));
            if (parseInt(manual) > 0) {
                searchType = 0;
            }
            let field = ''
            switch (searchType) {
                case this.formSettings.searchByEmail:
                    field = 'email';
                    break;
                case this.formSettings.searchByFirst:
                    field = 'first_name';
                    break;
                case this.formSettings.searchByFirstLast: // First & Last Name
                    field = 'label'
                    break;
            }

            if (field) {
                return _.find(this.dropdowns.users.options, user => {
                    return user[field] === manual;
                })
            }

            return _.find(this.dropdowns.users.options, user => {
                return parseInt(user.id) === parseInt(manual);
            })
        },

        findUserMethodsUpdated() {
            _.set(this.form.settings, this.formSettings.keys.userType, _.get(this.dropdowns.findUserMethods, 'selected.value'))
            this.userListUpdated();
        },

        userListUpdated() {
            if (parseInt(_.get(this.form.settings, this.formSettings.keys.userType)) === this.formSettings.findUserSelectOne) {
                _.set(this.form.settings, this.formSettings.keys.searchType, this.formSettings.searchByUserId)
                _.set(this.form.settings, this.formSettings.keys.manual, _.get(this.dropdowns.users, 'selected.id'))
            }
        },

        userSearchFieldUpdated() {
            _.set(this.form.settings, this.formSettings.keys.searchType, _.get(this.dropdowns.searchUserField, 'selected.value'));
        },

        userTriggerFieldUpdate() {
            _.set(this.form.settings, this.formSettings.triggerField, _.get(this.dropdowns.triggerFields.selected, 'user.id'));
        }

    },

    computed: {

        usingSelectFromList() {
            return parseInt(_.get(this.dropdowns.findUserMethods, 'selected.value')) === this.formSettings.findUserSelectOne;
        },

        usingFindUsingTriggerField() {
            return parseInt(_.get(this.dropdowns.findUserMethods, 'selected.value')) === this.formSettings.findUserByTriggerValue;
        },

    }


}
