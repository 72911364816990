<template>
    <div>

        <div v-if="collapse"
             @click="openStep"
             :class="{'border-b border-gray-100': !last}"
             class="flex py-1 items-center cursor-pointer">

            <div class="mx-1 w-9 h-9 p-2 sm:w-10 sm:h-10 text-gray-300 transform rotate-90 flex-shrink-0 ">
                <chevron-right-icon/>
            </div>

            <div v-if="hasStepIcon"
                 class="hidden sm:block flex-shrink-0 sm:w-5 sm:h-5 md:w-6 md:h-6 text-indigo-300 mr-2"
                 v-html="icon"></div>

            <div class="flex-1 min-w-0">
                <p class="truncate text-sm sm:text-base md:text-lg font-medium" v-text="name"></p>
            </div>

            <div class="flex-shrink-0 flex items-center mr-1" @click.stop="">

                <status-badge v-if="statusName" class="mr-2 sm:mr-4"
                              :class="statusClass">
                    {{ statusName }}
                </status-badge>

                <filter-badge :badge-name="badgeName"
                              v-if="badgeName && badgeName.length > 0"
                              v-bind="$attrs"
                              :badge-count="badgeCount"/>

                <default-dropdown v-if="showMenu">
                    <template #trigger>
                        <button-menu size="9" spacing="2"/>
                    </template>
                    <template #content>
                        <slot name="menu"/>
                    </template>
                </default-dropdown>
            </div>


        </div>

        <div v-if="collapse === false"
             :class="{'border-b border-gray-100': !last}"
             class="py-3 px-4 sm:pt-6 sm:pb-8 sm:px-12">

            <div :class="wrapperClasses">

                <div v-if="hasCenterIcon"
                     @click="closeStep"
                     class="m-auto flex items-center justify-center rounded-full border border-gray-100 p-1 p-2 w-10 h-10 md:p-3 md:w-15 md:h-15 mb-2 cursor-pointer"
                     v-html="centerIcon"></div>

                <div @click="closeStep"
                     class="mb-1 h-8 sm:h-12 flex items-center cursor-pointer"
                     :class="centerContent ? 'justify-center' : 'justify-between'">

                    <div class="flex-1">
                        <p v-if="!nameEditable"
                           :class="{'text-center': centerContent}"
                           class="text-lg sm:text-xl font-medium cursor-pointer">
                            {{ name }}
                        </p>

                        <div v-else class="w-full overflow-hidden">
                            <form @submit.prevent="updateName"
                                  @keydown.enter.prevent="updateName">

                                <input v-model="nameValue"
                                       maxlength="50"
                                       @focus="editing = true"
                                       :class="customClasses"
                                       @blur="updateName"
                                       v-autowidth="{minWidth: '120px', comfortZone: 10}"
                                       class="border-dashed border-gray-400 text-lg sm:text-xl font-medium focus:outline-none placeholder-gray-500 w-full truncate cursor-auto"
                                       :placeholder="namePlaceholder"/>


                            </form>
                        </div>

                    </div>

                    <div @click.stop=""
                         class="flex-shrink-0 flex items-center justify-end -mr-4">

                        <status-badge v-if="statusName"
                                      class="mr-2 sm:mr-4"
                                      :class="statusClass">
                            {{ statusName }}
                        </status-badge>

                        <filter-badge v-if="badgeName"
                                      :badge-name="badgeName"
                                      v-bind="$attrs"
                                      :badge-count="badgeCount"/>

                        <default-dropdown v-if="showMenu">
                            <template #trigger>
                                <button-menu size="9" spacing="2"/>
                            </template>
                            <template #content>
                                <slot name="menu"/>
                            </template>
                        </default-dropdown>
                    </div>

                </div>

                <slot name="content"/>

            </div>

        </div>

        <slot name="add_event"/>

    </div>
</template>

<script>
import ChevronRightIcon from "../../../svgs/general/ChevronRightIcon"
import AutoWidth from "../../../directives/AutoWidth"
import DotsMenu from "../../../svgs/menu/DotsMenu"
import DefaultDropdown from "../../../elements/DefaultDropdown"
import ButtonMenu from "../../../elements/buttons/ButtonMenu"
import FilterBadge from "../../filters/FilterBadge"
import CloseXButton from "../../../elements/buttons/CloseXButton"
import StatusBadge from "../../../elements/badges/StatusBadge"

export default {
    name: 'FlowStep',

    components: {
        StatusBadge,
        CloseXButton,
        FilterBadge,
        ButtonMenu,
        DefaultDropdown,
        DotsMenu,
        ChevronRightIcon
    },

    props: {
        name: {
            type: String,
            default: 'Step Name'
        },

        nameEditable: {
            type: Boolean,
            default: true,
        },

        open: {
            type: String
        },

        close: {
            type: String
        },

        last: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: String,
            default: ''
        },

        centerContent: {
            type: Boolean,
            default: false,
        },

        centerIcon: {
            type: String,
            default: ''
        },

        namePlaceholder: {
            type: String,
            default: 'Event Name'
        },

        badgeName: {
            type: String,
        },

        badgeCount: {
            type: Number,
            default: 0,
        },
        statusName: {
            type: String,
        },
        statusClass: {
            type: String,
        },

    },

    data() {
        return {
            editing: false,
            nameValue: this.name,
        }
    },

    watch: {
        name(value) {
            this.nameValue = value
        }
    },

    directives: {
        autowidth: AutoWidth,
    },

    methods: {
        openStep() {
            this.$router.push({path: this.open})
        },

        closeStep(event) {
            if (event.target.tagName === 'INPUT') {
                return true;
            }

            if (this.editing) {
                return true;
            }

            this.$router.push({path: this.close})
        },

        updateName(event) {
            event.target.blur()
            this.$emit('name-updated', this.nameValue)
            setTimeout(() => {
                this.editing = false
            }, 500)
        },
    },

    computed: {
        collapse() {
            return this.$route.path !== this.open;
        },

        hasStepIcon() {
            return this.icon.length > 1;
        },

        hasCenterIcon() {
            return this.centerIcon.length > 1
        },

        wrapperClasses() {
            if (this.centerContent) {
                return 'm-auto max-w-md border border-gray-100 p-4 sm:p-8';
            }

            return '';
        },

        customClasses() {
            if (this.name) {
                return 'hover:border-b focus:border-b active:border-b'
            }

            return 'border-b'
        },

        showMenu() {
            return !!this.$slots['menu']
        }
    }
}
</script>

