<template>

    <div class="flex flex-col items-center justify-center">
        <div class="w-full sm:max-w-md">
            <div class="h-16">
                <parsey-logo/>
            </div>
        </div>

        <div class="mt-8 w-full sm:max-w-md">

            <div :class="{'animate-shake': form.errors.hasErrors()}"
                 class="sm:bg-white py-8 px-4 sm:shadow-lg sm:rounded sm:px-10 sm:py-12">

                <p class="text-2xl mb-4">
                    {{ $root.user.first_name }},
                </p>

                <p class="text-gray-400 pb-6">
                    Thank you for confirming your email address, now create
                    a secure password to login with
                </p>

                <form>
                    <div>
                        <label for="password"
                               class="block text-sm leading-5 text-gray-700">
                            Create Password
                        </label>
                        <div class="mt-3">
                            <input-field name="password"
                                         :form="form"
                                         type="password"
                                         placeholder="unique password"></input-field>

                            <password-meter v-show="showPasswordMeter"
                                            :strength-meter-only="true"
                                            @feedback="passwordFeedback"
                                            strength-meter-class="password-meter"
                                            @score="passwordScore"
                                            :badge="false" v-model="form.password"/>

                            <p v-if="showPasswordMeter"
                               v-text="scoreStatement"
                               class="text-xs text-gray-400"></p>
                        </div>
                    </div>
                    <div class="mt-6">
                        <label for="password"
                               class="block text-sm leading-5 text-gray-700">
                            Confirm Password
                        </label>
                        <div class="mt-3">
                            <input-field name="password_confirmation"
                                         :form="form"
                                         type="password"
                                         placeholder="confirm"></input-field>
                        </div>
                    </div>

                    <div class="mt-8">
                        <span class="block w-full rounded-md shadow-sm">
                            <button @click="save"
                                    :disabled="form.busy"
                                    :class="{'disabled':form.busy || unableToSave}"
                                    v-html="form.buttonHtml('Continue', 'Validating...')"
                                    class="btn btn-teal btn-block">
                            </button>
                        </span>
                    </div>

                </form>

            </div>

        </div>
    </div>
</template>

<script>

import PasswordMeter from 'vue-simple-password-meter'
import InputField from "../elements/fields/InputField"
import ParseyLogo from "../svgs/ParseyLogo"

export default {
    components: {
        ParseyLogo,
        PasswordMeter,
        InputField
    },

    metaInfo: {
        title: 'Finish Registration'
    },

    data() {
        return {
            feedback: null,
            score: null,
            form: new ParseyForm({
                password: '',
                password_confirmation: '',
            })
        }
    },

    methods: {

        passwordFeedback(value) {
            this.feedback = value;
        },

        passwordScore(value) {
            this.score = value;
        },

        scoreStrength() {
            switch (this.score) {
                case 0:
                    return 'Easy'
                case 1:
                    return 'Weak'
                case 2:
                    return 'Better'
                case 3:
                    return 'Great'
                case 4:
                    return 'Excellent'
            }
        },

        save() {
            Parsey.put('register', this.form)
                .then(response => {
                    this.$store.commit('updateUser', response.user)
                    this.$router.push({name: response.route})
                })
        },

    },

    computed: {
        scoreStatement() {
            let statement = this.scoreStrength()
            if (this.form.password.length < 6) {
                statement += ', too short minimum 6 characters'
            }

            return statement;
        },

        unableToSave() {
            if (this.form.password.length < 6) {
                return true;
            }

            if (this.form.password !== this.form.password_confirmation) {
                return true
            }

            return false;
        },

        showPasswordMeter() {
            return this.form.password.length > 0 && !this.form.errors.has('password')
        }
    }
}
</script>
