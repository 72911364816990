<template>
    <div class="flex justify-center flex-col items-center mb-6">

        <div v-show="showEvents"
             @click="showEvents = false"
             class="fixed inset-0"></div>

        <div class="h-4 sm:h-6 border-l border-gray-100"></div>

        <div class="relative">

            <button type="button" @click="openEvents"
                    class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-7 h-7 p-1.5 bg-gray-50 text-gray-700 rounded-full cursor-pointer transition transform duration-300 hover:scale-125 hover:bg-gray-100 hover:text-gray-900 hover:shadow">
                <spin-icon v-if="loading"/>
                <plus-icon v-else/>
            </button>

            <transition enter-active-class="transition ease-out duration-200"
                        enter-class="opacity-0 translate-y-1"
                        enter-to-class="opacity-100 translate-y-0"
                        leave-active-class="transition ease-in duration-150"
                        leave-class="opacity-100 translate-y-0"
                        leave-to-class="opacity-0 translate-y-1">

                <div v-show="showEvents"
                     class="absolute left-1/2 transform -translate-x-1/2 mb-12 bottom-0 px-2 w-screen max-w-xs sm:px-0">
                    <div class="rounded-lg shadow-lg">
                        <div :style="maxHeight"
                             class="rounded-lg shadow-xs overflow-hidden overflow-y-scroll">
                            <div class="z-20 relative grid gap-4 bg-white px-4 py-4 sm:gap-4 sm:p-4">
                                <template v-for="(event, index) in appEvents"
                                          :key="`event_${event.id}`">
                                    <a @click.prevent="addEvent(event.key)"
                                       :title="event.name"
                                       class="-m-2 p-2 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer overflow-x-hidden">

                                        <div class="h-5 w-5 flex-shrink-0 text-indigo-600"
                                             v-html="event.icon"></div>

                                        <div class="space-y-1 min-w-0">
                                            <p class="text-sm truncate font-medium leading-6 text-gray-700">
                                                {{ event.name }}
                                            </p>
                                        </div>
                                    </a>
                                    <div v-if="showDivider(event, index)"
                                         :key="`divider_${event.id}`"
                                         class="border-b border-gray-50"></div>
                                </template>


                            </div>
                        </div>
                    </div>

                </div>


            </transition>

        </div>


    </div>
</template>

<script>
import PlusIcon from "../../../svgs/menu/PlusIcon"
import SpinIcon from "../../../svgs/general/SpinIcon"

export default {
    name: "LegacyAddEvents",

    components: {
        SpinIcon,
        PlusIcon
    },

    props: {
        action: {
            type: Object,
        }
    },

    data() {
        return {
            showEvents: false,
            heightFromTop: 400,
            loading: false,
            form: new ParseyForm({
                key: '',
                settings: [],
            })
        }
    },

    methods: {

        openEvents(el) {
            if (this.loading) {
                return true;
            }
            let heightFromTop = el.clientY;
            this.heightFromTop = heightFromTop - 40;
            this.showEvents = true;
        },

        addEvent(key) {
            this.showEvents = false;
            this.loading = true;
            this.form.key = key;
            this.$store.dispatch('flow/createLegacyEvent', {
                action: this.action,
                form: this.form,
            })

        },

        showDivider(event, index) {
            if (!event.integration) {
                return false;
            }
            let next = parseInt(index) + 1;

            return this.appEvents[next].integration === false
        }
    },

    computed: {
        maxHeight() {
            return {
                'max-height': `${this.heightFromTop}px`
            }
        },

        appEvents() {
            let allEvents = this.$store.getters["apps/legacyEvents"](this.action.app.key);
            let flowType = _.get(this.$store.state.flow, 'data.trigger.type');

            return _.filter(allEvents, (event) => {
                if (event.triggers.includes('any')) {
                    return true;
                }

                if (event.triggers.includes(flowType)) {
                    return true;
                }

                return false;
            })


        }
    }

}
</script>
