<template>
    <div class="flex flex-shrink-0 justify-center pb-5">
        <div class="flex flex-col items-center">
            <router-link :to="{name: 'Settings'}" class="flex text-sm rounded-full focus:outline-none focus:border-gray-200 transition duration-150 ease-in-out">
                <default-avatar size="12" :border="true"/>
            </router-link>
        </div>
    </div>
</template>

<script>

import DefaultAvatar from "../../elements/DefaultAvatar"

export default {
    name: 'UserGravatar',

    components: {
        DefaultAvatar
    }
}
</script>
