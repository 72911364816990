<template>

    <div class="relative">

        <router-link :to="{path: flow.views.edit}">
            <div class="truncate">
                <div :class="showMenu ? 'pr-18' : 'pr-4'"
                     class="pl-4 py-3 truncate cursor-pointer">
                    <div class="flex items-center">
                        <ellipsis-middle-text :classes="`flex-shrink truncate font-semibold md:text-lg ${flowNameCss}`"
                                              :text="flowName"/>
<!--                        <span v-if="flow.legacy"-->
<!--                              class="leading-relaxed flex-shrink-0 inline rounded-full ml-2 md:ml-3 bg-gray-50 text-gray-600 px-2 md:px-3 md:pt-px md:pb-2px text-2xs md:text-xs">Legacy</span>-->
                    </div>
                </div>
            </div>
        </router-link>

        <div v-if="showMenu" class="absolute top-0 right-0 flex mr-1 mt-3">
            <toggle-bullet :form="toggleForm" />

            <flows-menu :flow="flow"
                        @rename-flow="showRenameForm = true"
                        @delete-flow="showDeleteForm = true"/>
        </div>

        <router-link :to="{path: flow.views.edit}">
            <div class="px-4 pb-3">
                <flow-actions-list :flow="flow"/>
            </div>
        </router-link>

        <rename-flow-modal :show="showRenameForm"
                           @close="showRenameForm = false"
                           :flow="flow"/>

        <delete-legacy-flow :show="showDeleteForm"
                            @close="showDeleteForm = false"
                            :flow="flow"/>

    </div>
</template>

<script>

import {flowIndexMixin} from "./mixins/flow-mixins"
import FlowActionsList from "./partials/FlowActionsList"
import DeleteLegacyFlow from "../legacy/modals/DeleteLegacyFlow"

export default {

    name: "FlowCard",

    components: {
        DeleteLegacyFlow,
        FlowActionsList
    },

    mixins: [
        flowIndexMixin
    ],

}
</script>


