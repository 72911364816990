<template>
    <div class="grid grid-cols-2 md:grid-cols-6 gap-4 mb-4">
        <div class="col-span-2 flex items-center">
            <select-field :options="options.search_fields"
                          v-model="form.field"
                          @input="updateRule"
                          label="name"
                          group-values="fields"
                          group-label="group"
                          placeholder="Select field"
                          track-by="id"/>
        </div>
        <div class="col-span-2 flex items-center">
            <select-field :options="options.conditions"
                          v-model="form.condition"
                          @input="updateRule"
                          label="name"
                          placeholder="Select condition"
                          track-by="id"/>
        </div>
        <div class="col-span-2 flex items-center">
            <div class="flex-1">
                <input-field :form="form"
                             name="value"
                             @blur="updateRule"
                             :id="`rule_${rule.id}`"
                             placeholder="Enter a value"/>
            </div>
            <div class="flex-shrink-0">
                <button @click.prevent="deleteRule"
                        :disabled="deleteForm.busy"
                        :class="{disabled: deleteForm.busy}"
                        class="h-8 w-8 sm:h-11 sm:w-11 p-2 sm:p-3 ml-2 text-gray-600 hover:text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none rounded-full cursor-pointer">
                    <spin-icon v-if="deleteForm.busy"/>
                    <trash-icon v-else/>
                </button>
            </div>
        </div>

        <div v-if="!last" class="col-span-2 sm:hidden">
            <div class="border-t my-2 border-gray-50 relative">
                <p class="absolute top-0 text-gray-100 w-full text-center -mt-3">
                    <span class="bg-white px-1">AND</span>
                </p>
            </div>
        </div>

    </div>

</template>

<script>
import SelectField from "../../elements/fields/SelectField"
import InputField from "../../elements/fields/InputField"
import TrashIcon from "../../svgs/general/TrashIcon"
import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'FilterRule',

    components: {
        SpinIcon,
        TrashIcon,
        InputField,
        SelectField
    },

    props: {
        rule: {
            type: Object,
        },

        options: {
            type: Object
        },

        last: {
            type: Boolean,
            default: false,
        }
    },

    watch: {
        'form.value'(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.$store.commit('filters/setChanged')
        }
    },

    data() {
        return {
            form: new ParseyForm({
                field: this.rule.field,
                condition: this.rule.condition,
                value: this.rule.value,
            }),

            deleteForm: new ParseyForm({}),
        }
    },

    methods: {
        updateRule() {
            this.$store.dispatch('filters/updateRule', {
                path: this.rule.routes.update,
                form: this.form,
                filterId: this.rule.filter_id,
            })
        },

        deleteRule() {
            this.$store.dispatch('filters/deleteRule', {
                path: this.rule.routes.update,
                form: this.deleteForm,
                filterId: this.rule.filter_id,
                ruleId: this.rule.id,
            })
        }
    }

}
</script>
