<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Choose which tag you'd like to apply or remove
        </p>

        <div class="mb-4">
            <select-field :options="search.options"
                          v-model="search.selected"
                          label="name"
                          placeholder="Type to search"
                          :internal-search="false"
                          @search-change="search.search($event)"
                          :loading="search.searching"
                          :options-limit="100"
                          :no-result="search.noResult"
                          track-by="id"/>
        </div>

        <div class="flex">
            <radio-field :id="`add_${event.id}`" value="add" v-model="form.settings.tagType">
                Apply Tag
            </radio-field>

            <radio-field :id="`remove_${event.id}`" value="remove" v-model="form.settings.tagType">
                Remove Tag
            </radio-field>

        </div>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import RadioField from "../../../../elements/fields/RadioField"
import SelectField from "../../../../elements/fields/SelectField"

export default {
    name: 'AddRemoveTag',

    components: {
        SelectField,
        RadioField,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    data() {
        return {
            search: new ParseySearch({
                url: this.action.routes.search,
                type: 'tags',
                default: {
                    id: 0,
                    name: 'Searching...'
                },
                noResult: 'No tags found with that search'
            }),
            form: new ParseyForm({
                onlyUpdateDoNotAdd: false,
                key: this.event.key,
                settings: {
                    tag_id: null,
                    tagType: null,
                    tag: null,
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        if (this.form.settings.tag_id) {
            this.search.selected = {
                id: this.form.settings.tag_id,
                name: this.form.settings.tag,
            }
            this.search.options = [
                this.search.selected
            ]
        }
    },

    methods: {
        save() {
            this.form.settings.tag_id = this.search.selected.id;
            this.form.settings.tag = this.search.selected.name;
            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

    },

    computed: {
        noResult() {
            if (this.search.query.length <= 1) {
                return 'Type at least 2 characters'
            }

            return 'No tags found. Consider changing what you are searching';
        }
    }
}
</script>

