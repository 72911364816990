<template>
    <div class="px-4 py-3 sm:px-6">
        <div class="flex items-center">
            <div class="flex-shrink-0">
                <div class="w-12 h-12 mr-4 rounded-full border p-2.5 border-gray-100">
                    <div v-if="loading" class="bg-gray-100 w-full h-full rounded-full"></div>
                    <app-icon :app="icon"/>
                </div>
            </div>
            <div class="flex-1 min-w-0">
                <div class="flex items-center justify-between">
                    <div v-if="loading" class="bg-gray-100 w-48 h-4 rounded-full"></div>
                    <p v-else class="text-sm font-medium text-indigo-600 truncate">
                        {{ title }}
                    </p>
                    <div class="ml-2 flex-shrink-0 flex">
                        <div v-if="loading" class="bg-gray-100 w-10 h-4 rounded-full"></div>
                        <status-badge :class="badgeClass">
                            {{ badge }}
                        </status-badge>
                    </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                    <div class="flex-1 min-w-0 sm:flex">
                        <div v-if="loading" class="bg-gray-100 w-72 h-4 rounded-full"></div>
                        <p v-else class="text-sm text-gray-500 truncate pr-6">
                            {{ description }}
                        </p>
                    </div>
                    <div class="flex-shrink-0 mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <calendar-solid-icon class="mr-1.5 h-5 w-5 text-gray-400"/>
                        <div v-if="loading" class="bg-gray-100 w-32 h-4 rounded-full"></div>
                        <p v-else>
                            <time :datetime="date.atom">
                                {{ date.formatted }}
                            </time>
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import CalendarSolidIcon from "../../../svgs/general/CalendarSolidIcon"
import AppIcon from "../../flows/components/partials/AppIcon"
import StatusBadge from "../../../elements/badges/StatusBadge"

export default {
    name: 'HistoryRow',

    components: {
        StatusBadge,
        AppIcon,
        CalendarSolidIcon
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: 'Unknown',
        },

        description: {
            type: String,
            default: '',
        },

        badge: {
            type: String,
            default: '',
        },

        badgeClass: {
            type: String,
            default: '',
        },

        date: {
            type: Object,
        },
    }
}
</script>