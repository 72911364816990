<template>

    <div>
        <h2>404</h2>
        Sorry we weren't able to find this page you are trying to access.
    </div>

</template>

<script>
export default {

    name: 'NotFound',

    metaInfo: {
        title: '404 Not Found',
    }
}
</script>

