<template>

    <div class="h-screen flex overflow-hidden">

        <mobile-sidebar :main-navigation="mainNavigation" :menu-open="menuOpen"/>

        <desktop-sidebar :main-navigation="mainNavigation"/>

        <div v-if="$route.meta.sidebar" class="hidden md:flex md:flex-shrink-0">
            <!-- Extra Menu Sidebar -->

            <div class="flex flex-col w-64 border-r border-gray-50 bg-white h-screen px-4 pt-7 pb-4">

                <component :is="$route.meta.sidebar"></component>

            </div>

        </div>

        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <div class="md:hidden px-1 pt-1 sm:pl-3 sm:pt-3 flex">
                <button @click.stop="$store.commit('openMenu')"
                        class="h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar">
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                </button>

                <div v-if="page"
                     :class="{'pr-12': ! $route.meta.sidebar}"
                     class="flex-1 flex items-center overflow-hidden min-w-0" >
                    <p class="w-full font-semibold justify-center text-gray-700 truncate text-center">
                        {{ page }}
                    </p>
                </div>

                <div v-if="$route.meta.dropdown" class="w-12 h-12 flex items-center justify-center md:hidden">
                    <component :is="$route.meta.dropdown"/>
                </div>

            </div>

            <main id="main" class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
                <slot/>
            </main>
        </div>

        <parsey-notifications />

    </div>


</template>

<script>
import DesktopSidebar from "./partials/DesktopSidebar"
import MobileSidebar from "./partials/MobileSidebar"
import DefaultDropdown from "../elements/DefaultDropdown"
import ButtonMenu from "../elements/buttons/ButtonMenu"
import DropdownLink from "../elements/DropdownLink"
import ParseyNotifications from "../elements/notifications/NotificationList"

export default {

    name: "DefaultLayout",

    components: {
        ParseyNotifications,
        DropdownLink,
        ButtonMenu,
        DefaultDropdown,
        MobileSidebar,
        DesktopSidebar
    },

    data() {
        return {
            mainNavigation: [
                {
                    name: 'Add Flow',
                    route: 'CreateLegacyFlow',
                    icon: 'PlusIcon',
                },
                {
                    name: 'Flows',
                    route: 'Flows',
                    icon: 'FlowIcon',
                },
                {
                    name: 'History',
                    route: 'History',
                    icon: 'HistoryIcon',
                },
            ]
        }
    },

    computed: {

        page() {
            return this.$store.state.page
        },

        menuOpen() {
            return this.$store.state.menuOpen;
        },

    }

}
</script>

