import store from "./store/index"

export const registered = (to, from, next) => {
    if (store.state.user.verified === false) {
        next({name: 'ConfirmEmail'})
        return;
    }

    if (store.state.user.registered === false) {
        next({name: 'FinishRegistration'})
        return;
    }
    next()
}

export const emailConfirmed = (to, from, next) => {
    if (store.state.user.verified === true) {
        next({name: 'Home'})
        return;
    }
    next()
}

export const finishedRegistration = (to, from, next) => {
    if (store.state.user.registered === true) {
        next({name: 'Home'})
        return;
    }
    next()
}

export const legacyFlowTypes = (to, from, next) => {
    if (!['webhooks', 'emails', 'attachments'].includes(to.params.type)) {
        next({name: '404'})
        return;
    }
    next()
}

export const legacyPanelTypes = (to, from, next) => {
    if (!['edit', 'trigger', 'actions'].includes(to.params.panel)) {
        next({name: '404'})
        return;
    }
    next()
}

export const legacyEventsTypes = (to, from, next) => {
    if (_.isEmpty(to.params.events)) {
        next()
        return;
    }
    if (!['auth', 'events'].includes(to.params.events)) {
        next({name: '404'})
        return;
    }
    next()
}
