<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            Configure how the opportunity should be created
        </p>

        <form-error :form="form"/>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 mb-4">

            <input-label :label="false">
                <template #label>
                    <div class="flex items-center justify-between">
                        <default-label>
                            Opportunity Title
                        </default-label>
                        <div>
                            <checkbox-field :id="`opportunity_title_${event.id}`"
                                            :font-medium="false"
                                            v-model="form.settings.title.type"
                                            value="1" true-value="1" false-value="0">
                                Use trigger field
                            </checkbox-field>
                        </div>
                    </div>
                </template>

                <event-input-field v-if="form.settings.title.type === '0'"
                                   placeholder="Type title"
                                   v-model="form.settings.title.manual"/>

                <select-field v-else
                              :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.title"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>

            </input-label>

            <input-label label="Stage">

                <select-field :options="dropdowns.stages.options"
                              v-model="dropdowns.stages.selected"
                              :loading="dropdowns.stages.loading"
                              label="label"
                              track-by="id"
                              placeholder="Select a stage"/>

            </input-label>
        </div>

        <div :class="{'md:grid-cols-3 gap-x-4': usingFindUsingTriggerField, 'md:grid-cols-2 gap-x-10': !usingFindUsingTriggerField}"
             class="grid grid-cols-1  mb-4">
            <input-label label="Assign User to Opportunity">
                <select-field :options="dropdowns.findUserMethods.options"
                              v-model="dropdowns.findUserMethods.selected"
                              @input="findUserMethodsUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="usingSelectFromList" label="Users">
                <select-field :options="dropdowns.users.options"
                              v-model="dropdowns.users.selected"
                              :loading="dropdowns.users.loading"
                              @input="userListUpdated"
                              label="label"
                              track-by="id"
                              placeholder="Select user"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Trigger Field">
                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.user"
                              @input="userTriggerFieldUpdate"
                              label="label"
                              track-by="id"
                              placeholder="Select trigger field"/>
            </input-label>

            <input-label v-if="usingFindUsingTriggerField" label="Search User Field">
                <select-field :options="dropdowns.searchUserField.options"
                              v-model="dropdowns.searchUserField.selected"
                              @input="userSearchFieldUpdated"
                              label="label"
                              track-by="value"
                              placeholder="Select user field"/>
            </input-label>

        </div>

        <tab-pills>

            <tab-pill name="Notes" :selected="true">

                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10">

                    <input-label :label="false">
                        <template #label>
                            <div class="flex items-center justify-between">
                                <default-label>
                                    Follow-up action
                                </default-label>
                                <div>
                                    <checkbox-field :id="`opportunity_next_action_${event.id}`"
                                                    :font-medium="false"
                                                    v-model="form.settings.next_action_notes.type"
                                                    value="1" true-value="1" false-value="0">
                                        Use trigger field
                                    </checkbox-field>
                                </div>
                            </div>
                        </template>

                        <text-area-field v-if="form.settings.next_action_notes.type === '0'"
                                         rows="5" placeholder="Follow up notes"
                                         v-model="form.settings.next_action_notes.manual"/>

                        <select-field v-else
                                      :options="triggerFields"
                                      v-model="dropdowns.triggerFields.selected.nextAction"
                                      label="label"
                                      track-by="id"
                                      placeholder="Select trigger field"/>

                    </input-label>

                    <input-label :label="false">
                        <template #label>
                            <div class="flex items-center justify-between">
                                <default-label>
                                    Notes
                                </default-label>
                                <div>
                                    <checkbox-field :id="`opportunity_note_${event.id}`"
                                                    :font-medium="false"
                                                    v-model="form.settings.opportunity_notes.type"
                                                    value="1" true-value="1" false-value="0">
                                        Use trigger field
                                    </checkbox-field>
                                </div>
                            </div>
                        </template>

                        <text-area-field v-if="form.settings.opportunity_notes.type === '0'"
                                         rows="5" placeholder="Type note description"
                                         v-model="form.settings.opportunity_notes.manual"/>

                        <select-field v-else
                                      :options="triggerFields"
                                      v-model="dropdowns.triggerFields.selected.note"
                                      label="label"
                                      track-by="id"
                                      placeholder="Select trigger field"/>

                    </input-label>

                </div>

            </tab-pill>


            <tab-pill name="Custom Fields">

                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="appFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Opportunity"
                                      :refreshing="loading.refreshing"/>

            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="appFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Opportunity"
                                     :refreshing="loading.refreshing"/>
            </tab-pill>
        </tab-pills>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import CheckboxField from "../../../../elements/fields/CheckboxField"
import SelectField from "../../../../elements/fields/SelectField"
import EventInputField from "../../../../elements/fields/EventInputField"
import TextAreaField from "../../../../elements/fields/TextAreaField"
import TabPill from "../../../../elements/tabs/TabPill"
import TabPills from "../../../../elements/tabs/TabPills"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"
import {mapGetters} from "vuex"
import FindUserOptions from "../mixins/find-user-options"
import DefaultLabel from "../../../../elements/DefaultLabel"

export default {
    name: 'Opportunity',

    components: {
        DefaultLabel,
        LegacyMagicFields,
        LegacyCustomFields,
        TabPills,
        TabPill,
        TextAreaField,
        EventInputField,
        SelectField,
        CheckboxField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        FindUserOptions
    ],


    data() {
        return {
            loading: {
                fields: true,
                refreshing: true,
            },

            dropdowns: {
                triggerFields: {
                    loading: false,
                    selected: {
                        title: null,
                        nextAction: null,
                        note: null,
                    }
                },

                stages: {
                    loading: true,
                    options: [],
                    selected: null,
                }
            },

            form: new ParseyForm({
                key: this.event.key,

                settings: {
                    magic_fields: {},
                    custom_fields: {},
                    stage: '',
                    title: {
                        extracted: '',
                        manual: '',
                        type: '0',
                    },
                    user: {
                        search_type: '1', // Email
                        type: '4', // Assign to contact owner
                        manual: '',
                        extracted: '',
                    },
                    next_action_notes: {
                        extracted: '',
                        manual: '',
                        type: '0',
                    },
                    opportunity_notes: {
                        extracted: '',
                        manual: '',
                        type: '0',
                    },
                },
            }),
        }
    },

    mounted() {

        this.dropdowns.triggerFields.selected.title = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.title.extracted);
        })

        this.dropdowns.triggerFields.selected.nextAction = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.next_action_notes.extracted);
        })

        this.dropdowns.triggerFields.selected.note = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.opportunity_notes.extracted);
        })

        this.getActionFields();
        this.getOpportunityStages();
    },

    methods: {

        save() {

            this.form.settings.title.extracted = _.get(this.dropdowns.triggerFields.selected, 'title.id');
            this.form.settings.stage = _.get(this.dropdowns.stages, 'selected.id');

            this.form.settings.next_action_notes.extracted = _.get(this.dropdowns.triggerFields.selected, 'nextAction.id');
            this.form.settings.opportunity_notes.extracted = _.get(this.dropdowns.triggerFields.selected, 'note.id');

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getActionFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'opportunity'
            }).then(() => {
                this.loading.fields = false;
                this.loading.refreshing = false;
            })
        },

        refreshFields() {
            this.loading.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'opportunity'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        getOpportunityStages() {
            Parsey.get(`${this.action.routes.search}?type=opportunityStages&search=all`)
                .then(results => {
                    this.dropdowns.stages.loading = false;
                    this.dropdowns.stages.options = results;
                    this.dropdowns.stages.selected = _.find(results, stage => {
                        return parseInt(stage.id) === parseInt(this.form.settings.stage);
                    })
                })
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {
        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        appFields() {
            if (this.action.fields) {
                return this.action.fields.opportunity;
            }

            return null;
        },
    }
}
</script>

