<template>
    <p class="text-gray-400 text-sm mb-5">
        <slot/>
    </p>
</template>

<script>
export default {
    name: "EventSubText"
}
</script>