<template>
    <div>
        <button v-if="as === 'button'"
                type="submit"
                @click="closeDropDown"
                class="block w-full px-4 py-2 text-sm leading-5 text-gray-600 text-left hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <slot></slot>
        </button>

        <button v-else-if="as === 'selectable'"
                type="submit"
                @click="closeDropDown"
                class="flex items-center justify-between w-full px-4 py-2 text-sm leading-5 text-gray-600 text-left hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <slot></slot>
            <checkmark-icon v-if="selected" class="w-5 h-5 text-blue-500 flex-shrink-0"/>
        </button>

        <router-link v-else
                     active-class="bg-gray-50" :to="to" exact
                     @click.native="closeDropDown"
                     class="text-left truncate block px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <slot></slot>
        </router-link>
    </div>
</template>

<script>
import CheckmarkIcon from "../svgs/general/CheckmarkIcon"

export default {
    components: {
        CheckmarkIcon
    },

    props: {
        to: {
            type: Object,
        },
        as: {
            type: String,
            default: 'link'
        },
        closeOnClick: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        closeDropDown() {
            if (this.closeOnClick && this.$parent.close) {
                this.$parent.close()
            }
        }
    }
}
</script>
