<template>
    <tr>
        <table-cell :bold="true">

            <p class="truncate">
                {{ mailbox.name }}
            </p>

        </table-cell>
        <table-cell>
            <p title="Click to Copy"
               v-tooltip="copyTooltip"
               class="cursor-pointer truncate"
               @click="copyData">
                {{ mailbox.address }}
            </p>
            <input type="hidden" :id="`mailboxAddress_${mailbox.id}`" :value="mailbox.address"/>
        </table-cell>
        <table-cell :vertical-padding="false"
                    :classes="['hidden lg:table-cell w-40']"
                    align="center">
            <numbered-badge v-if="mailbox.forward_addresses.length"
                            :clickable="false"
                            color="green"
                            :tool-tip="true"
                            :tool-tip-content="{content: forwardAddresses, placement: 'right', html: true}"
                            :number="mailbox.forward_addresses.length"
                            name="Forward"/>
        </table-cell>
        <table-cell :vertical-padding="false"
                    :classes="['w-40 hidden lg:table-cell']"
                    align="center">

            <default-dropdown align="right" width="56" offset="10">
                <template #trigger>
                    <numbered-badge v-if="mailbox.flows.length"
                                    :clickable="false"
                                    color="indigo"
                                    :number="mailbox.flows.length"
                                    name="Flow"/>
                </template>
                <template #content>
                    <dropdown-link v-for="flow in mailbox.flows" :key="flow.id"
                                   :to="{path: flow.views.edit}">
                        {{ flow.name }}
                    </dropdown-link>
                </template>
            </default-dropdown>


        </table-cell>
        <table-cell>
            <div class="flex justify-end items-center">
                <div class="w-6 h-6">
                    <default-dropdown>
                        <template #trigger>
                            <button-menu size="6" spacing="0"/>
                        </template>
                        <template #content>
                            <form @submit.prevent="edit">
                                <dropdown-link as="button">
                                    Edit
                                </dropdown-link>
                            </form>

                            <dropdown-link :to="{path: mailbox.views.history}">
                                History
                            </dropdown-link>

                            <horizontal-line />

                            <form @submit.prevent="deleteMailbox">
                                <dropdown-link as="button">
                                    Delete
                                </dropdown-link>
                            </form>
                        </template>
                    </default-dropdown>
                </div>
            </div>

            <mailbox-modal :show="showEdit"
                           @updated="updated"
                           @close="showEdit = false"
                           :mailbox="mailbox"/>

            <mailbox-delete-modal :show="showDelete"
                                  @close="showDelete = false"
                                  @deleted="deleted"
                                  :mailbox="mailbox"/>

        </table-cell>
    </tr>
</template>

<script>
import TableCell from "../../elements/tables/TableCell"
import NumberedBadge from "../../elements/badges/NumberedBadge"
import DefaultDropdown from "../../elements/DefaultDropdown"
import ButtonMenu from "../../elements/buttons/ButtonMenu"
import DropdownLink from "../../elements/DropdownLink"
import MailboxModal from "./MailboxModal"
import MailboxDeleteModal from "./MailboxDeleteModal"
import HorizontalLine from "../../elements/HorizontalLine"

export default {
    name: 'MailboxRow',

    components: {
        HorizontalLine,
        MailboxDeleteModal,
        MailboxModal,
        DropdownLink,
        ButtonMenu,
        DefaultDropdown,
        NumberedBadge,
        TableCell
    },

    mixins: [
        require('../../mixins/copy')
    ],

    props: [
        'mailbox'
    ],

    data() {
        return {
            copy: {
                inputId: `mailboxAddress_${this.mailbox.id}`
            },
            showEdit: false,
            showDelete: false,
        }
    },

    computed: {

        forwardAddresses() {
            if (!this.mailbox.forward_addresses.length) {
                return '';
            }

            return this.mailbox.forward_addresses.join("<br/>")
        }
    },

    methods: {
        edit() {
            this.showEdit = true
        },

        updated(data) {
            this.$emit('updated', data)
        },

        deleteMailbox() {
            this.showDelete = true;
        },

        deleted(data){
            this.$emit('deleted', data)
        }
    }
}
</script>
