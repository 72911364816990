<template>

    <div class="relative">
        <slot name="leading"/>
        <input :value="value"
               :type="type"
               v-bind="$attrs"
               :ref="refName"
               :class="inputClass"
               @input="$emit('update', $event.target.value)"
               class="p-3 block w-full border border-gray-100 rounded placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1 transition text-sm">
        <slot name="trailing"/>
        <div v-if="spinning" class="absolute top-0 right-0 h-input flex items-center justify-center mr-3">
            <spin-icon class="h-4 w-4 bg-white text-gray-400"/>
        </div>
        <p v-if="helpMessage.length > 1"
           :class="helpColor"
           class="mt-1 text-xs">
            {{ helpMessage }}
        </p>
    </div>

</template>

<script>
import SpinIcon from "../../svgs/general/SpinIcon"

export default {
    name: 'EventInputField',

    components: {
        SpinIcon
    },

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'update'
    },

    props: {
        id: {
            type: String,
        },

        value: {
            type: [String, Number],
        },

        type: {
            type: String,
            default: 'text',
        },

        help: {
            type: String,
            default: '',
        },

        form: {
            type: Object,
        },

        formKey: {
            type: String,
        },

        inputClass: {
            type: [String, Object],
        },

        spinning: {
            type: Boolean,
            default: false,
        },

        focus: {
            type: Boolean,
            default: false,
        },

    },

    mounted() {
        if (this.focus) {
            this.$nextTick(() => this.$refs[this.refName].focus())
        }
    },

    computed: {
        helpMessage() {
            if (this.form && this.form.errors.has(this.formKey)) {
                return this.form.errors.get(this.formKey);
            }

            if (this.help.length > 1) {
                return this.help;
            }

            return '';
        },

        helpColor() {
            if (this.form && this.form.errors.has(this.formKey)) {
                return 'text-red-600';
            }

            return 'text-gray-300';
        },

        refName() {
            if (this.formKey) {
                return `input-field-${this.formKey}`;
            }

            return 'input-field-' + _.random(1000, 99999);
        }
    }

}
</script>

