<template>

    <div :class="{'mb-4': !last}">
        <template v-for="(rule, key, index) in filter.rules" :key="rule.id">

            <filter-rule :rule="rule"
                         :last="index === Object.keys(filter.rules).length - 1"
                         :options="options"/>

        </template>

        <div class="flex items-center justify-between mb-4">
            <div class="flex items-center">
                <button type="button"
                        @click="addRule"
                        class="btn btn-sm bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-700 w-24 sm:w-28 font-medium"
                        :class="{disabled: addRuleForm.busy}"
                        :disabled="addRuleForm.busy"
                        v-html="addRuleForm.buttonHtml('+ and', 'adding...')"></button>

                <button v-if="last"
                        type="button"
                        @click="addFilter"
                        class="btn btn-sm bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-700 w-24 sm:w-28 font-medium ml-4"
                        :class="{disabled: addFilterForm.busy}"
                        :disabled="addFilterForm.busy"
                        v-html="addFilterForm.buttonHtml('+ or', 'adding...')"></button>
            </div>

            <div class="flex justify-end">
                <p v-if="saving === filter.id" class="text-indigo-600 text-sm">Saving...</p>
                <button v-else-if="last"
                        @click.prevent="close"
                        :disabled="changed"
                        :class="{disabled: changed}"
                        class="btn btn-sm btn-teal-outline">
                    Close
                </button>
            </div>

        </div>

        <div v-if="!last" class="pt-4 border-t border-gray-50">
            <p class="text-sm text-gray-400">OR run this {{ property.type }} if...</p>
        </div>

    </div>

</template>

<script>

import FilterRule from "./FilterRule"
import {mapMutations, mapState} from "vuex"

export default {
    name: 'LegacyFilter',

    components: {
        FilterRule
    },

    props: {
        filter: {
            type: Object,
        },

        options: {
            type: Object,
        },

        last: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {
            addFilterForm: new ParseyForm({}),
            addRuleForm: new ParseyForm({}),
        }
    },

    methods: {
        ...mapMutations('filters', [
            'close',
        ]),

        addFilter() {
            this.$store.dispatch('filters/addFilter', {
                form: this.addFilterForm
            })
        },

        addRule() {
            this.$store.dispatch('filters/addRule', {
                path: this.filter.routes.rules,
                form: this.addRuleForm
            })
        },
    },

    computed: {
        ...mapState('filters', [
            'property',
            'saving',
            'changed',
        ]),
    }
}
</script>
