<template>

    <default-wrapper>

        <h1 class="mb-3 text-xl font-semibold text-gray-900 hidden md:block">Dashboard</h1>

        <dashboard-search/>

        <div class="mt-4 well">
            <div class="well-body">
                <p class="text-xl text-center my-12">On-boarding Widget</p>
            </div>
        </div>

        <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="well">
                <div class="well-body">
                    <p class="text-xl my-12 text-center">Quick Create or Edit Recent Flows</p>
                </div>
            </div>
            <div class="well">
                <div class="well-body">
                    <p class="text-xl my-12 text-center">Recently Processed Events</p>
                </div>
            </div>
        </div>

        <div class="mt-4 well">
            <div class="well-body">
                <p class="text-xl text-center my-12">Another Widget</p>
            </div>
        </div>

    </default-wrapper>

</template>

<script>
import DashboardSearch from "./components/DashboardSearch"
import DefaultWrapper from "../../layouts/partials/DefaultWrapper"

export default {

    name: "DashboardIndex",

    components: {
        DefaultWrapper,
        DashboardSearch
    },

    metaInfo: {
        title: 'Dashboard'
    }
}
</script>

