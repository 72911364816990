<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            This event will update an existing opportunity if one is found. If none is found it will
            do nothing. It always searches based on the most recently updated opportunity with
            the following criteria.
        </p>

        <form-error :form="form"/>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 mb-4">
            <input-label label="Find opportunity with">
                <select-field :options="dropdowns.findBy.options"
                              v-model="dropdowns.findBy.selected"
                              @input="updateFindBy"
                              label="label"
                              track-by="value"
                              placeholder="Select option"/>
            </input-label>

            <input-label v-if="parseInt(form.settings.findBy) === 0"
                         label="Select stages opportunity will be in">

                <select-field :options="dropdowns.searchStages.options"
                              v-model="dropdowns.searchStages.selected"
                              :loading="dropdowns.searchStages.loading"
                              label="label"
                              track-by="id"
                              :multiple="true"
                              placeholder="Select stages"/>

            </input-label>

            <input-label v-if="parseInt(form.settings.findBy) === 1"
                         label="Trigger Field with opportunity id">

                <select-field :options="triggerFields"
                              v-model="dropdowns.triggerFields.selected.opportunity"
                              label="label"
                              track-by="id"
                              placeholder="Select Trigger Field"/>

            </input-label>
        </div>

        <tab-pills>

            <tab-pill name="Opportunity Fields" :selected="true">

                <p class="text-gray-400 text-sm mb-3">
                    Update the stage or assign a different owner. You can
                    also adjust any of the Custom Field options and Magic Field options.
                </p>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
                    <input-label label="Stage">
                        <select-field :options="dropdowns.stages.options"
                                      v-model="dropdowns.stages.selected"
                                      :loading="dropdowns.stages.loading"
                                      label="label"
                                      track-by="id"
                                      placeholder="Select stage"/>
                    </input-label>
                </div>


                <div :class="{'md:grid-cols-3 gap-x-4': usingFindUsingTriggerField, 'md:grid-cols-2 gap-x-10': !usingFindUsingTriggerField}"
                     class="grid grid-cols-1  mb-4">
                    <input-label label="Assign Opportunity to User">
                        <select-field :options="dropdowns.findUserMethods.options"
                                      v-model="dropdowns.findUserMethods.selected"
                                      @input="findUserMethodsUpdated"
                                      label="label"
                                      track-by="value"
                                      placeholder="Select option"/>
                    </input-label>

                    <input-label v-if="usingSelectFromList" label="Users">
                        <select-field :options="dropdowns.users.options"
                                      v-model="dropdowns.users.selected"
                                      :loading="dropdowns.users.loading"
                                      @input="userListUpdated"
                                      label="label"
                                      track-by="id"
                                      placeholder="Select user"/>
                    </input-label>

                    <input-label v-if="usingFindUsingTriggerField" label="Trigger Field">
                        <select-field :options="triggerFields"
                                      v-model="dropdowns.triggerFields.selected.user"
                                      @input="userTriggerFieldUpdate"
                                      label="label"
                                      track-by="id"
                                      placeholder="Select trigger field"/>
                    </input-label>

                    <input-label v-if="usingFindUsingTriggerField" label="Search User Field">
                        <select-field :options="dropdowns.searchUserField.options"
                                      v-model="dropdowns.searchUserField.selected"
                                      @input="userSearchFieldUpdated"
                                      label="label"
                                      track-by="value"
                                      placeholder="Select user field"/>
                    </input-label>

                </div>
            </tab-pill>


            <tab-pill name="Custom Fields">

                <legacy-custom-fields :fields="triggerFieldAsObject"
                                      :custom-fields="customFields"
                                      :app-fields="appFields"
                                      @update="customFieldsUpdated"
                                      @refresh="refreshFields"
                                      name="Opportunity"
                                      :refreshing="loading.refreshing"/>

            </tab-pill>

            <tab-pill name="Magic Fields">
                <legacy-magic-fields :fields="triggerFieldAsObject"
                                     :magic-fields="magicFields"
                                     :app-fields="appFields"
                                     @update="magicFieldsUpdated"
                                     @refresh="refreshFields"
                                     name="Opportunity"
                                     :refreshing="loading.refreshing"/>
            </tab-pill>
        </tab-pills>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import FindUserOptions from "../mixins/find-user-options"
import SelectField from "../../../../elements/fields/SelectField"
import {mapGetters} from "vuex"
import TabPills from "../../../../elements/tabs/TabPills"
import TabPill from "../../../../elements/tabs/TabPill"
import LegacyCustomFields from "../partials/LegacyCustomFields"
import LegacyMagicFields from "../partials/LegacyMagicFields"

export default {
    name: 'UpdateOpportunity',

    components: {
        LegacyMagicFields,
        LegacyCustomFields,
        TabPill,
        TabPills,
        SelectField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },

    mixins: [
        FindUserOptions
    ],

    data() {
        return {
            loading: {
                fields: true,
                refreshing: true,
            },
            dropdowns: {
                triggerFields: {
                    selected: {
                        opportunity: null,
                    }
                },
                findBy: {
                    selected: null,
                    options: [
                        {
                            label: 'One of the selected stages',
                            value: 0,
                        },
                        {
                            label: 'The following opportunity id',
                            value: 1,
                        }
                    ],
                },
                searchStages: {
                    loading: true,
                    selected: [],
                    options: [],
                },
                stages: {
                    loading: true,
                    selected: null,
                    options: [],
                },
                findUserMethods: {
                    options: [
                        {
                            label: 'Choose user from a list',
                            value: 1,
                        },
                        {
                            label: 'Skip, do not update the current user',
                            value: 3,
                        },
                        {
                            label: 'Find user using a trigger field',
                            value: 2,
                        },
                        // Remove since it's the same as 3, if it's set to zero it will be updated to be 3
                        // {
                        //     label: 'Skip User Assignment',
                        //     value: 0,
                        // }
                    ],
                }

            },
            form: new ParseyForm({
                key: this.event.key,
                settings: {
                    findBy: 0,
                    opportunityActiveStages: null,
                    opportunityIdField: null,
                    stage: 0,
                    user: {
                        type: 3,
                        manual: '',
                        search_type: null,
                        extracted: null,
                    },
                    custom_fields: {},
                    magic_fields: {},
                },
            }),
        }
    },

    mounted() {
        this.dropdowns.findBy.selected = _.find(this.dropdowns.findBy.options, option => {
            return parseInt(option.value) === parseInt(this.form.settings.findBy)
        })

        this.dropdowns.triggerFields.selected.opportunity = _.find(this.triggerFields, field => {
            return parseInt(field.id) === parseInt(this.form.settings.opportunityIdField);
        })

        this.dropdowns.findUserMethods.selected = _.find(this.dropdowns.findUserMethods.options, option => {
            if (parseInt(this.form.settings.user.type) === 0) {
                return parseInt(option.value) === 3;
            }
            return parseInt(option.value) === parseInt(this.form.settings.user.type);
        })

        this.getOpportunityFields();
        this.getOpportunityStages();
    },

    methods: {

        save() {

            this.form.settings.stage = _.get(this.dropdowns.stages, 'selected.id');
            this.form.settings.findBy = _.get(this.dropdowns.findBy, 'selected.value')
            this.form.settings.opportunityIdField = _.get(this.dropdowns.triggerFields.selected, 'opportunity.id')
            this.form.settings.opportunityActiveStages = _.map(this.dropdowns.searchStages.selected, 'id')

            // console.log('FORM SETTINGS', JSON.parse(JSON.stringify(this.form.settings)));

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getOpportunityFields() {
            this.$store.dispatch('flow/getActionFields', {
                action: this.action,
                type: 'opportunity'
            }).then(() => {
                this.loading.fields = false;
                this.loading.refreshing = false;
            })
        },

        refreshFields() {
            this.loading.refreshing = true;
            this.$store.dispatch('flow/refreshActionFields', {
                action: this.action,
                type: 'opportunity'
            }).then(() => {
                this.loading.refreshing = false;
            })
        },

        prependStages(stages) {
            return [
                {
                    id: 0,
                    label: 'Skip, do not update stage'
                },
                ...stages
            ]
        },

        getOpportunityStages() {
            Parsey.get(`${this.action.routes.search}?type=opportunityStages&search=all`)
                .then(results => {
                    this.dropdowns.searchStages.loading = false;
                    this.dropdowns.searchStages.options = results;
                    this.dropdowns.stages.loading = false;
                    this.dropdowns.stages.options = this.prependStages(results);
                    this.dropdowns.searchStages.selected = _.filter(results, stage => {
                        if (!this.form.settings.opportunityActiveStages) {
                            return false;
                        }

                        return this.form.settings.opportunityActiveStages.includes(stage.id.toString());
                    })
                    this.dropdowns.stages.selected = _.find(this.dropdowns.stages.options, option => {
                        if (this.form.settings.stage.length === 0) {
                            return parseInt(option.id) === 0;
                        }

                        return parseInt(option.id) === parseInt(this.form.settings.stage)
                    })
                })
        },

        updateFindBy() {
            this.form.settings.findBy = _.get(this.dropdowns.findBy, 'selected.value');
        },

        customFieldsUpdated(mappedFields) {
            this.form.settings.custom_fields = mappedFields
        },

        magicFieldsUpdated(mappedFields) {
            this.form.settings.magic_fields = mappedFields
        },

    },

    computed: {

        ...mapGetters('flow', {
            triggerFields: 'triggerFieldList'
        }),

        triggerFieldAsObject() {
            return _.get(this.$store.state.flow.data, 'trigger.fields');
        },

        customFields() {
            return _.get(this.event, 'settings.custom_fields', {})
        },

        magicFields() {
            return _.get(this.event, 'settings.magic_fields', {})
        },

        appFields() {
            if (this.action.fields) {
                return this.action.fields.opportunity;
            }

            return null;
        },

    }
}
</script>

