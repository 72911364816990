<template>
    <div>

        <event-sub-text>
            Map trigger fields to {{ name }} fields
        </event-sub-text>

        <map-fields-grid>
            <div class="hidden sm:block">
                <p class="text-sm sm:text-base font-medium">Trigger Fields</p>
            </div>
            <div class="hidden sm:flex items-center">
                <p class="text-sm sm:text-base font-medium">{{ name }} Fields</p>
                <refresh-button @refresh="refreshFields" :refreshing="loading || refreshing"/>
            </div>
        </map-fields-grid>

        <map-fields-grid v-for="field in fields" :key="field.id">
            <div class="hidden sm:flex items-center justify-end">
                <div class="flex-1">
                    <fake-input>
                        {{ field.label }}
                    </fake-input>
                </div>
                <div class="flex-shrink-0 ml-4">
                    <div class="h-6 w-6 text-gray-400">
                        <right-arrow-icon />
                    </div>
                </div>

            </div>

            <div class="flex items-center">
                <div v-if="loading"
                     class="flex-1 animate-pulse">
                    <div class="w-full h-input rounded-lg bg-gray-100"></div>
                </div>
                <div v-else class="flex-1">

                    <div class="sm:hidden">
                        <p class="text-sm text-gray-700 mb-1">
                            <span class="text-gray-400 uppercase">Field:</span>
                            {{ field.label }}
                        </p>
                    </div>

                    <select-field :options="appFields"
                                  v-model="fieldMap[field.id].value"
                                  @input="updateFields"
                                  :loading="refreshing"
                                  label="label"
                                  group-values="fields"
                                  group-label="group"
                                  :placeholder="`Select one ${name} field`"
                                  track-by="name"/>
                </div>
            </div>
        </map-fields-grid>

    </div>


</template>

<script>
import ChevronRightIcon from "../../../../svgs/general/ChevronRightIcon"
import SelectField from "../../../../elements/fields/SelectField"
import RefreshButton from "../../../../elements/buttons/RefreshButton"
import MapFieldsGrid from "../../../../elements/tables/MapFieldsGrid"
import RightArrowIcon from "../../../../svgs/general/RightArrowIcon"
import FakeInput from "../../../../elements/fields/FakeInput"
import EventSubText from "../elments/EventSubText"

export default {
    name: 'MapFields',

    components: {
        EventSubText,
        FakeInput,
        RightArrowIcon,
        MapFieldsGrid,
        RefreshButton,
        SelectField,
        ChevronRightIcon,
    },

    props: {
        refreshing: {
            type: Boolean,
            default: false,
        },

        name: {
            type: String,
            default: 'App'
        },

        fields: {
            type: Object,
        },

        appFields: {
            type: Array,
            default: () => {
                return []
            }
        },

        extractionFields: {
            type: Object,
        }
    },

    data() {
        return {
            fieldMap: null,
        }
    },

    watch: {
        appFields() {
            this.updateMap()
        }
    },

    created() {
        this.updateMap();
    },

    methods: {

        updateMap() {
            this.fieldMap = _.keyBy(_.map(this.fields, field => {
                return {id: field.id, value: this.mapValue(field.id)}
            }), 'id')
        },

        mapValue(fieldId) {
            let field = _.get(this.extractionFields, `[${fieldId}]`, '');
            let value = null;

            if (!field) {
                return null;
            }

            _.find(this.appFields, appField => {
                let foundField = _.find(appField.fields, groupField => {
                    return groupField.name === field;
                })
                if (foundField) {
                    value = foundField;
                    return true;
                }

                return false;
            })

            return value
        },

        updateFields() {
            let fields = _(this.fieldMap)
                .pickBy(field => {
                    return field.value !== null;
                })
                .mapValues(field => {
                    return field.value.name;
                });

            this.$emit('updated', fields.value())
        },

        refreshFields() {
            this.$emit('refresh')
        }
    },

    computed: {
        loading() {
            if (!this.appFields) {
                return true;
            }

            return _.size(this.appFields) === 0;
        }
    }

}
</script>
