<template>
    <div>
        <div class="flex items-center">
            <input type="checkbox"
                   :id="id"
                   :value="value"
                   :checked="shouldBeChecked"
                   @change="updateInput"
                   class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-200 rounded-sm">
            <label :for="id"
                   :class="{'font-medium': fontMedium, 'text-sm': textSmall}"
                   class="ml-3 block ">
                <slot/>
            </label>
        </div>
        <div v-if="form">
            <p class="ml-7 mt-1 text-red-600 text-xs"
               v-show="form.errors.has(formKey)"
               v-text="form.errors.get(formKey)"></p>
        </div>

    </div>
</template>

<script>
export default {
    name: "CheckboxField",

    model: {
        prop: 'modelValue',
        event: 'change'
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        value: {
            type: String,
        },

        modelValue: {
            default: false
        },

        trueValue: {
            default: true
        },

        falseValue: {
            default: false
        },

        form: {
            type: Object,
        },

        formKey: {
            type: String,
        },

        fontMedium: {
            type: Boolean,
            default: true,
        },

        textSmall: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        shouldBeChecked() {
            if (this.modelValue instanceof Array) {
                return this.modelValue.includes(this.value)
            }

            return this.modelValue === this.trueValue
        }
    },

    methods: {
        updateInput(event) {
            let isChecked = event.target.checked

            if (this.modelValue instanceof Array) {
                let newValue = [...this.modelValue]

                if (isChecked) {
                    newValue.push(this.value)
                } else {
                    newValue.splice(newValue.indexOf(this.value), 1)
                }

                this.$emit('change', newValue)
            } else {
                this.$emit('change', isChecked ? this.trueValue : this.falseValue)
            }
        }
    }


}
</script>

