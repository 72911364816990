<template>

    <div class="border border-gray-100 rounded mb-2">
        <div v-show="!showEdit" class="flex justify-between items-center">
            <div class="flex-shrink-0 py-2 px-3">
                <p class="text-gray-700 text-sm">
                    {{ field.label }}
                </p>
            </div>
            <div class="flex">
                <div class="w-7 h-7 p-1 cursor-pointer"
                     @click="toggleShowing"
                     :class="{'text-gray-200': !showing, 'text-indigo-300': showing}">
                    <eye-icon/>
                </div>

                <default-dropdown>
                    <template #trigger>
                        <div class="w-7 h-7 p-1 text-gray-200 mr-1">
                            <dots-menu/>
                        </div>
                    </template>

                    <template #content>
                        <form @submit.prevent="showEdit = true">
                            <dropdown-link as="button">
                                Rename
                            </dropdown-link>
                        </form>

                        <horizontal-line />

                        <form @submit.prevent="deleteField">
                            <dropdown-link as="button">
                                Delete
                            </dropdown-link>
                        </form>

                    </template>

                </default-dropdown>

            </div>

        </div>

        <div v-show="showEdit" class="py-2 px-3">

            <form @submit.prevent="save" @keydown.enter="save">
                <input-label label="Name">
                    <input-field :form="form"
                                 :id="`field_${field.id}`"
                                 name="name"
                                 help="Label for your reference"
                                 placeholder="Field name"/>
                </input-label>

                <div class="flex justify-between">
                    <submit-button :form="form"/>

                    <button @click.prevent="showEdit = false"
                            class="btn btn-sm border-none btn-white">
                        Cancel
                    </button>
                </div>

            </form>

        </div>

    </div>

</template>

<script>
import DotsMenu from "../../../../svgs/menu/DotsMenu"

import EyeIcon from "../../../../svgs/general/EyeIcon"
import DefaultDropdown from "../../../../elements/DefaultDropdown"
import DropdownLink from "../../../../elements/DropdownLink"
import InputLabel from "../../../../elements/fields/InputLabel"
import InputField from "../../../../elements/fields/InputField"
import SubmitButton from "../../../../elements/buttons/SubmitButton"
import HorizontalLine from "../../../../elements/HorizontalLine"

export default {
    name: 'LegacyWebhookField',

    components: {
        HorizontalLine,
        SubmitButton,
        InputField,
        InputLabel,
        DropdownLink,
        DefaultDropdown,
        EyeIcon,
        DotsMenu
    },

    props: {
        field: {
            type: Object,
        }
    },

    data() {
        return {
            showEdit: false,
            form: new ParseyForm({
                id: this.field.id,
                name: this.field.label,
            })
        }
    },

    computed: {
        showing() {
            return _.get(this.field, 'path') === _.get(this.$store.state.flow, 'highlight.path')
        }
    },

    methods: {

        save() {
            this.$store.dispatch('flow/updateLegacyField', this.form)
                .then(() => {
                    this.showEdit = false;
                })
        },

        deleteField() {
            this.$store.dispatch('flow/deleteLegacyField', {
                field: this.field,
                form: this.form,
            })
        },

        toggleShowing() {
            if (this.showing) {
                return this.$store.commit('flow/unHighlightPath', this.field.path)
            }

            return this.$store.commit('flow/highlightPath', this.field.path)
        },


    }
}
</script>
