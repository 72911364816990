<template>

    <div :class="{'animate-pulse': loading}"
         @mouseenter="active = true"
         @mouseleave="active = false"
         class="flex flex-col items-center justify-center cursor-pointer">
        <div class="w-11 h-11 md:w-16 md:h-16 flex justify-center items-center mb-2">
            <div v-if="creating" class="text-indigo-300 p-4 border border-gray-100 rounded-full">
                <spin-icon/>
            </div>
            <div v-else :class="iconClasses" v-html="icon"></div>
        </div>

        <div v-html="appName"></div>
    </div>

</template>

<script>
import SpinIcon from "../../../../svgs/general/SpinIcon"

export default {
    name: 'LegacyApp',

    components: {SpinIcon},

    props: {
        app: {
            type: Object,
        },

        loading: {
            type: Boolean,
            default: true,
        },

        creating: {
            type: Boolean,
            default: false,
        },

    },

    data() {
        return {
            active: false,
        }
    },

    computed: {
        iconClasses() {
            let classList = [
                'rounded-full  p-2 md:p-3 '
            ];

            if (!this.active) {
                classList.push('w-10 h-10 md:w-15 md:h-15 border border-gray-100 ')
            }

            if (this.active) {
                classList.push('w-11 h-11 md:w-16 md:h-16 border-2 border-indigo-600')
            }

            return classList.join(' ');
        },

        icon() {
            if (this.loading) {
                return '<div class="bg-gray-100 w-full h-full rounded-full"></div>'
            }

            return this.app.icon;
        },

        appName() {
            if (this.loading) {
                return '<div class="bg-gray-50 w-24 h-4 rounded-full"></div>';
            }

            let classes = 'text-sm text-gray-400';
            if (this.active) {
                classes = 'text-sm text-gray-600 font-medium'
            }

            return `<p class="${classes}">${this.app.name}</p>`
        },
    },

}
</script>
