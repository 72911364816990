<template>

    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
        <slot/>
    </p>

</template>

<script>

export default {
    name: 'StatusBadge',
}
</script>
