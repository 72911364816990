<template>
    <form @submit.prevent="save">

        <p class="text-gray-400 text-sm mb-3">
            This will create or find a tag with the following details, then apply or remove it to
            the contact.
        </p>

        <form-error :form="form"/>

        <input-label label="Tag Name">
            <event-input-field v-model="form.settings.tag"
                               placeholder="My lucky tag name"/>
        </input-label>

        <input-label label="Category">
            <select-field :options="tagCategories"
                          v-model="form.dropdowns.category"
                          label="label"
                          track-by="id"
                          :loading="loading.categories"
                          placeholder="Select category"/>
        </input-label>

        <div class="flex">
            <radio-field :id="`add_${event.id}`" value="add" v-model="form.settings.tagType">
                Apply Tag
            </radio-field>

            <radio-field :id="`remove_${event.id}`" value="remove" v-model="form.settings.tagType">
                Remove Tag
            </radio-field>

        </div>

        <legacy-event-save-button :event="event" :form="form"/>

    </form>
</template>

<script>
import FormError from "../../../../elements/alerts/FormError"
import LegacyEventSaveButton from "../partials/LegacyEventSaveButton"
import InputLabel from "../../../../elements/fields/InputLabel"
import EventInputField from "../../../../elements/fields/EventInputField"
import SelectField from "../../../../elements/fields/SelectField"
import RadioField from "../../../../elements/fields/RadioField"

export default {
    name: 'CreateTag',

    components: {
        RadioField,
        SelectField,
        EventInputField,
        InputLabel,
        FormError,
        LegacyEventSaveButton
    },

    props: {
        action: {
            type: Object,
        },

        event: {
            type: Object,
        }
    },


    data() {
        return {
            loading: {
                categories: true,
            },
            lists: {
                categories: []
            },
            form: new ParseyForm({
                key: this.event.key,
                dropdowns: {
                    category: null,
                },
                settings: {
                    tag: '',
                    category_id: '0',
                    tagType: 'add',
                },
            }),
        }
    },

    mounted() {
        if (this.event && _.size(this.event.settings) > 0) {
            this.form.settings = JSON.parse(JSON.stringify(this.event.settings));
        }

        this.getCategories()
    },

    methods: {

        save() {
            this.form.settings.category_id = _.get(this.form, 'dropdowns.category.id');

            console.log('FORM SETTINGS', this.form.settings);

            this.$store.dispatch("flow/saveLegacyEvent", {
                action: this.action,
                event: this.event,
                form: this.form,
            })
        },

        getCategories() {
            Parsey.get(`${this.action.routes.search}?type=tagCategories&search=all`)
                .then(results => {
                    this.lists.categories = results;
                    this.loading.categories = false;
                    this.form.dropdowns.category = _.find(this.tagCategories, category => {
                        return category.id === parseInt(this.form.settings.category_id);
                    })
                })
        }

    },

    computed: {
        tagCategories() {
            let categories = [
                {
                    label: "Don't use a category",
                    id: '0',
                },
            ]

            return categories.concat(this.lists.categories);
        }
    }
}
</script>

