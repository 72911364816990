<template>

    <div class="relative">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <div class="h-6 w-6 text-gray-300">
                <search-icon/>
            </div>
        </div>
        <input id="flow-search"
               ref="flowSearch"
               type="text"
               v-model="search"
               class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-lg border-gray-100 rounded-md"
               placeholder="Search ">
    </div>

</template>

<script>
import SearchIcon from "../../../svgs/general/SearchIcon"

export default {

    name: "FlowSearch",

    components: {
        SearchIcon
    },

    mounted(){
        this.$nextTick(() => this.$refs.flowSearch.focus())
    },

    computed: {
        search: {
            get() {
                return this.$store.state.flows.search;
            },
            set(value) {
                this.$store.commit('flows/setSearch', value)
            }
        }
    }
}
</script>
