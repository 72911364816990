module.exports = {

    post(uri, form) {
        return Parsey.sendForm('post', uri, form);
    },

    put(uri, form) {
        return Parsey.sendForm('put', uri, form);
    },

    patch(uri, form) {
        return Parsey.sendForm('patch', uri, form);
    },

    delete(uri, form) {
        return Parsey.sendForm('delete', uri, form);
    },

    get(uri) {
        return new Promise((resolve, reject) => {
            axios.get(`${uri}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(errors => {
                    if (errors.response.status === 403) {
                        location.reload();
                    }
                    reject(errors.response.data);
                });
        });
    },

    sendForm(method, uri, form) {
        return new Promise((resolve, reject) => {
            form.startProcessing();

            axios[method](`${uri}`, JSON.parse(JSON.stringify(form)))
                .then(response => {
                    if (_.has(response, 'data.redirect_url')) {
                        form.busy = true;
                        form.redirecting = 'Success, redirecting...'
                        window.location = _.get(response, 'data.redirect_url')
                        return;
                    }
                    form.finishProcessing();
                    resolve(response.data);
                })
                .catch(errors => {
                    form.setErrors(errors.response.data.errors);
                    if (errors.response.status == 419) {
                        location.reload()
                    }

                    reject(errors.response.data);
                });
        });
    }

}
