<template>

    <flow-step :name="event.name"
               :open="$parent.openPath(event.id)"
               :close="action.views.edit"
               :icon="eventIcon"
               :last="false"
               badge-name="Filter"
               :badge-count="event.filters"
               @badge-clicked="openFilters"
               @name-updated="updateEventName">

        <template #content>
            <component :is="eventComponent" :event="event" :action="action"/>
        </template>

        <template #menu v-if="showMenu">

            <form @submit.prevent="openFilters">
                <dropdown-link as="button">
                    Filters
                </dropdown-link>
            </form>
            <form @submit.prevent.stop="deleteEvent">
                <dropdown-link as="button" :close-on-click="false">
                    <span v-if="confirmDelete === event.id" class="text-red-700">Click to Confirm</span>
                    <span v-else-if="deleting === event.id" class="text-red-700">Deleting...</span>
                    <span v-else>Delete</span>
                </dropdown-link>
            </form>

            <div v-if="$route.path !== action.views.edit">
                <horizontal-line/>

                <dropdown-link :to="{path: action.views.edit}">
                    Close
                </dropdown-link>
            </div>


        </template>

        <template #add_event v-if="last">
            <legacy-add-events :action="action"/>
        </template>

    </flow-step>


</template>

<script>
import FlowStep from "../components/FlowStep"
import LegacyAddEvents from "./LegacyAddEvents"
import DropdownLink from "../../../elements/DropdownLink"
import HorizontalLine from "../../../elements/HorizontalLine"
import {defineAsyncComponent} from "vue"


export default {
    name: 'LegacyEvent',

    components: {
        HorizontalLine,
        DropdownLink,
        LegacyAddEvents,
        FlowStep
    },

    mixins: [
        require('./mixins/delete-items')
    ],

    props: {

        showMenu: {
            type: Boolean,
            default: false,
        },

        last: {
            type: Boolean,
            default: false,
        },

        event: {
            type: Object,
        },

        action: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            nameForm: new ParseyForm({
                name: '',
            }),
        }
    },

    methods: {
        updateEventName(name) {
            this.nameForm.name = name
            this.$store.dispatch("flow/saveLegacyEvent", {
                form: this.nameForm,
                event: this.event,
            })
        },

        openFilters() {
            this.$store.dispatch('filters/openFilter', {
                type: 'events',
                typeId: this.event.id,
                property: {
                    type: 'event',
                    name: this.event.name,
                },
                count: this.event.filters,
                filters: this.event.routes.filters.index,
                options: this.event.routes.filters.options,
            })
        },

        deleteEvent() {
            this.deleteItem(this.event.id, 'flow/deleteLegacyEvent', {
                event: this.event,
                path: this.action.views.edit
            })
        }

    },

    computed: {

        eventComponent() {
            let key = _.get(this.event, 'key');

            return defineAsyncComponent(() => import(/* webpackMode: "eager" */`./${this.action.app.key}/${key}`))
        },

        eventIcon() {
            return _.get(this.$store.state.apps, `legacy.${this.action.app.key}.events.${this.event.key}.icon`)
        },
    }
}
</script>
